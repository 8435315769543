<template>
  <div class="central-panel-footer">
    <div class="icons">
      <svg-icon
        className="toggler"
        :iconClass="leftOpened ? 'general_arrow_left' : 'general_arrow_right'"
        size="32px"
        outerSize="48px"
        @click="toggleLeft"
      />
      <slot name="left-control" />
    </div>
    <div class="center">
      <slot name="center-control" />
    </div>
    <div class="icons">
      <slot name="right-control" />
      <svg-icon
        v-if="!hideRightToggler"
        className="toggler"
        :iconClass="rightOpened ? 'general_arrow_right' : 'general_arrow_left'"
        size="28px"
        outerSize="48px"
        @click="toggleRight"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CentralPanelFooter',
  props: {
    leftOpened: {
      type: Boolean,
      default: true,
    },
    rightOpened: {
      type: Boolean,
      default: true,
    },
    hideRightToggler: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleLeft() {
      this.$emit('toggleLeft');
    },
    toggleRight() {
      this.$emit('toggleRight');
    },
    openRight(value) {
      if (this.rightOpened === value) {
        return;
      }
      this.$emit('toggleRight');
    },
  },
  mounted() {
    this.$bus.$on('toggle-control-panel', this.toggleRight);
    this.$bus.$on('set-control-panel-opened', (value) => this.openRight(value));
  },
  beforeDestroy() {
    this.$bus.$off('toggle-control-panel');
    this.$bus.$off('set-control-panel-opened');
  }
};
</script>
<style lang="less" scoped>
.central-panel-footer {
  display: flex;
  align-items: center;
  height: @center-footer-height;
  justify-content: space-between;
  background-color: @bar-background-color;
  border-top: solid rem(1) #000000;
  .controls, .icons {
    display: flex;
  }
  > .icons {
    flex: 1;
    &:last-child {
      justify-content: flex-end;
    }
  }
  > .center {
    flex: 2;
  }
  position: relative;
}

:fullscreen .central-panel-footer {
  left: 50%;
  position: absolute;
  bottom: rem(20);
  width: rem(216);
  margin-left: rem(-108);
}

// In fullscreen mode, we don't need to show panel toggle button anymore.
:fullscreen .icon-container.toggler {
  display: none;
}
</style>
