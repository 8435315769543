import Vue from 'vue';
import Encoder from '@/models/Encoder';
import processDevicePack from '@/utility/processDevicePack';

const mutations = {
  setEncoder(state, encoders) {
    Object.keys(encoders).forEach((key) => {
      Vue.set(state, key, new Encoder(encoders[key], key));
    });
  },
  setEncoderByProps(state, encoders) {
    Object.keys(encoders).forEach((key) => {
      if (state[key] && state[key].options.status !== 'CAM_FILTER') {
        Object.keys(encoders[key]).forEach((prop) => {
          Vue.set(state[key].options, prop, encoders[key][prop]);
        });
      }
    });
  },
  updateWithDevicePack(state, setting) {
    Object.keys(setting).forEach((key) => {
      const devicePackFileName = state[key].options.device_pack_supported;
      if (key === '0') {
        setting[key].uid_video_mode.CurrentOpt = '1080P Full HD (16:9)';
      }
      const updatedSetting = processDevicePack.updateWithDevicePack(devicePackFileName, state[key].index, setting[key]);
      if (updatedSetting) {
        setting[key] = updatedSetting;
      }
      Vue.set(state[key], 'setting', setting[key]);
    });
    state.setting = setting;
  },
  setEncoderSetting(state, setting) {
    Object.keys(setting).forEach((key) => {
      Vue.set(state[key], 'setting', setting[key]);
    });
    state.setting = setting;
  },
  setEncoderSettingByProps(state, setting) {
    Object.keys(setting).forEach((key) => {
      Object.keys(setting[key]).forEach((prop) => {
        Vue.set(state[key].setting, prop, setting[key][prop]);
      });
    });
  },
  setRecordingStream(state, recordingStream) {
    Object.keys(recordingStream).forEach((key) => {
      Vue.set(state[key], 'recording_stream', recordingStream[key].recording_stream);
    });
  },
  setRecordingStatus(state, recordingStatus) {
    Object.keys(recordingStatus).forEach((key) => {
      Vue.set(state[key], 'recording_status', recordingStatus[key]);
    });
  },
  setRecordingStatusByProps(state, recordingStatus) {
    Object.keys(recordingStatus).forEach((key) => {
      Object.keys(recordingStatus[key]).forEach((prop) => {
        let isRec = recordingStatus[key][prop];
        if (prop === 'State') {
          isRec = !!(recordingStatus[key][prop] === 'Recording');
        }
        Vue.set(state[key].recording_status, prop.toLowerCase(), isRec ? 'ON' : 'OFF');
      });
    });
  },
  setRecordingEvent(state, recordingEvent) {
    Object.keys(recordingEvent).forEach((key) => {
      Vue.set(state[key], 'recording_event', recordingEvent[key]);
    });
  },
  setDIDOStatus(state, didoStatus) {
    Object.keys(didoStatus).forEach((key) => {
      if (state[key]) {
        Vue.set(state[key], 'di', didoStatus[key].di);
        Vue.set(state[key], 'do', didoStatus[key].do);
      }
    });
  },
  setDIDOStatusByProps(state, didoStatus) {
    Object.keys(didoStatus).forEach((key) => {
      if (state[key]) {
        if (didoStatus[key].DO) {
          Vue.set(state[key], 'do', didoStatus[key].DO);
        }
        if (didoStatus[key].DI) {
          Vue.set(state[key], 'di', didoStatus[key].DI);
        }
      }
    });
  },
  manualRecodingStart(state, encoderId) {
    state[encoderId].recording_status.manual = 'ON';
  },
  manualRecodingStop(state, encoderId) {
    state[encoderId].recording_status.manual = 'OFF';
  },
  writeSettingDirty(state, config) {
    Object.keys(config).forEach((key) => {
      if (key === 'index') {
        return;
      }
      if (state.dirtyTable[config.index] === undefined) {
        Vue.set(state.dirtyTable, config.index, {});
      }
      Vue.set(state.dirtyTable[config.index], key, state.setting[config.index][key].CurrentOpt);
      state.setting[config.index][key].CurrentOpt = config[key];
    });
  },
};
export default mutations;
