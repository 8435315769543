import mapParamter from './mapParameter';

const getters = {
  encoderSetting(state) {
    return Object.fromEntries(
      Object.keys(state.setting)
        .map((key) => [key, mapParamter(state, key)])
    );
  },
  encoderSettingPTZPreset: (state) => (channel) => Object.entries(state[channel].setting)
    .filter(([key, value]) => key.includes('uid_ptz_preset') && key.includes('name') && value.CurrentOpt !== '')
    .sort((a, b) => {
      const parsePresetId = (uid) => Number(uid[0].split('_')[2].substr(6));
      return parsePresetId(a) - parsePresetId(b);
    }),
  channelCount(state) {
    return Object.keys(state).filter((key) => !isNaN(key)).length;
  },
  availableEncoders(state) {
    return Object.keys(state)
      .filter((key) => !isNaN(key) && (state[key].options.status !== 'CAM_FILTER' && state[key].options.status !== 'CAM_EMPTY'))
      .map((key) => {
        state[key].index = Number(key);
        return state[key];
      });
  },
  smartSearchEncoders(state) {
    return getters.availableEncoders(state).filter((encoder) => encoder.options.motion_cell);
  },
  filteredEncoders(state) {
    return (type, searchText) => {
      const sortByIndex = (a, b) => parseInt(a.index, 10) - parseInt(b.index, 10);
      let encoders = getters.availableEncoders(state).sort(sortByIndex);
      switch (type) {
        case 'online':
          encoders = encoders.filter((encoder) => encoder.isOnline);
          break;
        case 'offline':
          encoders = encoders.filter((encoder) => encoder.isOffline);
          break;
        case 'ptz':
          encoders = encoders.filter((encoder) => encoder.isPTZ);
          break;
        case 'fisheye':
          encoders = encoders.filter((encoder) => encoder.isFisheye);
          break;
        case 'vca':
          encoders = encoders.filter((encoder) => encoder.isVCA);
          break;
        case 'index':
        default:
      }
      if (searchText === '' || !encoders) {
        return encoders;
      }
      return encoders.filter((encoder) => {
        if (encoder.displayName) {
          return encoder.displayName.indexOf(searchText) !== -1;
        }
        return false;
      });
    };
  },
};

export default getters;
