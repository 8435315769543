import axios from 'axios';

function generateParams(path, data) {
  const jsonData = JSON.stringify(data);
  return [
    `path=${encodeURIComponent(path)}`,
    `data=${encodeURIComponent(jsonData)}`,
  ].join('&');
}

export default {
  getSystemConfigure() {
    const configureUrl = `/scripts/configure.js?${new Date().getTime()}`;
    return axios.get(configureUrl)
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
        return Promise.reject();
      });
  },
  getSystemCapability() {
    return axios.post('/fcgi-bin/operator/system.capability')
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
        return Promise.reject();
      });
  },
  getSystemInfo() {
    return axios.post('/fcgi-bin/operator/gconf.query', 'path=/system/software/raphael/system')
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
        return Promise.reject();
      });
  },
  getSystemTime() {
    return axios.post('/fcgi-bin/operator/system.time')
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
        return Promise.reject();
      });
  },
  getRotatePeriod() {
    return axios.post('/fcgi-bin/operator/gconf.query', 'path=/system/software/localdisplay')
      .then((res) => {
        if (res.data?.live) {
          return Promise.resolve(res.data.live.rotate_interval);
        }
        return Promise.reject();
      });
  },
  getTmisManagerInfo() {
    return axios.post('/fcgi-bin/operator/gconf.query', 'path=/system/software/tmis-manager')
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
        return Promise.reject();
      });
  },
  updateSystemInfo(info) {
    const path = '/system/software/raphael/system';
    const params = generateParams(path, info);
    return axios.post('/fcgi-bin/operator/gconf.update', params)
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
        return Promise.reject();
      });
  },
  finishTrendMicroWizard(accept = false) {
    const path = '/system/software/tmis-manager';
    const data = { enable: accept, showTrendMicroWizard: false };
    const params = generateParams(path, data);

    return axios.post('/fcgi-bin/admin/gconf.update', params)
      .then(() => Promise.resolve());
  }
};
