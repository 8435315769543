<template>
  <div class="block-wrap">
    <div class="block-title">
      <h6 class="bold">
        {{ $t('Select time frame') }}
      </h6>
    </div>
    <div class="block-title">
      <h6 class="bold">
        {{ $t('From') }}
      </h6>
    </div>
    <div class="block-content date-time">
      <AppDateTimePicker
        id="search-from"
        data-testid="search-from"
        v-model="startDateTime"
        :pickerDisabled="disabled"
        :isError="validator.hasErrorHighlight('search-from')"
      />
    </div>
    <div class="block-title">
      <h6 class="bold">
        {{ $t('To') }}
      </h6>
    </div>
    <div class="block-content date-time">
      <AppDateTimePicker
        id="search-to"
        data-testid="search-to"
        v-model="endDateTime"
        :pickerDisabled="disabled"
        :isError="validator.hasErrorHighlight('search-to')"
        :errors="searchToErrors"
      />
    </div>
  </div>
</template>

<script>
import DateRangeValidation from '@/utility/dateRangeValidation';
import AppDateTimePicker from '@/components/ui/AppDateTimePicker.vue';

export default {
  name: 'SelectTimeFrame',
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
  },
  components: {
    AppDateTimePicker
  },
  data() {
    return {
      validator: new DateRangeValidation({}),
    };
  },
  methods: {
    validateDateTimeRange() {
      const dateTimeIdList = ['search-from', 'search-to'];

      dateTimeIdList.forEach((id) => {
        this.validator.validateRange(
          this.startDateTime,
          this.endDateTime,
          id,
          false
        );
      });
    },
  },
  computed: {
    startDateTime: {
      get() {
        return this.value.startDateTime;
      },
      set(value) {
        this.$emit('input', {
          startDateTime: value,
          endDateTime: this.value.endDateTime
        });
      }
    },
    endDateTime: {
      get() {
        return this.value.endDateTime;
      },
      set(value) {
        this.$emit('input', {
          startDateTime: this.value.startDateTime,
          endDateTime: value,
        });
      }
    },
    hasError() {
      return this.validator.errors.length !== 0;
    },
    searchToErrors() {
      return this.validator.getErrors('search-to').map((error) => ({
        message: this.$t(error.message),
        id: error.id,
        type: error.type
      }));
    }
  },
  watch: {
    startDateTime() {
      this.validateDateTimeRange();
    },
    endDateTime() {
      this.validateDateTimeRange();
    },
    hasError(value) {
      this.$emit('hasError', value);
    }
  },
};
</script>

<style lang="less" scoped>
.block-wrap{
  position: relative;
  z-index: 3;
}
.block-content {
  margin-bottom: 25px;
  &.date-time {
    display: flex;
    > div {
      &:first-child {
        margin-right: rem(10);
      }
    }
  }
  ::v-deep.app-time-picker,
  ::v-deep.date-input-wrapper {
    width: rem(123);
  }
}
</style>
