export default function generateWebglContext(canvas) {
  let gl = null;
  try {
    gl = canvas.getContext('webgl', { preserveDrawingBuffer: true });
    gl.viewportWidth = canvas.width;
    gl.viewportHeight = canvas.height;
    gl.enable(gl.DEPTH_TEST);
    // eslint-disable-next-line no-bitwise
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
  } catch (_) {
    try {
      gl = canvas.getContext('experimental-webgl', { preserveDrawingBuffer: true });
      gl.viewportWidth = canvas.width;
      gl.viewportHeight = canvas.height;
    } catch (e) {
      console.warn('initGL failed, error', e);
    }
  }
  return gl;
}
