import axios from 'axios';

const actions = {
  fetch({ commit, dispatch }) {
    return dispatch('fetchEncoder').then(() => dispatch('fetchSetting')).then(() => {
      dispatch('fetchDIDOStatus').catch((err) => {
        // eslint-disable-next-line no-console
        console.warn(err);
      });
      dispatch('fetchRecordingStream').catch((err) => {
        // eslint-disable-next-line no-console
        console.warn(err);
      });
      dispatch('fetchRecordingStatus').catch((err) => {
        // eslint-disable-next-line no-console
        console.warn(err);
      });
      dispatch('fetchRecordingEvent').catch((err) => {
        // eslint-disable-next-line no-console
        console.warn(err);
      });
    });
  },

  fetchEncoder({ commit, dispatch }) {
    return axios.post(
      '/fcgi-bin/operator/gconf.query', 'path=/system/software/meteor/encoder',
    ).then((res) => {
      commit('setEncoder', res.data);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
    });
  },

  fetchEncoderByIndex({ commit, dispatch }, encoderId) {
    return axios.post(
      '/fcgi-bin/operator/gconf.query', `path=/system/software/meteor/encoder/${encoderId}`,
    ).then((res) => {
      const data = { [encoderId]: res.data };
      commit('setEncoderByProps', data);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
    });
  },

  fetchSetting({ commit, dispatch }) {
    return axios.post(
      '/fcgi-bin/operator/gconf.query', 'path=/system/software/meteor/uisetting',
    ).then((res) => {
      commit('setEncoderSetting', res.data);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
    });
  },

  fetchSettingByIndex({ commit, dispatch }, encoderId) {
    return axios.post(
      '/fcgi-bin/operator/gconf.query', `path=/system/software/meteor/uisetting/${encoderId}`,
    ).then((res) => {
      const data = { [encoderId]: res.data };
      commit('setEncoderSettingByProps', data);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
    });
  },

  fetchRecordingStatus({ commit, dispatch }) {
    return axios.post(
      '/fcgi-bin/operator/gconf.query', 'path=/system/software/mars-rec/status',
    ).then((res) => {
      commit('setRecordingStatus', res.data);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
    });
  },

  fetchRecordingEvent({ commit, dispatch }) {
    return axios.post(
      '/fcgi-bin/operator/gconf.query', 'path=/system/software/mars-rec/event',
    ).then((res) => {
      commit('setRecordingEvent', res.data);
    }).catch((err) => {
      // eslint-disable-next-line no-console
    });
  },

  fetchRecordingStream({ commit, dispatch }) {
    return axios.post(
      '/fcgi-bin/operator/gconf.query', 'path=/system/software/mars-rec/recording_stream',
    ).then((res) => {
      commit('setRecordingStream', res.data);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
    });
  },

  fetchDIDOStatus({ commit, dispatch }) {
    return axios.post(
      '/fcgi-bin/operator/gconf.query', 'path=/system/software/harute/status',
    ).then((res) => {
      commit('setDIDOStatus', res.data);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
    });
  },

  toggleDigitalOutput({ commit, state }, { encoderId, index }) {
    const status = state[encoderId].do[index];

    return axios.post(
      '/fcgi-bin/operator/camera.do', `ch=${encoderId}&idx=${index}&opt=${status}`
    ).then((res) => {
      // commit('manualRecodingStop', status);
    }).catch((err) => {
    // eslint-disable-next-line no-console
      console.warn(err);
    });
  },

  toggleManualRecord({ commit, state, rootState }, encoderId) {
    const status = state[encoderId].recording_status.manual === 'ON';

    if (status) {
      return axios.post(
        '/fcgi-bin/operator/recording.stop', `channel=${encoderId}&username=${rootState.user.userName}`
      ).then((res) => {
        commit('manualRecodingStop', encoderId);
      }).catch((err) => {
      // eslint-disable-next-line no-console
        console.warn(err);
      });
    }
    return axios.post(
      '/fcgi-bin/operator/recording.start', `channel=${encoderId}&username=${rootState.user.userName}`
    ).then((res) => {
      commit('manualRecodingStart', encoderId);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
    });
  },
};
export default actions;
