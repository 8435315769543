import LocalTime from '@/utility/local_time';

export default class clientNotifications {
  constructor(info, timezone, encoders) {
    info.alarm_act = JSON.parse(info.alarm_act);
    this.options = info;
    this.options.timezone = timezone;

    this.options.time = new LocalTime({
      timestamp: this.timestamp,
      tzoffs: this.options.timezone,
    });

    const cameraName = encoders.find((item) => item.index === this.channel)?.name || '';
    this.options.device_name = this.channel === -1 ? 'system' : cameraName;
    this.encoders = encoders;
  }

  get timestamp() {
    return this.options.us_timestamp / 1000;
  }

  get notificationId() {
    return this.options.notificationID || 0;
  }

  get snapshotChannel() {
    let snapshotChannel = this.options.alarm_source;
    if (this.options.alarm_source === -1 && this.options.alarm_act?.type === 'recording') {
      const avalibleEncodersId = this.encoders.map((encoder) => encoder.index);
      const snapshotChannels = this.options.alarm_act?.data?.channels.filter(
        (channel) => avalibleEncodersId.indexOf(channel) !== -1);
      snapshotChannel = snapshotChannels.length > 0 ? Math.min(...snapshotChannels) : -1;
    }

    return snapshotChannel;
  }

  get channel() {
    return this.options.alarm_source;
  }

  get title() {
    return this.options.alarm_name;
  }

  get isRead() {
    return !!this.options.is_read;
  }

  set isRead(bool) {
    this.options.is_read = bool;
  }

  get triggerType() {
    return this.options.alarm_event_type;
  }

  get time() {
    return `${this.options.time.queryDate}T${this.options.time.queryTime}`;
  }

  get displayFullTime() {
    return this.options.time.displayFullTime;
  }

  get deviceName() {
    return this.options.device_name;
  }
}
