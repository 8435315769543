import VAOMCore from "./VAOM.mjs";

export default class BoundingBoxManager {
  constructor(reference) {
    this.reference = reference;
    this.shapes = [];
    this.core = new VAOMCore(this);
  }

  drawIfNecessary(canvas, ctx) {
    this.core.draw(
      this.reference.displayConfig.drawMetadataName ||
      this.reference.displayConfig.showPosition ||
      this.reference.displayConfig.showTrackingBlock ||
      this.reference.displayConfig.showObjectHeight,
      ctx,
      canvas.width,
      canvas.height,
      this.reference.displayConfig.drawMetadataName,
      this.reference.displayConfig.showPosition,
      this.reference.displayConfig.showTrackingBlock,
      this.reference.displayConfig.showObjectHeight,
    );
  }
  
  clear() {
    this.core.clear(this);
  }

  // called by event from this.shapes
  loitering() {
    this.reference.requestPaint();
  }

  update(shapes) {
    this.core.updateShapes(shapes);
  }
}
