/* eslint-disable no-bitwise */
/* eslint-disable no-console */
import NALU from '../model/NALU';
import NALU_TYPE from '../model/NaluType';

class NaluParser {
  constructor() {
    this.fragmentedNalu = null;
  }

  parseFragment(fragment) {
    const { rawData } = fragment;
    const data = new DataView(rawData.buffer, rawData.byteOffset, rawData.byteLength);
    const header = this.parseNALHeader(data.getUint8(0));

    const nalStartIndex = 1;
    const unit = {
      ...fragment,
      header,
      rawData: rawData.subarray(nalStartIndex),
    };

    if (header.type > 0 && header.type < 24) {
      return this.parseSingleNALUPacket(unit);
    }

    if (NALU_TYPE.TYPE.FU_A === header.type || NALU_TYPE.TYPE.FU_B === header.type) {
      return this.parseFragmentationUnit(unit);
    }

    /* 30 - 31 is undefined, ignore those (RFC3984). */
    console.log('ignore', header.type);
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  parseNALHeader(header) {
    return {
      nri: header & 0x60,
      type: header & 0x1F,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  parseSingleNALUPacket({
    rawData, header, dts, applicationData
  }) {
    if (this.fragmentedNalu) {
      console.warn('previous fragment is not completed');
      this.fragmentedNalu = null;
    }
    return new NALU({
      type: header.type,
      nri: header.nri,
      data: rawData.subarray(0),
      dts,
      applicationData
    });
  }

  parseFragmentationUnit({
    rawData, header, dts, applicationData
  }) {
    const data = new DataView(rawData.buffer, rawData.byteOffset, rawData.byteLength);
    let nalStartIndex = 0;
    const fuHeader = data.getUint8(nalStartIndex);
    const isStart = (fuHeader & 0x80) >>> 7;
    const isEnd = (fuHeader & 0x40) >>> 6;
    const payloadType = fuHeader & 0x1F;
    let fragment = null;

    nalStartIndex += 1;
    if (NALU_TYPE.TYPE.FU_B === header.type) {
      nalStartIndex += 2;
    }

    if (isStart) {
      if (this.fragmentedNalu) {
        console.warn('previous fregment is not completed');
        this.fragmentedNalu = null;
      }
      this.fragmentedNalu = new NALU({
        type: payloadType,
        nri: header.nri,
        data: rawData.subarray(nalStartIndex),
        dts,
        applicationData
      });
    }

    if (this.fragmentedNalu
        && this.fragmentedNalu.type === payloadType
        && this.fragmentedNalu.dts === dts) {
      if (!isStart) {
        this.fragmentedNalu.appendData(rawData.subarray(nalStartIndex));
      }
      if (isEnd) {
        fragment = this.fragmentedNalu;
        this.fragmentedNalu = null;
      }
    }
    return fragment;
  }
}

export default NaluParser;
