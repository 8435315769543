const snapshotsMap = new Map();

export default {
  getObserver(root) {
    const callback = (entries, observer) => {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }
        observer.unobserve(target);
        setTimeout(() => {
          const snapshotInstance = snapshotsMap.get(target);
          if (snapshotInstance) {
            snapshotInstance.tryToGetSnapshot();
            snapshotsMap.delete(target);
          }
        }, 100);
      });
    };
    const observer = new IntersectionObserver(
      callback, {
        root,
        threshold: 0.2,
      }
    );
    return {
      observe: (instance) => {
        snapshotsMap.set(instance.$el, instance);
        observer.observe(instance.$el);
      },
      unobserve: (instance) => {
        observer.unobserve(instance.$el);
        snapshotsMap.delete(instance.$el);
      },
      disconnect: () => {
        observer.disconnect();
      }
    };
  },
};
