import Vue from 'vue';

import Viewcell from '@/models/Viewcell';
import DEFAULT_PLAYBACK_VIEWCELL from '@/constants/defaultPlaybackViewCell';
import { SYSTEM_TRIGGERS, CAMERA_TRIGGERS } from '@/constants/triggers';
import keywordFilterRules from '@/utility/keywordFilterRules';

const theGetters = {
  alarmList(state) {
    const groupValues = [];

    Object.values(state.alarm.alarms).forEach((item, index) => {
      if (!item.name) {
        return;
      }

      groupValues.push({
        text: item.name,
        value: item.name,
        id: index
      });
    });

    return [
      {
        groupLabel: Vue.prototype.$t('Alarm list'),
        groupValues
      }
    ];
  },
  filteredDataLength(state, getters, rootState, rootGetters) {
    const { currentType } = state;

    return keywordFilterRules.searchKeywordFilter({
      currentType,
      keyword: state[currentType].keyword,
      rowData: state[currentType].result,
      timezone: rootGetters['system/systemTimezone']
    }).length;
  },
  filteredExportLength(state, getters, rootState, rootGetters) {
    const { currentType } = state;

    return keywordFilterRules.searchKeywordFilter({
      currentType,
      keyword: state[currentType].keyword,
      rowData: state[currentType].export,
      timezone: rootGetters['system/systemTimezone'],
      isExportSearch: true
    }).length;
  },
  pageSize(state, getters) {
    const { currentType } = state;
    const pageSize = Math.ceil(getters.filteredDataLength / state[currentType].MAX_PAGE_COUNT);

    return pageSize === 0 ? 1 : pageSize;
  },
  currentPageIndex(state) {
    const { currentType } = state;
    return state[currentType].currentPageIndex;
  },
  videoMap(state) {
    return state[state.currentType].result.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});
  },
  activeVideo(state, getters) {
    return getters.videoMap[state[state.currentType].activeVideoId];
  },
  nextPlayableChannel(state) {
    const current = state[state.currentType].playList
      .findIndex((viewcell) => viewcell.channel === state[state.currentType].playingChannel);
    if (current + 1 > state[state.currentType].playList.length - 1) {
      return -1;
    }
    return state[state.currentType].playList[current + 1].channel;
  },
  activeView(state) {
    const { currentType } = state;
    if (currentType === 'tmis') {
      return null;
    }

    if (state[currentType].playingChannel === -1 || !state[currentType].playList.length) {
      return new Viewcell({
        ...JSON.parse(JSON.stringify(DEFAULT_PLAYBACK_VIEWCELL))
      });
    }

    return state[currentType].playList
      .find((viewcell) => viewcell.channel === state[currentType].playingChannel);
  },
  nextPlayableVideoId(state) {
    const { currentType } = state;
    const { activeVideoId } = state[currentType];

    if (activeVideoId === -1) {
      let playable;
      if (currentType === 'alarm' || currentType === 'bookmark') {
        playable = state[currentType].result.find(
          (item) => item.relation_cameras.length !== 0 && item.recording_data
        );
      }
      if (currentType === 'vca') {
        playable = state[currentType].result.find(
          (item) => item.id !== -1
        );
      }
      return playable ? playable.id : -1;
    }

    // XXX: Need to sort() alarmList before doing this.
    const currentIndex = state[currentType].result.findIndex(
      (item) => item.id === activeVideoId
    );

    const parseResults = (index, item) => index > currentIndex
                                          && item.relation_cameras.length !== 0
                                          && item.recording_data !== 0;
    const rulesMap = {
      alarm: parseResults,
      bookmark: parseResults,
      vca: (index, item) => activeVideoId !== item.id && index > currentIndex
    };

    const next = state[currentType].result.find(
      (item, index) => rulesMap[currentType](index, item)
    );

    return next ? next.id : -1;
  },
  exportClipList(state) {
    const exportClips = [];
    state.exportDataList.forEach((event) => {
      if (event.recording_info.length === 0) {
        return;
      }
      event.recording_info.forEach((encoder) => {
        const { post_event, pre_event } = encoder.recording_event;
        const recordingType = `${encoder.recording_stream === 0 ? 'main' : 'sub'}_stream`;

        exportClips.push({
          encoderId: encoder.index,
          begin: Math.round((event.start / 1000)) - pre_event * 1000,
          length: (post_event + pre_event),
          recordingType
        });
      });
    });

    return exportClips;
  },
  playList(state) {
    return state[state.currentType].playList;
  },
  playingChannel(state) {
    return state[state.currentType].playingChannel;
  },
  currentResult(state) {
    return state[state.currentType].result;
  },
  triggerOptions(state, getters, rootState, rootGetters) {
    const supportMaping = {
      support_nvr_brute_force_attack_event: 'nvr_brute_force_attk',
      support_nvr_cyber_attack_event: 'nvr_cyber_attk',
      support_nvr_di_event: 'nvr_di',
      support_nvr_disk_error_event: 'disk_error',
      support_nvr_do_event: 'nvr_do',
      support_nvr_fan_event: 'fan_error',
      support_nvr_g_sensor_event: 'g_sensor',
      support_nvr_gps_disconnect_event: 'gps_lost',
      support_nvr_gps_overspeed_event: 'gps_overspeed',
      support_nvr_netlink_down_event: 'netlink_down',
      support_nvr_poe_event: 'poe_event',
      support_nvr_quarantine_event_event: 'nvr_quarantine_evt',
    };
    const systemTmisTrigger = ['nvr_brute_force_attk', 'nvr_cyber_attk', 'nvr_quarantine_evt'];
    const camTmisTrigger = ['cam_brute_force_attk', 'cam_cyber_attk', 'cam_quarantine_evt'];
    const isSupportTmis = rootGetters['system/systemCapability'].support_tmis;

    const systemCrowdControlTrigger = ['caution_occupancy_reached', 'full_occupancy_reached', 'full_occupancy_clear'];
    const isSupportCrowdControl = rootGetters['system/systemCapability'].support_crowd_control;

    const isSCOO = rootState.system.info.brand === 'SCOO';

    let unsupportedTriggers = Object.keys(rootState.system.capability).reduce((init, item) => {
      if (supportMaping[item] && !rootState.system.capability[item]) {
        init.push(supportMaping[item]);
      }
      return init;
    }, []);

    if (!isSupportTmis) {
      unsupportedTriggers = unsupportedTriggers.concat(systemTmisTrigger);
    }

    if (!isSupportCrowdControl) {
      unsupportedTriggers = unsupportedTriggers.concat(systemCrowdControlTrigger);
    }

    if (isSCOO) {
      unsupportedTriggers = unsupportedTriggers.concat(supportMaping['support_nvr_poe_event'])
    }

    const systemTrigger = Object.keys(SYSTEM_TRIGGERS).reduce((init, item) => {
      if (unsupportedTriggers.indexOf(item) === -1) {
        init.push({
          text: Vue.prototype.$t(SYSTEM_TRIGGERS[item]),
          value: item
        });
      }
      return init;
    }, []);

    const scooUnsupportCameraTrigger = ['cam_running_detct', 'cam_restricted_zone_detct', 'cam_parking_violation_detct', 'cam_smart_tracking_detct'];

    const cameraTrigger = Object.keys(CAMERA_TRIGGERS).reduce((init, item) => {
      if (isSupportTmis || camTmisTrigger.indexOf(item) === -1) {
        if (isSCOO && scooUnsupportCameraTrigger.indexOf(item) >= 0) {
            return init;
        }
        init.push({
          text: Vue.prototype.$t(CAMERA_TRIGGERS[item]),
          value: item
        });
      }
      return init;
    }, []);

    return [
      {
        groupLabel: Vue.prototype.$t('System trigger'),
        groupValues: systemTrigger
      },
      {
        groupLabel: Vue.prototype.$t('Camera trigger'),
        groupValues: cameraTrigger
      },
    ];
  },
  isCriteriaError(state, getters) {
    const {
      device, alarm, triggers, vcaEventType, tmisEventType
    } = state[state.currentType].criteria;

    const typeMap = {
      alarm: () => (device.length === 0
        || getters.alarmList[0].groupValues.length === 0 ? false : alarm.length === 0
        || triggers.length === 0),
      bookmark: () => device.length === 0,
      vca: () => device.length === 0 || vcaEventType.length === 0,
      tmis: () => device.length === 0 || tmisEventType.length === 0,
    };

    return typeMap[state.currentType]();
  },
  isExportingError(state) {
    return state.exportStatus === 'ERROR';
  },
  isExporting(state) {
    return state.exportStatus === 'EXPORTING';
  },
  hasSearchedResult(state, getters) {
    return getters.currentResult.length > 0;
  },
  isSearching(state) {
    return state.status === 'loading';
  }
};
export default theGetters;
