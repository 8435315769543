import English from '@/i18n/languages/English.json';
import French from '@/i18n/languages/French.json';
import German from '@/i18n/languages/German.json';
import Italian from '@/i18n/languages/Italian.json';
import Japanese from '@/i18n/languages/Japanese.json';
import Portuguese from '@/i18n/languages/Portuguese.json';
import Russian from '@/i18n/languages/Russian.json';
import SimpleChinese from '@/i18n/languages/SimpleChinese.json';
import Spanish from '@/i18n/languages/Spanish.json';
import TradChinese from '@/i18n/languages/TradChinese.json';
import Czech from '@/i18n/languages/Czech.json';
import Korean from '@/i18n/languages/Korean.json';
import Polish from '@/i18n/languages/Polish.json';
import Arabic from '@/i18n/languages/Arabic.json';

const DEFAULT_LANG_LIST_MAP = {
  English: {
    name: 'English',
    file: English
  },
  German: {
    name: 'Deutsch',
    file: German
  },
  Spanish: {
    name: 'Español',
    file: Spanish
  },
  French: {
    name: 'Français',
    file: French
  },
  Italian: {
    name: 'Italiano',
    file: Italian
  },
  Japanese: {
    name: '日本語',
    file: Japanese
  },
  Portuguese: {
    name: 'Português',
    file: Portuguese
  },
  SimpleChinese: {
    name: '简体中文',
    file: SimpleChinese
  },
  TradChinese: {
    name: '繁體中文',
    file: TradChinese
  },
  Czech: {
    name: 'Čeština',
    file: Czech,
  },
  Russian: {
    name: 'Русский',
    file: Russian
  },
  Korean: {
    name: '한국어',
    file: Korean
  },
  Polish: {
    name: 'Polski',
    file: Polish
  },
  Arabic: {
    name: 'عربى',
    file: Arabic
  }
};
export default DEFAULT_LANG_LIST_MAP;
