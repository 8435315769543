import { ab2str, str2ab, str2dataStr } from '@vivotek/lib-utility/convert_arraybuffer_string';
import { v4 as uuidv4 } from 'uuid';

function RTCHttpProtocol(channel) {
  const responseCallback = {};
  const requestQueue = {};
  let unfinishedResponse = null;
  const accepts = {
    '*': '*/*',
    text: 'text/plain',
    html: 'text/html',
    xml: 'application/xml, text/xml',
    json: 'application/json, text/javascript',
    image: 'image/*'
  };

  function extractDomain(url) {
    let domain;
    // find & remove protocol (http, ftp, etc.) and get domain
    if (url.indexOf('://') > -1) {
      [,, domain] = url.split('/');
    } else {
      [domain] = url.split('/');
    }

    // find & remove port number
    [domain] = domain.split(':');

    return domain;
  }

  this.channel = channel;

  channel.on('message', (message) => {
    const data = ab2str(message.data);
    let resStatus; let resContentType; let resContentLength; let resIsChunked; let
      resContent;
    let messageId;

    const rResStatus = /^HTTP\/[\d.]+\s(\d{3})\s[\w]+/;
    const rResContent = /\r\n\r\n((.|\n|\r)*)/;
    const rMessageId = /Set-Cookie:\s*message_id=([\w\d-]+)/;
    const rContentType = /Content-Type:\s*([\w\d/]+)/;
    const rContentLength = /Content-Length:\s*([\d]+)/;
    const rChunked = /Transfer-Encoding:\s*chunked/;

    resStatus = rResStatus.test(data)
      ? data.match(rResStatus)[1] : undefined;

    if (resStatus !== undefined) {
      resContentType = rContentType.test(data)
        ? data.match(rContentType)[1] : undefined;
      resContentLength = rContentLength.test(data)
        ? Number(data.match(rContentLength)[1]) : undefined;
      resIsChunked = rChunked.test(data);
      resContent = (data.match(rResContent)[0]).replace(/^\r\n\r\n/, '');
      messageId = data.match(rMessageId)[1].toUpperCase();

      // fail response process
      if (unfinishedResponse) {
        if (responseCallback[unfinishedResponse.messageId]) {
          responseCallback[unfinishedResponse.messageId](500, 'response not complete');
        }
        // clear unfinishedResponse
        unfinishedResponse = null;
      }

      // check Content-Length
      if (resContentLength && resContent.length < resContentLength) {
        unfinishedResponse = {
          status: resStatus,
          messageId,
          contentLength: resContentLength,
          content: resContent
        };
        return;
      }
      // check Transfer-Encoding is chunked and Content-Type is application/json
      if (resIsChunked && resContentType === 'application/json') {
        try {
          JSON.parse(resContent);
        } catch (e) {
          unfinishedResponse = {
            status: resStatus,
            chunked: resIsChunked,
            messageId,
            content: resContent
          };
          return;
        }
      }
    } else if (unfinishedResponse) {
      resStatus = unfinishedResponse.status;
      resContent = unfinishedResponse.content + data;
      messageId = unfinishedResponse.messageId;

      if (unfinishedResponse.chunked) {
        try {
          JSON.parse(resContent);
        } catch (e) {
          unfinishedResponse.content = resContent;
          return;
        }
      } else if (resContent.length < unfinishedResponse.contentLength) {
        unfinishedResponse.content = resContent;
        return;
      }

      unfinishedResponse = null;
    }

    if (responseCallback[messageId]) {
      responseCallback[messageId](Number(resStatus), resContent);
    }
  });
  this.serverLoading = 15; // server max loading of request during 1s
  this.send = function send(options) {
    const messageId = uuidv4();
    const method = options.method || options.type || 'GET';
    const url = options.url || '/';
    const dataType = options.dataType || '*';
    const timeout = options.timeout || 90; // sec
    const data = options.data ? options.data : '';
    const self = this;
    const httpMsg = [
      [method.toUpperCase(), url, 'HTTP/1.1'].join(' '),
      `Host: ${extractDomain(window.location.href)}`,
      `Accept: ${accepts[dataType] ? accepts[dataType] : accepts['*']}`,
      'Connection:keep-alive',
      `Content-Length:${data.length}`,
      'Content-Type: application/x-www-form-urlencoded; charset=UTF-8',
      `Cookie: message_id=${messageId}`,
      '',
      data
    ].join('\r\n');

    return new Promise((resolve, reject) => {
      requestQueue[messageId] = str2ab(httpMsg);
      responseCallback[messageId] = function callback(status, res) {
        let response = res;
        if (status === 200) {
          switch (dataType) {
            case 'image':
              response = new Blob([str2ab(res)]);
              break;
            case 'json':
              try {
                response = JSON.parse(str2dataStr(res));
              } catch (e) {
                reject(e);
                return;
              }
              break;
            default:
              response = str2dataStr(res);
          }
          if (options.success && typeof options.success === 'function') {
            options.success(response);
          }
          resolve(response);
        } else {
          if (options.fail && typeof options.fail === 'function') {
            options.fail(response);
          }
          reject(response);
        }

        // clear request timeout
        if (responseCallback[messageId].timeout) {
          clearTimeout(responseCallback[messageId].timeout);
        }
        delete responseCallback[messageId];
      };
      // prevent to send too many requests at the same time
      // eslint-disable-next-line no-shadow
      setTimeout((messageId, data) => {
        if (self.channel.readyState === 'open') {
          self.channel.send(data);
          // send request and set timeout if no matched response
          responseCallback[messageId].timeout = setTimeout(() => {
            console.error('Request Timeout');
            reject(new Error('Request Timeout'));
            delete responseCallback[messageId];
          }, timeout * 1000);
        }

        delete requestQueue[messageId];
      }, (1000 / self.serverLoading) * Object.keys(requestQueue).length, messageId, requestQueue[messageId]);
    });
  };
}

export default RTCHttpProtocol;
