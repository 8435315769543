// This class simulate Qt timer, such as
// Qt.createQmlObject("import QtQml 2.2; Timer { interval: 1500; }", canvas);

export default class SimulateQtTimer {
  constructor({
    interval = 1000,
  } = {}) {
    function noop() {};

    this.interval = interval;
    this.timeoutTicket = undefined;
    this.callback = noop;

    this.triggered = {
      connect: (callback = noop) => { this.callback = callback; },
    };

    this.start();
  }

  start() {
    if (this.timeoutTicket) {
      this.restart();
    } else {
      this.timeoutTicket = setTimeout(this.callback, this.interval);
    }
  }

  stop() {
    if (!this.timeoutTicket) {
      return;
    }

    this.timeoutTicket = clearTimeout(this.timeoutTicket);
  }

  restart() {
    this.stop();
    this.start();
  }

  destroy() {
    this.stop();
  }
}
