<template>
  <div class="layout-list">
    <div
      class="layout-item"
      :class="{ active: key === layout, disabled: disabledLayouts.indexOf(key) >= 0 }"
      v-show="hiddenLayouts.indexOf(key) === -1"
      v-for="(v, key) in LAYOUT_COUNT"
      :key="key"
      @click="select(key)"
    >
      <svg-icon
        :iconClass="`layout_${key.toLowerCase()}`"
        :disabled="disabledLayouts.indexOf(key) >= 0"
        size="60px"
      />
      <div><h6>{{ key }}</h6></div>
    </div>
  </div>
</template>

<script>
import LAYOUT_COUNT from '@/constants/layoutCount';

export default {
  name: 'LayoutSwitch',
  props: {
    value: {
      type: String,
      default: '2x2',
    },
    disabledLayouts: {
      type: Array,
      default: () => [],
    },
    hiddenLayouts: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      LAYOUT_COUNT,
      layout: this.value,
    };
  },
  computed: {
    options() {
      return Object.keys(this.LAYOUT_COUNT).map((v) => ({
        text: v,
        value: v,
      }));
    },
  },
  methods: {
    select(value) {
      this.layout = value;
    },
  },
  mounted() {
  }
};
</script>

<style lang="less" scoped>
.layout-list {
  display: flex;
  flex-wrap: wrap;
  .layout-item {
    width: rem(122);
    height: rem(89);
    padding-top: rem(16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: rem(1) solid transparent;
    > div {
      height: rem(40);
      line-height: rem(40);
      user-select: none;
      margin-top: rem(6);
    }
    &:hover, &:active, &.active, &:active {
      border: rem(1) solid @general-hover-background-color;
      background-color: @general-hover-background-color;
      > svg {
        color: #fff;
      }
    }
    &.active {
      border: rem(1) solid @general-active-color;
    }
    &.disabled {
      pointer-events: none;
      > div, > svg {
        color: #4F4F4F;
      }
    }
  }
}
</style>
