import { unpack, access, isNumber, requestPaint, unpackBits, base64Decode } from './utility.mjs';
import Shapes from './shapes.mjs';

export default class ProjectionManager {
  constructor(reference, initialProjection = null) {
    this.reference = reference;
    this.shapes = [];
    this.initialProjectionConfig = initialProjection;
    if (initialProjection) {
      this.update(new Shapes.Project(initialProjection));
    }
  }

  drawIfNecessary() {}

  update(shape) {
    this.shapes = [shape];
  }

  get projection() {
    return this.shapes.length ? this.shapes[0] : undefined;
  }

  updateInitialProjection(data) {
    if (!data) {
      this.shapes = [];
      return;
    }
    this.initialProjectionConfig = data;
    this.shapes = [new Shapes.Project(data)];
  }
}
