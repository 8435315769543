import DEFAULT_VIEWCELL from '@/constants/defaultViewCell';

const DEFAULT_PLAYBACK_VIEWCELL = {
  ...DEFAULT_VIEWCELL,
  timestamp: 0,
  begin: 0,
  end: 86400,
  tzoffs: 0,
  currentTime: 0,
  clipMode: false, // Clip mode: play until the end of option is met. Otherwise, keep playing.
};

export default DEFAULT_PLAYBACK_VIEWCELL;
