<template>
  <div
    class="app-time-picker"
    ref="wrapper"
    :class="{error: isError}"
  >
    <input
      :id="hourInputId"
      class="app-time-picker-input"
      type="text"
      maxlength="2"
      v-model.lazy="formattedHour"
      @focus="selectInput"
      @blur="handleBlur('checkHourInput', $event)"
      data-current-input="hour"
      autocomplete="off"
      :disabled="disabled"
    >
    :
    <input
      :id="minuteInputId"
      class="app-time-picker-input"
      type="text"
      v-model.lazy="formattedMinute"
      maxlength="2"
      @focus="selectInput"
      @blur="handleBlur('checkMinuteInput', $event)"
      data-current-input="minute"
      autocomplete="off"
      :disabled="disabled"
    >
    :
    <input
      :id="secondInputId"
      class="app-time-picker-input"
      type="text"
      v-model.lazy="formattedSecond"
      maxlength="2"
      @focus="selectInput"
      @blur="handleBlur('checkSecondInput', $event)"
      data-current-input="second"
      autocomplete="off"
      :disabled="disabled"
    >
  </div>
</template>

<script>
export default {
  name: 'AppTimePicker',
  props: {
    id: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: String
    },
    showFormat: {
      type: Boolean,
      default: true
    },
    isError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      hour: this.value.split(':')[0],
      minute: this.value.split(':')[1],
      second: this.value.split(':')[2],
      currentInput: null,
      backup: this.value
    };
  },
  computed: {
    hourInputId() {
      return `hour-input-${this.id}`;
    },
    minuteInputId() {
      return `minute-input-${this.id}`;
    },
    secondInputId() {
      return `second-input-${this.id}`;
    },
    formattedHour: {
      get() {
        return `00${this.hour}`.substr(-2);
      },
      set(v) {
        if (!this.checkInputTypeIsNumber(v)) {
          return;
        }
        this.setHour(v);
      }
    },
    formattedMinute: {
      get() {
        return `00${this.minute}`.substr(-2);
      },
      set(v) {
        if (!this.checkInputTypeIsNumber(v)) {
          return;
        }
        this.setMinute(v);
      }
    },
    formattedSecond: {
      get() {
        return `00${this.second}`.substr(-2);
      },
      set(v) {
        if (!this.checkInputTypeIsNumber(v)) {
          return;
        }
        this.setSecond(v);
      }
    },
    backupHour() {
      const h = this.backup.split(':')[0];
      if (Number(h) > 0 && Number(h) < 24) {
        return h;
      }
      return '00';
    },
    backupMinute() {
      const m = this.backup.split(':')[1];
      if (Number(m) > 0 && Number(m) < 60) {
        return m;
      }
      return '00';
    },
    backupSecond() {
      const s = this.backup.split(':')[2];
      if (Number(s) > 0 && Number(s) < 60) {
        return s;
      }
      return '00';
    }
  },
  methods: {
    emitTimeResult() {
      // v-model.lazy fire change event
      this.$emit('change', `${this.formattedHour}:${this.formattedMinute}:${this.formattedSecond}`);
      this.backup = `${this.formattedHour}:${this.formattedMinute}:${this.formattedSecond}`;
    },
    handleBlur(type, ev) {
      this[type](ev);
    },
    checkHourInput(ev) {
      let { value } = ev.target;

      if (!this.checkInputTypeIsNumber(value)) {
        value = this.hour;
      }
      this.setHour(value);
    },
    checkMinuteInput(ev) {
      let { value } = ev.target;

      if (!this.checkInputTypeIsNumber(value)) {
        value = this.minute;
      }
      this.setMinute(value);
    },
    checkSecondInput(ev) {
      let { value } = ev.target;
      if (!this.checkInputTypeIsNumber(value)) {
        value = this.second;
      }
      this.setSecond(value);
    },
    checkInputTypeIsNumber(value) {
      return !Number.isNaN(Number(value));
    },
    $_setHour(number) {
      if (this.hour.length === 2) {
        this.hour = '';
      }
      this.hour += number;

      if (Number(this.hour) > 23) {
        this.hour = this.backupHour;
      }
    },
    setHour(number) {
      this.$_setHour(number);
      this.emitTimeResult();
    },
    $_setMinute(number) {
      if (this.minute.length === 2) {
        this.minute = '';
      }
      this.minute += number;

      if (Number(this.minute) > 59) {
        this.minute = this.backupMinute;
      }
    },
    setMinute(number) {
      this.$_setMinute(number);
      this.emitTimeResult();
    },
    $_setSecond(number) {
      if (this.second.length === 2) {
        this.second = '';
      }
      this.second += number;

      if (Number(this.second) > 59) {
        this.second = this.backupSecond;
      }
    },
    setSecond(number) {
      this.$_setSecond(number);
      this.emitTimeResult();
    },
    selectInput(ev) {
      const { target } = ev;
      target.select();
    }
  },
  watch: {
    value: {
      handler(newValue) {
        const [h, m, s] = newValue.split(':');
        this.$_setHour(h);
        this.$_setMinute(m);
        this.$_setSecond(s);
      },
      immediate: true,
    }
  },
};
</script>

<style scope lang="less">
.app-time-picker {
  display: inline-flex;
  align-items: flex-end;
  border: 1px solid #adadad;
  width: rem(148);
  height: rem(32);
  padding: rem(6) rem(12);
  background-color: #121212;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
  > input[type=text] {
    width: rem(23);
    border: none;
    padding: 0;
    background-color: transparent;
    color: #adadad;
    text-align: center;
    font-size: rem(14);
  }
  .colon{
    display: flex;
    align-items: center;
  }
  .time-format {
    font-size: rem(14);
    color: #e0e0e0;
    text-align: left;
    line-height: rem(14);
    &.disabled {
      color: #4f4f4f;
    }
  }
  &.error {
    border: 1px solid #ffb029;
  }
}
</style>
