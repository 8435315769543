import XMLParser from './RuleParserVerXML.mjs';
import JSONParser from './RuleParserVerJSON.mjs';
import Shapes from './shapes.mjs';
import VAOMCore from './VAOM.mjs';
import { unpack } from './utility.mjs';
export default class RuleManager {
  constructor(reference) {
    this.reference = reference;
    this.shapes = [];
    this.parsers = [new XMLParser(reference, this), new JSONParser(reference, this)];
    this.summary = null;
    this.core = new VAOMCore(this);
  }

  addShape(shape) {
    this.shapes.push(shape);
    if (shape.hasSummary) {
      this.createSummary();
    }
  }

  // this is called by the shape instance when event is dispatched on the interface.
  accumulative(vaom, countIn, countOut) {
    this.createSummary();
    this.summary.accumulative(countIn, countOut);
  }

  createSummary() {
    if (!this.summary) {
      this.summary = new Shapes.Summary();
    }
  }

  // this is called by the shape instance when event is dispatched on the interface.
  // XXX: the design of this function is a little tricky.
  // it should be directly manipulated by the vaom who is triggering behaviorAlarm
  // but it needs projection from projectionManager
  behaviorAlarm(vaom, info) {
    let projection = this.reference.projectionManager.projection;
    if (!projection) {
      return;
    }
    vaom.objectDetected(
      unpack(info.Objects).filter(function(obj) {
        return (obj.Type === "Missing" || obj.Type === "Abandon") && unpack(obj.Polygon).length >= 3;
      }).map(function(obj) {
        return unpack(obj.Polygon).map(function(pt) {
          return projection.ProjectPoint({ x: pt.x, y: pt.y, z: 0 });
        });
      })
    );

    if (info.RuleType === "MissingObjectDetection" ||
        info.RuleType === "UnattendedObjectDetection") {
      vaom.objectDetected(unpack(info.Objects).filter(function(obj) {
          return unpack(obj.Polygon).length >= 3;
        }).map(function(obj) {
          return unpack(obj.Polygon).map(function(pt) {
              return projection.ProjectPoint({ x: pt.x, y: pt.y, z: 0 });
          });
        })
      );
    }
  }

  handleRule($rule) {
    this.clear();
    if (!$rule) {
      return;
    }
    this.parsers.forEach((parser) => {
      parser.parse($rule);
    })
  }

  drawIfNecessary(canvas, ctx) {
    let reference = this.reference;
    if (reference.ptzInfoManager && reference.ptzInfoManager.isTracking) {
      return;
    }
    this.shapes.forEach(e => {
      if (
        (reference.displayConfig.showAnalyticRules === undefined || e.name === reference.displayConfig.showAnalyticRules) &&
        (e.preset === undefined || e.preset === reference.ptzInfoManager.currentPreset)
      ) {
        e.draw(
          reference.displayConfig.ruleScaleRatio,
          ctx,
          canvas.width,
          canvas.height,
          reference.displayConfig.showSummary,
          reference.displayConfig.showRuleName,
          reference.displayConfig.showDirection,
          reference.displayConfig.showExclusiveArea
        );
      }
    });
    if (this.summary && reference.displayConfig.showSummary) {
      this.summary.draw(ctx, canvas.width, canvas.height);
    }
  }
  
  clear() {
    if (this.summary) {
      this.summary.destroy();
      this.summary = null;
    }
    this.core.clear();
    this.reference.missingObjectManager.clear();
    this.reference.cellMotionManager.clear();
  }
}
