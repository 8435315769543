import { ab2str } from '@vivotek/lib-utility/convert_arraybuffer_string';
import { getTagged } from '@vivotek/lib-utility/logger';
import { combineBuffer } from '@vivotek/lib-utility/byteArray';

const Log = getTagged('utils/rtspTools');

export const parseBinaryToJSON = function parseBinaryToJSON(notify) {
  let res;

  try {
    res = JSON.parse(ab2str(notify));
  } catch (e) {
    Log.warn('parse notify fail', e);
  }

  return res;
};

export const readUInt16BE = function readUInt16BE(bArray, index, length) {
  let val = '';
  const arrayLength = length === undefined ? 2 : length;

  for (let i = 0; i < arrayLength; i += 1) {
    val += (`0${bArray[index + i].toString(16)}`)?.substr(-2);
  }
  return parseInt(val, 16);
};

export const checkStringInHead = function checkStringInHead(arrayData, str) {
  return [].map.call(str, (val) => val.charCodeAt(0)).every((val, i) => arrayData[i] === val);
};

export const checkIsRTPData = function checkBinaryFirstIs(bArray, value = 0x24) {
  return bArray[0] === value && (bArray[1] >= 0 || bArray[1] < 256);
};

export const buildSdpMessage = function buildSdpMessage(cmd, host, params = {}, payload = null) {
  let requestString = `${cmd} ${host} ${this.version}\r\n`;
  Object.keys(params).forEach((param) => {
    requestString += `${param}: ${params[param]}\r\n`;
  });
  // TODO: binary payload
  if (payload) {
    requestString += `Content-Length: ${payload.length}\r\n`;
  }
  requestString += '\r\n';
  if (payload) {
    requestString += payload;
  }
  return requestString;
};

export const parseSdp = function parseSdp(sdpData, previous) {
  const lines = [...(previous ?? []), ...sdpData.split('\n')];
  let headers = {};
  let mediaHeaders = [];
  let content = [];
  let statusCode;
  let statusText;

  let remainedData = null;
  const result = [];
  lines.forEach((_line, index) => {
    const line = _line.trim();
    if (line.length === 0) {
      remainedData = lines.slice[index];
      return; // end of sdp data
    }
    content.push(line);
    if (line.indexOf('RTSP/') >= 0) {
      if (statusCode) {
        result.push({
          status: Number(statusCode),
          statusText: statusText.join(' '),
          headers,
          mediaHeaders,
          content: content.join('\n')
        });
        headers = {};
        mediaHeaders = [];
        content = [];
      }
      [, statusCode, ...statusText] = line.split(' ');
      return;
    }

    if (line[1] === '=') {
      mediaHeaders.push(line.trim());
    } else if (line) {
      const split = line.split(':');
      const data = split.slice(1).join(':').trim();

      headers[split[0].trim().toLowerCase()] = data;
    }
  });

  result.push({
    status: Number(statusCode),
    statusText: statusText.join(' '),
    headers,
    mediaHeaders,
    content: content.join('\n')
  });

  return { parsedData: result, remainedData };
};

export const combinePacketArray = function combinePacketArray(packets) {
  const combinePackets = packets.reduce((prev, curr) => {
    if (!prev) {
      return curr;
    }
    return combineBuffer(prev, curr);
  // eslint-disable-next-line no-use-before-define
  }, combinePackets);
  return combinePackets;
};
