import { unpack, access, isNumber } from './utility.mjs';
import Shapes from './shapes.mjs';

export default class MetadataParserrVerJSON {
  constructor(reference, parent) {
    this.reference = reference;
    this.parent = parent;
  }

  parseMissingObject($metadata) {
    const { reference } = this;
    reference.missingObjectManager.update(unpack(access($metadata.Frame, "Objects")).filter((o) => {
      return unpack(o.Behaviour).some((b) => {
        return b === "Missing" || b === "Unattended";
      }) && unpack(o.Pos2D).length >= 3;
    }).map((o) => {
      return new Shapes.MissingObject(
        {
          objects: unpack(o.Pos2D).map((pt) => {
            return new Shapes.Point(pt.x / 10000, pt.y / 10000);
          }),
        }, reference.missingObjectManager,
      );
    }));
  }

  praseProjection($metadata) {
    const { objectConfig, displayConfig } = this.reference;
    const { reference } = this;
    if (!reference.projectionManager.initialProjectionConfig) {
      var p = $metadata.Project;
      if (["CamH", "Cx", "Cy", "H", "Offsetx", "Offsety", "W", "f"].every(isNumber.bind(p))) {
        reference.projectionManager.update(
          new Shapes.Project(
            { Cx: p.Cx, Cy: p.Cy, CamHeight: p.CamH, TiltAngle: isNumber.call(p, "Tilt") ? p.Tilt : 0, RollAngle: p.Roll, FocalLength: p.f, OffsetX: p.Offsetx, OffsetY: p.Offsety, ResolutionW: p.W, ResolutionH: p.H },
            reference.projectionManager
          )
        );
      } else if (["fx", "fy", "cx", "cy", "k1", "k2", "k3", "k4", "IsFishEye", "ORGWidth", "ORGHeight", "CamHeight", "TiltAngle"].every(isNumber.bind(p))) {
        reference.projectionManager.update(new Shapes.Project({
          M1: [[p.fx, 0, p.cx],
          [0, p.fy, p.cy],
          [0, 0, 1]],
          D1: [p.k1, p.k2, p.k3, p.k4,
          Number([p.k5]), Number([p.k6]), Number([p.k7]), Number([p.k8]),
          Number([p.k9]), Number([p.k10]), Number([p.k11]), Number([p.k12])],
          CamHeight: p.CamHeight,
          TiltAngle: p.TiltAngle,
          RollAngle: p.RollAngle,
          ORGHeight: p.ORGHeight,
          ORGWidth: p.ORGWidth,
          IsFishEye: p.IsFishEye,
          IsCylindStitch: p.IsCylindStitch,
          CylindStitch: p.CylindStitch
        }, reference.projectionManager));
      }
    }
    let projection = reference.projectionManager.projection;
    if (projection) {
      unpack($metadata[reference.deviceConfig.isStitch ? "Stitch" : "Frame"]).slice(0, 1).forEach((f) => {
        var objects = unpack(f.Objects).filter(function (o) {
          return unpack(o.Behaviour).every(function (b) {
            return b !== "Missing" && b !== "Unattended";
          });
        });
        if (displayConfig.hideVehicleObject) {
          objects = objects.filter((o) => o.Type !== 'Vehicle');
        }
        if (displayConfig.hideHumanObject) {
          objects = objects.filter((o) => o.Type !== 'Human');
        }
        var rectangles = objects.filter(function (o) {
          if (o.Type === "Vehicle") {
            return unpack(o.Pos2D).length >= 3;
          }
          return ["Id"].every(isNumber.bind(o));
        }).map((o) => {
          if (o.Type === "Vehicle") {
            return new Shapes.BoundingBox({
              id: o.Id,
              points: unpack(o.Pos2D).map(pt => new Shapes.Point(pt.x / 10000, pt.y / 10000).scale(objectConfig.scaleX, objectConfig.scaleY).translate(objectConfig.translation)),
              color: "#FF00FF",
            }, reference.boundingBoxManager);
          }
          if (!displayConfig.displayHumanAs2D && ["x", "y"].every(isNumber.bind(o.Centroid)) && ["Height"].every(isNumber.bind(o)) && ["x", "y"].every(isNumber.bind(o.Origin))) {
            return new Shapes.BoundingBox3D({
              id: typeof o["GId"] === "number" ? o.GId : o.Id,
              height: o.Height,
              origin: projection.ProjectPoint({ x: o.Origin.x, y: o.Origin.y, z: 0 }).scale(objectConfig.scaleX, objectConfig.scaleY).translate(objectConfig.translation),
              peak: projection.ProjectPoint({ x: o.Centroid.x, y: o.Centroid.y, z: reference.isStitch ? 0 : o.Height * 2 / 3 }).scale(objectConfig.scaleX, objectConfig.scaleY).translate(objectConfig.translation),
              points: projection.Get2DBoundingBox([
                { x: o.Centroid.x - 180, y: o.Centroid.y - 180, z: 0 },
                { x: o.Centroid.x + 180, y: o.Centroid.y - 180, z: 0 },
                { x: o.Centroid.x + 180, y: o.Centroid.y + 180, z: 0 },
                { x: o.Centroid.x - 180, y: o.Centroid.y + 180, z: 0 },
                { x: o.Centroid.x - 180, y: o.Centroid.y - 180, z: o.Height },
                { x: o.Centroid.x + 180, y: o.Centroid.y - 180, z: o.Height },
                { x: o.Centroid.x + 180, y: o.Centroid.y + 180, z: o.Height },
                { x: o.Centroid.x - 180, y: o.Centroid.y + 180, z: o.Height }
              ]).map(pt => pt.scale(objectConfig.scaleX, objectConfig.scaleY).translate(objectConfig.translation))}, reference.boundingBoxManager);
          } else {
            return new Shapes.BoundingBox({
              id: o.Id, 
              points: unpack(o.Pos2D).map(pt => new Shapes.Point(pt.x / 10000, pt.y / 10000).scale(objectConfig.scaleX, objectConfig.scaleY).translate(objectConfig.translation)),
              color: "#00FF00"
            }, reference.boundingBoxManager);
          }
        });
        var faces = objects.filter(function (o) {
          return ["h", "w", "x", "y"].every(isNumber.bind(access(o.Face, "Rectangle"))) && isNumber.call(o, "Id");
        }).map(function (o) {
          return new Shapes.Face({
            id: o.Id,
            rectangle: o.Face.Rectangle
          }, reference.boundingBoxManager);
        });
        if (faces.length) {
          rectangles = rectangles.concat(faces);
        }
        reference.boundingBoxManager.update(rectangles);
      });
    } else {
      var objects = unpack(access($metadata.Frame, "Objects")).filter(o => unpack(o.Behaviour).every(b => b !== "Missing" && b !== "Unattended"));
      if (displayConfig.hideVehicleObject) {
        objects = objects.filter((o) => o.Type !== 'Vehicle');
      }
      if (displayConfig.hideHumanObject) {
        objects = objects.filter((o) => o.Type !== 'Human');
      }
      var rectangles = objects.filter(o => unpack(o.Pos2D).length >= 3).map(o => new Shapes.BoundingBox({
        id: o.Id,
        points: unpack(o.Pos2D).map(pt => new Shapes.Point(pt.x / 10000, pt.y / 10000).scale(objectConfig.scaleX, objectConfig.scaleY).translate(objectConfig.translation)),
        color: o.Type === "Vehicle" ? "#FF00FF" : "#00FF00"}, reference.boundingBoxManager));
      rectangles.concat(objects.filter(o => ["h", "w", "x", "y"].every(isNumber.bind(access(o.Face, "Rectangle"))) && isNumber.call(o, "Id")).map(o => new Shapes.Face({
        id: o.Id,
        points: o.Face.Rectangle
      }, reference.boundingBoxManager)));

      reference.boundingBoxManager.update(rectangles);
    }
  }

  parseCellMotion($metadata) {
    const { reference } = this;
    unpack($metadata[reference.deviceConfig.isStitch ? "Stitch" : "Frame"]).slice(0, 1).forEach(f => {
      if (f.CellMotion && f.CellMotion.Layout && (f.CellMotion.Layout.Width || 0) && (f.CellMotion.Layout.Height || 0)) {
        reference.cellMotionManager.update({
          rowCount: f.CellMotion.Layout.Height,
          columnCount: f.CellMotion.Layout.Width,
          cells: f.CellMotion.ActiveCells,
        }, reference.cellMotionManager);
      } else {
        reference.cellMotionManager.update(null);
      }
    });
  }

  parseCounting(data) {
    const { reference } = this;
    unpack(data.CountingInfo).forEach((countingInfo) => {
      reference.eventInterface.dispatch('accumulative', countingInfo.RuleName, countingInfo.In || 0, countingInfo.Out || 0);
    });
  }

  parseBehaviorAlarm(data) {
    const { reference } = this;
    if (reference.displayConfig.showSummary) {
      unpack(data.BehaviorAlarmInfo).forEach((info) => {
        reference.eventInterface.dispatch('behaviorAlarm', info.RuleName, info);
      })
    }
  }

  parseBehaviorAlarmVer2(data) {
    const { reference } = this;
    if (reference.displayConfig.showSummary) {
      unpack(data.BehaviorAlarmInfo).filter((behaviorAlarmInfo) => {
        // XXX: this should not be decided here.
        // high order module(us as data reciever and parser) should know detail of low order module(rule's type).
        if (behaviorAlarmInfo.RuleType === "RunningDetection" || behaviorAlarmInfo.EdgeEvent === "Falling") {
          return false;
        }
        return true;
      }).forEach((info) => {
        reference.eventInterface.dispatch('behaviorAlarm', info.RuleName, info);
      });
    }
  }

  parseEvent($metadata) {
    const { reference } = this;
    const self = this;
    unpack($metadata.Data).forEach((data) => {
      switch (data.RuleType) {
        case "Counting":
          self.parseCounting(data);
          break;
        case "BehaviorAlarm":
          self.parseBehaviorAlarm(data);
          break;
        case "CellMotion":
          unpack(data.CellMotionInfo).filter((cellMotionInfo) => {
            return cellMotionInfo.Status == 1;
          }).forEach((info) => {
            reference.eventInterface.dispatch(
              'behaviorAlarm',
              info.Name,
              info,
            );
          });
          break;
        case "ZoneDetection":
          unpack(data.ZoneInfo).map((info) => info.RuleName).forEach((name) => {
            reference.eventInterface.dispatch('behaviorAlarm', name);
          });
          break;
        default:
          self.parseBehaviorAlarmVer2(data);
      }
    });
  }

  parse($metadata) {
    switch ($metadata.Tag) {
      case "MetaData":
        this.praseProjection($metadata);
        this.parseMissingObject($metadata);
        this.parseCellMotion($metadata);
        break;
      case "Event":
        this.parseEvent($metadata);
        break;
      case "Status":
        this.reference.ptzInfoManager.parseStatus($metadata);
        break;
      default:
        break;
    }
  }
}