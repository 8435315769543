import { unpackBits, base64Decode } from "./utility.mjs";
import Shapes from './shapes.mjs';
import VAOMCore from "./VAOM.mjs";

export default class CellMotionManager {
  constructor(reference) {
    this.reference = reference;
    this.shapes = [];
    this.core = new VAOMCore(this);
  }

  update(config) {
    if (!config) {
      this.clear();
      return;
    }
    if (this.activeCellString !== (config.cell || '')) {
      this.shapes = [new Shapes.CellMotion({
        columnCount: config.columnCount,
        rowCount: config.rowCount,
        cells: unpackBits(base64Decode(config.cells))
      }, this)];
      this.activeCellString = (config.cells || '');
      this.reference.requestPaint();
    }
  }
  
  clear() {
    this.core.clear();
  }

  drawIfNecessary(canvas, ctx) {
    this.core.draw(
      this.reference.displayConfig.showCellMotion,
      ctx,
      canvas.width,
      canvas.height,
    );
  }
}
