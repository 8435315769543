<script>
import { mapMutations, mapGetters } from 'vuex';
import DisplayPanel from './ControlDisplayPanel.vue';

export default {
  name: 'PlaybackDisplayPanel',
  mixins: [DisplayPanel],
  methods: {
    ...mapMutations('playback', ['updateAllViewDisplayConfig']),
  },
  computed: {
    ...mapGetters('playback', ['activeView']),
    isCameraStatusDisabled() {
      return this.disabled || this.activeView.status === 'NO_RECORDING'
       || this.activeView.status === 'NOT_SUPPORT';
    }
  }
};
</script>
