<template>
  <div class="message-block">
    <svg-icon
      :iconClass="iconClass"
      :size="'16px'"
      className="message-icon"
    />
    <slot name="message" />
  </div>
</template>
<script>
export default {
  name: 'AppCardMessage',
  props: {
    iconClass: {
      type: String,
      required: true
    },
  },
};
</script>
<style lang="less" scoped>
.message-block {
  background: black;
  display: flex;
  align-items: center;
  margin: rem(10) 0;
  flex-direction: row;
  color: #2986FF;
  &.alert{
    color: #FFB029;
    .message-icon{
      color: #FFB029;
    }
  }
  .message-icon {
    color: #2986FF;
    padding-left: rem(12);
    cursor: auto;
  }
  .message {
    line-height: rem(18);
    font-size: rem(12);
    padding-right: rem(12);
    padding-left: rem(6);
    padding-top: rem(10);
    padding-bottom: rem(10);
    word-wrap: break-word;
  }
}
</style>
