import axios from 'axios';
import download from '@/utility/download';
import polling from '@/utility/polling';

export default {
  createTask({ exportClips, fileName, type }) {
    const task_info = {};
    exportClips.forEach((clip) => {
      if (!task_info[clip.encoderId]) {
        task_info[clip.encoderId] = {
          recording_type: clip.recordingType,
          time_intervals: []
        };
      }
      task_info[clip.encoderId].time_intervals.push({
        start_secs: Math.round((clip.begin / 1000)),
        length_secs: clip.length
      });
    });
    return axios.post(
      '/fcgi-bin/operator/recording.export_create',
      `task_info=${JSON.stringify(task_info)}&filename=${fileName}&type=${JSON.stringify(type)}`
    ).then(({ data }) => {
      if (data.status === 500) {
        return Promise.reject(data);
      }
      return Promise.resolve(data);
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.warn(error);
      return Promise.reject(error);
    });
  },
  queryTaskStatus(ticket) {
    return axios.post('/fcgi-bin/operator/recording.export_status', `ticket=${ticket}`)
      .then(({ data }) => {
        if (data.status === 500) {
          return Promise.reject(data);
        }
        return Promise.resolve(data);
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.warn(error);
        return Promise.reject(error);
      });
  },
  fetchFile(ticket, fileName) {
    download.downloadFile(`/fcgi-bin/operator/recording.export_fetch?ticket=${ticket}`, fileName);
    return Promise.resolve({});
  },
  async export({ exportClips, fileName, type }) {
    const TIME_OUT = 600 * 1000; // 10 minute
    const ticket = await this.createTask({ exportClips, fileName, type });
    const finalStatus = await polling.poll(
      () => this.queryTaskStatus(ticket),
      (status) => status === 'done',
      (status) => status === 'media export failed',
      TIME_OUT,
      1000
    );
    if (ticket && finalStatus === 'done') {
      await this.fetchFile(ticket, fileName);
    }
  },
};
