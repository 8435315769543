<template>
  <div
    class="view-cell-hud"
    :data-unloading="unloading"
    data-testid="viewcell-hud"
  >
    <div class="view-cell-status">
      <SvgIcon
        v-if="isLoading"
        iconClass="status_loading"
        size="60px"
      />
      <template v-else>
        <div
          class="icon-image"
          :class="statusIconClass"
        />
        <div
          class="status-text"
          v-if="!!statusText"
        >
          <span>{{ statusText }}</span>
          <span>{{ statusHint }}</span>
        </div>
      </template>
    </div>
    <div
      v-if="viewcell.status !== 'LOADING'"
      class="status-list"
      v-show="viewcell.shouldShowStatusIcon"
    >
      <div v-if="viewcell.shouldDisplayTMISIcon && systemCapability.support_tmis">
        {{ $t('Trend Micro') }}
      </div>
      <div v-if="viewcell.shouldDisplayVCAIcon">
        {{ $t('VCA') }}
      </div>
      <div v-if="isDevMode">
        {{ viewcell.options.stream_index }}
        {{ viewcell.status }}
      </div>
      <div v-if="isDevMode && viewcell.resolutionString">
        {{ viewcell.resolutionString }}
      </div>
      <div v-if="isDevMode && viewcell.codecString">
        {{ viewcell.codecString }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ViewCellHud',
  props: {
    viewcell: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    ...mapGetters('system', ['systemCapability']),
    isDevMode() {
      return process.env.NODE_ENV === 'development';
    },
    statusIconClass() {
      if (this.viewcell.shouldShowCameraOffline) {
        return 'camera-disconnected';
      }
      switch (this.viewcell.status) {
        case 'TIMEOUT':
          return 'video-timeout';
        case 'NOT_SUPPORT':
          return 'unsupported-codec';
        case 'NO_RECORDING':
          return 'no-recording';
        default:
          return '';
      }
    },
    unloading() {
      return this.viewcell.status === 'UNLOADING';
    },
    isLoading() {
      // debugger
      return this.viewcell.status === 'LOADING' || this.viewcell.status === 'SEEKING';
    },
    statusText() {
      if (this.unloading) {
        return '';
      }
      if (this.viewcell.shouldShowCameraOffline) {
        return this.$t('Camera disconnected');
      }
      switch (this.viewcell.status) {
        case 'NOT_SUPPORT':
          return this.$t('Unsupported codec');
        case 'NO_RECORDING':
          return this.$t('No recording clips');
        case 'TIMEOUT':
          return this.$t('Video timeout');
        default:
          return '';
      }
    },
    statusHint() {
      switch (this.viewcell.status) {
        case 'TIMEOUT':
          return this.$t('Please refresh web browser.');
        default:
          return '';
      }
    }
  },
};
</script>

<style lang="less" scoped>
.view-cell-hud {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  &[data-unloading="true"] {
    background-color: black;
  }

  .view-cell-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon-image {
      height: rem(80);
      width: rem(80);
      &.camera-disconnected {
        background-image: url('../../assets/illustration_camera_disconnected.svg');
      }
      &.video-timeout {
        background-image: url('../../assets/illustration_timeout.svg');
      }
      &.unsupported-codec {
        background-image: url('../../assets/illustration_unsupported_codec.svg');
      }
      &.no-recording {
        background-image: url('../../assets/illustration_no_recording.svg');
      }
    }
    .status-text {
      color: #E0E0E0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .status_loading {
    pointer-events: none;
    animation:spin 1.5s linear infinite;
  }
}

.status-list {
  display: flex;
  position: absolute;
  bottom: rem(8);
  right: rem(8);
  > div {
    height: rem(24);
    line-height: rem(24);
    padding: 0 rem(12);
    margin-left: rem(8);
    font-size: rem(12);
    color: #FFFFFF;
    background-color: rgba(79, 79, 79, 0.5);
  }

}
</style>
