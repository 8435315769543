import Vue from 'vue';
import { SYSTEM_TRIGGERS, CAMERA_TRIGGERS } from '@/constants/triggers';
import { VCA_EVENT_TYPE } from '@/constants/eventType';
import LocalTime from '@/utility/local_time';

function formatDateTime(start, timezone) {
  return new LocalTime({
    timestamp: start / 1000,
    tzoffs: timezone,
  }).displayFullTime;
}

export default {
  searchKeywordFilter({
    currentType, keyword, rowData, timezone, isExportSearch = false
  }) {
    return rowData.filter((listItem) => {
      const time = formatDateTime(listItem.start, timezone);
      const type = Vue.prototype.$t(SYSTEM_TRIGGERS[listItem.source_type]
       || CAMERA_TRIGGERS[listItem.source_type]
       || VCA_EVENT_TYPE[listItem.source_type]
       || listItem.source_type || listItem.EventType);

      const typeMap = {
        alarm: ['alarm_name', 'camera_name'],
        bookmark: ['bookmark_name', 'camera_name'],
        vca: ['ruleName', 'camera_name'],
        tmis: ['Device',
          'SeqNum',
          'MessageType',
          'RuleId',
          'PeerIPAddress',
          'Direction',
          'DeviceIPAddress',
          'OriginatorIPAddress',
          'DeviceRole',
          'DomainPeerAndPortPath',
          'PeerIPAndServerPort']
      };

      const array = typeMap[currentType].reduce((init, item) => {
        if (isExportSearch) {
          init.push(`${listItem[item]} - ${time}`);
        } else {
          init.push(listItem[item]);
        }
        return init;
      }, type === 'bookmark' ? [time] : [time, type]);

      return array.find((value) => value
        .toLowerCase()
        .indexOf(
          keyword.toLowerCase()
        ) !== -1);
    });
  },
  smartSearchKeywordFilter({
    currentType, keyword, rowData, timezone
  }) {
    const result = rowData.filter((listItem) => {
      const time = formatDateTime(listItem.MotionTriggerTimestamp * 1000, timezone);
      const array = [listItem.cameraName, listItem.eventType, listItem.type || '', time];
      return array.find((value) => value
        .toLowerCase()
        .indexOf(
          keyword.toLowerCase()
        ) !== -1);
    });
    return result;
  }
};
