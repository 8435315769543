const actions = {
  goPreviousPage({ state, commit }) {
    if (state[state.currentType].currentPageIndex === 0) {
      return;
    }
    commit('goPreviousPage');
  },
  goNextPage({ state, getters, commit }) {
    if (state[state.currentType].currentPageIndex + 1 >= getters.pageCount) {
      return;
    }
    commit('goNextPage');
  },
  setCurrentType({ state, commit }, payload) {
    if (state[payload].result.length === 0) {
      commit('setAiStatus', 'initial');
    } else {
      commit('setAiStatus', 'searched');
    }
    commit('setCurrentType', payload);
  },
  setSortedList({ state, commit }, list) {
    if (state.status === 'searched') {
      commit('setResultList', { type: state.currentType, list });
    }
  },
  querySmartSearchII({ state, dispatch, commit }) {
    const {
      searchKey, date, device,
      timeFilter,
      coordinates,
      sensitivity, peopleDetection
    } = state.smartSearch.criteria;

    const startTime = new Date(`${date.startDateTime.date}T${date.startDateTime.time}`).getTime();
    const endTime = new Date(`${date.endDateTime.date}T${date.endDateTime.time}`).getTime();

    let { polygon, bitmap } = state.smartSearch.criteria;
    // No set any area, we use entire area
    if (coordinates.length === 0) {
      polygon = [{ x: 0, y: 0 }, { x: 1, y: 0 }, { x: 1, y: 1 }, { x: 0, y: 1 }];
      bitmap = new Array(4096).fill(1);
    }
    const activeCells = peopleDetection ? [] : bitmap;
    const noramlizedPolygon = peopleDetection ? polygon : [];

    const params = {
      searchKey,
      startTime,
      endTime,
      locationID: `Loc${device + 1}`,
      duration: timeFilter.duration,
      interval: timeFilter.interval,
      sensitivity,
      activeCells,
      peopleDetection,
      polygon: noramlizedPolygon
    };
    dispatch('websocket/querySmartSearchII', params, { root: true });
  },
  queryHumanDetectionArea({ state, dispatch }) {
    const { device } = state.smartSearch.criteria;
    dispatch(
      'websocket/queryHumanDetectionArea',
      { cameraID: device },
      { root: true }
    );
  },
  setSmartMotionSearchResult({
    dispatch, commit, getters
  }, { data, isFinal, encoders }) {
    commit('setSmartMotionSearchResult', { data, isFinal, encoders });
    if (getters.status === 'busy' || getters.smartSearchIIResultCount === 200) {
      dispatch('stopSmartSearchII');
    }
  },
  setSearchKeyword({ commit }, keyword) {
    commit('setSearchKeyword', keyword);
  },
  setExportList({ state, commit }, result) {
    commit('setPlayAll', false);
    commit('setExportList', result);
    commit('setSelectedExportList', result);
  },
  setActiveVideoId({
    commit, getters, rootState, rootGetters
  }, id) {
    commit('setActiveVideo', {
      data: getters.videoMap[id],
      encoders: rootState.encoder,
      timezone: rootGetters['system/systemTimezone'],
    });
  },
  setNextPlayableDataIfNecessary({
    state, dispatch, getters
  }) {
    if (state[state.currentType].isPlayingAll && getters.nextPlayableVideoId !== -1) {
      dispatch('setActiveVideoId', getters.nextPlayableVideoId);
    }
    if (state[state.currentType].isPlayingAll && getters.nextPlayableVideoId === -1) {
      dispatch('setPlayAll', false);
    }
  },
  setPlayAll({
    commit, state, getters, dispatch
  }, value) {
    commit('setPlayAll', value);
    if (state[state.currentType].activeVideoId === -1 && getters.nextPlayableVideoId !== -1) {
      dispatch('setActiveVideoId', getters.nextPlayableVideoId);
    }
  },
  resetStatusBeforeSearching({ commit }) {
    commit('resetStatusBeforeSearching');
  },
  stopSmartSearchII({ state, dispatch, commit }) {
    if (state[state.currentType].result.length === 0) {
      if (state.status !== 'busy') {
        commit('setAiStatus', 'failed');
      }
    } else {
      commit('setAiStatus', 'searched');
    }
    const params = {
      searchKey: state.smartSearch.criteria.searchKey,
    };
    dispatch('websocket/stopSmartSearchII', params, { root: true });
  },
};
export default actions;
