// Commented code is for collecting translation e.g. $t('xxxx')
// Add a new value with same comment with $t('xxxxx') format
const SYSTEM_TRIGGERS = {
  // $t('DI'),
  // $t('DO'),
  // $t('Disk failure'),
  // $t('Disk full'),
  // $t('Cyber attack'),
  // $t('Brute force attack'),
  // $t('PoE error'),
  // $t('Quarantine event'),
  // $t('Abnormal G-sensor motion'),
  // $t('Speeding'),
  // $t('GPS disconnected'),
  // $t('Fan error'),
  // $t('Network failure'),
  nvr_di: 'DI',
  nvr_do: 'DO',
  disk_error: 'Disk failure',
  storage_full: 'Disk full',
  nvr_cyber_attk: 'Cyber attack',
  nvr_brute_force_attk: 'Brute force attack',
  poe_event: 'PoE error',
  nvr_quarantine_evt: 'Quarantine event',
  g_sensor: 'Abnormal G-sensor motion',
  gps_overspeed: 'Speeding',
  gps_lost: 'GPS disconnected',
  fan_error: 'Fan error',
  netlink_down: 'Network failure',
  caution_occupancy_reached: 'Caution occupancy reached',
  full_occupancy_reached: 'Full occupancy reached',
  full_occupancy_clear: 'Full occupancy clear',
};

const CAMERA_TRIGGERS = {
  // $t('Motion detection'),
  // $t('PIR'),
  // $t('Tampering detection'),
  // $t('Camera disconnected'),
  // $t('Camera DI'),
  // $t('Camera DO'),
  // $t('Brute force attack'),
  // $t('Cyber attack'),
  // $t('Quarantine event'),
  // $t('Line crossing detection'),
  // $t('Crowd detection'),
  // $t('Unattended object detection'),
  // $t('Missing object detection'),
  // $t('Face detection'),
  // $t('Loitering detection'),
  // $t('Intrusion detection'),
  // $t('Running detection'),
  // $t('Restricted zone detection'),
  // $t('Parking violation detection'),
  // $t('Smart tracking advanced'),
  motion: 'Motion detection',
  pir: 'PIR',
  tampering: 'Tampering detection',
  video_lost: 'Camera disconnected',
  cam_di: 'Camera DI',
  cam_do: 'Camera DO',
  cam_brute_force_attk: 'Brute force attack',
  cam_cyber_attk: 'Cyber attack',
  cam_quarantine_evt: 'Quarantine event',
  cam_line_crossing_detct: 'Line crossing detection',
  cam_crowd_detct: 'Crowd detection',
  cam_unattended_obj_detct: 'Unattended object detection',
  cam_missing_obj_detct: 'Missing object detection',
  cam_face_detct: 'Face detection',
  cam_loitering_detct: 'Loitering detection',
  cam_intrusion_detct: 'Intrusion detection',
  cam_running_detct: 'Running detection',
  cam_restricted_zone_detct: 'Restricted zone detection',
  cam_parking_violation_detct: 'Parking violation detection',
  cam_smart_tracking_detct: 'Smart tracking advanced',
};

export {
  SYSTEM_TRIGGERS,
  CAMERA_TRIGGERS
};
