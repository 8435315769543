function padLeftZero(string, count) {
  if (string.length >= count) {
    return string;
  }
  return `${new Array(count - string.length).fill(0).join('')}${string}`;
}

function arrayToString(array) {
  return array.join(',');
}

function stringToArray(string) {
  return string === '' ? [] : string.split(',');
}

function dateStringToTime(string) {
  if (string.length <= 5) { // assume it's already HH:MM format
    return string;
  }
  return new Date(string).toLocaleTimeString('en-US', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  });
}

function secondsToMinutes(string) {
  return Math.round(+string / 60);
}

function minutesToSeconds(string) {
  return +string * 60;
}

const binaryToDigitArray = (rawData) => (+rawData).toString(2).split('').reverse().map((data, index) => ({ index, on: !!+data }))
  .filter((data) => data.on)
  .map((data) => data.index);

const digitArrayToBinary = (data) => data.map((digit) => 2 ** digit)
  .reduce((accumulator, current) => current + accumulator, 0);

const sourceToStream = (data) => (/c\d+s(\d+)/.exec(data) || [0, '0'])[1];
const streamToSource = (data) => `c0s${data}`;

const transform = {
  qualitypriority: {
    from: (raw) => +raw,
  },
  maxvbrbitrate: {
    from: (raw) => +raw / 1000,
    to: (data) => data * 1000,
  },
  bitrate: {
    from: (raw) => +raw / 1000,
    to: (data) => data * 1000,
  },
  weekday: {
    from: (rawData) => padLeftZero((+rawData).toString(2), 7)
      .split('').map((day, index) => ({ index, on: !!+day }))
      .filter((day) => day.on)
      .map((day) => day.index),
    to: (data) => data.map((day) => 2 ** (6 - day))
      .reduce((accumulator, current) => current + accumulator),
  },
  mdwin: {
    from: binaryToDigitArray,
    to: digitArrayToBinary,
  },
  vadp: {
    from: binaryToDigitArray,
    to: digitArrayToBinary,
  },
  vi: {
    from: binaryToDigitArray,
    to: digitArrayToBinary,
  },
  exttriggerstatus: {
    from: stringToArray,
    to: arrayToString,
  },
  // XXX: supports regular expression
  exttriggerstatus1: {
    from: stringToArray,
    to: arrayToString,
  },
  exttriggerstatus2: {
    from: stringToArray,
    to: arrayToString,
  },
  exttriggerstatus3: {
    from: stringToArray,
    to: arrayToString,
  },
  exttriggerstatus4: {
    from: stringToArray,
    to: arrayToString,
  },
  begintime: {
    to: dateStringToTime,
  },
  endtime: {
    to: dateStringToTime,
  },
  source: {
    matchList: ['streamprofile'],
    from: sourceToStream,
    to: streamToSource,
  },
  maxduration: {
    // ex, media_i0_videoclip_maxduration, can set
    // {
    //   matchList: ['media', 'videoclip'], // in order
    //   from: secondsToHours,
    //   to: hoursToSeconds,
    // }
    // TODO, modify to array if media also need to transform to other format.
    matchList: ['recording'],
    from: secondsToMinutes,
    to: minutesToSeconds,
  },
  patrolseq: {
    from: stringToArray,
    to: arrayToString,
  },
  patroldwelling: {
    from: stringToArray,
    to: arrayToString,
  },
};

function matchKeys(matchList, key) {
  if (matchList === undefined) {
    return true;
  }

  let matchedPos = -1;
  return matchList.every((value) => {
    const re = `(?<=_|^)${value}(?=_)`;
    const matchedPosTmp = key.search(re);
    if (matchedPosTmp > matchedPos) {
      matchedPos = matchedPosTmp;
      return true;
    }

    return false;
  });
}

const BANNED_NONARRAY_KEY = ['smartstream'];

/**
 * Convert key=value pairs in settings store into r/w nested object
 * @param {Object} store The vuex store object
 * @param {String} prefix The prefix you want to drain
 * @param {Boolean} useArray (experimental) When this is turned on,
 *                  it will convert c0/i0/s0 into array
 *                  and put channel index 0 into 'channel'.
 */
function mapSettings(state, encoderKey, useArray = true) {
  const result = {};
  Object.keys(state.setting[encoderKey]).forEach((key) => {
    const array = key.split('_');
    let parent = result;
    array.forEach((category, index) => {
      // this is leaf, we need to create a getter/setter object
      if (index === array.length - 1) {
        const object = {
          get() {
            if (transform[category]
              && transform[category].from
              && matchKeys(transform[category].matchList, key)) {
              return transform[category].from(state.setting[encoderKey][key].CurrentOpt);
            }
            return state.setting[encoderKey][key].CurrentOpt;
          },
          set(value) {
            const config = {
              index: encoderKey,
            };
            if (transform[category]
              && transform[category].to
              && matchKeys(transform[category].matchList, key)) {
              config[key] = transform[category].to(value);
            } else {
              config[key] = value;
            }
            window.$_store.commit('encoder/writeSettingDirty', config);
          },
          enumerable: true,
        };
        const data = {
          key,
          options: state.setting[encoderKey][key].Options,
        };
        Object.defineProperty(data, 'model', object);
        if (parent[category] && parent[category].capability) {
          data.capability = parent[category].capability;
        }
        if (category === 'capability') {
          parent[category] = data.model;
          return;
        }
        parent[category] = data;
        return;
      }
      if (useArray) {
        // c0 => c0, c, 0
        // mode0 => mode0, mode, 0
        const matched = category.match(/^(\D+)(\d)$/);
        if (!matched || BANNED_NONARRAY_KEY.indexOf(matched[1]) >= 0) {
          // For normal key
          if (!(category in parent)) {
            parent[category] = {};
            // just go straight
          }
          parent = parent[category];
          return;
        }
        // For array-type keys
        if (!(matched[1] in parent)) {
          parent[matched[1]] = [];
        }
        if (!parent[matched[1]][matched[2]]) {
          parent[matched[1]][matched[2]] = {
            $index: matched[2],
          };
        }
        const THE_PARENT = parent;
        if (matched[1] === 'c' && !THE_PARENT.channel) {
          THE_PARENT.channel = parent[matched[1]][matched[2]];
        }
        parent = parent[matched[1]][matched[2]];
        return;
      }
      // not leaf!
      if (!(category in parent)) {
        parent[category] = {};
      }
      parent = parent[category];
    });
  });
  return result.uid;
}

export default mapSettings;
