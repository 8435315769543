import store from '@/store';
import { TMIS_EVENT_TYPE, VCA_EVENT_TYPE } from '@/constants/eventType';

function getAllEventTypes(isVCA) {
  return isVCA ? Object.keys(VCA_EVENT_TYPE) : Object.keys(TMIS_EVENT_TYPE);
}

export default {
  async queryEvent({
    deviceList, startTime, endTime, isVCA = true, eventTypeList = getAllEventTypes(isVCA)
  }) {
    const data = {
      deviceList, eventTypeList, startTime, endTime
    };

    if (isVCA) {
      return store.dispatch('websocket/queryEventList', data);
    }
    return store.dispatch('websocket/queryTrendMicroEventList', data);
  }
};
