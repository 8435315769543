import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const ui = {
  namespaced: true,
  state: {
    overlay: {
      showSearchExportModal: false,
      showSceneEditor: false,
      showPlaybackExportModal: false,
      showSmartSearchExportModal: false,
      showSystemRestartModal: false
    },
    dirty: {},
    isViewCellExpanded: false,
    navigationURL: '',
    busyHandler: undefined,
    confirmNavigationCallback: undefined,
  },
  actions,
  mutations,
  getters,
};

export default ui;
