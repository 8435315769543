import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    configure: {},
    capability: {},
    info: null,
    timeInfo: {
      timestamp: 0,
      timezone: 0
    },
    pollingId: -1
  },
  actions,
  mutations,
  getters
};
