const theGetters = {
  exportLength(state) {
    return (state.endTime.valueOf() - state.beginTime.valueOf()) / 1000;
  },
  isError(state) {
    return state.status === 'ERROR';
  },
  isExporting(state) {
    return state.status === 'EXPORTING';
  },
  isInvalid(state) {
    return state.status === 'INVALID';
  }
};

export default theGetters;
