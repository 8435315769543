<template>
  <div class="input-field">
    <div
      class="app-input-wrapper"
      :class="{
        error: displayErrorMessage,
        disabled: disabled,
        searchable: searchable,
        focus: !isBlurred,
        'fixed-input-wrapper': hasFixedText
      }"
      @click="focus"
    >
      <svg-icon
        v-if="searchable"
        iconClass="general_search"
        size="20px"
      />
      <div class="prefix">
        {{ prefix }}
      </div>
      <input
        ref="input"
        class="app-input"
        :disabled="disabled"
        :id="id"
        :type="type"
        :maxlength="maxLength"
        :placeholder="placeholderText"
        v-model="vModel"
        @blur="blur($event)"
      >
      <svg-icon
        v-if="searchable && vModel.length !== 0"
        class="app-input-clear-icon"
        iconClass="general_clear"
        :disabled="disabled"
        @click="clearInput"
        size="20px"
      />
      <div class="suffix">
        {{ suffix }}
      </div>
    </div>
    <div
      v-show="displayErrorMessage"
      class="app-input-error-message"
    >
      <slot name="error-message-icon" />
      {{ errorMessage? errorMessage : validatedErrorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    validate: {
      type: [Array],
      required: false,
      default: () => []
    },
    id: {
      type: [String],
      required: true,
    },
    type: {
      type: [String],
      required: false,
      default: 'text',
    },
    maxLength: {
      type: [Number],
      required: false,
      default: Infinity
    },
    autoValidate: {
      type: [Boolean],
      default: false,
    },
    searchable: {
      type: [Boolean],
      required: false,
      default: false,
    },
    disabled: {
      type: [Boolean],
      required: false,
      default: false,
    },
    placeholder: {
      type: [String, Number],
      required: false,
      default: ''
    },
    prefix: {
      type: [String, Number],
      required: false,
      default: ''
    },
    suffix: {
      type: [String, Number],
      required: false,
      default: ''
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessage: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isBlurred: true,
      validatedErrorMessage: '',
    };
  },
  methods: {
    checkError() {
      return this.$_checkError(this.value);
    },
    $_checkError(value) {
      if (!this.validate) {
        return false;
      }
      this.validatedErrorMessage = '';

      const validations = Array.isArray(this.validate) ? this.validate : [this.validate];

      const result = validations.some((item) => {
        if (item.isRequired) {
          if (value.length === 0) {
            this.validatedErrorMessage = this.$t('The field cannot be empty');
            return true;
          }
          return false;
        }

        if (!item.pattern(value)) {
          this.validatedErrorMessage = item.errorMessage;
          return true;
        }
        return false;
      });

      this.$emit('validate', {
        id: this.id,
        error: result,
      });

      return result;
    },
    blur() {
      this.isBlurred = true;
      this.$emit('blur');
    },
    focus() {
      this.isBlurred = false;
      this.$refs.input.focus();
      this.$emit('focus');
    },
    select() {
      this.$refs.input.select();
    },
    clearInput() {
      if (this.disabled) {
        return;
      }

      this.$emit('input', '');
    }
  },
  computed: {
    vModel: {
      set(value) {
        if (this.autoValidate) {
          this.$_checkError(value);
        }
        this.$emit('input', value);
      },
      get() {
        return this.value;
      },
    },
    hasFixedText() {
      return !!this.prefix || !!this.suffix;
    },
    placeholderText() {
      if (this.placeholder) {
        return this.placeholder;
      }
      if (this.searchable) {
        return this.$t('Search');
      }
      return '';
    },
    displayErrorMessage() {
      return (this.isBlurred && !!this.validatedErrorMessage) || this.hasError;
    },
  },
};
</script>

<style scoped lang="less">
.input-field {
  width: 100%;
}

.app-input-wrapper {
  width: 100%;
  height: rem(32);
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: @input-background-color;
  border: rem(1) solid @input-border-color;
  padding: rem(6) rem(12);
  &.searchable {
    padding: rem(6);
  }
  &.disabled {
    border: rem(1) solid @input-border-color-disabled;
  }
  &:hover:not(.disabled) {
    border: rem(1) solid @input-border-color-hover;
  }
  &:active:not(.disabled) {
    border: rem(1) solid @input-border-color-active;
  }
  &.focus:not(.disabled) {
    border: rem(1) solid @input-border-color-active;
  }
  &.error {
    border: rem(1) solid @input-border-color-error;
  }
  .general-search {
    margin: rem(0) rem(6);
  }
}

.fixed-input-wrapper {
  display: flex;
  .app-input[type] {
    padding: 0 rem(10);
  }
}

.app-input-clear-icon {
  background-color: transparent !important;
  color: #ADADAD;
}

.app-input-error-message {
  display: flex;
  align-items: center;
  font-size: rem(12);
  color: @input-font-color-error;
  margin-top: rem(5);

  >.icon-container{
    margin-right: rem(5);
  }
}

.app-input[type] {
  width: 100%;
  height: rem(20);
  background: @input-background-color;
  border: none;
  box-sizing: border-box;
  color: @input-font-color;
  &:focus {
    outline: none;
  }
  &:hover {
    color: @input-font-color-hover;
  }
  &:disabled {
    color: @input-font-color-disabled;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px @input-background-color inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.prefix,
.suffix {
  font-family: SegoeUI;
  font-size: rem(14);
  color: #adadad;
  line-height: rem(20);
}

.prefix {
  left: rem(12);
}

.suffix {
  right: rem(12);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: @input-placeholder-color;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder,
::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: @input-placeholder-color;
}

.general_search {
  margin-left: rem(10);
}
</style>
