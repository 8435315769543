<template>
  <div
    class="panel-content"
    :class="{ disabled }"
  >
    <AppUniqueAccordionToggleBlock
      :disabled="disabled"
      :title="name"
      :opened="shouldOpenAccordion"
    >
      <div
        class="block wrap camera-info-content"
        v-if="!disabled"
      >
        <div class="block-line">
          <h6>{{ $t('IP Address') }}</h6>
          <h6>{{ encoder.address }}</h6>
        </div>
        <div class="block-line">
          <h6>{{ $t('Model') }}</h6>
          <h6>{{ isCameraOnline ? encoder.extModel : '' }}</h6>
        </div>
        <div
          class="block-line"
          v-if="isLiveviewMode"
        >
          <h6>{{ $t('Resolution') }}</h6>
          <h6>{{ isCameraOnline ? resolution : '' }}</h6>
        </div>
        <div
          class="block-line"
          v-if="isLiveviewMode"
        >
          <h6>{{ $t('Codec') }}</h6>
          <h6>{{ isCameraOnline ? codec : '' }}</h6>
        </div>
        <div
          class="block-line"
          v-if="isLiveviewMode"
        >
          <h6>{{ $t('Frame rate') }}</h6>
          <h6>{{ isCameraOnline? frameRate : '' }}</h6>
        </div>
      </div>
    </AppUniqueAccordionToggleBlock>
    <div
      class="block static"
      v-if="isLiveviewMode"
    >
      <div class="block-line">
        <div
          class="manual-record"
          :class="{
            active:encoder.isManualRecording,
            disabled: disabled || isCameraOffline || isUnsupportedCodec
          }"
          @click="toggleManualRecord(channel)"
        >
          <span>{{ $t('Manual record') }}</span>
          <div class="button-icon">
            <svg-icon
              :iconClass="recordable ? 'status_record_on_primary' : 'status_record_off_primary'"
              size="20px"
            />
          </div>
        </div>
      </div>
      <div class="block-line flex-start button-list">
        <!-- <div
          class="microphone"
          @click="toggleTwoWayAudio"
        >
          <svg-icon
            iconClass="general_microphone_line"
            :disabled="disabled || isCameraOffline"
          />
        </div> -->
        <svg-icon
          @click="saveSnapshot"
          iconClass="general_capture_line"
          :disabled="disabled || isCameraOffline || isUnsupportedCodec"
        />
        <svg-icon
          iconClass="general_bookmark"
          @click="showBookmarkDialog"
          :disabled="disabled || isCameraOffline || isUnsupportedCodec"
        />
      </div>
    </div>
    <AppUniqueAccordionToggleBlock
      :disabled="disabledImageBlock"
      :title="$t('Image')"
      :opened="shouldOpenAccordion"
    >
      <div
        class="block wrap"
        v-if="!disabled"
      >
        <div class="block-line">
          <div
            class="fit-screen-ratio"
            @click="toggleFitFullscreenEnable"
            :class="{ disabled: isLoading || activeView.isFisheye }"
          >
            <span>{{ $t('Fit screen with ratio') }}</span>
            <div class="button-icon">
              <svg-icon
                iconClass="general_fit_screen"
                size="20px"
                :disabled="isLoading || activeView.isFisheye"
              />
            </div>
          </div>
        </div>
        <div class="block-line">
          <svg-icon
            v-if="!isViewCellExpanded"
            iconClass="general_fullscreen_secondary"
            :disabled="shouldDisableExpand"
            @click="toggleViewCellExpand"
          />
          <svg-icon
            v-if="isViewCellExpanded"
            iconClass="general_unfullscreen_secondary"
            :disabled="shouldDisableExpand"
            @click="toggleViewCellExpand"
          />
        </div>
      </div>
    </AppUniqueAccordionToggleBlock>
    <AppUniqueAccordionToggleBlock
      v-if="!isLiveviewMode || hasAudioCapability"
      :disabled="disabledAudioBlock"
      :title="$t('Audio')"
      :opened="shouldOpenAccordion"
    >
      <div class="block wrap">
        <div class="block-line volume-control">
          <AppSlider
            id="control-panel-volume"
            :value="volume"
            :label="$t('Volume__sound volume')"
            @change="setVolume"
            :hasInput="true"
          >
            <SvgIcon
              slot="icon"
              className="volume-control"
              :iconClass="!isAudioMute ? 'general_volume_on' : 'general_volume_mute'"
              size="28px"
              @click="isAudioMute ? activeView.unmute() : activeView.mute()"
            />
          </AppSlider>
        </div>
      </div>
    </AppUniqueAccordionToggleBlock>
    <AppUniqueAccordionToggleBlock
      v-if="isLiveviewMode || systemCapability.support_dual_stream_recording"
      :disabled="disabled || isCameraOffline"
      :title="$t('Stream')"
      :opened="shouldOpenAccordion"
    >
      <div
        class="block wrap"
        v-if="!disabled"
      >
        <AppDropdown
          :options="streamOptions"
          v-model="activeView.streamIndex"
        />
      </div>
    </AppUniqueAccordionToggleBlock>
    <AppUniqueAccordionToggleBlock
      v-if="!isLoading && !disabled && !activeView.isFisheye"
      :disabled="shouldDisableDigitalZoomBlock"
      :title="$t('Digital zoom')"
      :opened="true"
    >
      <div
        class="block wrap"
        v-if="!disabled"
      >
        <div class="block-line">
          <h6 class="bold">
            {{ $t('Digital zoom') }}
          </h6>
          <AppToggleButton
            id="digital-zoom-toggle"
            v-model="activeView.enableDigitalZoom"
            :disabled="activeView.status !== 'PLAYING'"
          />
        </div>
        <template v-if="activeView.enableDigitalZoom && activeView.status === 'PLAYING'">
          <div class="block-line">
            <div>
              <span class="p">{{ $t('Zoom') }}</span>
              <button
                class="ghost small-primary zoom-100-button"
                @click="resetDigitalZoomRatio"
              >
                100%
              </button>
            </div>
            <span class="p">
              {{ digitalZoomRatio }}%
            </span>
          </div>
          <div class="block-line">
            <AppSlider
              :width="'100%'"
              :min="100"
              :max="400"
              v-model="digitalZoomRatio"
            />
          </div>
          <div class="block-line">
            <ControlPanelPIP v-model="digitalZoomRatio" />
          </div>
        </template>
      </div>
    </AppUniqueAccordionToggleBlock>
    <AppUniqueAccordionToggleBlock
      v-if="!isLoading && !disabled && activeView.isFisheye"
      :disabled="shouldDisableFisheyeBlock"
      :title="$t('Fisheye')"
      :opened="shouldOpenAccordion"
    >
      <div
        class="block wrap"
        v-if="!disabled"
      >
        <AppCardMessage
          v-if="isLocalDewarped"
          iconClass="general_hint"
        >
          <span
            slot="message"
            class="message"
          >
            <!-- eslint-disable-next-line max-len -->
            {{ $t('The original fisheye view has already been dewarped by the camera.') }}<br>
            {{ $t('Go to camera web for changing this setup.') }}</span>
        </AppCardMessage>
        <AppDropdown
          :disabled="isLocalDewarped"
          :options="fisheyeOptions"
          v-model="dewarpMode"
        />
      </div>
    </AppUniqueAccordionToggleBlock>
    <AppUniqueAccordionToggleBlock
      v-if="!disabled && !activeView.isFisheye && encoder.isPTZ && isLiveviewMode"
      :disabled="isCameraOffline || isUnsupportedCodec"
      :title="$t('PTZ')"
      :opened="shouldOpenAccordion"
    >
      <ControlControlPanelPTZ
        :channel="channel"
        :presetOptions="presetOptions"
        v-if="!disabled"
      />
    </AppUniqueAccordionToggleBlock>
    <AppUniqueAccordionToggleBlock
      v-if="!disabled && DigitalInputData.length !== 0 && DigitalOutputData.length !== 0 && isLiveviewMode"
      :disabled="isCameraOffline || isUnsupportedCodec"
      :title="$t('DIDO')"
      :opened="shouldOpenAccordion"
    >
      <div
        class="block wrap"
        v-if="!disabled"
      >
        <div class="block-line">
          <AppTable
            :headerList="[{name:$t('Digital input')}]"
            :bodyList="DigitalInputData"
            :disablePlay="true"
            :scroll="false"
            theme="dido"
          >
            <template slot="body">
              <tr
                v-for="(item, index) in DigitalInputData"
                :key="index"
              >
                <td>
                  <!-- Do not translate DI/DO -->
                  DI-{{ index+1 }}
                </td>
                <td class="flex-align-center">
                  <SvgIcon
                    iconClass="general_check"
                    size="20px"
                  />
                  <span v-if="item === 1">
                    {{ $t('On') }}
                  </span>
                  <span v-if="item === 0">
                    {{ $t('Off') }}
                  </span>
                </td>
              </tr>
            </template>
          </AppTable>
        </div>
        <div class="block-line">
          <AppTable
            :headerList="[{name:$t('Digital output')}]"
            :bodyList="DigitalOutputData"
            :scroll="false"
            :disablePlay="true"
            theme="dido"
          >
            <template slot="body">
              <tr
                v-for="(fields,index) in DigitalOutputData"
                :key="index"
              >
                <td>
                  <!-- Do not translate DI/DO -->
                  DO-{{ index+1 }}
                </td>
                <td>
                  <div class="">
                    <AppRadioButton
                      name="do_high"
                      type="radio"
                      v-model="DigitalOutputData[index]"
                      :value="1"
                      :label="$t('On')"
                      :disabled="!isSupportedByDevicePack"
                      @change="digitalOutputChange(index)"
                    />
                  </div>
                  <div class="">
                    <AppRadioButton
                      name="do_low"
                      type="radio"
                      v-model="DigitalOutputData[index]"
                      :value="0"
                      :label="$t('Off')"
                      :disabled="!isSupportedByDevicePack"
                      @change="digitalOutputChange(index)"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </AppTable>
        </div>
      </div>
    </AppUniqueAccordionToggleBlock>
    <BookmarkDialogue />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import PLUGINFREE from '@/constants/pluginfree';
import AppRadioButton from '@/components/ui/AppRadioButton.vue';
import AppSlider from '@/components/ui/AppSlider.vue';
import AppToggleButton from '@/components/ui/AppToggleButton.vue';
import AppTable from '@/components/ui/AppTable.vue';
import AppDropdown from '@/components/ui/AppDropdown.vue';
import AppCardMessage from '@/components/ui/AppCardMessage.vue';
import AppUniqueAccordionToggleBlock from '@/components/AppUniqueAccordionToggleBlock.vue';
import BookmarkDialogue from '@/components/BookmarkDialogue.vue';
import ControlPanelPIP from './ControlControlPanelPIP.vue';
import ControlControlPanelPTZ from './ControlControlPanelPTZ.vue';

export default {
  name: 'ControlPanel',
  components: {
    AppSlider,
    AppDropdown,
    AppCardMessage,
    AppRadioButton,
    AppToggleButton,
    AppTable,
    ControlPanelPIP,
    ControlControlPanelPTZ,
    AppUniqueAccordionToggleBlock,
    BookmarkDialogue
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: 'liveview'
    }
  },
  data() {
    return {
      fitFullscreenEnable: false,
      digitalZoomRatio: 100,
      shouldOpenAccordion: true,
    };
  },
  methods: {
    ...mapActions('encoder', ['toggleManualRecord', 'toggleDigitalOutput']),
    toggleFitFullscreenEnable() {
      if (this.activeView.isFisheye) {
        return;
      }
      this.activeView.stretch = !this.activeView.stretch;
    },
    toggleViewCellExpand() {
      this.$bus.$emit('active-viewcell-expand');
    },
    saveSnapshot() {
      this.$bus.$emit('viewcell-control', 'snapshot');
    },
    showBookmarkDialog() {
      this.$bus.$emit('showBookmarkDialogue',
        {
          encoderId: this.channel,
          cameraName: this.encoder.name,
          start: 0
        });
    },
    digitalOutputChange(index) {
      this.toggleDigitalOutput({
        encoderId: this.channel,
        index
      });
    },
    resetDigitalZoomRatio() {
      this.digitalZoomRatio = 100;
    },
    setVolume(value) {
      this.activeView.volume = value;
    },
  },
  watch: {
    activeView: {
      handler(val) {
        if (!val.enableDigitalZoom) {
          this.resetDigitalZoomRatio();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['scene', 'ui']),
    ...mapGetters('scene', ['activeLayout', 'activeView']),
    ...mapGetters('encoder', ['encoderSetting', 'encoderSettingPTZPreset']),
    ...mapGetters('system', ['systemCapability']),
    isLiveviewMode() {
      return this.mode === 'liveview';
    },
    disabled() {
      if (this.isLiveviewMode) {
        return this.activeView.encoder_id === -1;
      }
      return this.activeView.encoder_id === -1 || !this.activeView.recordingQueried;
    },
    disabledImageBlock() {
      if (this.isLiveviewMode) {
        return this.disabled || this.isCameraOffline || this.isUnsupportedCodec;
      }
      return this.disabled || this.noRecording;
    },
    disabledAudioBlock() {
      if (this.isLiveviewMode) {
        return this.disabled || this.isCameraOffline || this.isUnsupportedCodec || this.isLoading
          || !this.hasAudioCapability;
      }
      return this.disabled || this.noRecording || this.isLoading;
    },
    shouldDisableDigitalZoomBlock() {
      if (this.isLiveviewMode) {
        return this.disabled || this.isCameraOffline || this.isUnsupportedCodec || this.isLoading;
      }
      return this.disabled || this.noRecording || this.isLoading;
    },
    shouldDisableFisheyeBlock() {
      if (this.isLiveviewMode) {
        return this.disabled || this.isCameraOffline || this.isUnsupportedCodec || this.isLoading;
      }
      return this.disabled || this.noRecording || this.isLoading;
    },
    name() {
      return this.activeView?.camera?.options ? this.activeView.camera.options.name : this.$t('Camera name');
    },
    volume() {
      return this.activeView.volume;
    },
    channel() {
      return this.activeView?.options?.encoder_id;
    },
    dewarpMode: {
      get() {
        if (this.isLiveviewMode) {
          return this.activeView.dewarpMode;
        }
        return this.activeView.options.dewarp_mode;
      },
      set(value) {
        if (this.isLiveviewMode) {
          this.activeView.dewarpMode = value;
        }
        this.activeView.options.dewarp_mode = value;
      }
    },
    encoderStreamConfig() {
      if (!this.encoderSetting[this.channel]) {
        return null;
      }
      const mainStream = this.activeView.camera?.mainStream;
      const subStream = this.activeView.camera?.subStream;
      const index = this.activeView.streamIndex ? subStream : mainStream;
      return this.encoderSetting[this.channel].s[index];
    },
    recordable() {
      return this.encoder.isManualRecording
        && !(this.disabled || this.isCameraOffline || this.isUnsupportedCodec);
    },
    encoder() {
      return this.activeView && this.activeView.camera ? this.activeView.camera : { options: {} };
    },
    DigitalInputData() {
      return this.encoder.di || [];
    },
    DigitalOutputData() {
      return this.encoder.do || [];
    },
    codec() {
      if (!this.encoderStreamConfig) {
        return this.activeView.codecString;
      }
      return this.encoderStreamConfig.video.codec.model;
    },
    frameRate() {
      if (!this.encoderStreamConfig) {
        return this.activeView.frameRateString;
      }
      switch (this.codec) {
        case 'H.265':
          return `${this.encoderStreamConfig.h265.max.frame.rate.model} fps`;
        case 'H.264':
          return `${this.encoderStreamConfig.h264.max.frame.rate.model} fps`;
        case 'JPEG':
          return `${this.encoderStreamConfig.mjpeg.max.frame.rate.model} fps`;
        default:
          return '';
      }
    },
    resolution() {
      if (!this.encoderStreamConfig) {
        return this.activeView.resolutionString;
      }
      return this.encoderStreamConfig.frame.size.model;
    },
    isViewCellExpanded() {
      return this.ui.isViewCellExpanded;
    },
    isAudioMute() {
      return this.activeView?.options?.volume === 0;
    },
    isCameraOffline() {
      return this.encoder?.isCameraOffline;
    },
    isCameraOnline() {
      return this.encoder?.isOnline;
    },
    isLoading() {
      return this.activeView.status === 'LOADING';
    },
    isUnsupportedCodec() {
      return this.activeView.status === 'NOT_SUPPORT';
    },
    isSupportedByDevicePack() {
      return this.encoder?.isSupportedByDevicePack;
    },
    hasAudioCapability() {
      if (!this.isLiveviewMode) {
        return true;
      }
      if (!this.encoderSetting[this.channel]?.audio) {
        return false;
      }
      return +(this.encoderSetting[this.channel].audio.naudioin.model);
    },
    isLocalDewarped() {
      return this.activeView?.dewarpInfo?.isLocalDewarped;
    },
    shouldDisableExpand() {
      return this.activeLayout === '1x1';
    },
    fisheyeOptions() {
      const arr = [];
      if (!this.encoder) {
        return arr;
      }
      return Object.keys(PLUGINFREE.DEWARP).map((key) => ({
        text: key,
        value: PLUGINFREE.DEWARP[key]
      }));
    },
    streamOptions() {
      if (!this.encoder) {
        return [];
      }

      return [
        { text: this.$t('Main stream'), value: 0 },
        { text: this.$t('Sub stream'), value: 1 },
      ];
    },
    $_getStreamCodec() {
      return (index) => (
        this.encoderSetting[this.channel]?.s[index].video.codec.model
      );
    },
    presetOptions() {
      if (!this.encoder) {
        return [];
      }

      const arr = this.encoderSettingPTZPreset(this.channel).map((i) => ({
        text: i[1].CurrentOpt,
        value: i[1].CurrentOpt
      }));

      return arr;
    },
  }
};
</script>

<style lang="less" scoped>
.panel-content.disabled {
  pointer-events: none;
}

span.p {
  color: #E0E0E0;
}

.zoom-100-button {
  width: rem(60);
  height: rem(20);
  margin-left: rem(20);
}

.panel-content {
  .block {
    padding: 0 rem(40);
    &.wrap {
      padding-top: rem(10);
      padding-bottom: rem(20);
    }
    &.static {
      border-bottom: rem(1px) solid #000000;
    }

    .flex-start {
      justify-content: flex-start;
    }
  }
}

.camera-info-content {
  > .block-line {
    h6 {
      &:nth-child(1) {
        color: #8f8f8f;
      }
      &:nth-child(2) {
        color: #fff;
      }
    }
  }
}

.icon-wrapper {
  margin-right: rem(20);
}

.manual-record,
.fit-screen-ratio {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  width: 100%;
  height: rem(32);
  margin-bottom: rem(10);
  position: relative;
  cursor: pointer;

  &.disabled {
    border: 1px solid @body-font-color-disabled;
    cursor: default;
    color: @body-font-color-disabled;
  }

  > span {
    color: inherit;
    font-size: rem(14);
    line-height: 1;
  }

  .button-icon {
    display: inline-flex;
    position: absolute;
    color: inherit;
    right: rem(10);
  }
}

.manual-record {
  margin-top: rem(26);
  &.active:not {
    span {
      color: #000;
    }
    background-color: #fff;
  }
  &.disabled {
    pointer-events: none;
  }
}

.button-list {
  margin-bottom: rem(10);
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.message-block {
  margin-top: 0;
}

.error-content{
  display: flex;
  align-items: center;
  color:#FFB029;
}

.svg-icon.volume-control {
  margin-right: rem(10);
}
</style>
