import Zoomer from './Zoomer';

export default class DigitalZoomProcessor {
  constructor() {
    this.dragStart = null;
    this.dragging = null;
    this.zoomer = new Zoomer();
  }

  resize(w, h) {
    this.zoomer && this.zoomer.resize(w, h);
    this.width = w;
    this.height = h;
  }

  render(w, h) {
    this.zoomer && this.zoomer.render(w, h);
    this.sourceWidth = w;
    this.sourceHeight = h;
  }

  handleEvent(evt, ref) {
    if (this[`_handle_${evt.type}`]) {
      this[`_handle_${evt.type}`](evt, ref);
      return true;
    }
    return false;
  }

  _handle_wheel(evt, ref) {
    this.zoomer.wheel(evt, ref);
  }

  _handle_panmove(evt, ref) {
    const defaultPIP = {
      x: 0, y: 0, width: 1, height: 1
    };
    const {
      x, y, width, height
    } = ref.settings.pip || defaultPIP;
    const sourceRatio = this.sourceWidth / this.sourceHeight;
    const targetRatio = this.width / this.height;
    let screenX;
    let screenY;

    if (ref.settings.stretch) {
      screenX = ref.width;
      screenY = ref.height;
    } else if (sourceRatio < targetRatio) {
      screenX = ref.width / (this.width / (sourceRatio * this.height));
      screenY = ref.height;
    } else {
      screenX = ref.width;
      screenY = ref.height / (this.height / (this.width / sourceRatio));
    }

    const deltaX = evt.deltaX / screenX;
    const deltaY = evt.deltaY / screenY;

    ref.config('pip', {
      x: Math.min(Math.max(x + (deltaX * width), 0), 1 - width),
      y: Math.min(Math.max(y + (deltaY * height), 0), 1 - height),
      width,
      height,
    });
  }
}
