<template>
  <div
    class="accordion-block"
    :class="{ disabled: disabled, opened: internalOpened }"
    :toggle-type="toggleType"
  >
    <div
      class="block-line"
      @click="toggle"
    >
      <div
        v-if="toggleType === 'gui-style-1'"
        class="title"
      >
        <h5
          class="bold"
          :title="title"
        >
          {{ title }}
        </h5>
      </div>
      <div
        v-if="toggleType === 'gui-style-2'"
        class="title"
      >
        <svg-icon
          iconClass="general_scene"
          size="32px"
        />
        <h5
          class="bold"
          :title="title"
        >
          {{ title }}
        </h5>
      </div>
      <div class="controls">
        <slot name="control" />
        <svg-icon
          :iconClass="internalOpened ? 'general_arrow_bottom' : 'general_arrow_top'"
          size="20px"
          outerSize="32px"
        />
      </div>
    </div>
    <slot v-if="internalOpened && !disabled" />
  </div>
</template>

<script>
export default {
  name: 'AppUniqueAccordionToggleBlock',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    toggleType: {
      type: String,
      default: 'gui-style-1',
    },
  },
  watch: {
    disabled(value) {
      if (value) {
        this.internalOpened = false;
      } else {
        this.internalOpened = this.opened;
      }
    },
    opened() {
      this.internalOpened = this.opened;
    },
  },
  data() {
    return {
      internalOpened: this.disabled ? false : this.opened
    };
  },
  methods: {
    toggle() {
      if (this.disabled) {
        return;
      }
      this.internalOpened = !this.internalOpened;
      this.$emit('clickToggleBlock', this.internalOpened);
    },
  }
};
</script>

<style lang="less" scoped>
.accordion-block {
  border-bottom: 1px solid #000000;

  &.disabled {
    pointer-events: none;
    h5 {
      color: @body-font-color-disabled;
    }
  }
  .block-line {
    display: flex;
  }

  .title {
    display: flex;
    flex: 1;
    height: rem(60);
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    h5 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &[toggle-type="gui-style-1"] {
    & .title {
      margin-left: rem(40);
    }
    .controls {
      margin-right: rem(34);
    }
    h5 {
      max-width: rem(220);
    }
  }

  &[toggle-type="gui-style-2"] {
    & .title {
      margin-left: rem(18);
    }
    .controls {
      margin-right: rem(18);
    }
    h5 {
      max-width: rem(160);
    }
    &.opened div{
      color: #2986FF;
    }
  }
}

.controls {
  display: flex;
  align-items: center
}
</style>
