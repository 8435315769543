const DEFAULT_TIMEOUT = 10 * 1000;
const DEFAULT_INTERVAL = 1000;

export default {
  poll(fn, resolveCondition, rejectCondition, timeout, theInterval) {
    const endTime = Number(new Date()) + (timeout || DEFAULT_TIMEOUT);
    const interval = theInterval || DEFAULT_INTERVAL;

    const checkCondition = (resolve, reject) => {
      const axiosPromise = fn();
      axiosPromise.then((data) => {
        if (resolveCondition(data)) {
          resolve(data);
        } else if (rejectCondition(data)) {
          reject(data);
        } else if (Number(new Date()) < endTime) {
          setTimeout(checkCondition, interval, resolve, reject);
        } else {
          reject(new Error(`timed out for ${fn}`));
        }
      }).catch((error) => reject(error));
    };
    return new Promise(checkCondition);
  }
};
