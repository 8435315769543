export default {
  getNavigatorLanguage() {
    const lang = window.navigator.userLanguage || window.navigator.language;
    let relang = '';
    if (typeof (lang) !== 'undefined') {
      relang = lang.toLowerCase();
    }

    let current = 'English';
    switch (relang) {
      case 'en-us':
        current = 'English';
        break;
      case 'de':
      case 'de-de':
      case 'de-ch':
      case 'de-li':
      case 'de-at':
      case 'de-lu':
        current = 'German';
        break;
      case 'es':
      case 'es-ar':
      case 'es-ve':
      case 'es-uy':
      case 'es-gt':
      case 'es-hn':
      case 'es-sv':
      case 'es-es':
      case 'es-do':
      case 'es-cl':
      case 'es-ni':
      case 'es-pa':
      case 'es-py':
      case 'es-pr':
      case 'es-us':
      case 'es-mx':
      case 'es-ec':
      case 'es-cr':
      case 'es-co':
      case 'es-bo':
      case 'es-pe':
        current = 'Spanish';
        break;
      case 'fr':
      case 'fr-ca':
      case 'fr-ch':
      case 'fr-be':
      case 'fr-fr':
      case 'fr-mc':
      case 'fr-lu':
        current = 'French';
        break;
      case 'it':
      case 'it-it':
      case 'it-ch':
        current = 'Italian';
        break;
      case 'ja':
      case 'ja-jp':
        current = 'Japanese';
        break;
      case 'pt':
      case 'pt-pt':
      case 'pt-br':
        current = 'Portuguese';
        break;
      case 'zh-cn':
      case 'zh-hans':
      case 'zh-sg':
        current = 'SimpleChinese';
        break;
      case 'zh-tw':
      case 'zh-hanf':
      case 'zh-hk':
      case 'zh-mo':
        current = 'TradChinese';
        break;
      case 'cs':
      case 'cs-CZ':
        current = 'Czech';
        break;
      case 'ru':
      case 'ru-RU':
        current = 'Russian';
        break;
      default:
        break;
    }
    return current;
  }
};
