const actions = {
  toggleSceneEditor({ commit }) {
    commit('toggleSceneEditor');
    commit('hideAllOverlay', {
      exclusiveKey: 'showSceneEditor'
    });
  },
  toggleSearchExport({ commit }) {
    commit('toggleSearchExport');
    commit('hideAllOverlay', {
      exclusiveKey: 'showSearchExportModal'
    });
  },
  toggleSmartSearchExport({ commit }) {
    commit('toggleSmartSearchExport');
    commit('hideAllOverlay', {
      exclusiveKey: 'showSmartSearchExportModal'
    });
  },
  togglePlaybackExport({ commit }) {
    commit('togglePlaybackExport');
    commit('hideAllOverlay', {
      exclusiveKey: 'showPlaybackExportModal'
    });
  },
  waitForSystemRestart({ commit }) {
    commit('toggleSystemRestart');
  },
  setDirty({ commit }, key) {
    commit('setDirty', key);
  },
  navigate({
    commit, state, getters, dispatch
  }, { url, callback }) {
    commit('setURL', url);
    if (callback) {
      commit('registerConfirmNavigationCallback', callback);
    }

    if (getters.isBusy && state.busyHandler) {
      state.busyHandler();
      return;
    }
    dispatch('confirmNavigation');
  },
  confirmNavigation({ state, commit }) {
    if (!state.confirmNavigationCallback) {
      commit('directToURL');
      commit('clearURL');
      return;
    }
    state.confirmNavigationCallback().then(() => {
      // reset
      commit('unregisterConfirmNavigationCallback');
      commit('directToURL');
    });
  },
  rejectNavigation({ commit }) {
    commit('clearURL');
    commit('unregisterConfirmNavigationCallback');
  },
  clearDirty({ commit, getters }, key) {
    commit('clearDirty', key);
  }
};

export default actions;
