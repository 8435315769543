<template>
  <div
    v-on-clickaway="onBuzzerDropdownClickaway"
    v-if="isBuzz && isAdministrator"
    data-testid="header-buzzer-button"
  >
    <SvgIcon
      iconClass="general_buzzer_stop"
      @click="toggleBuzzerDropdown"
      size="28px"
      outerSize="48px"
      :active="isBuzzerDropdownOpened"
    />
    <div
      class="buzzer-dropdown"
      v-if="isBuzzerDropdownOpened"
    >
      <SvgIcon
        class="icon"
        iconClass="general_buzzer"
        size="32px"
        outerSize="32px"
      />
      <div
        class="str"
      >
        <div class="bold">
          {{ $t('Buzzer') }}
        </div>
        <div>{{ $t('Click to turn off.') }}</div>
      </div>
      <button
        class="turn-off-button"
        @click="turnOffBuzzer"
      >
        {{ $t('Turn off buzzer') }}
      </button>
    </div>
  </div>
</template>

<script>

import { directive as onClickaway } from 'vue-clickaway';
import BuzzerApi from '@/services/BuzzerApi';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'BuzzerPanel',
  data() {
    return {
      isBuzzerDropdownOpened: false,
    };
  },
  directives: {
    onClickaway,
  },
  computed: {
    ...mapState(['websocket']),
    ...mapGetters('user', ['isAdministrator']),
    isBuzz() {
      const isBuzzing = !!this.websocket.buzzer;
      if (!isBuzzing) {
        this.onBuzzerDropdownClickaway();
      }
      return isBuzzing;
    },
  },
  methods: {
    toggleBuzzerDropdown() {
      this.isBuzzerDropdownOpened = !this.isBuzzerDropdownOpened;
    },
    onBuzzerDropdownClickaway() {
      this.isBuzzerDropdownOpened = false;
    },
    turnOffBuzzer() {
      BuzzerApi.turnOffBuzzer();
    },
  }
};
</script>

 <style lang="less" scoped>
.buzzer-dropdown {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: rem(0);
  background: #2E2E2E;
  border: 1px solid #000000;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.30);;
  z-index: 999;
  transform: translateY(rem(48)) translateX(-40%);
  width: rem(356);
  height: rem(84);
  font-size: rem(14);
  padding: rem(20);
}
.str {
  display: inline-block;
  position: absolute;
  margin-left: rem(10);
  > div {
    color: #B2B2B2;
  }
  > div.bold {
    font-weight: bold;
    color: #C2C2C2;
  }
}
.turn-off-button {
  cursor: pointer;
  position: absolute;
  background-color: #FFB029;
  height: rem(24);
  width: initial;
  bottom: rem(20);
  right: rem(20);
  color: #2E2E2E;
  border: none;
  outline: none;
  &:hover {
    background-color: #FFB029;
    border: none;
  }
}
</style>
