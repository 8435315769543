<template>
  <span class="p alert">
    <svgIcon
      iconClass="general_alert"
      size="20px"
    />
    {{ message }}
  </span>
</template>

<script>
export default {
  name: 'AppFormErrorMessage',
  props: {
    message: {
      type: String,
      required: true,
      default: ''
    }
  },
};
</script>

<style scoped lang="less">
.p.alert {
  color: #FFB029;
  line-height: rem(32);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: rem(20);
  .svg-icon {
    margin-right: rem(10);
  }
}
</style>
