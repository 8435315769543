<template>
  <div>
    <div class="date-time-wrapper">
      <AppDatePicker
        :id="`${id}Date`"
        v-model="vModel.date"
        @input="handleDate"
        :isError="isError"
        @opened="onOpened"
        @selectMonth="onSelectMonth"
        :calendarDisabled="calendarDisabled"
        :calendarDisabledDates="calendarDisabledDates"
        class="date-picker"
        :pickerDisabled="pickerDisabled"
      />
      <AppTimePicker
        :id="`${id}Time`"
        :showFormat="false"
        v-model="vModel.time"
        @change="handleTime"
        :isError="isError"
        :disabled="pickerDisabled"
      />
    </div>
    <div
      class="date-time-error"
      v-if="errorMessage"
    >
      <span class="p error-text">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import AppDatePicker from '@/components/ui/AppDatePicker.vue';
import AppTimePicker from '@/components/ui/AppTimePicker.vue';

export default {
  name: 'AppDateTimePicker',
  components: {
    AppDatePicker,
    AppTimePicker
  },
  props: {
    errors: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      required: true
    },
    isError: {
      type: Boolean
    },
    value: {
      type: Object,
      required: true
    },
    calendarDisabled: {
      type: Boolean,
      default: false
    },
    calendarDisabledDates: {
      type: Array,
      default: () => []
    },
    pickerDisabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    emitValidationEvent(value) {
      this.$emit('validate', {
        date: value.date,
        time: value.time
      });
    },
    handleInput(value) {
      this.$emit('input', {
        date: value.date,
        time: value.time
      });
      this.emitValidationEvent(value);
    },
    handleDate(date) {
      if (date === this.value.date) {
        return;
      }
      this.vModel = {
        date,
        time: this.value.time
      };
    },
    handleTime(time) {
      if (time === this.value.time) {
        return;
      }
      this.vModel = {
        date: this.value.date,
        time
      };
    },
    onOpened(value) {
      this.$emit('opened', value);
    },
    onSelectMonth(value) {
      this.$emit('selectMonth', value);
    }
  },
  computed: {
    errorMessage() {
      if (this.errors.length === 0) {
        return '';
      }
      return this.errors[0].message;
    },
    vModel: {
      set(value) {
        this.handleInput(value);
      },
      get() {
        return {
          date: this.value.date,
          time: this.value.time
        };
      }
    }
  }
};
</script>

<style lang="less" scoped>
.date-time-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .date-picker {
    margin-right: rem(20);
  }
}
.date-time-error {
  position: relative;
  height: 1rem;
  .error-text {
    color: @input-font-color-error;
    position: absolute;
    top: rem(3);
  }
}
</style>
