import transformPointForStretch from './transformPointForStretch';

export default class JoystickProcessor {
  handleEvent(evt, ref) {
    if (this[`_handle_${evt.type}`]) {
      return this[`_handle_${evt.type}`](evt, ref);
    }
    return false;
  }

  _handle_wheel(evt, ref) {
    const { joystick } = ref.settings;

    if (!joystick) {
      return;
    }

    joystick.emit('wheel', evt, ref);
    this;
  }

  _handle_panstart(evt, ref) {
    const { joystick, stretch } = ref.settings;
    let value;

    if (stretch) {
      value = {
        x: evt.offsetX,
        y: evt.offsetY,
      };
    } else {
      value = transformPointForStretch(evt, evt.detail.target);
    }

    if (!joystick || !value) {
      return;
    }

    joystick.emit('panstart', evt, ref);

    ref.server.postMessage({
      config: 'dragStart',
      value,
    });
    this;
  }

  _handle_panmove(evt, ref) {
    const { dragEventExpired } = this;
    const { joystick, stretch } = ref.settings;
    let value;

    const now = Date.now();

    if (stretch) {
      value = {
        x: evt.offsetX,
        y: evt.offsetY,
      };
    } else {
      value = transformPointForStretch(evt, evt.detail.target);
    }

    if (!joystick || (dragEventExpired && dragEventExpired > now) || !value) {
      return;
    }

    joystick.emit('panmove', evt, ref);

    ref.server.postMessage({
      config: 'dragging',
      value,
    });

    this.dragEventExpired = now + 100;
  }

  _handle_panend(evt, ref) {
    const { joystick } = ref.settings;

    if (!joystick) {
      return;
    }

    joystick.emit('panend', evt, ref);

    ref.server.postMessage({
      config: 'dragStart',
      value: null,
    });
    ref.server.postMessage({
      config: 'dragging',
      value: null,
    });
    this;
  }

  _handle_click(evt, ref) {
    const { joystick } = ref.settings;

    if (!joystick) {
      return;
    }

    joystick.emit('click', evt, ref);
    this;
  }
}
