import RTP from '../model/RTP';

class RTPFactory {
  constructor(sdp) {
    this.sdp = sdp;
    this.tsOffsets = {};
    Object.keys(sdp.media).forEach((pay) => {
      const { trackID } = sdp.media[pay];
      this.tsOffsets[trackID] = { last: 0, overflow: 0 };
    });
  }

  build(packet/* uint8array */) {
    const rtp = new RTP(packet, this.sdp);

    const tsOffset = this.tsOffsets[rtp.trackID];
    if (tsOffset) {
      rtp.timestamp += tsOffset.overflow;
      if (tsOffset.last && Math.abs(rtp.timestamp - tsOffset.last) > 0x7fffffff) {
        tsOffset.overflow += 0xffffffff;
        rtp.timestamp += 0xffffffff;
      }

      tsOffset.last = rtp.timestamp;
    }

    return rtp;
  }
}

export default RTPFactory;
