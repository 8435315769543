<template>
  <AppDialogue
    name="bookmark"
    ref="bookmarkDialogue"
    :title="$t('Add bookmark')"
    :buttons="bookmarkButtons"
    data-testid="bookmark-dialogue"
  >
    <div
      class=""
      slot="content"
    >
      <AppInputFields
        id="bookmark-context"
        class="bookmark-context-input"
        :disabled="bookmarkButtons[0].status === 'success'"
        v-model="bookmarkContent"
        :hasError="bookmarkIsError"
        :errorMessage="$t('Failed to add. Try again.')"
      >
        <template #error-message-icon>
          <SvgIcon
            name="error-message-icon"
            iconClass="general_hint"
            size="20px"
          />
        </template>
      </AppInputFields>
    </div>
  </AppDialogue>
</template>

<script>
import BookmarkApi from '@/services/BookmarkApi';
import AppDialogue from '@/components/ui/AppDialogue.vue';
import AppInputFields from '@/components/ui/AppInputFields.vue';

export default {
  components: {
    AppDialogue,
    AppInputFields,
  },
  data() {
    return {
      bookmarkContent: 'Bookmark',
      bookmarkIsError: false,
      bookmarkStatus: '',
      cameraName: null,
      encoderId: null,
      start: 0,
      callback: null
    };
  },
  methods: {
    showBookmarkDialog() {
      this.bookmarkContent = 'Bookmark';
      this.bookmarkIsError = false;
      this.bookmarkStatus = '';
      this.$refs.bookmarkDialogue.show();
    },
    hideBookmarkDialog() {
      this.bookmarkContent = 'Bookmark';
      this.$refs.bookmarkDialogue.hide();
      this.bookmarkIsError = false;
      this.bookmarkStatus = '';
      this.encoderId = null;
      this.cameraName = null;
      this.callback = null;
    },
    saveBookMark() {
      const dialogue = this.$refs.bookmarkDialogue;
      dialogue.setButtonsStatus(['loading', 'disabled']);
      this.bookmarkStatus = 'loading';
      this.bookmarkIsError = false;
      return BookmarkApi.saveBookMark({
        encoderId: this.encoderId,
        cameraName: this.cameraName,
        content: this.bookmarkContent,
        start: this.start
      }).then(() => {
        dialogue.setButtonsStatus(['success', 'disabled']);
        this.bookmarkStatus = 'success';
        this.encoderId = null;
        this.cameraName = null;
        if (this.callback) {
          this.callback();
          this.callback = null;
        }
      }).catch(() => {
        dialogue.setButtonsStatus();
        this.bookmarkIsError = true;
        this.bookmarkStatus = '';
      });
    },
  },
  computed: {
    bookmarkButtons() {
      return [
        {
          class: 'primary',
          text: this.bookmarkStatus === 'loading' ? this.$t('Adding') : this.$t('Add'),
          icon: '',
          status: this.bookmarkStatus,
          clickHandler: () => {
            this.saveBookMark();
          }
        },
        {
          class: 'ghost medium-secondary',
          text: this.$t('Cancel'),
          icon: '',
          status: this.bookmarkStatus === 'loading' ? 'disabled' : '',
          clickHandler: () => {
            this.hideBookmarkDialog();
          }
        }
      ];
    }
  },
  mounted() {
    this.$bus.$on('showBookmarkDialogue', ({
      encoderId, cameraName, start, callback
    }) => {
      this.encoderId = encoderId;
      this.cameraName = cameraName;
      this.start = start;
      this.callback = callback;

      this.showBookmarkDialog();
    });
  },
  destroyed() {
    this.$bus.$off('showBookmarkDialogue');
  },
};
</script>

<style lang="less" scoped>
.bookmark-context-input {
  padding-top: rem(15);
  padding-bottom: rem(25);
}
</style>
