import EXPORT from '@/constants/export';
import DEFAULT_PLAYBACK_VIEWCELL from '@/constants/defaultPlaybackViewCell';
import Viewcell from '@/models/Viewcell';
import LocalTime from '@/utility/local_time';

const mutations = {
  goPreviousPage(state) {
    state[state.currentType].currentPageIndex -= 1;
  },
  goNextPage(state) {
    state[state.currentType].currentPageIndex += 1;
  },
  setAiStatus(state, status) {
    state.status = status;
    if (status === 'loading') {
      state[state.currentType].currentPageIndex = 0;
    }
  },
  setCurrentType(state, type) {
    state.currentType = type;
  },
  setPlayAll(state, value) {
    state[state.currentType].isPlayingAll = value;
  },
  setExportList(state, result) {
    const { currentType } = state;
    state[currentType].export = result;
  },
  setSelectedExportList(state, result) {
    state.exportDataList = result;
  },
  clearResult(state) {
    const { currentType } = state;

    state[currentType].result = [];
  },
  setInitialTimeFrame(state, timeInfo) {
    if (state.smartSearch.criteria.date.endDateTime) {
      // inited
      return;
    }
    const endLocalTime = new LocalTime({
      timestamp: timeInfo.timestamp * 1000,
      tzoffs: timeInfo.timezone
    });
    const startLocalTime = endLocalTime.forwardMilliseconds(3600 * -1000);
    ['smartSearch'].forEach((type) => {
      state[type].criteria.date.endDateTime = {
        date: endLocalTime.queryDate,
        time: endLocalTime.queryTime,
      };
      state[type].criteria.date.startDateTime = {
        date: startLocalTime.queryDate,
        time: startLocalTime.queryTime,
      };
    });
  },
  setSmartMotionSearchResult(state, { data, isFinal, encoders }) {
    const keys = Object.keys(data);
    if (keys.length === 0 && state.smartSearch.result.length !== 0) {
      state.status = 'searched';
      return;
    }
    if (keys.length === 0 && state.smartSearch.result.length === 0) {
      state.status = isFinal ? 'failed' : 'busy';
      return;
    }

    if (!state[state.currentType].isPeopleDetection) {
      data.eventType = 'motion_detection';
    } else {
      data.eventType = 'intrusion_detection';
    }
    const currentChannel = state.smartSearch.criteria.device;
    data.channel = currentChannel;
    data.isPeopleDetection = state.smartSearch.criteria.isPeopleDetection;
    data.cameraName = encoders.find((encoder) => encoder.index === currentChannel).name;
    data.id = state.smartSearch.result.length;
    state.smartSearch.result.push(data);
  },
  setSearchKeyword(state, keyword) {
    const { currentType } = state;

    state[currentType].currentPageIndex = 0;
    state[currentType].keyword = keyword;
    state[currentType].isPlayingAll = false;
  },
  setActiveVideo(state, { data, encoders, timezone }) {
    const { currentType } = state;
    if (!data) {
      state[currentType].activeVideoId = -1;
      state[currentType].playList = [];
      return;
    }
    state[currentType].activeVideoId = data.id;
    const encoder = encoders[data.channel];
    // XXX: We need to store the Viewcell info inside state, otherwise it will not be reactive.
    const viewcell = new Viewcell({
      ...JSON.parse(JSON.stringify(DEFAULT_PLAYBACK_VIEWCELL)),
      encoder_id: encoder.index,
      begin: new LocalTime({
        timestamp: data.MotionStartTimestamp - encoder.recording_event.pre_event * 1000,
        tzoffs: timezone
      }),
      end: new LocalTime({
        timestamp: data.MotionStartTimestamp + (encoder.recording_event.post_event) * 1000,
        tzoffs: timezone
      }),
      clipMode: true,
    }, encoder);
    state[currentType].playList = [viewcell];
  },
  setExportStatus(state, status) {
    if (EXPORT.EXPORT_STATE.indexOf(status) === -1) {
      return;
    }
    state.exportStatus = status;
  },
  setResultList(state, { type, list }) {
    state[type].result = list;
  },
  updateRegion(state, { coordinates, bitmap, polygon }) {
    // this is smart search 2 only.
    state.smartSearch.criteria.coordinates = [...coordinates];
    state.smartSearch.criteria.bitmap = [...bitmap];
    state.smartSearch.criteria.polygon = [...polygon];
  },
  updatePeopleDetectionArea(state, peopleDetectionArea = null) {
    state.smartSearch.criteria.peopleDetectionArea = peopleDetectionArea;
  },
  resetStatusBeforeSearching(state) {
    const { currentType } = state;
    state[currentType].playList = [];
    state[currentType].activeVideoId = -1;
    state[currentType].result = [];
    state[currentType].export = [];
    state[currentType].keyword = '';
  },
  resetCriteriaToDefault(state) {
    const { currentType } = state;
    const { criteria } = state[currentType];
    criteria.date = {
      startDateTime: null,
      endDateTime: null
    };
    criteria.peopleDetection = false;
    criteria.peopleDetectionArea = false;
    criteria.sensitivity = 7;
    criteria.timeFilter = {
      duration: '500',
      interval: '1500'
    };
    criteria.polygon = [];
    criteria.bitmap = [];
    criteria.coordinates = [];
  }
};
export default mutations;
