import transformPointByRatio from './transformPointByRatio';

export default class Zoomer {
  constructor() {
    this.x = 0;
    this.y = 0;
    this.w = 1;
    this.h = 1;
    this.value = 100;
    this.width = 0;
    this.height = 0;
  }

  resize(width, height) {
    this.width = width;
    this.height = height;
  }

  render(width, height) {
    this.sourceWidth = width;
    this.sourceHeight = height;
  }

  wheel(ev, ref) {
    const { ratio } = ref.server.cropCanvas.pip;

    if (ev.wheelDelta > 0) {
      this.value = Math.min(ratio + 20, 400);
    }
    if (ev.wheelDelta < 0) {
      this.value = Math.max(ratio - 20, 100);
    }
    if (ref.settings.stretch) {
      this.zoom(ev.offsetX / this.width, ev.offsetY / this.height, ref);
    } else {
      const t = transformPointByRatio(
        ev.offsetX,
        ev.offsetY,
        this.sourceWidth,
        this.sourceHeight,
        this.width,
        this.height
      );
      if (t.x < 0 || t.y < 0 || t.x > 1 || t.y > 1) {
        return;
      }
      this.zoom(t.x, t.y, ref);
    }
  }

  zoom(x, y, ref) {
    this.w = 100 / this.value;
    this.h = 100 / this.value;
    ref.config('pip', {
      cx: x,
      cy: y,
      width: this.w,
      height: this.h,
      ratio: this.value,
    });
  }
}
