import Vue from 'vue';
import LAYOUT_COUNT from '@/constants/layoutCount';
import DEFAULT_VIEWCELL from '@/constants/defaultViewCell';
import Viewcell from '@/models/Viewcell';

const createDefaultPage = () => ({
  layout: '2x2',
  view: new Array(4).fill({}).map(() => (
    new Viewcell(JSON.parse(JSON.stringify({ ...DEFAULT_VIEWCELL })))
  ))
});

const getDeviceScenePages = (layout, encoders, channelCount) => {
  const pages = [];
  const views = [];
  new Array(channelCount).fill('').forEach((_, index) => {
    const defaultViewCell = new Viewcell(
      JSON.parse(JSON.stringify({ ...DEFAULT_VIEWCELL, encoder_id: index })), encoders[index]
    );
    views.push(defaultViewCell);
    if (views.length === LAYOUT_COUNT[layout]) {
      pages.push({ layout, view: views.slice() });
      views.splice(0, views.length);
    }
  });
  if (views.length !== 0) {
    new Array(LAYOUT_COUNT[layout] - views.length).fill({}).forEach(() => {
      const defaultViewCell = new Viewcell(
        JSON.parse(JSON.stringify({ ...DEFAULT_VIEWCELL }))
      );
      views.push(defaultViewCell);
    });
    pages.push({ layout, view: views.slice() });
  }
  return pages;
};

const getTargetScene = (state) => {
  if (state.isSceneMode) {
    return state.scenes[state.active];
  }
  return state.deviceScene;
};

const mutations = {
  createScene(state, index) {
    state.scenes[index].name = 'New scene';
    state.scenes[index].pages = [createDefaultPage()];
  },

  removeScene(state, index) {
    state.scenes[index].name = '';
    state.scenes[index].pages = [];
  },

  changeSceneName(state, config) {
    state.scenes[config.sceneIndex].name = config.name;
  },

  setIsSceneMode(state, isSceneMode) {
    state.isSceneMode = isSceneMode;
    state.active = -1;
    state.activePageIndex = 0;
    state.activeViewIndex = -1;
    state.activeDeviceIndex = -1;
  },

  setActive(state, active) {
    state.active = active;
    state.activePageIndex = 0;
    state.activeViewIndex = -1;
    state.activeDeviceIndex = -1;
  },

  setDefault(state, defaultScene) {
    state.default = defaultScene;
  },

  setActiveDeviceIndex(state, deviceIndex) {
    state.activeDeviceIndex = deviceIndex;
  },

  setScene(state, { scenes, encoders }) {
    if (!scenes) {
      return;
    }

    state.default = scenes.default_scene;
    const sceneList = scenes.scene_list;
    Object.entries(sceneList).forEach(([key, scene]) => {
      Vue.set(state.scenes, key, {
        name: scene.name,
        pages: Object.entries(scene.pages).filter(
          ([k, page]) => (page.layout !== '')
        ).map(
          ([k, page]) => ({
            layout: page.layout,
            view: JSON.parse(page.view).map(
              (view) => new Viewcell(view, encoders[view.encoder_id])
            ),
          })
        )
      });
    });
  },

  generateDeviceScene(state, { layout, encoders, channelCount }) {
    const pages = getDeviceScenePages(layout, encoders, channelCount);
    Vue.set(state.deviceScene, 'name', 'device-list');
    Vue.set(state.deviceScene, 'pages', pages);
  },

  setActiveViewIndex(state, viewIndex) {
    state.activeViewIndex = viewIndex;
  },

  setAllViewDisplayConfig(state, displayConfig) {
    const targetScene = getTargetScene(state);

    targetScene.pages.forEach((page) => {
      page.view.forEach((view) => {
        Object.keys(displayConfig).forEach((key) => {
          view.options.display[key] = displayConfig[key];
        });
      });
    });
  },

  setActivePageIndex(state, page) {
    state.activePageIndex = page;
  },

  goPreviousPage(state) {
    state.activePageIndex -= 1;
    state.activeDeviceIndex = -1;
    state.activeViewIndex = -1;
  },

  goNextPage(state) {
    state.activePageIndex += 1;
    state.activeDeviceIndex = -1;
    state.activeViewIndex = -1;
  },

  goNextNonEmptyPage(state) {
    const targetScene = getTargetScene(state);
    const firstNonEmptyPageIndex = targetScene.pages.findIndex((page) => (
      page.view.filter((v) => !v.isEmpty).length > 0));
    const nextNonEmptyPageIndex = targetScene.pages.findIndex((page, index) => (
      index > state.activePageIndex && page.view.filter((v) => !v.isEmpty).length > 0));
    const activePageIndex = nextNonEmptyPageIndex > firstNonEmptyPageIndex
      ? nextNonEmptyPageIndex : firstNonEmptyPageIndex;
    if (activePageIndex === state.activePageIndex) {
      return;
    }
    state.activePageIndex = activePageIndex;
    state.activeDeviceIndex = -1;
    state.activeViewIndex = -1;
  },

  changeLayout(state, { layout, encoders, channelCount }) {
    // edit mode means using object config
    if (state.isSceneMode || typeof layout !== 'string') {
      let sceneIndex = +state.active;
      let pageIndex = +state.activePageIndex;
      let $layout = layout;
      if (typeof layout !== 'string') {
        sceneIndex = layout.sceneIndex;
        pageIndex = layout.pageIndex;
        $layout = layout.layout;
      }
      const currentPage = state.scenes[sceneIndex].pages[pageIndex];
      currentPage.layout = $layout;
      const newCount = LAYOUT_COUNT[$layout];
      const oldCount = currentPage.view ? currentPage.view.length : 0;
      if (newCount > oldCount) {
        const newAdded = new Array(newCount - oldCount).fill({})
          .map(() => (new Viewcell({ ...DEFAULT_VIEWCELL })));
        currentPage.view = currentPage.view.concat(newAdded);
      } else {
        currentPage.view.splice(newCount, oldCount - newCount);
      }
    } else {
      // regenerate deviceScene pages
      state.deviceScene.pages = getDeviceScenePages(layout, encoders, channelCount);
    }
    state.activeDeviceIndex = -1;
    state.activeViewIndex = -1;
    state.activePageIndex = 0;
  },

  createPage(state, sceneIndex) {
    state.scenes[sceneIndex].pages.push(createDefaultPage());
  },

  removePage(state, payload) {
    const { pages } = state.scenes[payload.sceneIndex];
    if (pages.length <= 1) {
      return;
    }
    pages.splice(payload.pageIndex, 1);
  },

  assignScene(state, { sceneIndex, payload }) {
    Vue.set(state.scenes, sceneIndex, payload);
  },

  setViewData(state, config) {
    const currentPage = state.scenes[config.sceneIndex].pages[config.pageIndex];
    currentPage.view[config.viewIndex].encoder_id = config.encoderIndex;
  },

  handleFirstFetch(state) {
    if (state.default !== -1) {
      state.active = state.default;
      state.isSceneMode = true;
    }
  },
};

export default mutations;
