export default function transformPointForStretch(evt, canvasElement) {
  const { offsetX, offsetY } = evt;
  const {
    width, height, offsetWidth, offsetHeight
  } = canvasElement;
  const sourceRatio = width / height;
  const destinationRatio = offsetWidth / offsetHeight;

  if (sourceRatio < destinationRatio) {
    const viewWidth = offsetHeight * sourceRatio;
    const fillWidth = Math.round((offsetWidth - viewWidth) / 2);

    if (fillWidth <= offsetX && offsetX <= fillWidth + viewWidth) {
      return {
        x: ((offsetX - fillWidth) / viewWidth) * offsetWidth,
        y: offsetY,
      };
    }
  } else {
    const viewHeight = offsetWidth / sourceRatio;
    const fillHeight = Math.round((offsetHeight - viewHeight) / 2);

    if (fillHeight <= offsetY && offsetY <= fillHeight + viewHeight) {
      return {
        x: offsetX,
        y: ((offsetY - fillHeight) / viewHeight) * offsetHeight
      };
    }
  }

  return null;
}
