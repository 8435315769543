<template>
  <div
    v-on-clickaway="onStorageDropdownClickaway"
    data-testid="header-storage-button"
  >
    <SvgIcon
      v-if="!isStorageAbnormal"
      class="icon"
      iconClass="status_volume_default"
      @click="toggleStorageDropdown"
      size="28px"
      outerSize="48px"
      :active="isStorageDropdownVisible"
    />
    <SvgIcon
      v-else
      class="icon"
      iconClass="status_volume_alert"
      @click="toggleStorageDropdown"
      size="28px"
      outerSize="48px"
      :active="isStorageDropdownVisible"
    />
    <div
      class="storage-dropdown"
      v-if="isStorageDropdownVisible"
    >
      <div
        class="information"
      >
        <div class="title">
          <h5 class="bold">
            {{ $t('Storage') }}
          </h5>
        </div>
        <div
          class="loading"
          v-show="!isfetchingDone"
        />
        <div
          class="status-progress-bar-container"
          v-show="!isStorageAbnormal"
        >
          <AppProgressStatusBar
            :progress="usedProgress*100"
            :rules="[{ rule: () => isAlmostFull, color: '#FF615C' }]"
            :topLabel="`${usedPercentage}`"
            :bottomLabel="`${storage.Used} ${$t('GB of')} ${storage.Capacity} ${$t('GB used')}`"
          />
        </div>
        <div
          class="abnormal-nofitication"
          v-show="isfetchingDone && isStorageAbnormal "
        >
          <div class="empty-block">
            <div class="icon no-volume-icon" />
          </div>
          <div class="message">
            <h6>{{ abnormalStorageMessage }}</h6>
          </div>
        </div>
      </div>
      <div class="option-separator" />
      <div
        class="button-group"
        v-if="isAdministrator"
      >
        <div
          class="storage-button"
          @click="onClickStorageSettings"
        >
          <h6>{{ $t('Storage settings') }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway';
import AppProgressStatusBar from '@/components/ui/AppProgressStatusBar.vue';
import utils from '@/utility/utils';
import {
  mapGetters
} from 'vuex';

export default {
  name: 'AppHeaderStorageButton',
  directives: {
    onClickaway,
  },
  components: {
    AppProgressStatusBar,
  },
  data() {
    return {
      isStorageDropdownVisible: false,
    };
  },
  methods: {
    toggleStorageDropdown() {
      this.isStorageDropdownVisible = !this.isStorageDropdownVisible;
    },
    onStorageDropdownClickaway() {
      this.isStorageDropdownVisible = false;
    },
    onClickStorageSettings() {
      const url = `${window.location.origin}/settings.html#!settings/settings_storage_storage`;
      window.open(url, '_blank');
      this.onStorageDropdownClickaway();
    },
    goStoragePage() {
      window.location = `${window.location.origin}/settings.html#!settings/settings_storage_storage`;
    }
  },
  computed: {
    ...mapGetters('user', ['isAdministrator']),
    ...mapGetters('websocket', ['storage']),
    usedProgress() {
      return Number(this.storage.Used / this.storage.Capacity) || 0;
    },
    usedPercentage() {
      return this.usedProgress.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
    },
    abnormalStorageMessage() {
      if (this.isNoDiskInstalled) {
        return this.$t('No disk is installed');
      }
      if (this.isNoVolumeCreated) {
        return this.$t('No volume is created');
      }
      return '';
    },
    isStorageAbnormal() {
      return this.storage.Status !== 'Normal';
    },
    isAlmostFull() {
      return this.storage.Status === 'AlmostFull';
    },
    isNoDiskInstalled() {
      return this.storage.Status === 'NoDisk';
    },
    isNoVolumeCreated() {
      return this.storage.Status === 'NoVolume';
    },
    isfetchingDone() {
      return !utils.isEmptyObject(this.storage);
    }
  }
};
</script>

<style lang="less" scoped>
.storage-dropdown {
  z-index: 999;

  position: absolute;
  top: rem(0);
  right: rem(0);
  min-width: rem(396);
  background: #2E2E2E;
  border: 1px solid #000000;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.30);
  transform: translateY(rem(48)) translateX(-40%);
  .information {
    padding: rem(18) rem(20);
    .loading {
      width: rem(360);
      height: rem(60);
    }
    .abnormal-nofitication {
      .no-volume-icon {
        height: rem(100);
        width: rem(100);
        background-image: url('../assets/illustration_no_volume.svg');
      }
      .message {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(40);
      }
    }
  }
  .title {
    padding: rem(10) 0;
  }
}
.button-group {
  display: flex;
  flex-direction: row;

  .storage-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: rem(74);
    border: solid 1px black;
    cursor: pointer;
    &.disabled {
     pointer-events: none;
     color: #4F4F4F;
    }
  }
  .storage-button span {
    text-align: center;
  }
}

</style>
