<template>
  <div class="wrapper">
    <div class="toggle-button-control">
      <input
        type="checkbox"
        v-if="id"
        :id="id"
        :value="value"
        @change="toggle"
        :true-value="defaultTrueValue"
        :false-value="defaultFalseValue"
        :disabled="disabled"
      >
      <div
        class="toggle-button"
        :class="{ checked: checked, disable: disabled}"
        @click="toggle"
        :style="buttonBackgroundCSS"
      >
        <div
          class="pure-button"
          :style="buttonCSS"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppToggleButton',
  props: {
    value: {
      type: [String, Boolean],
      default: undefined,
    },
    trueValue: {
      type: [String, Number],
      default: undefined,
    },
    falseValue: {
      type: [String, Number],
      default: undefined,
    },
    colors: {
      type: Object,
      default() {
        return {
          cardColor: '#232323',
          normalColor: '#e0e0e0',
          checkedNormalColor: '#2986ff',
          disabledUncheckedColor: '#4F4F4F',
          disabledCheckedColor: '#222222',
          disabledBorderColor: '#4F4F4F',
        };
      },
    },
    id: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 20,
    },
    diameter: {
      type: Number,
      default: 14,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      if (this.disabled) {
        return;
      }
      const v = (this.checked) ? this.defaultFalseValue : this.defaultTrueValue;
      this.$emit('input', v);
    },
  },
  computed: {
    buttonBackgroundCSS() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
        boxShadow: 'inset 0px 0px 1px 0px rgba(0, 0, 0, 0.33)',
        borderRadius: '12px',
        transition: 'all 0.3s ease-in-out',
        backgroundColor: `${this.backgroundColor}`,
        cursor: 'pointer',
        border: `1px solid ${this.backgroundBorderColor}`,
      };
    },
    buttonCSS() {
      return {
        height: `${this.diameter}px`,
        width: `${this.diameter}px`,
        border: `2px solid ${this.buttonBorderColor}`,
        borderRadius: '10px',
        background: `${this.buttonBackgroundColor}`,
        transition: 'all 0.3s ease-in-out',
      };
    },
    buttonBorderColor() {
      if (this.disabled && this.checked) {
        return this.colors.disabledBorderColor;
      }
      if (!this.disabled && this.checked) {
        return this.colors.checkedNormalColor;
      }
      return this.colors.cardColor;
    },
    buttonBackgroundColor() {
      if (this.disabled && !this.checked) {
        return this.colors.disabledUncheckedColor;
      }
      if (this.disabled && this.checked) {
        return this.colors.disabledCheckedColor;
      }
      if (!this.disabled && !this.checked) {
        return this.colors.normalColor;
      }
      return this.colors.normalColor;
    },
    backgroundColor() {
      if (!this.disabled && this.checked) {
        return this.colors.checkedNormalColor;
      }
      if (this.disabled && this.checked) {
        return this.colors.disabledUncheckedColor;
      }
      return this.colors.cardColor;
    },
    backgroundBorderColor() {
      if (!this.disabled && this.checked) {
        return this.colors.checkedNormalColor;
      }
      if (!this.disabled && !this.checked) {
        return this.colors.normalColor;
      }
      return this.colors.disabledUncheckedColor;
    },
    checked() {
      return (this.value === this.defaultTrueValue) && !(this.value === this.defaultFalseValue);
    },
    // Note: To increase readability, set default value in computed
    // instead of setting in props default value
    defaultTrueValue() {
      return (typeof this.trueValue === 'undefined') ? true : this.trueValue;
    },
    // Note: To increase readability, set default value in computed
    // instead of setting in props default value
    defaultFalseValue() {
      return (typeof this.falseValue === 'undefined') ? false : this.falseValue;
    },
  },
};
</script>

<style scoped lang="less">
.wrapper {
  display: inline-block;
}
.toggle-button-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    display: none;
  }
  .toggle-button {
    box-sizing: border-box;
    &.checked {
      box-shadow: none;
      .pure-button {
        transform: translate(21px, 0px);
      }
      &:not(.disable) .pure-button {
        background-color: #ffffff !important;
      }
    }
    &:not(.disable) {
      // Note: CSS rule is in computed > buttonBackgroundCSS
      .pure-button {
        // Note: CSS rule is in computed > buttonCSS
        &:hover {
          background-color: #ffffff !important;
        }
      }
      &:hover {
        border: 1px solid #ffffff !important;
      }
      &.checked:hover {
        background-color: #0066ff !important;
        border: 1px solid #0066ff !important;
      }
    }
  }
}
</style>
