const GROUP = { ADMIN: 'administrator', USER: 'operator' };
const getters = {
  isAdministrator(state) {
    return state.group === GROUP.ADMIN;
  },
  isPasswordVerified(state) {
    const admin = state.users[0];
    return (state.userName !== admin.username // not default administrator
        || !admin.do_check_password // skip with don't remind me
        || admin.password_verified !== false);
  },
  isTmisVerified(state, getter, rootState, rootGetters) {
    if (!rootGetters['system/systemCapability'].support_tmis) {
      // skip check tmis info
      return true;
    }
    if (state.tmis.showTrendMicroWizard && !state.tmis.expire && state.group === 'administrator') {
      return false;
    }
    return true;
  },
};
export default getters;
