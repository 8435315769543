<template>
  <div
    class="panel-content"
    @change="change"
  >
    <AppUniqueAccordionToggleBlock
      :disabled="disabled"
      :title="$t('Status bar')"
      :opened="shouldOpenAccordion"
    >
      <div
        class="block wrap"
        v-if="!disabled"
      >
        <div class="block-line main">
          <h6 class="bold">
            {{ $t('Information') }}
          </h6>
          <AppToggleButton
            id="infoAll"
            v-model="infoEnable"
            @input="toggleInfo"
          />
        </div>
        <div class="block-line sub">
          <CheckBox
            trueValue="1"
            falseValue="0"
            v-if="displayConfig"
            v-model="displayConfig.camera_type"
            id="cameraType"
            name="cameraType"
            :label="$t('Camera type')"
          />
        </div>
        <div class="block-line sub">
          <CheckBox
            trueValue="1"
            falseValue="0"
            v-model="ipOrNameEnable"
            id="cameraIpOrName"
            name="cameraIpOrName"
            @input="checkCamIpOrName"
            :label="$t('Camera name & IP address')"
          />
        </div>
        <div class="block-line sub-2">
          <AppRadioButton
            name="ipOrName"
            type="radio"
            v-model="ipOrName"
            value="name"
            :label="$t('Camera name')"
          />
        </div>
        <div class="block-line sub-2">
          <AppRadioButton
            name="ipOrName"
            type="radio"
            v-model="ipOrName"
            value="ip"
            :label="$t('IP address')"
          />
        </div>
        <div class="block-line sub">
          <CheckBox
            trueValue="1"
            falseValue="0"
            v-if="displayConfig"
            v-model="displayConfig.camera_time"
            id="cameraTime"
            name="cameraTime"
            :label="$t('Camera time')"
          />
        </div>
        <div class="block-line">
          <button
            class="apply-to-all ghost medium-secondary"
            @click="applyToAllCells('info')"
          >
            {{ $t('Apply to all view cells') }}
          </button>
        </div>
      </div>
    </AppUniqueAccordionToggleBlock>
    <AppUniqueAccordionToggleBlock
      :disabled="isCameraStatusDisabled"
      :title="$t('Camera status')"
      :opened="shouldOpenAccordion"
    >
      <div
        class="block wrap"
        v-if="!disabled"
      >
        <div
          class="block-line main"
          v-if="systemCapability.support_tmis"
        >
          <h6 class="bold">
            {{ $t('Trend Micro IoT Security') }}
          </h6>
          <AppToggleButton
            id="statusTmis"
            v-model="displayConfig.tmis"
            @input="change"
            trueValue="1"
            falseValue="0"
          />
        </div>
        <div class="block-line main">
          <h6 class="bold">
            {{ $t('Video content analysis') }}
          </h6>
          <AppToggleButton
            id="statusVca"
            v-model="displayConfig.vca"
            @input="change"
            trueValue="1"
            falseValue="0"
          />
        </div>
        <div class="block-line">
          <button
            class="apply-to-all ghost medium-secondary"
            @click="applyToAllCells('status')"
          >
            {{ $t('Apply to all view cells') }}
          </button>
        </div>
      </div>
    </AppUniqueAccordionToggleBlock>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import AppRadioButton from '@/components/ui/AppRadioButton.vue';
import AppToggleButton from '@/components/ui/AppToggleButton.vue';
import AppUniqueAccordionToggleBlock from '@/components/AppUniqueAccordionToggleBlock.vue';

export default {
  name: 'DisplayPanel',
  components: {
    CheckBox: () => import('@/components/ui/CheckBox.vue'),
    AppRadioButton,
    AppToggleButton,
    AppUniqueAccordionToggleBlock,
  },
  data() {
    return {
      shouldOpenAccordion: true,
      infoEnable: true,
      ipOrNameEnable: '1',
      config: {
        info: ['camera_type', 'camera_name', 'camera_ip', 'camera_time'],
        status: ['tmis', 'vca']
      }
    };
  },
  computed: {
    ...mapState(['scene']),
    ...mapGetters('scene', ['activeView']),
    ...mapGetters('system', ['systemCapability']),
    disabled() {
      return this.activeView.encoder_id === -1;
    },
    displayConfig() {
      return this.activeView?.options?.display || {};
    },
    encoder() {
      return this.activeView?.camera || {};
    },
    ipOrName: {
      get() {
        const isIp = this.displayConfig?.camera_ip === '1';
        const isName = this.displayConfig?.camera_name === '1';
        if (!isIp && !isName) {
          return 'null';
        }
        return isIp ? 'ip' : 'name';
      },
      set(value) {
        const ipVal = (value === 'ip') ? '1' : '0';
        const nameVal = (value === 'name') ? '1' : '0';
        this.activeView.options.display.camera_ip = ipVal;
        this.activeView.options.display.camera_name = nameVal;
      }
    },
    isCameraStatusDisabled() {
      return this.disabled || this.encoder.isCameraOffline;
    }
  },
  methods: {
    ...mapActions('scene', ['updateAllViewDisplayConfig', 'updateScene']),
    change() {
      this.updateScene();
    },
    toggleInfo(val) {
      const value = val === false ? '0' : '1';
      this.displayConfig.camera_type = value;
      this.displayConfig.camera_time = value;

      this.ipOrNameEnable = value;
      this.checkCamIpOrName(value);
      this.change();
    },
    checkCamIpOrName(value) {
      if (value === '0') {
        this.ipOrName = 'null';
      } else {
        this.ipOrName = 'name';
      }
    },
    applyToAllCells(type) {
      const displayConfig = {};
      this.config[type].forEach((key) => {
        displayConfig[key] = this.activeView.options.display[key];
      });
      this.updateAllViewDisplayConfig(displayConfig);
    }
  },
  watch: {
    displayConfig: {
      handler() {
        const check = ['camera_type', 'camera_name', 'camera_ip', 'camera_time'].some((key) => this.displayConfig[key] === '1');
        this.infoEnable = check;
      },
      immediate: true,
      deep: true
    },
    ipOrName: {
      handler(value) {
        if (value !== 'null') {
          this.ipOrNameEnable = '1';
        } else {
          this.ipOrNameEnable = '0';
        }
      },
      immediate: true
    }
  },
};
</script>

<style lang="less" scoped>

.panel-content {
  height: 100%;
}
.block {
  padding: 0 rem(40);
  .sub, .sub-2 {
    min-height: rem(30px);
  }
  .sub-2 {
    padding-left: rem(30px);
  }
  &.wrap {
    padding-bottom: rem(20px);
  }
}
.bold {
  font-weight: bold;
}
.apply-to-all {
  margin-top: rem(15px);
  position: relative;
}

</style>
