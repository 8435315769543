export default function transformPointByRatio(x, y, sourceWidth, sourceHeight, targetWidth, targetHeight) {
  const sourceRatio = sourceWidth / sourceHeight;
  const targetRatio = targetWidth / targetHeight;
  if (sourceRatio < targetRatio) {
    const xR = x / targetHeight;
    const ratioDiff = ((targetRatio - sourceRatio) / 2);
    return {
      x: (xR - ratioDiff) / sourceRatio,
      y: y / targetHeight,
    };
  }
  const viewHeight = targetWidth / sourceRatio;
  const viewPad = (targetHeight - viewHeight) / 2;
  return {
    x: x / targetWidth,
    y: (y - viewPad) / viewHeight,
  };
}
