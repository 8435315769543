import { PLUGINFREE } from '@vivotek/lib-medama';

const SUPPORT_VIDEO_CODEC_MAP = {
  H264: 'H.264',
  HEVC: 'H.265'
};

export default Object.freeze({
  ...PLUGINFREE,
  WORKER_264_SRC: `${process.env.BASE_URL}libde264/h264decoder-1.0.0.1.js`,
  WORKER_265_SRC: `${process.env.BASE_URL}libde265/h265decoder-1.0.0.3.js`,
  DEWARP: {
    '1O': 0,
    '1R': 1,
    '1P': 2,
    /* These options are supported but for some reason we don't want to open them to the use
    '1O3R': 3,
    '1O8R': 4,
    */
  },
  MOUNTTYPE: {
    wall: 0,
    ceiling: 1,
    floor: 2,
  },
  PLAYBACK_RATE_MAP: [0.25, 0.5, 1, 2, 4],
  SUPPORT_VIDEO_CODEC: PLUGINFREE?.SUPPORT_VIDEO_CODEC.map((codec) => SUPPORT_VIDEO_CODEC_MAP[codec]).concat(['SVC']) || ['H.264', 'H.265', 'SVC']
});
