<template>
  <th class="sort-button-wrapper">
    <SvgIcon
      iconClass="general_arrange"
      size="28px"
      outerSize="32px"
      :active="isSortDropdownOpen"
      @click="isSortDropdownOpen = !isSortDropdownOpen"
    />
    <div
      class="sort-dropdown"
      v-if="isSortDropdownOpen"
      v-on-clickaway="onSortDropdownClickaway"
    >
      <div
        class="option"
        v-for="(item, index) in headerList"
        :key="index"
        @click="sort(index)"
        :class="{
          active: currentHeaderIndex === index
        }"
      >
        {{ item.name }}
        <SvgIcon
          :iconClass="sortIcon"
          size="20px"
          v-if="currentHeaderIndex === index && item.sort !== null"
        />
      </div>
    </div>
  </th>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';

export default {
  name: 'AppTableSortDropdown',
  directives: {
    onClickaway,
  },
  props: {
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isSortDropdownOpen: false,
    };
  },
  methods: {
    sort(index) {
      if (this.disabled) {
        return;
      }

      this.$parent.sortHandler(index);
    },
    onSortDropdownClickaway() {
      this.isSortDropdownOpen = false;
    },
  },
  computed: {
    headerList() {
      return this.$parent.headerList;
    },
    currentHeaderIndex() {
      return this.$parent.currentHeaderIndex;
    },
    sortIcon() {
      return this.$parent.sortIcon;
    },
  },
};
</script>

<style lang="less" scoped>
.sort-button-wrapper {
  overflow: initial !important;
  position: relative;
  .sort-dropdown {
    position: absolute;
    background: #2e2e2e;
    border: 1px solid #000000;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    width: 166px;
    z-index: 2;
    .option {
      display: flex;
      // height: 32px;
      align-items: center;
      padding: 8px 12px;
      font-size: 12px;
      color: #ffffff;
      &.active {
        background: #3e3e3e;
        color: #2986ff;
      }
    }
  }
}
</style>
