<template>
  <modal
    :name="name"
    ref="general-modal"
    class="modal-content-container"
    v-on="$listeners"
    :reset="true"
    :width="largeContent ? '828px' : '540px'"
    :height="height"
    :clickToClose="clickToClose"
    @before-close="onClose"
  >
    <div class="header">
      <h5 class="bold">
        {{ title }}
      </h5>
      <svg-icon
        className="close-icon"
        iconClass="general_cross"
        size="20px"
        outerSize="32px"
        :disabled="disabledCloseIcon"
        @click="onCloseIconClick"
      />
    </div>
    <slot name="content" />
    <div
      class="control"
      :data-large-content="largeContent"
    >
      <button
        v-for="(item,index) in buttonList"
        :key="index"
        @click="clickHandler(item.clickHandler)"
        class="dialogue-button"
        :class="[item.class,item.status]"
        :disabled="item.status !== ''"
      >
        <SvgIcon
          v-if="statusIcons[index]"
          class="button-icon"
          :iconClass="statusIcons[index]"
          size="20px"
        />
        {{ item.text }}
      </button>
    </div>
  </modal>
</template>
<script>
import Vue from 'vue';
import VModal from 'vue-js-modal';

Vue.use(VModal);

export default {
  name: 'AppDialogue',
  props: {
    name: {
      // Note: if you have multiple dialogues in the same component
      // You should assign a unique name for AppDialogue
      // otherwise, you will trigger both AppDialogues with the same name
      type: String,
      default: 'dialogue',
    },
    type: {
      type: String,
      default: 'confirm'
    },
    title: {
      type: String,
      default() {
        return this.$t('Title');
      },
    },
    height: {
      type: String,
      default: 'auto',
    },
    /**
     * There is 2 type of dialog for now.
     * One is only 1 wording for a simple alert/confirm
     * Another is having huge content inside.
     * The button location and format and dialog width is different.
     */
    largeContent: {
      type: Boolean,
      default: false,
    },
    closeWhenClickButton: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: null
    },
    clickToClose: {
      // If set to false, it will not be possible to
      // close modal by clicking on the background or by pressing Esc key
      type: Boolean,
      default: true,
    },
    primaryText: {
      type: String,
      default: ''
    },
    secondaryText: {
      type: String,
      default: ''
    },
    closeIconClickHandler: {
      type: Function,
      default: undefined,
    },
    onClose: {
      type: Function,
      default: () => {}
    },
  },
  methods: {
    show() {
      this.$modal.show(this.name);
    },
    hide() {
      this.$modal.hide(this.name);
    },
    closeWhenClickButtonHandler() {
      if (this.closeWhenClickButton) {
        this.hide();
      }
    },
    setButtonsStatus(status = ['', '']) {
      const target = !this.buttons ? this.defaultButtonList : this.buttons;

      target.forEach((item, index) => {
        item.status = status[index] || '';
      });

      if (status.includes('success')) {
        setTimeout(() => {
          this.hide();
          target.forEach((item, index) => {
            item.status = '';
          });
        }, 2000);
      }
    },
    clickHandler(itemHandler) {
      itemHandler();
      if (this.closeWhenClickButtonHandler) {
        this.closeWhenClickButtonHandler();
      }
    },
    onCloseIconClick() {
      if (!this.closeIconClickHandler) {
        this.hide();
        return;
      }
      this.closeIconClickHandler();
    }
  },
  computed: {
    defaultButtonList() {
      return [
        {
          class: 'primary',
          text: this.primaryText || this.$t('OK'),
          icon: '',
          status: '',
          clickHandler: () => {
            this.$emit('confirm', true);
          }
        },
        {
          class: 'ghost medium-secondary',
          text: this.secondaryText || this.$t('Cancel'),
          icon: '',
          status: '',
          clickHandler: () => {
            this.$emit('confirm', false);
          }
        }
      ];
    },
    disabledCloseIcon() {
      const status = this.buttonList.map((item) => item.status);
      if (status.includes('loading')) {
        return true;
      }
      return false;
    },
    buttonList() {
      if (this.buttons) {
        return this.buttons;
      }
      if (this.type === 'alert') {
        return [this.defaultButtonList[0]];
      }
      return this.defaultButtonList;
    },
    statusIcons() {
      const iconMapping = [
        {
          loading: 'status_loading_s',
          success: 'general_success_s'
        },
        {
          loading: 'status_loading',
          success: 'status_success'
        }
      ];
      return this.buttonList.map((item, index) => iconMapping[index][item.status] || '');
    },
  }
};
</script>
<style lang="less" scoped>
.modal-content-container {
  .header {
    line-height: rem(60);
    .close-icon {
      color: #B2B2B2;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .control {
    margin-top: rem(30);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: rem(30);
    .dialogue-button {
      position: relative;
      margin-left: rem(20);
      width: rem(148);
      display: flex;
      align-items: center;
      justify-content: center;
      .button-icon {
        position: absolute;
        right: rem(5);
      }
      &.loading {
        &.primary{
          background: #0066FF;
          border: 1px solid #0066FF;
          color:#fff
        }
        .button-icon{
          pointer-events: none;
          animation:spin 1.5s linear infinite;
        }
      }
      &.success.primary{
        background: #4FD345;
        border: 1px solid #4FD345;
        color:#fff
      }
    }
    &[data-large-content] {
      justify-content: flex-end;
    }
  }
}
</style>
