<template>
  <div
    class="calendar-panel"
    :class="{ 'default-background': useDefaultBackground, disabled }"
    @mousedown.prevent.stop=""
  >
    <div class="calendar-inner">
      <div class="calendar-header">
        <div
          class="prev"
          @mousedown.prevent.stop="selectPrev"
        />
        <div
          class="title"
          @mousedown.prevent.stop="selectView"
        >
          <h4>{{ title }}</h4>
        </div>
        <div
          class="next"
          @mousedown.prevent.stop="selectNext"
        />
      </div>
      <div
        class="row weekdays"
        v-if="view === 'DATES'"
      >
        <div
          class="cell"
          v-for="day in 7"
          :key="day"
        >
          {{ dayNames[day - 1] }}
        </div>
      </div>
      <div
        class="dates"
        v-if="view === 'DATES'"
      >
        <div
          v-for="(_, row) in 6"
          :key="row"
          class="row"
        >
          <!-- eslint disabled -->
          <div
            v-for="(__, column) in 7"
            :key="column"
            class="cell date"
            :class="{
              today: !getDate(row, column).outdate &&
                checkIsToday(getDate(row, column)),
              selected: !disabled && !getDate(row, column).outdate &&
                checkIsSelectedDay(getDate(row, column)),
              disabled: getDate(row, column).disabled || getDate(row, column).outdate,
              outdate: getDate(row, column).outdate
            }"
            :title="(!getDate(row, column).outdate && getDate(row, column).disabled)
              ? disableTip : ''"
            @mousedown.prevent.stop="selectDate(getDate(row, column))"
          >
            {{ getDate(row, column).day }}
          </div>
          <!-- eslint enabled -->
        </div>
      </div>
      <div
        class="months"
        v-if="view === 'MONTHS'"
      >
        <div
          v-for="(_, row) in 3"
          :key="row"
          class="row"
        >
          <div
            v-for="(__, column) in 4"
            :key="column"
            class="cell month"
            :class="{ selected: checkIsSelectedMonth(year, getMonth(row, column)) }"
            @mousedown.prevent.stop="selectMonth(getMonth(row, column))"
          >
            {{ monthNames[getMonth(row, column)] }}
          </div>
        </div>
      </div>
      <div
        class="years"
        v-if="view === 'YEARS'"
      >
        <div
          v-for="(_, row) in 3"
          :key="row"
          class="row"
        >
          <div
            v-for="(__, column) in 4"
            :key="column"
            class="cell year"
            :class="{ selected: getYear(row, column) === selectedYear }"
            @mousedown.prevent.stop="selectYear(getYear(row, column))"
          >
            {{ getYear(row, column) }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button
        class="ghost small-secondary today"
        @mousedown.prevent.stop="selectToday"
        :disabled="disabled || shouldDisableTodayButton"
      >
        {{ $t('Today') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    useDefaultBackground: {
      type: Boolean,
      default: true,
    },
    today: {
      type: Date,
      default: () => new Date()
    },
    disableDates: {
      type: Array,
      default: () => []
    },
    disableTip: {
      type: String,
      default: ''
    },
    clearSelectedDateOnDisable: {
      type: Boolean,
      default: false,
    },
    defaultDate: {
      type: Object,
      default: () => ({
        year: 0,
        month: 0,
        date: 0
      })
    }
  },
  data() {
    return {
      view: 'DATES',
      year: this.today.getFullYear(),
      month: this.today.getMonth(),
      selectedYear: null,
      selectedMonth: null,
      selectedDay: null,
      selectedDate: null,
    };
  },
  computed: {
    title() {
      switch (this.view) {
        case 'DATES':
          return `${this.monthFullNames[this.month]} ${this.year}`;
        case 'MONTHS':
          return this.year;
        case 'YEARS':
          return `${this.startYear} - ${this.endYear}`;
        default:
          return '';
      }
    },
    monthNames() {
      return [this.$t('Jan'), this.$t('Feb'), this.$t('Mar'), this.$t('Apr'),
        this.$t('May'), this.$t('Jun'), this.$t('Jul'), this.$t('Aug'),
        this.$t('Sep'), this.$t('Oct'), this.$t('Nov'), this.$t('Dec')];
    },
    monthFullNames() {
      return [this.$t('January'), this.$t('February'), this.$t('March'), this.$t('April'),
        this.$t('May'), this.$t('June'), this.$t('July'), this.$t('August'),
        this.$t('September'), this.$t('October'), this.$t('November'), this.$t('December')];
    },
    dayNames() {
      return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    },
    startYear() {
      const thisYear = this.today.getFullYear();
      if (this.year < thisYear) { // before this page
        const page = Math.ceil((thisYear - this.year) / 12);
        return thisYear - 12 * page;
      }
      if (this.year >= thisYear + 12) { // after this page
        const page = Math.floor((this.year - (thisYear + 12)) / 12) + 1;
        return thisYear + 12 * page;
      }
      return thisYear;
    },
    endYear() {
      return this.startYear + 11;
    },
    firstDayIndex() {
      let day = new Date(this.year, this.month, 1).getDay() + 1;
      day = -(day - 1);

      return day;
    },
    daysInMonth() {
      return new Date(this.year, this.month + 1, 0).getDate();
    },
    daysInPreviousMonth() {
      return new Date(this.year, this.month, 0).getDate();
    },
    shouldDisableTodayButton() {
      const date = {
        year: this.selectedYear, month: this.selectedMonth, day: this.selectedDay
      };
      return this.selectedYear === this.year && this.selectedMonth === this.month
        && this.checkIsToday(date);
    },
  },
  created() {
    this.setDefaultDate();
  },
  watch: {
    disableDates() {
      this.$forceUpdate();
    },
    disabled() {
      if (this.disabled && this.clearSelectedDateOnDisable) {
        this.selectedDate = null;
      }
    },
  },
  methods: {
    setDefaultDate() {
      const { year, month, date } = this.defaultDate;
      if (year === 0) {
        return;
      }
      this.setSelectedDate(year, month, date);
    },
    selectPrev() {
      if (this.view === 'DATES') {
        if (this.month > 0) {
          this.month -= 1;
        } else {
          this.month = 11;
          this.year -= 1;
        }
        this.$emit('selectMonth', `${this.year}-${(`00${this.month + 1}`).substr(-2)}`);
      } else if (this.view === 'MONTHS') {
        this.year -= 1;
        this.month = 0;
      } else if (this.view === 'YEARS') {
        this.year -= 12;
        this.month = 0;
      }
    },
    selectNext() {
      if (this.view === 'DATES') {
        if (this.month < 11) {
          this.month += 1;
        } else {
          this.month = 0;
          this.year += 1;
        }
        this.$emit('selectMonth', `${this.year}-${(`00${this.month + 1}`).substr(-2)}`);
      } else if (this.view === 'MONTHS') {
        this.year += 1;
        this.month = 0;
      } else if (this.view === 'YEARS') {
        this.year += 12;
        this.month = 0;
      }
    },
    selectView() {
      if (this.view === 'DATES') {
        this.setView('MONTHS');
      } else if (this.view === 'MONTHS') {
        this.setView('YEARS');
      }
    },
    selectToday(ev) {
      if (this.today.getFullYear() !== this.year || this.today.getMonth() !== this.month) {
        this.$emit('selectMonth', `${this.today.getFullYear()}-${(`00${this.today.getMonth() + 1}`).substr(-2)}`);
      }
      const todayLabel = `${this.today.getFullYear()}-${(`00${this.today.getMonth() + 1}`).substr(-2)}-${(`00${this.today.getDate()}`).substr(-2)}`;
      const today = {
        year: this.today.getFullYear(),
        month: this.today.getMonth(),
        day: this.today.getDate(),
        disabled: this.disableDates.indexOf(todayLabel) > 0
      };
      this.selectDate(today);
      this.setView('DATES');
    },
    selectDate(date) {
      if (date.disabled) {
        return;
      }
      const { year, month, day } = date;
      this.setSelectedDate(year, month, day);
      this.$emit('selectDate', { year, month, day });
    },
    selectMonth(month) {
      this.month = month;
      this.setView('DATES');
      this.$emit('selectMonth', `${this.year}-${(`00${this.month + 1}`).substr(-2)}`);
    },
    selectYear(year) {
      this.year = year;
      this.setView('MONTHS');
    },
    setView(view) {
      if (['YEARS', 'MONTHS', 'DATES'].indexOf(view) < 0) {
        return;
      }
      this.view = view;
    },
    setSelectedDate(year, month, day) {
      this.selectedDate = new Date(year, month, day);
      this.selectedYear = year;
      this.selectedMonth = month;
      this.selectedDay = day;
      this.year = year;
      this.month = month;
    },
    getDate(row, column) {
      const date = {
        day: this.firstDayIndex + row * 7 + column + 1,
        disabled: false,
        outdate: false
      };
      let { year, month } = this;
      if (date.day <= 0) {
        date.day += this.daysInPreviousMonth;
        date.outdate = true;
        date.disabled = true;
        month -= 1;
        if (month < 0) {
          month = 11;
          year -= 1;
        }
      } else if (date.day > this.daysInMonth) {
        date.day -= this.daysInMonth;
        date.outdate = true;
        date.disabled = true;
        month += 1;
        if (month > 11) {
          month = 0;
          year += 1;
        }
      }
      date.year = year;
      date.month = month;

      const dateLabel = `${year}-${(`00${month + 1}`).substr(-2)}-${(`00${date.day}`).substr(-2)}`;

      if (this.disableDates.indexOf(dateLabel) >= 0) {
        date.disabled = true;
      }
      return date;
    },
    getMonth(row, column) {
      return row * 4 + column;
    },
    getYear(row, column) {
      // startYear, startYear, ..., endYear, endYear + 1
      return (row * 4 + column) + this.startYear;
    },
    checkIsToday(date) {
      const { year, month, day } = date;
      return new Date(year, month, day).getTime() === this.today.setHours(0, 0, 0, 0);
    },
    checkIsSelectedDay(date) {
      if (!this.selectedDate) {
        return false;
      }
      const { year, month, day } = date;
      return new Date(year, month, day).getTime() === this.selectedDate.getTime();
    },
    checkIsSelectedMonth(year, month) {
      return (month === this.selectedMonth) && (year === this.selectedYear);
    }
  },
};
</script>

<style lang="less" scoped>
.calendar-panel {
  &.default-background {
    border: rem(1) solid #ADADAD;
    background-color: @calendar-background-color;
    color: @calendar-font-color;
  }
  border: rem(1) solid transparent;
  width: rem(286);
  box-sizing: border-box;
  padding: rem(16);
  margin: 0 auto;
  font-size: rem(12);
  z-index: 1;
  .calendar-inner {
    display: flex;
    flex-flow: column nowrap;
    height: rem(226);
    width: 100%;
    padding-bottom: rem(16);
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .cell {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
  }

  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(16);
    .title {
      outline:none;
      color: #E0E0E0;
      h4 {
        color: inherit;
      }
      &:not(.r-yeartitle) {
        cursor: pointer;
      }
    }
    .prev, .next {
      cursor: pointer;
      outline: none;
      margin: 0 rem(10);
      &:before {
        position: relative;
        top: 0;
        content: "";
        display: inline-block;
        width: rem(9);
        height: rem(9);
        border-top: rem(2) solid #8F8F8F;
        transform: rotate(-45deg);
      }
    }

    .prev:before {
      border-left: rem(2) solid #8F8F8F;
      transform: rotate(-45deg);
      margin-left: 0.3em;
    }

    .next:before {
      border-right: rem(2) solid #8F8F8F;
      transform: rotate(45deg);
      margin-right: 0.3em;
    }
  }

  .weekdays .cell {
    color: @calendar-font-color-weekdays;
    margin-bottom: rem(5);
  }

  .dates {
    display: flex;
    flex-flow: column nowrap;
    outline: none;

    .date {
      padding: rem(6) rem(5);
      margin: 0 rem(5);
      cursor: pointer;

      &.today {
        color: @calendar-font-color-today;
        &.disabled {
          cursor: default;
        }
      }
      &.selected {
        background-color: @calendar-background-color-selected;
        border-radius: 50%;
        color: @calendar-font-color-selected;
      }
      &.outdate, &.disabled:not(.today) {
        color: @calendar-font-color-disabled;
        cursor: default;
      }

      &:hover:not(.outdate):not(.disabled) {
        background-color: @calendar-background-color-hover;
        border-radius: 50%;
      }
    }
  }

  .months, .years {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 6;
    flex-basis: 0;

    .row {
      flex-grow: 1;
    }

    .month, .year {
      cursor: pointer;
      outline: none;
      &.selected {
        color: @calendar-font-color-selected;
        background-color: @calendar-background-color-selected;
      }
      &:hover {
        background-color:@calendar-background-color-hover;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    button.today {
      width: auto;
    }
  }
}

.calendar-panel.disabled {
  pointer-events: none;
  div {
    color: @calendar-font-color-disabled !important;
    pointer-events: none;
    .prev:before, .next:before {
      border-top: rem(2) solid @calendar-font-color-disabled;
    }
    .prev:before {
      border-left: rem(2) solid @calendar-font-color-disabled;
    }
    .next:before {
      border-right: rem(2) solid @calendar-font-color-disabled;
    }
  }
}
</style>
