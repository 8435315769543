const theGetters = {
  isDirty(state) {
    return Object.keys(state.dirty).some((name) => (state.dirty[name]));
  },
  isBusy(state, getters, rootState, rootGetters) {
    return (getters.isDirty
      || rootGetters['search/hasSearchedResult']
      || rootGetters['search/isSearching']
      || rootGetters['search/isExporting']
      || rootGetters['ai/hasSearchedResult']
      || rootGetters['ai/isSearching']
      || rootGetters['ai/isExporting']
    );
  }
};
export default theGetters;
