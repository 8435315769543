<template>
  <div
    id="device-list"
    class="device-list"
    :class="{ disabled }"
  >
    <div
      v-for="(device) in devicesData"
      :key="device.index"
      class="device-wrapper"
      :class="{
        active: device.index === activeDeviceIndex,
        disabled: selectedDevices.includes(device.index) ? false : shouldSelectDisable
      }"
      @click="selectDevice(device.index)"
      @dblclick="doubleClick(device.index)"
    >
      <drag
        :draggable="draggable"
        :transferData="device.index"
        @dragstart="selectDevice(device.index)"
      >
        <div class="device">
          <CheckBox
            v-if="multiSelectable"
            :trueValue="device.index"
            :value="device.index"
            :disabled="selectedDevices.includes(device.index) ? false : shouldSelectDisable"
            v-model="selectedDevices"
            @click.native.stop=""
            @change="$emit('toggleSelectedDevices', { selectedDevices, index: device.index })"
          />
          <div class="camera-icon">
            <svg-icon
              :iconClass="device.generalIconClass"
              :disabled="selectedDevices.includes(device.index) ? false : shouldSelectDisable"
            />
          </div>
          <h6
            :title="device.displayName"
          >
            {{ device.displayName }}
          </h6>
        </div>
      </drag>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueDragDrop from 'vue-drag-drop';
import { mapGetters } from 'vuex';

Vue.use(VueDragDrop);

export default {
  name: 'AppDeviceList',
  components: {
    CheckBox: () => import('@/components/ui/CheckBox.vue'),
  },
  props: {
    devicesData: {
      type: Array,
      default: () => [],
    },
    activeDeviceIndex: {
      type: Number,
      default: -1
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiSelectable: {
      type: Boolean,
      default: false
    },
    selectedCountLimit: {
      type: Number,
      default: Infinity
    },
    defaultSelectedDevices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedDevices: [],
    };
  },
  computed: {
    ...mapGetters('encoder', ['isPTZ', 'isFisheye']),
    shouldSelectDisable() {
      return this.selectedCount >= this.selectedCountLimit;
    },
    selectedCount() {
      return this.selectedDevices.length;
    },
  },
  methods: {
    selectDevice(deviceIndex) {
      this.$emit('selectDevice', deviceIndex);
    },
    doubleClick(deviceIndex) {
      this.$emit('confirmDevice', deviceIndex);
    },
    setDefaultSelectedDevices() {
      this.selectedDevices = this.defaultSelectedDevices;
    }
  },
  created() {
    this.setDefaultSelectedDevices();
  }
};
</script>

<style lang="less" scoped>
.device-list.disabled {
  pointer-events: none;
  overflow-y: hidden;
  .device-wrapper .device h6 {
    color: @body-font-color-disabled;
  }
}

.device-wrapper {
  height: rem(40);
  line-height: rem(40);
  padding-top: rem(4);
  padding-left: rem(18);
  margin-bottom: rem(1);
  cursor: pointer;
  &:hover {
    background: @general-hover-background-color;
  }
  &.active, &:active {
    background: #2986ff;
    .device h6 {
      color: #fff;
    }
  }
  &.disabled {
    pointer-events: none;
    .device h6 {
      color: #4f4f4f;
    }
  }
}

.device {
  display: flex;
  align-items: center;
  .svg-icon {
    display: inline-block;
    vertical-align: middle;
    width: rem(32px);
    height: rem(32px);
    &[data-state="active"] {
      background-color: @general-active-color;
    }
  }
  h6 {
    user-select: none;
    padding-left: rem(10);
    color: #e0e0e0;
    max-width: rem(200);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
  }
}

.camera-icon {
  position: relative;
  height: rem(32);
  width: rem(32);
  > div {
    position: absolute;
  }
}
</style>
