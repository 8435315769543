import axios from 'axios';

export default {
  queryAlarms({
    channels, start, end, alarmNames, eventTypes, haveRecordingOnly, maxRecCnt
  }) {
    return axios
      .post('/fcgi-bin/operator/database.alarm_search',
        {
          channels: `${channels}`,
          start,
          end,
          alarm_names: alarmNames.join(),
          event_types: eventTypes.join(),
          have_recording_only: haveRecordingOnly,
          max_rec_cnt: maxRecCnt,
        })
      .then((res) => {
        if (res.data && res.data.rows) {
          return Promise.resolve(res.data.rows);
        }
        return Promise.reject();
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
};
