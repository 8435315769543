const getters = {
  notifications(state) {
    return state.info;
  },
  latestNotificationsCount(state) {
    return state.latestNotificationsCount;
  },
  unreadNotificationsCount(state) {
    return state.info.reduce(
      (acc, info) => (!info.isRead ? acc + 1 : acc),
      0
    );
  }
};
export default getters;
