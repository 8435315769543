<template>
  <div
    :class="`icon-container svg-icon ${svgClass} ${className} ${iconClass}`"
    :data-state="state"
    :data-interactable="hasClickHandler || interactable"
    :style="outerSize ? {
      width: covertToRem(outerSize),
      height: covertToRem(outerSize),
    } : null"
    @click="handleClickEvent($event)"
    @mouseenter="hovered=true"
    @mouseleave="hovered=false"
    @mousedown="mousedown=true"
    @mouseup="mousedown=false"
    aria-hidden="true"
  >
    <svg
      :width="covertToRem(size)"
      :height="covertToRem(size)"
    >
      <!-- This is fallback once we don't have the icon with state -->
      <use :xlink:href="pureIconName" />
      <!-- This is icon with state -->
      <use :xlink:href="iconName" />
      <!-- This is for web component -->
      <use v-if="!useInlineSvg" :xlink:href="onlinePureIconName" />
    </svg>
  </div>
</template>

<script>
import './main';
import DEFAULT_FONT_SIZE from './defaultFontSize';

export default {
  name: 'SvgIcon',
  data() {
    return {
      hovered: false,
      mousedown: false,
      hasClickHandler: !!(this.$listeners && this.$listeners.click),
    };
  },
  props: {
    // use inline version of svg instead of fetching sprite.svg.
    // the later is for web component use case; we will not
    // build a sprite.svg for vue project.
    // for web component this property needs to be true
    useInlineSvg: {
      type: Boolean,
      default: true,
    },
    // if the icon is used by router it will not has click handler at this level,
    // so we need another props.
    interactable: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: '2rem',
    },
    outerSize: {
      type: [Number, String],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    state() {
      if (this.disabled) {
        return 'disabled';
      }
      if (this.stateless) {
        return 'normal';
      }
      if (this.mousedown || this.active) {
        return 'active';
      }
      if (this.hovered) {
        return 'hovered';
      }
      return 'normal';
    },
    iconName() {
      if ((/^status_/g).test(this.iconClass)) {
        return `#icon-icon_${this.iconClass}`;
      }
      if (this.disabled) {
        return `#icon-icon_${this.iconClass.replace('normal', 'disable')}`;
      }
      if (this.mousedown || this.active) {
        return `#icon-icon_${this.iconClass.replace('normal', 'active')}`;
      }
      if (this.hovered) {
        return `#icon-icon_${this.iconClass.replace('normal', 'hover')}`;
      }
      return `#icon-icon_${this.iconClass}`;
    },
    pureIconName() {
      return `#icon-icon_${this.iconClass}`;
    },
    onlinePureIconName() {
      return `./sprite.svg#icon-icon_${this.iconClass}`;
    },
    svgClass() {
      if (this.disabled) {
        return `disabled svg-icon ${this.className} ${this.iconClass}`;
      }
      if (this.className) {
        return `svg-icon ${this.className} ${this.iconClass}`;
      }
      return `svg-icon ${this.iconClass}`;
    },
  },
  methods: {
    covertToRem(size) {
      if (typeof size === 'string' && size.indexOf('rem') >= 0) {
        return size;
      }
      return `${parseFloat(size) / DEFAULT_FONT_SIZE}rem`;
    },
    handleClickEvent(event) {
      if (this.disabled) {
        return;
      }
      this.$emit('click', event);
    },
  }
};
</script>

<style lang="less">
.body {
  // this is for themeing.
  opacity: 1;
}
</style>
<style scoped lang="less">
.icon-container {
  color: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &[data-interactable="true"]:not([data-state="disabled"]) {
    cursor: pointer;
    &:hover, &:active, &[data-state="active"] {
      background-color: @general-hover-background-color;
    }
    &[data-interactable="true"] {
      cursor: pointer;
    }
  }
  > svg {
    overflow: hidden;
    fill: currentColor;
    user-select: none;
  }
}
.disabled.svg-icon {
  pointer-events: none;
}

</style>
