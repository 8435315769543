<template>
  <div
    class="streaming-block"
  >
    <DisplayStatusBar
      class="display-status-bar"
      ref="statusBar"
      :viewcell="viewcell"
      :globalDisplayConfig="globalDisplayConfig"
      :shouldDisableExpand="shouldDisableExpand"
      :shouldDisableControlToggler="isFullscreen"
      :isViewCellExpanded="isViewCellExpanded"
      :simpleMode="isSimpleMode"
      @saveSnapshot="snapshot"
      v-if="shouldDisplayStatusBar"
      v-on="$listeners"
    />
    <div class="camera-wrapper">
      <component
        v-if="readyToDisplay"
        :is="componentName"
        ref="pluginlessWrapper"
        :mode="mode"
        :viewinfo="viewcell"
        :needLiteMode="needLiteMode"
        v-on="$listeners"
        :camera="camera"
        :channel="viewcell.channel + 1"
        :stream="streamIndex"
        :stretch="stretch || viewcell.isFisheye"
        :volume="volume"
        :mute="isMute"
        :username="user.userName"
        :sessionId="user.sessionId"
        :liteMode="liteMode"
        :dewarpType="dewarpType"
        :dewarpPreset="dewarpPreset"
        :isActiveView="isActiveView"
        :dewarpInfo="camera.dewarpInfo"
        :playTime="mode !== 'liveview' ? viewcell.playTime : false"
        :playbackRate="mode !== 'liveview' ? viewcell.playbackRate : false"
        :showPeopleDetectionArea="showPeopleDetectionArea"
        :showExclusiveArea="showExclusiveArea"
        :duration="duration"
        @enableDewarp="processEnableDewarp"
        @play="processViewcellPlay"
        :joystick="camera.isPTZ && mode === 'liveview' ? joystick(camera) : null"
        @error="processError"
        @dewarpPreset="processDewarpPreset"
        @retry="processRetry"
        @stop="onStop"
        @end="onStop"
      />
      <div v-if="isEmpty">
        <div
          v-if="!$slots.empty"
          class="empty"
        />
        <slot name="empty" />
      </div>
      <ViewcellHud
        v-else
        :mode="mode"
        :viewcell="viewcell"
      />
      <slot name="overlay" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DisplayStatusBar from '@/components/DisplayStatusBar.vue';
import ViewcellHud from '@/components/viewcell/ViewcellHud.vue';
import PluginlessWrapper from '@/components/viewcell/PluginlessWrapper.vue';
import PluginlessWrapperPlayback from '@/components/viewcell/PluginlessWrapperPlayback.vue';
import PluginlessWrapperExportPlayback from '@/components/viewcell/PluginlessWrapperExportPlayback.vue';
import JoystickProcessor from '../utility/JoystickProcessor';

import CameraPTZApi from '@/services/CameraPTZApi';

export default {
  name: 'DisplayStreamingBlock',
  components: {
    DisplayStatusBar,
    PluginlessWrapper,
    PluginlessWrapperPlayback,
    PluginlessWrapperExportPlayback,
    ViewcellHud,
  },
  props: {
    noStatusbar: {
      type: Boolean,
      default: false,
    },
    viewcell: {
      type: Object,
      default: () => {},
    },
    isActiveView: {
      type: Boolean,
      default: false,
    },
    shouldDisableExpand: {
      type: Boolean,
      default: false,
    },
    shouldDisplayEmptyIcon: {
      type: Boolean,
      default: true,
    },
    isViewCellExpanded: {
      type: Boolean,
      default: false
    },
    isFullscreen: {
      type: Boolean,
    },
    globalDisplayConfig: {
      type: Object,
      default: null,
    },
    needLiteMode: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: () => 'liveview',
    },
    mute: {
      type: Boolean,
      default: false,
    },
    showExclusiveArea: {
      type: Boolean,
      default: true,
    },
    showPeopleDetectionArea: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 0, // infinity
    },
  },
  data() {
    return {
      visibilityTimeout: null,
    };
  },
  watch: {
    streamIndex() {
      if (!this.isEmpty && !this.viewcell.shouldShowCameraOffline) {
        this.setViewStatus('LOADING');
      }
    },
    isActiveView(val) {
      if (val) {
        this.fetchStreamVCARule();
      }
    },
  },
  computed: {
    ...mapState(['user']),
    controlEventName() {
      if (this.mode === 'liveview' || this.mode === 'playback') {
        return 'viewcell-control';
      }
      return 'export-viewcell-control';
    },
    isEmpty() {
      if (this.mode === 'liveview') {
        return !this.viewcell?.camera?.isAvailable;
      }
      return !(this.viewcell?.camera?.isAvailable && this.viewcell?.options.begin);
    },
    camera() {
      return this.viewcell && this.viewcell.camera;
    },
    shouldDisplayStatusBar() {
      return this.camera?.isAvailable && !this.noStatusbar;
    },
    readyToDisplay() {
      if (!this.viewcell) {
        return false;
      }
      if (!this.camera?.isAvailable) {
        return false;
      }
      // view status is not available
      if (!this.viewcell.isAvailable) {
        return false;
      }
      return true;
    },
    componentName() {
      switch (this.mode) {
        case 'playback':
          return 'PluginlessWrapperPlayback';
        case 'export':
          return 'PluginlessWrapperExportPlayback';
        default:
          return 'PluginlessWrapper';
      }
    },
    isSimpleMode() {
      return this.mode === 'export';
    },
    displayConfig() {
      return this.viewcell.displayConfig;
    },
    streamingConfig() {
      return this.viewcell.options;
    },
    cameraIndex() {
      return +this.viewcell.channel;
    },
    volume() {
      return this.viewcell?.volume;
    },
    stretch() {
      return this.viewcell?.stretch;
    },
    streamIndex() {
      return this.viewcell?.streamIndex;
    },
    dewarpType() {
      return this.viewcell.dewarpType;
    },
    dewarpPreset() {
      return this.viewcell.dewarpPreset;
    },
    liteMode() {
      return this.needLiteMode;
    },
    isStreaming() {
      return this.viewcell.status === 'PLAYING' || this.viewcell.status === 'PAUSED';
    },
    isMute() {
      return this.mute || !this.isActiveView || !this.isStreaming;
    },
  },
  beforeDestroy() {
    this.unbindControlEvents();
    this.clearVisibilityTimeout();
  },
  methods: {
    setViewStatus(status = '') {
      this.viewcell.status = status;
    },
    bindControlEvents() {
      this.$bus.$on(this.controlEventName, this.control);
    },
    control(payload) {
      if (!this.isActiveView) {
        return;
      }

      if (typeof payload === 'string' && this[payload]) {
        this[payload]();
      } else if (typeof payload === 'object' && payload.action && this[payload.action]) {
        this[payload.action](payload.data, payload.caller);
      }
    },
    snapshot() {
      if (this.viewcell.status === 'LOADING') {
        return;
      }

      if (!this.isActiveView) {
        setTimeout(() => {
          this.$refs.pluginlessWrapper.snapshot();
        }, 330);
      } else {
        this.$refs.pluginlessWrapper.snapshot();
      }
    },
    unbindControlEvents() {
      this.$bus.$off(this.controlEventName, this.control);
    },
    retry() {
      if (this.viewcell.status === 'TIMEOUT') {
        this.processRetry();
      }
    },
    processRetry() {
      this.setViewStatus('LOADING');
      this.setVisibilityTimeout();
    },
    processViewcellPlay(viewcell) {
      this.viewcell.frameRate = viewcell.frameRate;
      this.viewcell.isFisheye = viewcell.isFisheye;
      this.setViewStatus('PLAYING');
      this.clearVisibilityTimeout();
    },
    processError(err) {
      switch (err.toString()) {
        case 'Error: Unsupported Media Type': // MPEG-4
        case 'Error: codec: JPEG is not support':
        case 'Error: codec: HEVC is not support':
          this.setViewStatus('NOT_SUPPORT');
          break;
        case 'Error: Failed to get stream.':
          if (this.mode !== 'liveview') {
            this.setViewStatus('NO_RECORDING');
            return;
          }
          this.setViewStatus('STOPPED');
          break;
        default:
          this.setViewStatus('STOPPED');
          break;
      }
    },
    processEnableDewarp(info) {
      this.viewcell.dewarpInfo.isValid = info.isValid;
      this.viewcell.dewarpInfo.isLocalDewarped = info.isLocalDewarped;
    },
    processDewarpPreset(preset) {
      this.viewcell.dewarpPreset = JSON.stringify(preset);
    },
    joystick(camera) {
      const processor =  new JoystickProcessor();

      processor.on('ptz', ({x, y, speed}) => {
        const { maxSpeed } = camera;

        CameraPTZApi.joystick(camera.index, {
          x: Math.round(x * maxSpeed),
          y: Math.round(y * maxSpeed),
          speed: Math.round(speed * maxSpeed),
        });
      });

      return processor;
    },
    onStop() {
      // eslint-disable-next-line no-console
      console.warn(`camera ${this.camera.index} stopped`);
      if (this.viewcell.status === 'SEEKING') {
        return;
      }
      this.setViewStatus('STOPPED');
    },
    setVisibilityTimeout() {
      if (!document.hidden && document.hasFocus()) {
        return;
      }
      this.clearVisibilityTimeout();
      this.visibilityTimeout = setTimeout(() => {
        this.setViewStatus('TIMEOUT');
      }, 60 * 1000);
    },
    clearVisibilityTimeout() {
      if (this.visibilityTimeout) {
        clearTimeout(this.visibilityTimeout);
      }
      delete this.visibilityTimeout;
    },
    speedUp() {
      this.viewcell.speedUp();
    },
    speedDown() {
      this.viewcell.speedDown();
    },
    nextFrame() {
      this.$refs.pluginlessWrapper.nextFrame();
      this.setViewStatus('PAUSED');
    },
    pause(data, caller) {
      this.$refs.pluginlessWrapper.pause();
      this.setViewStatus('PAUSED');
    },
    resume() {
      this.$refs.pluginlessWrapper.resume();
    },
    play() {
      this.setViewStatus('LOADING');
      if (!this.$refs.pluginlessWrapper) {
        return;
      }
      this.$refs.pluginlessWrapper.play();
    },
    backward() {
      this.viewcell.backward();
    },
    forward() {
      this.viewcell.forward();
    },
    unload() {
      /**
       * This is coming from MultiViewcell Manager(ViewcellLayout).
       * When leaving page and hardware accerelation is enabled in Chrome,
       * video element will turn green.
       * To sync the behavior, always show disconnecting to the user.
       */
      if (this.viewcell.status === 'PLAYING' || this.viewcell.status === 'PAUSED') {
        this.viewcell.status = 'UNLOADING';
      }
    },
    fetchStreamVCARule() {
      const { rule, isVCA, isAvailable } = this.camera || {};

      if (isAvailable && rule && Object.values(rule).length === 0 && isVCA && this.mode === 'liveview') {
        this.camera.fetchLiveviewRule();
      }
    },
  },
  mounted() {
    this.bindControlEvents();

    if (this.isActiveView) {
      this.fetchStreamVCARule();
    }
  },
};
</script>

<style lang="less" scoped>
.streaming-block {
  width: 100%;
  height: 100%;
  background-color: #000;
  flex-flow: column;
  display: flex;
  position: relative;

  .camera-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    > div {
      width: 100%;
      height: 100%;
    }
  }

  .empty {
    width: 100%;
    height: 100%;
    background-image: url('/logo_images/logo_company.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
  }
}
</style>
