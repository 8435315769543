import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    websocket: null,
    serial: 0,
    interval: null,
    buzzer: false,
    storage: {
      Capacity: 0,
      Used: 0,
      Status: 'Normal'
    }
  },
  actions,
  mutations,
  getters
};
