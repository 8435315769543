import { unpack, access, isPoint, toPointVerJSON, isPointVerJSON, toPoint } from './utility.mjs'
import Shapes from './shapes.mjs';
import VAOMCore from './VAOM.mjs';

export default class ExclusiveAreaManager {
  constructor(drawer, initialExclusiveArea = {}) {
    this.drawer = drawer;
    this.reference = drawer;
    this.shapes = [];
    this.core = new VAOMCore(this);
    this.handleRule(initialExclusiveArea);
  }
  
  /**
   * This is basically ExclusiveArea but for Smart Tracking camera.
   */
   parseExclusiveMask(configMap) {
    unpack(configMap).filter(rule => rule.PresetName !== undefined)
      .forEach((rule) => {
        unpack(rule.Field).map(field => unpack(field).filter(isPointVerJSON).map(toPointVerJSON)).filter(Shapes.isPolygon)
          .forEach((points) => {
            this.shapes.push(new Shapes.ExclusiveMask({
              points,
              preset: rule.PresetName}, this));
          });
      });
  }

  parseExclusiveArea(configMap) {
    unpack(access(configMap.ExclusiveArea, "Field")).map((field) => {
      return unpack(field).filter(isPointVerJSON).map(toPointVerJSON);
    }).filter(Shapes.isPolygon).forEach((points) => {
      this.shapes.push(new Shapes.ExclusiveArea({ points }, this));
    }, this);

    unpack(configMap.Polygon).map((polygon) => {
      return unpack(polygon.Point).filter(isPoint).map(toPoint);
    }).filter(Shapes.isPolygon).forEach((points) => {
      this.shapes.push(new Shapes.ExclusiveArea({ points }, this));
    }, this);
  }

  handleRule($rule) {
    this.clear();
    if (!$rule) {
      return;
    }
    this.parseExclusiveArea($rule);
    this.parseExclusiveMask($rule);
  }

  clear() {
    this.core.clear();
  }
  
  drawIfNecessary(canvas, ctx) {
    this.core.draw(
      this.reference.displayConfig.showExclusiveArea,
      ctx,
      canvas.width,
      canvas.height,
    );
  }
}