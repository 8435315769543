/* eslint-disable no-unused-vars */
import DevicePackRT from '@/legacy/device_pack_rt';

const sCur = 'CurrentOpt';
const sOpt = 'Options';
const sCur_RT = 'currentOpt';
const sOpt_RT = 'opts';

function sameArray(arr1, arr2) {
  if (!(Array.isArray(arr1) && Array.isArray(arr2)
  && arr1.length === arr2.length)) {
    return false;
  }
  return arr1.every((val, i) => val === arr2[i]);
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function adjustField(attrConstructor) {
  const options = attrConstructor[sOpt];
  if (!attrConstructor
      || !isNumeric(attrConstructor[sCur])
      || !options || !Array.isArray(options)
      || options.length < 1) {
    return attrConstructor;
  }
  const current = Number(attrConstructor[sCur]);
  const numOptions = attrConstructor[sOpt]
    .filter((v) => isNumeric(v));
  let maxVal,
    minVal;

  let maxOpt = numOptions[0];
  maxVal = Number(maxOpt);
  minVal = Number(maxOpt);
  numOptions.slice(1).forEach((v) => {
    const n = Number(v);
    if (n > maxVal) {
      maxVal = n;
      maxOpt = v;
    } else if (n < minVal) {
      minVal = n;
    }
  });

  if (current > maxVal) {
    // attrConstructor[sCur] = maxOpt;
    attrConstructor[sCur] = maxOpt;
  } if (current < minVal) {
    // attrConstructor[sCur] = minVal;
    // -> THIS IS DANGEROUS ... bypass this for now
  }
  return attrConstructor;
}

export default {
  updateWithDevicePack(filename, camID, setting) {
    // return changed attributes list
    // get full filename from querying to meteor encoder
    if (!filename) {
      return null;
    }

    const devicePackRT = DevicePackRT[filename];

    if (!devicePackRT) {
      return null;
    }

    const reqList = {};
    const changedAttrs = [];
    let req;
    let attrChanged;

    const devicePackCtrl = {
      GetUISet: (camId, attr) => {
        const that = setting;

        req = reqList[attr];
        if (!req) {
          reqList[attr] = {};
          req = reqList[attr];
          req[sCur_RT] = that[attr] ? that[attr][sCur] : '';
        }
        return req;
      }
    };

    devicePackRT(devicePackCtrl, camID);

    Object.keys(reqList).forEach((key) => {
      req = reqList[key];
      attrChanged = false;
      if ((setting[key])
       && (!setting[key][sOpt]
            || (req[sOpt_RT] && !sameArray(req[sOpt_RT], setting[key][sOpt])))) {
        attrChanged = true;
        setting[key][sOpt] = req[sOpt_RT];
        setting[key] = adjustField(setting[key]);
      }

      if (attrChanged) {
        changedAttrs.push(key);
      }
    });
    return setting;
  }
};
