import MicroEvent from '@vivotek/lib-utility/microevent';
import ConnectionPool from './connection_pool';

const reconnectTimeout = 3 * 60; // sec

class RTCPager {
  constructor(options) {
    this.deviceId = options.deviceId;
    this.rank = options.rank;
    this.onlineRegion = options.onlineRegion;
    this.stopOnOffline = options.stopOnOffline;

    this.retryExpire = Date.now() + reconnectTimeout * 1000;
    this.retryTimeout = null;

    this.connectRTCConnection();
  }

  connectRTCConnection() {
    const { deviceId, rank, onlineRegion } = this;
    const deviceInfo = { deviceId, rank, onlineRegion };
    ConnectionPool.createRTCConnection(deviceInfo)
      .then((rtcConnection) => {
        // logServer.SetHolePunchEndTime(logServerTicket, rtcConnection);
        this.setConnectionSuccuss(rtcConnection);
      }, (err) => {
        // logServer.SetHolePunchEndTime(logServerTicket, null, err);
        this.setConnectionFailed(err);
      });
  }

  disconnectRTCConnection(manually = true) {
    this.clearRetryTimeout();
    ConnectionPool.disconnectRTCConnection(this.deviceId);
    if (!manually) {
      this.trigger('failed');
    }
  }

  setConnectionSuccuss(rtcConnection) {
    this.trigger('success', rtcConnection);
  }

  setConnectionFailed(err) {
    if (Date.now() >= this.retryExpire
        || (err.message.includes('device offline') && this.stopOnOffline)
        || err.message === 'permission denied'
        || err.message === 'fail to connect to signal') {
      this.clearRetryTimeout();
      this.trigger('failed', err);
      return;
    }

    this.retryTimeout = setTimeout(() => {
      this.connectRTCConnection();
    }, 2 * 1000);
  }

  clearRetryTimeout() {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = null;
    }
    this.retryExpire = null;
  }

  destroy() {
    this.off('success');
    this.off('failed');
    this.disconnectRTCConnection(true);
  }
}

export default MicroEvent.mixin(RTCPager);
