<script>
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import ControlPanel from './ControlControlPanel.vue';
import LiveviewControlPanelPIP from './ControlControlPanelPIP.vue';

const ControlPanelPIP = Vue.extend({
  mixins: [LiveviewControlPanelPIP],
  computed: {
    ...mapGetters('playback', ['activeView']),
  },
});

export default {
  name: 'PlaybackControlPanel',
  extends: ControlPanel,
  components: {
    ControlPanelPIP
  },
  computed: {
    ...mapState(['playback']),
    ...mapGetters('playback', ['activeView']),
    shouldDisableExpand() {
      return this.playback.view.length <= 1;
    },
    noRecording() {
      return this.activeView.status === 'NO_RECORDING';
    },
    activeLayout() {
      return this.playback.layout;
    }
  },
};
</script>

<style lang="less" scoped>
.panel-content.disabled {
  pointer-events: none;
}

.block {
  padding: 0 rem(40);
  &.wrap {
    padding-top: rem(10);
    padding-bottom: rem(20);
  }
}

.camera-info-content {
  > .block-line {
    h6 {
      &:nth-child(1) {
        color: #8f8f8f;
      }
      &:nth-child(2) {
        color: #fff;
      }
    }
  }
}
.icon-wrapper {
  margin-right: rem(20);
}
.zoom-100-button {
  width: rem(60);
  height: rem(20);
  margin-left: rem(20);
}
.manual-record,
.fit-screen-ratio {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  width: 100%;
  height: rem(32);
  margin-bottom: rem(10);
  position: relative;
  cursor: pointer;
  &.disabled {
    border: 1px solid @body-font-color-disabled;
    cursor: default;
    > span {
      color: @body-font-color-disabled;
    }
  }
  > span {
    color: #E0E0E0;
    font-size: rem(14);
    line-height: 1;
  }
  .button-icon {
    display: inline-flex;
    position: absolute;
    right: rem(10);
  }
}
.manual-record {
  margin-top: rem(26);
  &.active:not {
    span{
      color:#000;
    }
    background-color: #fff;
  }
}

.button-list {
  margin-bottom: rem(10);
}

.svg-icon.volume-control {
  margin-right: rem(10);
}
</style>
