import axios from 'axios';
import EventApi from '@/services/EventApi';
import AlarmApi from '@/services/AlarmApi';

const actions = {
  fetchAlarmList({ state, commit }) {
    return axios
      .post('/fcgi-bin/operator/gconf.query', 'path=/system/software/corona/alarm')
      .then((res) => {
        commit('setAlarmList', res.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.warn(err);
      });
  },
  queryCurrentType({ state, dispatch }) {
    const { currentType } = state;

    const map = {
      alarm: 'alarmSearchQuery',
      bookmark: 'bookmarkSearchQuery',
      vca: 'VCASearchQuery',
      tmis: 'TMISSearchQuery'
    };

    dispatch(map[currentType]);
  },
  setUrlQueryToCriteriaOption({ dispatch, commit, rootGetters }, url) {
    if (!url) {
      return;
    }

    const searchParams = new URLSearchParams(url);
    const params = {
      channels: searchParams.get('channel'),
      start: searchParams.get('time'),
      end: searchParams.get('time'),
      alarmNames: [searchParams.get('alarmName')],
      eventTypes: [searchParams.get('triggerType')],
      haveRecordingOnly: false,
      maxRecCnt: 200,
    };
    commit('searchAgain');
    dispatch('alarmSearchQuery', params);
    commit('setCurrentType', 'alarm');
    commit('ui/hideAllOverlay', {}, { root: true });
  },
  alarmSearchQuery({
    state, getters, commit, rootGetters
  }, payload) {
    let parameter;
    if (payload) {
      parameter = payload;
    } else {
      const {
        alarm, date, device, triggers
      } = state.alarm.criteria;

      const channels = device.map((item) => item.value);

      // cameras + system
      const isSeletingAllDevices = channels.length === rootGetters['encoder/availableEncoders'].length + 1;

      const alarmNames = alarm.length === getters.alarmList[0].groupValues.length ? []
        : alarm.map((name) => name.value);

      parameter = {
        channels: `${isSeletingAllDevices ? '' : channels}`,
        start: `${date.startDateTime.date}T${date.startDateTime.time}`,
        end: `${date.endDateTime.date}T${date.endDateTime.time}`,
        alarmNames,
        eventTypes: triggers.map((item) => item.value),
        haveRecordingOnly: false,
        maxRecCnt: 200,
      };
    }

    return AlarmApi.queryAlarms(parameter)
      .then((rows) => {
        commit('setResultList', { type: 'alarm', list: [] });
        commit('setExportList', []);

        if (rows.length === 0) {
          commit('setSearchStatus', 'failed');
        } else {
          commit('setSearchStatus', 'searched');
          commit('setAlarmResultList', { rows, encoders: rootGetters['encoder/availableEncoders'] });
        }
      })
      .catch((err) => {
        commit('setSearchStatus', 'failed');
        // eslint-disable-next-line no-console
        console.warn(err);
      });
  },
  bookmarkSearchQuery({ state, commit, rootGetters }, payload) {
    let parameter;
    if (payload) {
      parameter = payload;
    } else {
      const { date, device } = state.bookmark.criteria;
      const isSeletingAllDevices = device.length === rootGetters['encoder/availableEncoders'].length;
      const channels = isSeletingAllDevices ? '' : device.join();

      parameter = {
        channels: `${channels}`,
        start: `${date.startDateTime.date}T${date.startDateTime.time}`,
        end: `${date.endDateTime.date}T${date.endDateTime.time}`,
        alarmNames: [],
        eventTypes: ['bookmark'],
        haveRecordingOnly: false,
        maxRecCnt: 200,
      };
    }

    return AlarmApi.queryAlarms(parameter)
      .then((rows) => {
        commit('setResultList', { type: 'bookmark', list: [] });
        commit('setExportList', []);

        if (rows.length === 0) {
          commit('setSearchStatus', 'failed');
        } else {
          commit('setSearchStatus', 'searched');
          commit('setBookmarkResultList', { rows, encoders: rootGetters['encoder/availableEncoders'] });
        }
      })
      .catch((err) => {
        commit('setSearchStatus', 'failed');
        // eslint-disable-next-line no-console
        console.warn(err);
      });
  },
  TMISSearchQuery({
    state, commit, rootState, rootGetters
  }) {
    const { device, tmisEventType, date } = state.tmis.criteria;
    const deviceList = device.map((item) => {
      if (item.value === -1) {
        return '';
      }
      return `C_${item.value + 1}`;
    });
    const startTime = new Date(`${date.startDateTime.date}T${date.startDateTime.time}`).getTime() / 1000;
    const endTime = new Date(`${date.endDateTime.date}T${date.endDateTime.time}`).getTime() / 1000;

    return EventApi.queryEvent({
      deviceList, startTime, endTime, isVCA: false, eventTypeList: tmisEventType
    })
      .then((data) => {
        commit('setResultList', { type: 'tmis', list: [] });
        commit('setExportList', []);

        if (data.length === 0) {
          commit('setSearchStatus', 'failed');
        } else {
          commit('setSearchStatus', 'searched');
          commit('setTMISResultList', { data, encoders: rootGetters['encoder/availableEncoders'], serverName: rootState.system.info.server_name });
        }
      })
      .catch((err) => {
        commit('setSearchStatus', 'failed');
        // eslint-disable-next-line no-console
        console.warn(err);
      });
  },
  VCASearchQuery({
    state, commit, rootState, rootGetters
  }) {
    const {
      device, vcaEventType, date
    } = state.vca.criteria;

    const startTime = `${date.startDateTime.date}T${date.startDateTime.time}`;
    const endTime = `${date.endDateTime.date}T${date.endDateTime.time}`;
    const deviceList = device.map((item) => `C_${item + 1}`);

    return EventApi.queryEvent({
      deviceList, startTime, endTime, eventTypeList: vcaEventType
    })
      .then((data) => {
        commit('setResultList', { type: 'tmis', list: [] });
        commit('setExportList', []);

        if (data.length === 0) {
          commit('setSearchStatus', 'failed');
        } else {
          commit('setSearchStatus', 'searched');
          commit('setVCAEventList', { data, encoders: rootGetters['encoder/availableEncoders'] });
        }
      })
      .catch((err) => {
        commit('setSearchStatus', 'failed');
        // eslint-disable-next-line no-console
        console.warn(err);
      });
  },
  goPreviousPage({ state, commit }) {
    if (state[state.currentType].currentPageIndex === 0) {
      return;
    }
    commit('goPreviousPage');
  },
  goNextPage({ state, getters, commit }) {
    if (state[state.currentType].currentPageIndex + 1 >= getters.pageCount) {
      return;
    }
    commit('goNextPage');
  },
  setCurrentType({ state, commit }, payload) {
    if (state[payload].result.length === 0) {
      commit('setSearchStatus', 'initial');
    } else {
      commit('setSearchStatus', 'searched');
    }
    commit('setCurrentType', payload);
  },
  setSelectedExportList({ state, commit }, result) {
    commit('setSelectedExportList', result);
  },
  setExportList({ state, commit }, result) {
    commit('setPlayAll', false);
    commit('setExportList', result);
    commit('setSelectedExportList', result);
  },
  setPlayingChannel({ state, commit }, channel) {
    commit('setPlayingChannel', channel);
  },
  setSortedList({ state, commit }, list) {
    commit('setResultList', { type: state.currentType, list });
  },
  setNextPlayableDataIfNecessary({
    state, dispatch, getters
  }) {
    if (getters.nextPlayableChannel === -1) {
      if (state[state.currentType].isPlayingAll && getters.nextPlayableVideoId !== -1) {
        dispatch('setActiveVideoId', getters.nextPlayableVideoId);
      }
      if (state[state.currentType].isPlayingAll && getters.nextPlayableVideoId === -1) {
        dispatch('setPlayAll', false);
      }
      return;
    }
    dispatch('setPlayingChannel', getters.nextPlayableChannel);
  },
  setPlayAll({
    commit, state, getters, dispatch
  }, value) {
    commit('setPlayAll', value);
    if (state[state.currentType].activeVideoId === -1 && getters.nextPlayableVideoId !== -1) {
      dispatch('setActiveVideoId', getters.nextPlayableVideoId);
    }
  },
  setActiveVideoId({
    commit, getters, rootState, rootGetters
  }, id) {
    commit('setActiveVideo', {
      data: getters.videoMap[id],
      encoders: rootState.encoder,
      timezone: rootGetters['system/systemTimezone'],
    });
  },
  setSearchKeyword({ commit }, keyword) {
    commit('setSearchKeyword', keyword);
  },
  searchAgain({ commit }) {
    commit('searchAgain');
  },
};

export default actions;
