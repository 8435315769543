<template>
  <div
    class="export-modal"
    data-testid="export-modal"
  >
    <AppModal
      :title="title"
      :shouldDisableClose="isExporting"
      @close="closeModal"
    >
      <div
        slot="modal-content"
        class="export-content"
      >
        <div class="content">
          <slot name="content" />
        </div>
        <div class="settings">
          <ExportSettings
            :disabled="isExporting"
            :timeRangeMax="timeRangeMax"
            :supportTimeRange="supportTimeRange"
            :showExportWarning="showExportWarning"
            v-model="settings"
          />
        </div>
      </div>
      <div
        slot="modal-controls"
        class="export-control"
      >
        <AppFormErrorMessage
          v-show="isError"
          :message="$t('Failed to export. Try again.')"
        />
        <button
          :class="{
            'export-button': true,
            'isExporting': isExporting
          }"
          @click="handleExport"
          :disabled="shouldDisableExport"
        >
          {{ exportButtonText }}
          <SvgIcon
            class="icon spinning loading-icon"
            iconClass="status_loading_s"
            size="20px"
            v-show="isExporting"
          />
        </button>
      </div>
    </AppModal>
    <AppDialogue
      name="_exit"
      ref="exit"
      :closeWhenClickButton="true"
      :title="$t('Are you sure you want to leave?')"
      :buttons="buttons"
    >
      <div slot="content">
        <h6>
          {{ $t('Your changes will be lost if you leave.') }}
        </h6>
      </div>
    </AppDialogue>
  </div>
</template>

<script>
import AppModal from '@/components/ui/AppModal.vue';
import AppDialogue from '@/components/ui/AppDialogue.vue';
import ExportSettings from '@/components/ExportSettings.vue';
import AppFormErrorMessage from '@/components/ui/AppFormErrorMessage.vue';

export default {
  name: 'ExportModal',
  components: {
    AppModal,
    AppDialogue,
    ExportSettings,
    AppFormErrorMessage
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    settings: {
      type: Object,
      default: () => {}
    },
    isExporting: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    timeRangeMax: {
      type: Number,
      default: 30,
    },
    exportText: {
      type: String,
      default: ''
    },
    supportTimeRange: {
      type: Boolean,
      default: false
    },
    showExportWarning: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isDirty: false
    };
  },
  watch: {
    settings: {
      handler() {
        if (!this.isDirty) {
          this.isDirty = true;
        }
      },
      deep: true
    }
  },
  computed: {
    buttons() {
      return [
        {
          class: 'primary', // button class
          text: this.$t('Stay on this page'), // button text
          icon: '', // button svg icon
          status: '', // 'success', 'disabled', 'loading', ''
          clickHandler: () => {
            this.$refs.exit.hide();
          }
        },
        {
          class: 'ghost medium-secondary', // button class
          text: this.$t('Leave'), // button text
          icon: '', // button svg icon
          status: '', // 'success', 'disabled', 'loading', ''
          clickHandler: () => {
            this.$emit('close');
          }
        }
      ];
    },
    exportButtonText() {
      const exportText = this.exportText === '' ? this.$t('Export') : this.exportText;
      return this.isExporting ? this.$t('Exporting') : exportText;
    },
    shouldDisableExport() {
      return this.isExporting || this.disabled;
    },
  },
  methods: {
    closeModal() {
      if (this.isDirty) {
        this.$refs.exit.show();
        return;
      }
      // if viewcell is playing, need to stop
      this.$emit('close');
    },
    handleExport() {
      this.$emit('confirm');
    },
    setDirty() {
      this.isDirty = true;
    }
  },
  created() {
    this.$bus.$on('ExportSettings:isDirty', this.setDirty);
  },
  beforeDestroy() {
    this.$bus.$off('ExportSettings:isDirty', this.setDirty);
  },
};
</script>

<style lang="less" scoped>
.export-modal {
  height: 100%;
  .export-content {
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    .content {
      width: 100%;
      flex-shrink: 1;
    }
    .settings {
      flex-basis: rem(396);
      flex-grow: 0;
    }
  }

  .export-control {
    padding: rem(25) rem(36);
    >.export-button {
      width: rem(220);
      height: rem(32);
      position: relative;
      &.isExporting {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
</style>
