import EXPORT from '@/constants/export';
import DEFAULT_PLAYBACK_VIEWCELL from '@/constants/defaultPlaybackViewCell';
import Viewcell from '@/models/Viewcell';
import LocalTime from '@/utility/local_time';

const mutations = {
  goPreviousPage(state) {
    state[state.currentType].currentPageIndex -= 1;
  },
  goNextPage(state) {
    state[state.currentType].currentPageIndex += 1;
  },
  setResultList(state, { type, list }) {
    state[type].result = list;
  },
  setCurrentType(state, type) {
    state.currentType = type;
  },
  setSearchStatus(state, status) {
    state.status = status;
    if (status === 'loading') {
      state[state.currentType].currentPageIndex = 0;
    }
  },
  setAlarmList(state, alarms) {
    state.alarm.alarms = alarms;
  },
  setAlarmResultList(state, { rows, encoders }) {
    state.alarm.currentPageIndex = 0;
    const result = rows.map((item) => {
      const comment = JSON.parse(item.comment);
      const avalibleRecordingEncoder = encoders.filter(
        (encoder) => comment.recording_info[encoder.index]
      );
      item.camera_name = comment.camera_name;
      item.recording_info = avalibleRecordingEncoder;
      item.relation_cameras = comment.relation_cameras;

      let snapshotChannel = item.channel;
      if (item.channel === -1 && item.recording_data === 1) {
        const avalibleEncodersId = avalibleRecordingEncoder
          .filter((encoder) => item.relation_cameras.indexOf(encoder.index) >= 0)
          .map((encoder) => encoder.index);
        snapshotChannel = avalibleEncodersId.length > 0 ? Math.min(...avalibleEncodersId) : -1;
      }
      item.snapshot_channel = snapshotChannel;
      return item;
    });
    state.alarm.result = result;
  },
  setBookmarkResultList(state, { rows, encoders }) {
    state.bookmark.currentPageIndex = 0;
    const result = rows.map((item) => {
      const bookmarkEncoder = encoders.find(
        (encoder) => item.channel === encoder.index
      );

      item.bookmark_name = item.comment;
      item.camera_name = item.alarm_name;
      item.recording_info = [bookmarkEncoder];
      item.relation_cameras = [item.channel];

      item.snapshot_channel = item.channel;
      return item;
    });
    state.bookmark.result = result;
  },

  setVCAEventList(state, { data, encoders }) {
    const arr = [];
    Object.keys(data).forEach((key) => {
      const cameraIndex = data[key].Alias.slice(2) - 1;
      const cameraName = encoders.find((encoder) => encoder.index === cameraIndex)?.name;
      const recordingInfo = encoders.find((item) => item.index === cameraIndex);
      data[key].EvnRec.forEach((item) => {
        arr.push({
          id: arr.length,
          channel: cameraIndex,
          snapshot_channel: cameraIndex,
          camera_name: cameraName,
          start: item.StaTm['@DLSaving'] ? new Date(item.StaTm['#text']).getTime() * 1000 + item.StaTm['@DLSaving'] * 60 : new Date(item.StaTm).getTime() * 1000,
          recording_info: [recordingInfo],
          ruleName: item.Evn.Value,
          source_type: item.Evn.Type
        });
      });
    });
    state.vca.result = arr;
  },
  setInitialTimeFrame(state, timeInfo) {
    if (state.alarm.criteria.date.endDateTime) {
      // inited
      return;
    }
    const endLocalTime = new LocalTime({
      timestamp: timeInfo.timestamp * 1000,
      tzoffs: timeInfo.timezone
    });
    const startLocalTime = endLocalTime.forwardMilliseconds(86400 * -1000);
    ['alarm', 'tmis', 'bookmark', 'vca'].forEach((type) => {
      state[type].criteria.date.endDateTime = {
        date: endLocalTime.queryDate,
        time: endLocalTime.queryTime,
      };
      state[type].criteria.date.startDateTime = {
        date: startLocalTime.queryDate,
        time: startLocalTime.queryTime,
      };
    });
  },
  setTMISResultList(state, { data, encoders, serverName }) {
    state.tmis.result = data.eventList.map((item) => {
      let deviceName;

      if (item.DeviceId === '0') {
        deviceName = serverName;
      } else {
        // Camera DeviceId's format is C_x and x is 1-based, e.g., C_1, C_15.
        const cameraIndex = item.DeviceId.slice(2) - 1;
        const camera = encoders.find((encoder) => encoder.index === cameraIndex);
        deviceName = camera?.name || '';
      }
      return {
        Device: deviceName,
        Time: item.Time,
        EventType: item.EventType,
        SeqNum: item.SeqNum,
        MessageType: item.MessageType,
        RuleId: item.RuleId,
        PeerIPAddress: item.PeerIPAddress,
        Direction: item.Direction,
        DeviceIPAddress: item.DeviceIPAddress,
        OriginatorIPAddress: item.OriginatorIPAddress,
        DeviceRole: item.DeviceRole,
        DomainPeerAndPortPath: item.DomainPeerAndPortPath,
        PeerIPAndServerPort: item.PeerIPAndServerPort,
        start: new Date(item.Time).getTime() * 1000
      };
    });
  },
  setPlayingChannel(state, channel) {
    state[state.currentType].playingChannel = channel;
  },
  searchAgain(state) {
    const { currentType } = state;
    state[currentType].playList = [];
    state[currentType].activeVideoId = -1;
    state[currentType].playingChannel = -1;
    state[currentType].result = [];
    state[currentType].export = [];
    state[currentType].keyword = '';
  },
  setExportList(state, result) {
    const { currentType } = state;
    state[currentType].export = result;
  },
  setSelectedExportList(state, result) {
    state.exportDataList = result;
  },
  setPlayAll(state, value) {
    state[state.currentType].isPlayingAll = value;
  },
  setActiveVideo(state, { data, encoders, timezone }) {
    const { currentType } = state;
    if (!data) {
      state[currentType].activeVideoId = -1;
      state[currentType].playList = [];
      return;
    }
    state[currentType].activeVideoId = data.id;
    // XXX: We need to store the Viewcell info inside state, otherwise it will not be reactive.

    state[currentType].playList = data.recording_info.map((encoder) => {
      const viewcell = new Viewcell({
        ...JSON.parse(JSON.stringify(DEFAULT_PLAYBACK_VIEWCELL)),
        encoder_id: encoder.index,
        begin: new LocalTime({
          timestamp: data.start / 1000 - encoder.recording_event.pre_event * 1000,
          tzoffs: timezone
        }),
        end: new LocalTime({
          timestamp: data.start / 1000 + (encoder.recording_event.post_event) * 1000,
          tzoffs: timezone
        }),
        clipMode: true,
      }, encoder);
      return viewcell;
    });
    state[currentType].playingChannel = state[currentType].playList[0].channel;
  },
  setSearchKeyword(state, keyword) {
    const { currentType } = state;

    state[currentType].currentPageIndex = 0;
    state[currentType].keyword = keyword;
    state[currentType].isPlayingAll = false;
  },
  setActiveVCARuleData(state, payload) {
    state.vca.activeVCARule.data = payload;
  },
  setActiveVCARuleId(state, payload) {
    state.vca.activeVCARule.id = payload;
  },
  setExportStatus(state, status) {
    if (EXPORT.EXPORT_STATE.indexOf(status) === -1) {
      return;
    }
    state.exportStatus = status;
  }
};

export default mutations;
