import { unpackXMLRule, unpack, access, isPoint, toPoint } from './utility.mjs';
import Shapes from './shapes.mjs';

export default class RuleParserVerXML {
  constructor(reference, parent) {
    this.reference = reference;
    this.parent = parent;
  }

  parse($rule) {
    const { reference, parent } = this;
    unpackXMLRule($rule).forEach((rule) => {
      switch (rule["@Type"])
      {
      case "LineDetection":
        if (typeof rule["@Name"] === "string") {
        var points = unpack(access(rule.Polyline, "Point")).filter(isPoint).map(toPoint);
        if (Shapes.isPolyline(points)) {
          parent.addShape(
            new Shapes.LineDetection({
              canvas: reference.drawer,
              name: rule["@Name"],
              points,
              direction: rule.Direction
            }, parent)
          );
        }
      }
        break;
      case "FieldDetection":
      case "LoiteringDetection":
        if (typeof rule["@Name"] === "string") {
          var points = unpack(access(rule.Polygon, "Point")).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            parent.addShape(
              new Shapes.FieldDetection({
                canvas: reference.drawer,
                name: rule["@Name"],
                points
              }, parent)
            );
          }
        }
        break;
      case "Counting":
        if (typeof rule["@Name"] === "string") {
          unpack(access(rule.PolylineArray, "Segment")).slice(0, 1).forEach((segment) => {
            var points = unpack(segment.Point).filter(isPoint).map(toPoint);
            if (Shapes.isPolyline(points)) {
              parent.addShape(
                new Shapes.Counting({
                  canvas: reference.drawer,
                  name: rule["@Name"],
                  points
                }, parent)
              );
            }
          });
        }
        break;
      case "NonDetectionField":
        reference.exclusiveAreaManager.handleRule(rule);
        break;
      case "FlowPathCounting":
        if (typeof rule["@Name"] === "string") {
          var segments = unpack(access(rule.PolylineArray, "Segment")).map(function(segment) {
            return unpack(segment.Point).filter(isPoint).map(toPoint);
          });
          if (Shapes.isFlowPathCounting(segments)) {
            parent.addShape(new Shapes.FlowPathCounting({
              canvas: reference.drawer,
              name: rule["@Name"],
              segments
            }, parent));
          }
        }
        break;
      }
    });
  }
}