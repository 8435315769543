export default function DragLine({
  start,
  current,
  width,
  height,
}) {
  this.start = start;
  this.current = current;
  this.width = width;
  this.height = height;
}

DragLine.prototype.draw = function(ctx, targetWidth, targetHeight) {
  const scaleWidthRatio = targetWidth / this.width;
  const scaleHeightRatio = targetHeight / this.height;
  const mouseStartX = this.start.x * scaleWidthRatio;
  const mouseStartY = this.start.y * scaleHeightRatio;
  const mouseCurrX = this.current.x * scaleWidthRatio;
  const mouseCurrY = this.current.y * scaleHeightRatio;
  const scaleRatio = scaleHeightRatio;

  ctx.globalAlpha = 0.5;
  ctx.setLineDash([10]);
  ctx.beginPath();
  ctx.moveTo(mouseStartX, mouseStartY);
  ctx.lineTo(mouseCurrX, mouseCurrY);
  ctx.closePath();
  ctx.lineWidth = 2 * scaleRatio;
  ctx.strokeStyle = '#FFFFFF';
  ctx.stroke();

  ctx.globalAlpha = 1;
  ctx.setLineDash([]);
  ctx.beginPath();
  ctx.arc(mouseStartX, mouseStartY, 7 * scaleRatio, 0, 2 * Math.PI);
  ctx.fillStyle = '#2986FF';
  ctx.fill();
  ctx.lineWidth = 2 * scaleRatio;
  ctx.strokeStyle = '#FFFFFF';
  ctx.stroke();

  ctx.beginPath();
  ctx.arc(mouseCurrX, mouseCurrY, 7 * scaleRatio, 0, 2 * Math.PI);
  ctx.fillStyle = '#2986FF';
  ctx.fill();
  ctx.strokeStyle = '#2986FF';
  ctx.stroke();
  ctx.beginPath();
  ctx.arc(mouseCurrX, mouseCurrY, 4.5 * scaleRatio, 0, 2 * Math.PI);
  ctx.fillStyle = '#2986FF';
  ctx.fill();
  ctx.lineWidth = 1.5 * scaleRatio;
  ctx.strokeStyle = '#FFFFFF';
  ctx.stroke();
};
