import Vue from 'vue';
import LocalTime from '@/utility/local_time';
import EXPORT from '@/constants/export';

const mutations = {
  setExportRange(state, { begin, end }) {
    if (typeof begin !== 'undefined' && begin instanceof LocalTime) {
      Vue.set(state, 'beginTime', begin);
    }
    if (typeof end !== 'undefined' && end instanceof LocalTime) {
      Vue.set(state, 'endTime', end);
    }
  },
  setStatus(state, status) {
    if (EXPORT.EXPORT_STATE.indexOf(status) === -1) {
      return;
    }
    state.status = status;
  }
};

export default mutations;
