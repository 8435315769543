<template>
  <div>
    <AppDialogue
      name="_globalExit"
      ref="exit"
      data-testid="exit-dialogue"
      :closeWhenClickButton="true"
      :title="$t('Are you sure you want to leave?')"
      :buttons="buttons"
      :closeIconClickHandler="onClickExitDialogCloseIcon"
      :onClose="onClose"
    >
      <div
        slot="content"
        class="default-dialog-content"
      >
        <h6>
          {{ $t('Your changes will be lost if you leave.') }}
        </h6>
      </div>
    </AppDialogue>
  </div>
</template>

<script>
import AppDialogue from '@/components/ui/AppDialogue.vue';
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppDialogueManager',
  components: {
    AppDialogue,
  },
  data() {
    return {
      isConfirmed: false,
    };
  },
  mounted() {
    this.registerBusyHandler(this.openDialog);
  },
  beforeDestroy() {
    this.unregisterBusyHandler();
  },
  methods: {
    ...mapMutations('ui', ['registerBusyHandler', 'unregisterBusyHandler']),
    ...mapActions('ui', ['confirmNavigation', 'rejectNavigation']),
    ...mapActions('ai', ['stopSmartSearchII']),
    onClickExitDialogCloseIcon() {
      this.$refs.exit.hide();
      this.rejectNavigation();
    },
    openDialog() {
      this.$refs.exit.show();
    },
    onClose() {
      if (!this.isConfirmed) {
        this.rejectNavigation();
      }
      this.isConfirmed = false;
    }
  },
  computed: {
    ...mapGetters('ai', ['status']),
    buttons() {
      return [
        {
          class: 'primary', // button class
          text: this.$t('Stay on this page'), // button text
          icon: '', // button svg icon
          status: '', // 'success', 'disabled', 'loading', ''
          clickHandler: () => {
            this.rejectNavigation();
            this.$refs.exit.hide();
          }
        },
        {
          class: 'ghost medium-secondary', // button class
          text: this.$t('Leave'), // button text
          icon: '', // button svg icon
          status: '', // 'success', 'disabled', 'loading', ''
          clickHandler: () => {
            /*
              We need to stop search in ai page.
              Maybe this component should not concern ai store...
            */
            if (this.status === 'loading') {
              this.stopSmartSearchII();
            }
            this.isConfirmed = true;
            this.confirmNavigation();
          }
        }
      ];
    },
  },
};
</script>

<style>
</style>
