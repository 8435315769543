<template>
  <div class="snapshot-wrapper">
    <div
      class="empty-block"
      v-if="shouldShowNoRecording"
    >
      <div class="icon no-recording-icon" />
      <span class="p">{{ $t('No recording clips') }}</span>
    </div>
    <div
      class="empty-block no-snapshot-block"
      v-if="shouldShowNoSnapshot"
    >
      <div class="icon no-snapshot-icon" />
    </div>
    <img
      v-if="snapshotUrl"
      ref="snapshot"
      :src="snapshotUrl"
      draggable="false"
    >
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'SnapshotWrapper',
  props: {
    channel: {
      type: Number,
      default: 0,
    },
    snapshotTime: {
      type: Number,
      default: -1
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 300
    },
    username: {
      type: String,
      default: '',
    },
    sessionId: {
      type: String,
      default: '',
    },
    observer: {
      type: Object,
      default: null
    },
    streamType: {
      type: String,
      default: 'main',
    },
  },
  data() {
    return {
      snapshotUrl: null,
      rtspChannel: null,
      rendered: false,
      retrySnapshotTimeout: null,
      retrySnapshotCount: 0,
      MAX_RETRY_COUNT: 3,
      isStreamTypeChanged: false,
      hasNoRecording: false
    };
  },
  computed: {
    shouldShowNoRecording() {
      return this.channel <= 0 || this.hasNoRecording;
    },
    shouldShowNoSnapshot() {
      return this.channel > 0 && !this.snapshotUrl && !this.hasNoRecording;
    }
  },
  methods: {
    ...mapActions('snapshot', ['getSnapshot', 'closeRtspChannel']),
    tryToGetSnapshot() {
      if (this.rendered) {
        return Promise.resolve();
      }

      const {
        channel, streamType, snapshotTime, width, height
      } = this;

      return this.getSnapshot({
        channel, streamType, snapshotTime, width, height
      })
        .then(this.renderSnapshot)
        .catch((err) => {
          this.handleSnapshotError(err);
        });
    },
    renderSnapshot(blob) {
      this.snapshotUrl = URL.createObjectURL(blob);
      this.$nextTick(() => {
        const image = this.$refs.snapshot;
        if (!image) {
          return;
        }
        image.width = this.width;
        image.height = this.height;
        image.onload = () => {
          this.rendered = true;
          URL.revokeObjectURL(this.snapshotUrl);
        };
      });
    },
    handleSnapshotError(error) {
      // eslint-disable-next-line no-console
      console.error(`${error.toString()} in channel: ${this.channel}, streamType ${this.streamType}, snapshot time ${this.snapshotTime}`);
      switch (error.toString()) {
        case 'Error: Unsupported Media Type':
        case '401': // has no authority
          break;
        case 'Error: Failed to get stream.': // has no recording data
          if (!this.isStreamTypeChanged) {
            this.streamType = (this.streamType === 'main') ? 'sub' : 'main';
            this.retrySnapshotTimeout = setTimeout(this.tryToGetSnapshot);
            this.isStreamTypeChanged = true;
          } else {
            this.hasNoRecording = true;
          }
          break;
        default:
          clearTimeout(this.retrySnapshotTimeout);
          if (this.retrySnapshotCount < this.MAX_RETRY_COUNT) {
            this.retrySnapshotTimeout = setTimeout(this.tryToGetSnapshot, 5 * 1000);
            this.retrySnapshotCount += 1;
          }
      }
    },
  },
  beforeDestroy() {
    this.closeRtspChannel();
    this.observer.unobserve(this);
    this.snapshotUrl = null;
    if (this.retrySnapshotTimeout) {
      clearTimeout(this.retrySnapshotTimeout);
      this.retrySnapshotTimeout = null;
    }
  },
  mounted() {
    if (this.observer && this.channel > 0 && this.snapshotTime >= 0) {
      this.observer.observe(this);
    }
  }
};
</script>

<style lang="less" scoped>
  .snapshot-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    .empty-block {
      border: rem(1) solid #202020;
      position: relative;
      height: 100%;
      width: 100%;
      .no-recording-icon {
        background-image: url('../../assets/illustration_no_recording.svg');
        background-position-y: rem(-10);
      }
      .no-snapshot-icon {
        background-image: url('../../assets/illustration_no_snapshot.svg');
      }
      > span {
        position: absolute;
        bottom: rem(10);
      }
    }
    > img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
</style>
