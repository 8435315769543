<template>
  <div
    class="filter-button-container"
    v-on-clickaway="onFilterDropdownClickaway"
  >
    <SvgIcon
      class="icon"
      iconClass="general_filter"
      @click="toggleFilterButton"
      size="28px"
      outerSize="32px"
      :active="isOpened"
      :disabled="disabled"
    />
    <!-- filter dropdown -->
    <div
      class="filter-dropdown"
      v-if="isOpened"
    >
      <div class="options-container">
        <div
          v-for="(opt, i) in options"
          class="list"
          :key="'list-'+i"
        >
          <div
            class="title"
            v-if="opt.title"
          >
            <span class="p">
              {{ opt.title }}
            </span>
          </div>
          <div
            class="option"
            v-for="(item) in opt.items"
            @click="setValue(item.filter)"
            :key="item.name"
          >
            <SvgIcon
              class="icon"
              :iconClass="item.filter === value ? 'general_check' : 'placeholder'"
              size="20px"
            />
            <span class="p">
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway';

export default {
  name: 'AppDeviceListFilterButton',
  props: {
    value: {
      type: String,
      default: 'index',
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpened: false,
    };
  },
  directives: {
    onClickaway,
  },
  computed: {
    options() {
      return [
        {
          items: [
            {
              name: this.$t('All cameras'),
              filter: 'index',
            }
          ]
        },
        {
          title: this.$t('Online/Offline'),
          items: [
            {
              name: this.$t('Online camera'),
              filter: 'online',
            },
            {
              name: this.$t('Offline camera'),
              filter: 'offline',
            }
          ]
        },
        {
          title: this.$t('Type'),
          items: [
            {
              name: this.$t('PTZ camera'),
              filter: 'ptz',
            },
            {
              name: this.$t('Fisheye camera'),
              filter: 'fisheye',
            }
          ]
        }
      ];
    }
  },
  methods: {
    onFilterDropdownClickaway() {
      this.isOpened = false;
    },
    toggleFilterButton() {
      this.isOpened = !this.isOpened;
    },
    setValue(val) {
      this.$emit('input', val);
    }
  }
};
</script>
<style lang="less" scoped>
.filter-button-container {
  display: flex;
}

.dropdown {
  position: absolute;
  margin-top: rem(-16);
  margin-left: rem(66);

  min-width: rem(166);
  background: #2E2E2E;
  border: 1px solid #000000;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.30);;
}
.filter-dropdown {
  .dropdown();
  z-index: 2;
  transform: translateY(rem(48)) translateX(-40%);
  padding-bottom: rem(5);
}

.list {
  padding-top: rem(5);
  &:first-child {
    border-bottom: rem(1) solid #000;
    padding-bottom: rem(5);
  }
  .title {
    padding-left: rem(10);
    height: rem(32);
    color: #6F6F6F;
    display: flex;
    align-items: center;
  }
}

.option {
  height: rem(32);
  width: 100%;
  cursor: pointer;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;

  > .icon {
    margin-left: rem(10);
  }
  > span {
    margin-left: rem(10);
  }
  &.title {
    font-size: rem(14);
    color: gray;
  }
  &:hover {
    background-color: #3e3e3e;
  }
}
</style>
