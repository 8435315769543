import DEFAULT_VIEWCELL from '@/constants/defaultViewCell';
import utils from '@/utility/utils';
import Encoder from '@/models/Encoder';
import LayoutHelper from '@/utility/layoutHelper';
import Viewcell from '@/models/Viewcell';
import { PREFER_MAINSTREAM_LIMIT } from '@/constants/layoutCountThreshold';

const theGetters = {
  availableSceneIndex(state) {
    return state.scenes.findIndex((scene) => scene.name === '');
  },

  validScenes(state) {
    if (!state.scenes) {
      return [];
    }
    return state.scenes.filter((scene) => scene.name !== '').map((scene, index) => ({
      index: +index,
      ...scene
    }));
  },

  activeScene(state, getters) {
    if (state.isSceneMode) {
      return state.scenes[state.active];
    }
    return state.deviceScene;
  },

  layoutDisplayConfig(state, getters) {
    return LayoutHelper.getLayoutDisplayConfig(getters.activeLayout);
  },

  activeView(state, getters) {
    const defaultViewCell = new Viewcell(JSON.parse(JSON.stringify({ ...DEFAULT_VIEWCELL })), new Encoder());
    if (!getters.pages[state.activePageIndex]) {
      return defaultViewCell;
    }
    const view = getters.pages[state.activePageIndex].view[state.activeViewIndex];
    return (view?.isEmpty || !view) ? defaultViewCell : view;
  },

  pages(state, getters) {
    if (!getters.activeScene || utils.isEmptyObject(getters.activeScene)) {
      return [];
    }
    return getters.activeScene.pages.filter((page) => page.layout !== '');
  },

  activePage(state, getters) {
    if (!getters.pages.length) {
      return '';
    }
    return getters.pages[state.activePageIndex];
  },

  preferMainStream(state, getters) {
    const validCameraCount = getters.activePage
      .view.filter((viewcell) => (!viewcell.isEmpty)).length;
    return validCameraCount >= PREFER_MAINSTREAM_LIMIT;
  },

  activeLayout(state, getters) {
    if (!getters.activePage) {
      return '';
    }
    return getters.activePage.layout;
  },

  pageCount(state, getters) {
    return getters.pages.length;
  },

  hasNoSceneData(state, getters) {
    return getters.validScenes.length === 0;
  },

  hasNoViewcells(state, getters) {
    return getters.activeScene.pages.findIndex((page) => page.view.filter((v) => !v.isEmpty).length > 0) === -1;
  },

  sortedScenes(state) {
    return (sortType, sortOrder, searchText) => {
      const scenes = theGetters.validScenes(state);
      const sortByAsc = (a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      };
      switch (sortType) {
        case 'name':
          if (sortOrder === 'asc') {
            scenes.sort(sortByAsc);
          } else { // desc
            scenes.sort(sortByAsc).reverse();
          }
          break;
        default:
      }
      if (searchText === '' || !scenes) {
        return scenes;
      }
      return scenes.filter((scene) => {
        if (scene.name) {
          return scene.name.indexOf(searchText) !== -1;
        }
        return false;
      });
    };
  },
  scenePageLayoutInfo(state, getters) {
    if (!getters.activeScene || !Object.keys(getters.activeScene).length) {
      return [];
    }

    return getters.activeScene.pages.map(
      (page, pageIndex) => page.view.map(
        (view, viewIndex) => ({
          pageIndex, viewIndex, encoder_id: view.channel, status: view.camera?.status
        })
      )
    ).flat();
  },
};

export default theGetters;
