<template>
  <div
    class="app-tabs"
    :data-tab-type="tabType"
  >
    <div
      class="tabs-item"
      :class="{ active: value === index, disabled }"
      :key="index"
      v-for="(tabItem,index) in options"
      @click="setCurrentTab(index)"
    >
      {{ tabItem }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTabs',
  props: {
    value: {
      type: Number,
      required: true,
      default: 0
    },
    options: {
      type: Array,
      required: true
    },
    tabType: {
      type: String,
      required: false,
      default: 'gui-style-1'
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    setCurrentTab(index) {
      this.$emit('input', index);
    }
  }
};
</script>

<style lang="less" scoped>
.app-tabs {
  &[data-tab-type="gui-style-1"] {
    display: flex;
    border-bottom: 2px solid @tabs-gui-style-1-border-color;

    .tabs-item {
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      flex: 1;
      height: @tab-height;
      position: relative;
      font-size: rem(16);
      color: @tabs-gui-style-1-text-normal-color;
      text-align: center;

      &.active:not(.disabled) {
        color: @tabs-gui-style-1-text-active-color;
        font-weight: 600;

        &::after {
          content: "";
          position: absolute;
          border-bottom: 2px solid @general-active-color;
          bottom: -2px;
          height: 2px;
          left: rem(18);
          right: rem(18);
        }
      }

      &.disabled {
        color: #4F4F4F;
        pointer-events: none;
      }
    }
  }

  &[data-tab-type="gui-style-2"] {
    display: flex;
    justify-content: center;

    .tabs-item {
      width: 50%;
      height: @tab-height;;
      cursor: pointer;
      background: #000000;
      font-size: rem(16);
      color: #C2C2C2;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        background: #202020;
        color: #fff;
        font-weight: 600;
      }

      &:hover {
        background: #121212;
      }
    }
  }
}
</style>
