<script>
import PluginlessWrapperPlayback from '@/components/viewcell/PluginlessWrapperPlayback.vue';

export default {
  extends: PluginlessWrapperPlayback,
  name: 'PluginlessWrapperExportPlayback',
  methods: {
    createImage() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          const player = this.viewcell.getPlayer();
          // H.265 needs lazyMode: false

          if (!player) {
            reject();
            return;
          }

          let params = {
            source: player,
            lazyMode: false,
          }

          params = Object.assign(params, {
            stretch: this.stretch,
          });

          this.image = this.createLensImage(params);

          if (this.isYUVCanvas) {
            this.removePlayerFromWrapper();
          }

          this.appendImageToWrapper(this.image.element);

          resolve(this.image);
        });
      });
    },
  }
};
</script>
