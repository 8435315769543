import RSA from './rsa_encrypt/rsa_encrypt';

// Note: this is legacy code from Banshee with renaming only

const encryption = {
  encrypt(username, password, public_key) {
    // text format: "de12ad34...be56ef78:USERNAME:PASSWORD"
    let pad = Math.ceil(Math.random() * Math.pow(2, 32)).toString(16);
    let text = `:${username}:${password}`;
    let encode_l; let seg_l; let pad_l; let encode = ''; let l;
    switch ((public_key && public_key.n) ? public_key.n.length : 0) {
      case 256: // 1024 bits
        // text length is (1024 / 8 - 11) * 2 = 234
        seg_l = 117;
        encode_l = 234; // 2 segments
        break;
      case 128: // 512 bits
        // text length is (512 / 8 - 11) * 3 = 159
        seg_l = 53;
        encode_l = 159; // 3 segments
        break;
      default:
        // panic!!
        console.log('WARN: Unknown length of public key, generating random bytes');
        return `${pad}f00000000${pad}ba222222222${pad}`;
    }

    pad_l = encode_l - text.length;
    for (l = pad.length; l < pad_l; l += l) {
      pad += pad;
    }
    text = pad.slice(0, pad_l) + text;

    public_key = RSA.newPubKey(public_key.n.toLowerCase());
    for (l = 0; l < encode_l; l += seg_l) {
      encode += RSA.encrypt(text.slice(l, l + seg_l), public_key);
    }

    return encode;
  }
};

export default encryption;
