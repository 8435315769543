import Vue from 'vue';

const mutations = {
  setSystemConfigure(state, data) {
    Vue.set(state, 'configure', data);
  },
  setSystemCapability(state, data) {
    Vue.set(state, 'capability', data);
  },
  setSystemTimeInfo(state, data) {
    Vue.set(state, 'timeInfo', data);
  },
  setSystemInfo(state, data) {
    Vue.set(state, 'info', data);
  }
};

export default mutations;
