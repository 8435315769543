import EventEmitter from 'events';
import { getTagged, Logger } from '@vivotek/lib-utility/logger';

const Log = getTagged('rtsp', Logger.DEBUG);

class SdpResponse extends EventEmitter {
  constructor(options) {
    super();
    this.RESPONSE_TIMEOUT = options.timeout || 30; // 6;

    this.CSeq = options.CSeq;
    this.sdpMsg = options.sdpMsg;
    this.responseHandler = options.responseHandler;
    this.timeoutHandler = options.timeoutHandler;

    this.requestTime = new Date().getTime();
    this.timeout = setTimeout(() => {
      Log.error(`CSeq ${this.CSeq}: Request Timeout. ${this.sdpMsg}`);
      this.timeoutHandler();
    }, this.RESPONSE_TIMEOUT * 1000);
  }

  destroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onResponse([status, data]) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const responseTime = new Date().getTime() - this.requestTime;
    this.responseHandler(status, data, responseTime);
  }
}

export default SdpResponse;
