<template>
  <div class="control">
    <AppTabs
      :options="tabsOptions"
      :disabled="shouldPanelTabsDisable"
      v-model="currentPanel"
    />
    <component
      v-show="showControlPanel"
      :key="`control-panel-${activeViewIndex}`"
      :mode="mode"
      :is="controlPanel"
    />
    <component
      v-show="showDisplayPanel"
      :key="`display-panel-${activeViewIndex}`"
      :is="displayPanel"
    />
  </div>
</template>

<script>
import AppTabs from '@/components/ui/AppTabs.vue';
import { mapState, mapGetters } from 'vuex';
import LiveviewControlPanel from './components/ControlControlPanel.vue';
import PlaybackControlPanel from './components/PlaybackControlControlPanel.vue';
import LiveviewDisplayPanel from './components/ControlDisplayPanel.vue';
import PlaybackDisplayPanel from './components/PlaybackControlDisplayPanel.vue';

export default {
  name: 'Control',
  components: {
    AppTabs,
    LiveviewControlPanel,
    PlaybackControlPanel,
    LiveviewDisplayPanel,
    PlaybackDisplayPanel
  },
  props: {
    mode: {
      type: String,
      default: 'liveview'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      controlPanel: this.mode === 'liveview' ? LiveviewControlPanel : PlaybackControlPanel,
      displayPanel: this.mode === 'liveview' ? LiveviewDisplayPanel : PlaybackDisplayPanel,
      fetchDone: false,
      currentPanel: 0
    };
  },
  computed: {
    ...mapState(['scene']),
    ...mapGetters('playback', { playbackView: 'activeView', playbackViewIndex: 'activeViewIndex' }),
    ...mapGetters('scene', { liveviewView: 'activeView' }),
    activeView() {
      if (this.mode === 'liveview') {
        return this.liveviewView;
      }
      return this.playbackView;
    },
    activeViewIndex() {
      if (this.mode === 'liveview') {
        return this.scene.activeViewIndex;
      }
      return this.playbackViewIndex;
    },
    showControlPanel() {
      return this.currentPanel === 0;
    },
    showDisplayPanel() {
      return this.currentPanel === 1;
    },
    shouldPanelTabsDisable() {
      return this.activeView.channel === -1;
    },
    tabsOptions() {
      return [this.$t('Control'), this.$t('Display')];
    }
  }
};
</script>

<style lang="less" scope>
.control {
  box-sizing: border-box;
}
</style>
