<!--
ref: https://github.com/mariomka/vue-checkbox-radio/blob/master/src/components/Radio.vue
-->
<template>
  <div class="radio-box">
    <input
      type="radio"
      :id="id"
      :name="name"
      :value="value"
      :disabled="disabled"
      :checked="checked"
      @change="onChange"
      ref="radio"
    >
    <label
      class="label-box"
      :for="id"
    >
      <svg-icon
        iconClass="status_radiobutton_checked_normal"
        v-if="checked"
        :disabled="disabled"
        size="1.25rem"
        outerSize="15px"
      />
      <svg-icon
        iconClass="status_radiobutton_unchecked_normal"
        v-if="!checked"
        :disabled="disabled"
        size="1.25rem"
        outerSize="15px"
      />
      <div
        class="text"
        :disabled="disabled"
      >{{ label }}</div>
    </label>
  </div>
</template>
<script>
export default {
  name: 'AppRadioButton',
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    modelValue: {
      type: String,
      default: undefined,
    },
    id: {
      type: String,
      default() {
        // eslint-disable-next-line no-underscore-dangle
        return `radio-id-'${this._uid}`;
      },
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    checked() {
      return this.modelValue === this.value;
    },
  },
  methods: {
    onChange(e) {
      this.$emit('input', this.checked ? '' : this.value);
      this.$emit('change', e);
    },
  },
};
</script>
<style lang="less" scoped>
.radio-box {
  input[type="radio"] {
    display: none;
  }
  .svg-icon {
    margin: rem(2.5) rem(2.5);
  }
  .label-box {
    display: flex;
  }
  .text {
    &[disabled] {
      color: #4F4F4F;
    }
    font-size: 14px;
    align-self: center;
    margin-left: rem(10);
  }
}
</style>
