export default Object.freeze({
  '1x1': 1,
  '2x2': 4,
  '3x3': 9,
  '4x4': 16,
  '1M+5': 6,
  '1M+7': 8,
  '1M+12': 13,
  '1P+3': 4,
  '1P+6': 7,
  '2P+3': 5,
  '1V+6': 7,
  '2V+3': 5,
  '3V': 3,
});
