import axios from 'axios';

export default {
  zoom(channel, option) {
    return axios.post('/fcgi-bin/operator/camera.zoom', `ch=${channel}&opt=${option}`)
      .then(({ data, status }) => {
        if (data === 0) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
  focus(channel, option) {
    return axios.post('/fcgi-bin/operator/camera.focus', `ch=${channel}&opt=${option}`)
      .then(({ data, status }) => {
        if (data === 0) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
  move(channel, option) {
    return axios.post('/fcgi-bin/operator/camera.ptz_move', `ch=${channel}&opt=${option}`)
      .then(({ data, status }) => {
        if (data === 0) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
  preset(channel, option) {
    return axios.post('/fcgi-bin/operator/camera.ptz_preset', `ch=${channel}&opt=${option}`)
      .then(({ data, status }) => {
        if (data === 0) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
  panControl(channel, option) {
    return axios.post('/fcgi-bin/operator/camera.pan_ctrl', `ch=${channel}&opt=${option}`)
      .then(({ data, status }) => {
        if (data === 0) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
  patrol(channel, option) {
    return axios.post('/fcgi-bin/operator/camera.patrol', `ch=${channel}&opt=${option}`)
      .then(({ data, status }) => {
        if (data === 0) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
  joystick(channel, { x, y, speed }) {
    const isStopCommand = [x, y, speed].every((val) => val === 0);
    const path = `/system/software/meteor/uisetting/${channel}/`;
    const param = JSON.stringify({
      uid_ptz_joystick_x: { CurrentOpt: x },
      uid_ptz_joystick_y: { CurrentOpt: y },
      uid_ptz_joystick_speed: { CurrentOpt: speed },
    });
    const params = [
      `path=${encodeURIComponent(path)}`,
      `data=${encodeURIComponent(param)}`,
    ].join('&');

    return axios.post('/fcgi-bin/admin/gconf.update', params)
      .then(({ data, status }) => {
        if (status !== 200) {
          return Promise.reject(Error(`update fail with status: ${status}`));
        }
        if (!data || data === '-1') {
          if (isStopCommand) {
            return this.joystick(channel, { x, y, speed });
          }

          return Promise.reject(Error(`update fail with data: ${data}`));
        }
        return this.checkTicketComplete(data, channel);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
  checkTicketComplete(ticket, channel) {
    const path = `/system/software/meteor/ticket/${ticket}/`;
    const params = `path=${encodeURIComponent(path)}`;

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        axios.post('/fcgi-bin/operator/gconf.query', params)
          .then(({ data, status }) => {
            if (status !== 200) {
              reject(Error(`query fail with status: ${status}`));
            }
            if (data === '' || data[channel] === 1) {
              return resolve();
            }
            return this.checkTicketComplete(ticket, channel);
          })
          .catch((err) => reject(err));
      }, 300);
    });
  },
};
