import Viewcell from '@/models/Viewcell';
import DEFAULT_PLAYBACK_VIEWCELL from '@/constants/defaultPlaybackViewCell';
import keywordFilterRules from '@/utility/keywordFilterRules';

const theGetters = {
  activeView(state) {
    const { currentType } = state;
    if (!state[currentType].playList.length) {
      return new Viewcell({
        ...JSON.parse(JSON.stringify(DEFAULT_PLAYBACK_VIEWCELL))
      });
    }

    return state[currentType].playList[0];
  },
  videoMap(state) {
    return state[state.currentType].result.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});
  },
  activeVideo(state, getters) {
    return getters.videoMap[state[state.currentType].activeVideoId];
  },
  filteredDataLength(state, getters, rootState, rootGetters) {
    const { currentType } = state;

    return keywordFilterRules.smartSearchKeywordFilter({
      currentType,
      keyword: state[currentType].keyword,
      rowData: state[currentType].result,
      timezone: rootGetters['system/systemTimezone']
    }).length;
  },
  filteredExportLength(state, getters, rootState, rootGetters) {
    const { currentType } = state;

    return keywordFilterRules.smartSearchKeywordFilter({
      currentType,
      keyword: state[currentType].keyword,
      rowData: state[currentType].export,
      timezone: rootGetters['system/systemTimezone']
    }).length;
  },
  isTimeFilterError(state) {
    const { duration, interval } = state.smartSearch.criteria.timeFilter;
    function checkError(params) {
      const number = Number(params);
      return number < 0 || number > 9999 || isNaN(number);
    }

    if (duration.length === 0 || interval.length === 0) {
      return true;
    }
    return checkError(duration) || checkError(interval);
  },
  isCriteriaError(state, getters) {
    if (getters.isTimeFilterError) {
      return true;
    }

    const {
      device
    } = state[state.currentType].criteria;

    const typeMap = {
      smartSearch: () => device.length === 0,
    };

    return typeMap[state.currentType]();
  },
  pageSize(state, getters) {
    const { currentType } = state;
    const pageSize = Math.ceil(getters.filteredDataLength / state[currentType].MAX_PAGE_COUNT);
    return pageSize === 0 ? 1 : pageSize;
  },
  currentPageIndex(state) {
    const { currentType } = state;
    return state[currentType].currentPageIndex;
  },
  status(state) {
    return state.status;
  },
  nextPlayableVideoId(state) {
    const { currentType } = state;
    const { activeVideoId } = state[currentType];

    if (activeVideoId === -1) {
      const playable = state[currentType].result.find(
        (item) => item.id !== -1
      );
      return playable ? playable.id : -1;
    }

    // XXX: Need to sort() alarmList before doing this.
    const currentIndex = state[currentType].result.findIndex(
      (item) => item.id === activeVideoId
    );

    const rulesMap = {
      smartSearch: (index, item) => activeVideoId !== item.id && index > currentIndex
    };

    const next = state[currentType].result.find(
      (item, index) => rulesMap[currentType](index, item)
    );

    return next ? next.id : -1;
  },
  isExportingError(state) {
    return state.exportStatus === 'ERROR';
  },
  isExporting(state) {
    return state.exportStatus === 'EXPORTING';
  },
  isSearching(state) {
    return state.status === 'loading';
  },
  hasSearchedResult(state) {
    return state[state.currentType].result.length > 0;
  },
  exportClipList(state, getters, rootState, rootGetters) {
    const exportClips = [];
    const encoders = rootGetters['encoder/availableEncoders'];
    state.exportDataList.forEach((data) => {
      const encoder = encoders[data.channel];
      const { post_event, pre_event } = encoder.recording_event;
      const recordingType = `${encoder.recording_stream === 0 ? 'main' : 'sub'}_stream`;

      exportClips.push({
        encoderId: encoder.index,
        begin: Math.round((data.MotionStartTimestamp)) - pre_event * 1000,
        length: (post_event + pre_event),
        recordingType
      });
    });

    return exportClips;
  },
  smartSearchIIResultCount(state) {
    return state.smartSearch.result.length;
  }
};
export default theGetters;
