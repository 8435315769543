export default class MouseEventDispatcher {
  constructor(element, reference) {
    this.element = element;
    this.reference = reference;
    this.dragStart = null;
    this.dragging = null;
    // mouse event
    if (this.element && this.element.addEventListener) {
      this.element.tabIndex = -1;
      this.element.addEventListener('mousedown', this);
      this.element.addEventListener('mousemove', this);
      this.element.addEventListener('mouseup', this);
      this.element.addEventListener('wheel', this);
    } else {
      console.warn('event target missing');
    }
  }

  destroy() {
    this.element.removeEventListener('mousedown', this);
    this.element.removeEventListener('mousemove', this);
    this.element.removeEventListener('mouseup', this);
    this.element.removeEventListener('wheel', this);
    delete this.element;
    delete this.reference;
    window.removeEventListener('resize', this);
    window.removeEventListener('mouseup', this);
  }

  handleEvent(event) {
    if (this[`_handle_${event.type}`]) {
      this[`_handle_${event.type}`](event);
    }
  }

  // ePTZ control
  _handle_mousedown(evt) {
    this.reference.dispatch(evt);
    // prevent scrolling and selecting other elements
    evt.preventDefault();
    // we still want to focus our canvas
    this.element.focus();

    // Register mouseup on `window` to catch mouseup evfvent off element,
    // and even out of the browser window.
    window.addEventListener('mouseup', this);

    const dragEvent = new CustomEvent('panstart', {
      detail: {
        target: evt.target,
        related: evt,
      }
    });

    dragEvent.offsetX = evt.offsetX;
    dragEvent.offsetY = evt.offsetY;

    this.reference.dispatch(dragEvent);
    this.mouseDown = {
      x: evt.offsetX,
      y: evt.offsetY
    };
  }

  _handle_mouseup(evt) {
    evt.preventDefault();
    this.reference.dispatch(evt);
    window.removeEventListener('mouseup', this);

    if (!this.mouseMove) {
      const event = new CustomEvent('click', {
        detail: {
          target: evt.target,
          related: evt,
        }
      });

      event.offsetX = evt.offsetX;
      event.offsetY = evt.offsetY;

      this.reference.dispatch(event);
    }

    const dragEvent = new CustomEvent('panend');

    dragEvent.offsetX = evt.offsetX;
    dragEvent.offsetY = evt.offsetY;

    this.reference.dispatch(dragEvent);
    this.mouseDown = null;
    this.mouseMove = null;
  }

  _handle_mousemove(evt) {
    const { mouseMove, mouseDown } = this;

    if (!mouseDown) {
      return;
    }

    evt.preventDefault();
    this.reference.dispatch(evt);

    const deltaX = (mouseMove ? mouseMove.x : mouseDown.x) - evt.offsetX;
    const deltaY = (mouseMove ? mouseMove.y : mouseDown.y) - evt.offsetY;
    const dragEvent = new CustomEvent('panmove', {
      detail: {
        target: evt.target,
        related: evt,
      }
    });

    dragEvent.deltaX = deltaX;
    dragEvent.deltaY = deltaY;
    dragEvent.offsetX = evt.offsetX;
    dragEvent.offsetY = evt.offsetY;

    this.reference.dispatch(dragEvent);
    this.mouseMove = {
      x: evt.offsetX,
      y: evt.offsetY
    };
  }

  _handle_wheel(evt) {
    evt.preventDefault();
    this.reference.dispatch(evt);
    return true;
  }
}
