import Vue from 'vue';

const mutations = {
  setAllUsers(state, users) {
    Vue.set(state, 'users', users);
  },
  setUserInfo(state, user) {
    Vue.set(state.users, user.userIndex, user.info);
  },
  setProfile(state, profile) {
    Vue.set(state, 'userName', profile.username);
    Vue.set(state, 'group', profile.group);
    Vue.set(state, 'sessionId', profile.sessionId);
    // note: this is follow original session name in front-end
    // eslint-disable-next-line no-underscore-dangle
    document.cookie = `_SID_=${profile._SID_};`;
  },
  setTmisInfo(state, info) {
    Vue.set(state, 'tmis', info);
  },
  clearProfile(state) {
    document.cookie = '_SID_=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    state.userName = '';
    state.group = '';
    state.sessionId = '';
  }
};

export default mutations;
