import { getTagged } from '@vivotek/lib-utility/logger';
import $postMessage from './postMessage';

const Log = getTagged('ThreadingCanvas');

export default class PIPImageServer {
  constructor({ destination }, _, client) {
    this.source = null;
    this.destination = destination;
    this.client = client;
    if (this.destination) {
      this.finalContext = this.destination.getContext('2d');
    }
    this.sourceWidth = 0;
    this.sourceHeight = 0;
    this.targetWidth = 0;
    this.targetHeight = 0;
    this.readyPromise = Promise.resolve();
  }

  ready() {
    return this.readyPromise;
  }

  send(data) {
    $postMessage({
      dispatch: 'message',
      event: {
        type: 'message',
        data,
      },
    }, this.client);
  }

  postMessage(data) {
    $postMessage(data, this);
  }

  terminate() {
    delete this.finalContext;
    delete this.source;
    delete this.destination;
  }

  command(command, ...args) {
    this[command](...args);
  }

  resize(width, height) {
    this.targetWidth = width;
    this.targetHeight = height;
    this.destination.width = width;
    this.destination.height = height;
  }

  render(imageBitmap, width, height) {
    this.sourceWidth = width;
    this.sourceHeight = height;
    this.finalContext.clearRect(0, 0, this.targetWidth, this.targetHeight);
    this.finalContext.drawImage(imageBitmap, 0, 0, width, height, 0, 0, this.targetWidth, this.targetHeight);
  }

  handleEvent(evt) {
    if (this[`_handle_${evt.type}`]) {
      this[`_handle_${evt.type}`](evt);
    }
  }

  config(key, value) {
    this[key] = value;
    if (this[`watch_${key}`]) {
      this[`watch_${key}`](value);
    }
  }
}
