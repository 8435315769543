import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  userName: '',
  group: '',
  sessionId: '',
  users: {},
  tmis: {}
};

const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default user;
