<template>
  <!-- eslint-disable max-len  -->
  <div class="ptz-pan-control">
    <svg
      width="220px"
      height="220px"
      viewBox="0 0 220 220"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      @mousedown="handleMouseDown"
    >
      <g
        id="Components"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Components_04"
          transform="translate(-316.000000, -271.000000)"
        >
          <g
            id="ptz-/-ptz04"
            transform="translate(316.000000, 271.000000)"
          >
            <g id="ptz_ptz04">
              <circle
                id="ptz_bg"
                stroke="#979797"
                fill="#ADADAD"
                cx="110"
                cy="110"
                r="109.5"
              />
              <g
                id="ptz_ptz04_02"
                transform="translate(2.000000, 2.000000)"
              >
                <circle
                  fill="#121212"
                  cx="108"
                  cy="108"
                  r="108"
                />
                <circle
                  cx="108"
                  cy="108"
                  r="108"
                />
                <g
                  id="ptz_move_right_wrapper"
                  data-ptz-position="right"
                >
                  <path
                    d="M184.3677,184.3674 C203.9117,164.8234 215.9997,137.8234 215.9997,108.0004 C215.9997,78.1774 203.9117,51.1774 184.3677,31.6334 L107.9997,108.0004 L184.3677,184.3674 Z"
                    id="Fill-8"
                    fill="#121212"
                  />
                  <polyline
                    id="Fill-10"
                    fill="#ADADAD"
                    points="180.1998 99.6633 188.5368 108.0003 180.1998 116.3373"
                  />
                </g>
                <g
                  id="ptz_move_down_wrapper"
                  data-ptz-position="down"
                >
                  <path
                    d="M32,184.367 C51.544,203.912 78.544,216 108.368,216 C138.191,216 165.191,203.912 184.735,184.367 L108.368,108 L32,184.367 Z"
                    id="Fill-1"
                    fill="#121212"
                  />
                  <polyline
                    id="Fill-12"
                    fill="#ADADAD"
                    points="116.337 180.2004 108 188.5374 99.663 180.2004"
                  />
                </g>
                <g
                  id="ptz_move_left_wrapper"
                  data-ptz-position="left"
                >
                  <path

                    d="M31.632,32 C12.088,51.544 0,78.544 0,108.367 C0,138.19 12.088,165.19 31.632,184.734 L108,108.367 L31.632,32 Z"
                    id="Fill-4"
                    fill="#121212"
                  />
                  <polyline
                    id="Fill-16"
                    fill="#ADADAD"
                    points="35.7999 116.3371 27.4629 108.0001 35.7999 99.6631"
                  />
                </g>
                <g
                  id="ptz_move_up_wrapper"
                  data-ptz-position="up"
                >
                  <path
                    d="M31.6324,31.633 L108.0004,108 L184.3674,31.633 C164.8234,12.088 137.8234,0 108.0004,0 C78.1764,0 51.1764,12.088 31.6324,31.633"
                    id="Fill-6"
                    fill="#121212"
                  />
                  <polyline
                    id="Fill-14"
                    fill="#ADADAD"
                    points="99.6631 35.8 108.0001 27.463 116.3371 35.8"
                  />
                </g>
              </g>
              <g
                id="ptz_move_home_wrapper"
                data-ptz-position="home"
                transform="translate(63.000000, 63.000000)"
              >
                <path
                  d="M93.9997,47.0002 C93.9997,72.9572 72.9577,94.0002 46.9997,94.0002 C21.0427,94.0002 -0.0003,72.9572 -0.0003,47.0002 C-0.0003,21.0422 21.0427,0.0002 46.9997,0.0002 C72.9577,0.0002 93.9997,21.0422 93.9997,47.0002"
                  fill="#000000"
                />
                <g
                  data-ptz-position="home"
                  transform="translate(32.333300, 32.333200)"
                >
                  <path
                    d="M24.4447,14.667 C24.4447,20.067 20.0667,24.444 14.6667,24.444 C9.2667,24.444 4.8887,20.067 4.8887,14.667 C4.8887,9.267 9.2667,4.889 14.6667,4.889 C20.0667,4.889 24.4447,9.267 24.4447,14.667 Z"
                    id="Stroke-4"
                    stroke="#ADADAD"
                    stroke-width="2"
                  />
                  <path
                    d="M18.3334,14.667 C18.3334,16.691 16.6914,18.333 14.6664,18.333 C12.6424,18.333 11.0004,16.691 11.0004,14.667 C11.0004,12.642 12.6424,11 14.6664,11 C16.6914,11 18.3334,12.642 18.3334,14.667"
                    id="dot"
                    fill="#ADADAD"
                  />
                  <line
                    x1="14.6664"
                    y1="6.03961325e-14"
                    x2="14.6664"
                    y2="4.889"
                    id="Stroke-8"
                    stroke="#ADADAD"
                    stroke-width="3"
                  />
                  <line
                    x1="14.6664"
                    y1="24.4443"
                    x2="14.6664"
                    y2="29.3333"
                    id="Stroke-10"
                    stroke="#ADADAD"
                    stroke-width="3"
                  />
                  <line
                    x1="29.3334"
                    y1="14.667"
                    x2="24.4444"
                    y2="14.667"
                    id="Stroke-12"
                    stroke="#ADADAD"
                    stroke-width="3"
                  />
                  <line
                    x1="4.889"
                    y1="14.667"
                    x2="-3.94351218e-13"
                    y2="14.667"
                    id="Stroke-14"
                    stroke="#ADADAD"
                    stroke-width="3"
                  />
                </g>
              </g>
              <circle
                cx="110"
                cy="110"
                r="110"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'PTZMoveControl',
  methods: {
    handleMouseDown(e) {
      this.$emit('move', e.target.parentElement.dataset.ptzPosition);
    },
  }
};
</script>

<style lang="less" scoped>
#ptz_move_right_wrapper,
#ptz_move_down_wrapper,
#ptz_move_left_wrapper,
#ptz_move_up_wrapper {
  &:hover {
    path {
      fill: #2e2e2e;
    }
    polyline {
      fill: #ffffff;
    }
  }
  &:active {
    path {
      fill: #2986ff;
    }
    polyline {
      fill: #ffffff;
    }
  }
}

#ptz_move_home_wrapper {
  &:hover {
    > path {
      fill: #2e2e2e;
    }
  }
  &:active {
    > path {
      fill: #2986ff;
    }
  }
  &:active,
  &:hover {
    g {
      > path {
        stroke: #fff;
        &#dot {
          fill: #fff;
        }
      }
      line {
        stroke: #ffffff;
      }
    }
  }
}
</style>
