import DEFAULT_PLAYBACK_VIEWCELL from '@/constants/defaultPlaybackViewCell';
import LocalTime from '@/utility/local_time';
import Viewcell from '@/models/Viewcell';
import LAYOUT_COUNT from '@/constants/layoutCount';

const mutations = {
  updateTime(state, {
    target, begin, end, timestamp, tzoffs
  }) {
    if (begin) {
      state.selectedDate = begin;
    }
    // update viewcells to this date
    target.forEach((view) => {
      if (begin) {
        view.options.begin = begin || 0;
      }
      if (end) {
        view.options.end = end || 86400;
      }
      if (timestamp) {
        view.playTime.timestamp = timestamp;
      } else {
        view.playTime.timestamp = begin.projection(tzoffs).timestamp;
      }
      if (tzoffs) {
        view.playTime.tzoffs = tzoffs;
      }
    });
  },

  setActiveEncoderId(state, encoderId) {
    state.activeEncoderId = encoderId;
  },

  addSelectedEncoder(state, {
    channel,
    encoders,
    timezone,
  }) {
    state.activeEncoderId = channel;

    const options = {
      begin: new LocalTime(0).useUniTimezone(),
      end: new LocalTime(0).useUniTimezone(),
    };
    const firstNonEmptyViewcell = state.view.find((viewcell) => viewcell.channel !== -1);
    if (firstNonEmptyViewcell) {
      options.begin = firstNonEmptyViewcell.options.begin;
      options.end = firstNonEmptyViewcell.options.end;
    }
    const defaultViewCell = new Viewcell(JSON.parse(
      JSON.stringify({
        ...DEFAULT_PLAYBACK_VIEWCELL,
        encoder_id: channel,
      })
    ), encoders[channel]);
    defaultViewCell.options.begin = options.begin;
    defaultViewCell.options.end = options.end;

    const firstEmptyViewcellIndex = state.view.findIndex((viewcell) => viewcell.channel === -1);
    if (firstEmptyViewcellIndex === -1) {
      // there is not empty viewcell, just push a new viewcell with channel data.
      state.view.push(defaultViewCell);
    } else {
      // replace
      state.view.splice(firstEmptyViewcellIndex, 1, defaultViewCell);
    }
    if (options.begin.timestamp !== 0) {
      defaultViewCell.queryRecordingData();
      defaultViewCell.playTime.timestamp = options.begin.timestamp;
      defaultViewCell.playTime.tzoffs = timezone;
    }

    const selectedCount = state.view.length;
    if (selectedCount > LAYOUT_COUNT[state.layout]) {
      const autoLayout = Object.keys(LAYOUT_COUNT)
        .find((layout) => LAYOUT_COUNT[layout] >= selectedCount);
      state.layout = autoLayout;
    }

    const count = LAYOUT_COUNT[state.layout];
    if (state.view.length < count) {
      new Array(count - state.view.length).fill(1).forEach(() => {
        state.view.push(new Viewcell(JSON.parse(JSON.stringify(DEFAULT_PLAYBACK_VIEWCELL))));
      });
    }
  },

  removeSelectedEncoder(state, { channel, encoders }) {
    if (channel === state.activeEncoderId) {
      state.activeEncoderId = -1;
    }

    const index = state.view.findIndex((viewcell) => viewcell.channel === channel);
    state.view.splice(
      index,
      1,
      new Viewcell(JSON.parse(JSON.stringify(DEFAULT_PLAYBACK_VIEWCELL)))
    );
  },

  updateAllViewDisplayConfig(state, displayConfig) {
    state.view.forEach((encoder) => {
      Object.keys(displayConfig).forEach((key) => {
        encoder.options.display[key] = displayConfig[key];
      });
    });
  },

  changeLayout(state, layout) {
    const newLayoutCount = LAYOUT_COUNT[layout];
    state.view = state.view.filter((viewcell) => viewcell.channel !== -1);
    const nonEmptyViewcellCount = state.view.length;
    if (newLayoutCount < nonEmptyViewcellCount) {
      state.view.splice(newLayoutCount);
    }
    if (newLayoutCount > nonEmptyViewcellCount) {
      new Array(newLayoutCount - nonEmptyViewcellCount).fill(1).forEach(() => {
        state.view.push(new Viewcell(JSON.parse(JSON.stringify(DEFAULT_PLAYBACK_VIEWCELL))));
      });
    }
    state.layout = layout;
  }
};

export default mutations;
