<template>
  <div
    v-on-clickaway="onNotificationDropdownClickaway"
    ref="notificationDropdown"
    data-testid="header-notification-button"
  >
    <svg-icon
      class="icon"
      iconClass="general_notification"
      @click="toggleNotificationDropdown"
      size="28px"
      outerSize="48px"
      :active="isNotificationDropdownOpened"
    />
    <div
      class="hint-count"
      v-if="unreadNotificationsCount !== 0"
    >
      {{ unreadCount }}
    </div>
    <div
      class="notification-dropdown"
      v-if="isNotificationDropdownOpened"
    >
      <div class="notification-header">
        <h6 class="bold">
          {{ $t('Notification') }}
        </h6>
        <div
          class="mark-read-all"
          @click="setAllAsRead"
          :class="{ disabled: hasNoNotifications }"
        >
          <SvgIcon
            iconClass="general_show"
            size="20px"
            :disabled="hasNoNotifications"
          />
          <h6>{{ $t('Mark all as read') }}</h6>
        </div>
        <div
          class="notification-hint"
          v-if="isNotificationDropdownOpened && hasNewNotifications"
        >
          <span>{{ $t('New notifications') }}</span>
          <button
            class="refresh-button"
            @click="refresh"
          >
            {{ $t('Refresh') }}
          </button>
        </div>
      </div>
      <div
        class="empty-block notification-empty"
        v-if="hasNoNotifications"
      >
        <div
          class="icon no-notification-icon"
        />
        <h6>{{ $t('You don\'t have any notifications') }}</h6>
        <span class="paragraph">
          {{ $t('Please check later.') }}
        </span>
      </div>
      <div
        v-else
        class="notification-container"
        ref="list"
      >
        <div
          v-for="(item, index) in latestNotifications"
          :key="`notification-${item.notificationId}`"
        >
          <div
            class="notification-item pointer"
            @click="setAsReadAndDirect(item)"
          >
            <div
              v-if="!item.isRead"
              class="new-hint"
            />
            <div
              class="item"
              :class="{ 'item-with-new': !item.isRead }"
            >
              <h6 :title="item.title">
                {{ item.title }}
              </h6>
              <div class="content">
                <SnapshotWrapper
                  class="snapshot"
                  :ref="`snapshotWrapper-${item.notificationId}`"
                  :data-index="item.notificationId"
                  :key="`${item.snapshotChannel}-${item.notificationId}`"
                  :channel="item.snapshotChannel + 1"
                  :snapshotTime="item.timestamp"
                  :username="user.userName"
                  :sessionId="user.sessionId"
                  :observer="observer"
                />
                <div class="info">
                  <div>
                    <span class="paragraph info-name">{{ $t('Time') }}:</span>
                    <span
                      class="paragraph info-value"
                      :title="item.displayFullTime"
                    >
                      {{ item.displayFullTime }}
                    </span>
                  </div>
                  <div>
                    <span class="paragraph info-name">{{ $t('Trigger source') }}:</span>
                    <span
                      class="paragraph info-value"
                      :title="getDeviceName(item)"
                    >
                      {{ getDeviceName(item) }}
                    </span>
                  </div>
                  <div>
                    <span class="paragraph info-name">{{ $t('Trigger type') }}:</span>
                    <span
                      class="paragraph info-value"
                      :title="translateTypeName(item.triggerType)"
                    >
                      {{ translateTypeName(item.triggerType) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="option-separator"
            v-if="index < latestNotifications.length"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { directive as onClickaway } from 'vue-clickaway';
import {
  mapState, mapActions, mapGetters, mapMutations
} from 'vuex';
import SnapshotWrapper from '@/components/viewcell/SnapshotWrapper.vue';
import { SYSTEM_TRIGGERS, CAMERA_TRIGGERS } from '@/constants/triggers';
import { VCA_EVENT_TYPE } from '@/constants/eventType';
import IntersectionObserverHelper from '@/utility/intersectionObserverHelper';

export default {
  name: 'NotificationPanel',
  components: {
    SnapshotWrapper
  },
  data() {
    return {
      isNotificationDropdownOpened: false,
      observer: null
    };
  },
  directives: {
    onClickaway,
  },
  watch: {
    notifications(newValue, oldValue) {
      if (oldValue.length === 0 && newValue.length > 0) {
        // apply first latestNotificationsCount
        this.updateLatestNotificationsCount(this.notifications.length);
      }
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters('notification', ['notifications', 'latestNotificationsCount', 'unreadNotificationsCount']),
    ...mapGetters('system', ['systemTimezone']),
    ...mapGetters('search', ['currentResult', 'hasSearchedResult']),
    ...mapGetters('encoder', ['availableEncoders']),
    latestNotifications() {
      return this.notifications.slice(`${-this.latestNotificationsCount}`);
    },
    hasNoNotifications() {
      return this.latestNotificationsCount === 0;
    },
    hasNewNotifications() {
      return this.notifications.length > this.latestNotificationsCount;
    },
    unreadCount() {
      if (this.unreadNotificationsCount > 9) {
        return '9+';
      }
      return this.unreadNotificationsCount;
    },

  },
  methods: {
    ...mapActions('ui', ['navigate']),
    ...mapMutations('notification', ['updateLatestNotificationsCount']),
    ...mapActions('search', ['fetchAlarmList', 'setUrlQueryToCriteriaOption']),
    ...mapActions('websocket', ['sendNotificationAsRead']),
    getSearchAlarmUrl(id) {
      const item = this.notifications.find((info) => info.notificationId === id);

      if (!item) {
        return '';
      }

      const url = `${window.location.origin}/search.html#/`;
      const search = new URLSearchParams({
        alarmName: item.title,
        channel: item.channel,
        triggerType: item.triggerType,
        time: item.time
      });
      return `${url}?${search}`;
    },
    setAllAsRead() {
      this.sendNotificationAsRead(0);
    },
    setAsReadAndDirect(item) {
      if (!item.isRead) {
        this.sendNotificationAsRead(item.notificationId);
      }

      this.navigate({
        url: this.getSearchAlarmUrl(item.notificationId),
      });
      this.onNotificationDropdownClickaway();
    },
    toggleNotificationDropdown() {
      this.isNotificationDropdownOpened = !this.isNotificationDropdownOpened;
      if (this.isNotificationDropdownOpened) {
        this.refresh();
      }
    },
    onNotificationDropdownClickaway() {
      this.isNotificationDropdownOpened = false;
    },
    getDeviceName(item) {
      return item.channel === -1 ? this.$t('System') : item.deviceName;
    },
    refresh() {
      this.updateLatestNotificationsCount(this.notifications.length);
      if (this.$refs.list) {
        this.$refs.list.scrollTop = 0;
      }
    },
    translateTypeName(typeKey) {
      return this.$t(
        VCA_EVENT_TYPE[typeKey]
        || CAMERA_TRIGGERS[typeKey]
        || SYSTEM_TRIGGERS[typeKey]
        || typeKey
      );
    }
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  mounted() {
    this.observer = IntersectionObserverHelper.getObserver(this.$refs.notificationDropdown.$el);
  }
};
</script>

<style lang="less" scoped>
.hint-count {
  border: rem(2) solid #202020;
  border-radius: 50%;
  height: rem(16);
  width: rem(16);
  line-height: rem(14);
  background-color: #FF6600;
  color: #FFFFFF;
  position: absolute;
  top: rem(5);
  margin-left: rem(23);
  text-align: center;
  font-size: rem(12);
}

.notification-dropdown {
  position: absolute;
  top: rem(0);
  background: #2E2E2E;
  border: rem(1) solid #000000;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.30);;
  z-index: 999;
  transform: translateY(rem(48)) translateX(-60%);
  width: rem(400);
  max-height: 80vh;
  font-size: rem(14);
  display: flex;
  flex-direction: column;

  .notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(18) rem(20);
    border-bottom: rem(1) solid #000000;
    .mark-read-all {
      display: flex;
      align-items: center;
      cursor: pointer;
      > svg {
        margin-right: rem(6);
      }

      &.disabled {
        cursor: default;
        > h6 {
          color: @body-font-color-disabled;
        }
      }
    }
  }
  .notification-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.notification-empty {
  padding-bottom: rem(18);
  .no-notification-icon {
    background-image: url('../assets/illustration_no_notification.svg');
  }
}

.notification-hint {
  width: rem(316);
  height: rem(56);
  background-color: #545454;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  top: rem(60);
  left: rem(42);

  span {
    flex: 1;
    margin-left: rem(20);
  }
  button {
    min-width: rem(60);
    width: fit-content;
    height: rem(24);
    margin-right: rem(20);
  }
}
.notification-item {
  display: flex;
  flex-direction: row;

  &.pointer {
    cursor: pointer;
  }
  .new-hint {
    width: rem(4);
    background-color: #FFB029;
  }
  .item {
    flex: 1;
    &.item-with-new {
      margin-left: rem(-4);
    }
  }
}
.item {
  display: flex;
  flex-direction: column;
  width: rem(360);
  padding: rem(18);
  padding-top: rem(7);

  > h6 {
    width: rem(350);
    color: #ADADAD;
    margin-bottom: rem(5);
    word-break: break-all;
  }
  > .content {
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;

    > .snapshot {
      margin-top: rem(7);
      width: rem(103);
      height: rem(58);
      background-size: rem(28);
      background-position-y: rem(5);
    }
    > .info {
      flex: 1;
      margin-left: rem(10);
      > div {
        line-height: rem(16);
        margin-bottom: rem(5);
      }

      .info-name {
        color: #8f8f8f;
        margin-right: rem(10);
        display: inline-block;
        overflow: hidden;
      }

      .info-value {
        display: inline-block;
        width: rem(150);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
</style>

<style lang="less">
.notification-item .snapshot-wrapper .empty-block {
  .no-recording-icon, .no-snapshot-icon {
    height: rem(28);
    width: rem(28);
  }
  .no-recording-icon {
    background-position-y: rem(-5);
  }
  > span {
    bottom: rem(5);
    @media only screen and (max-width: 1366px){
      bottom: 0;
    }
  }
}
</style>
