import axios from 'axios';
import store from '@/store';

export default {
  getLiveviewVCARule(channel) {
    const path = `/system/software/meteor/vca_config/${channel}`;
    const params = `path=${encodeURIComponent(path)}`;
    return axios.post('/fcgi-bin/operator/gconf.query', params).then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
      return Promise.reject();
    });
  },
  getPlaybackVCARule(channel, timestamp) {
    const data = {
      locationIDs: `${[`Loc${channel + 1}`]}`,
      timestamp: Math.floor(timestamp / 1000),
    };

    return store.dispatch('websocket/queryPlaybackVCARule', data);
  }
};
