import EventEmitter from 'events';

export default class JoystickProcessor extends EventEmitter {
  constructor({
    width = 0,
    height = 0,
  } = {}) {
    super();
    this.width = width;
    this.height = height;
    this.dragEventExpired = Date.now();
    this.dragStart = null;
    this.dragging = null;

    this.on('panstart', this.panstart);
    this.on('panend', this.panend);
    this.on('panmove', this.panmove);
  }

  resize({
    width = 0,
    height = 0,
  } = {}) {
    this.width = width;
    this.height = height;
  }

  panstart(evt) {
    this.dragStart = {
      x: evt.offsetX,
      y: evt.offsetY
    };
  }

  panend() {
    this.dragStart = null;
    this.emit('ptz', { x: 0, y: 0, speed: 0 });
  }

  panmove(evt) {
    const now = Date.now();
    const { width, height, dragStart, dragEventExpired } = this;

    if (!dragStart || dragEventExpired > now) {
      return;
    }

    const deltaX = evt.offsetX - this.dragStart.x;
    const deltaY = this.dragStart.y - evt.offsetY;
    const deltaDirect = Math.sqrt(deltaX ** 2 + deltaY ** 2);
    const radius = Math.max(width, height);
    const speed = Math.min(deltaDirect / radius, 1);
    const x = deltaX / width;
    const y = deltaY / height;

    this.dragEventExpired = now + 100;
    this.emit('ptz', { x, y, speed });
  }
};
