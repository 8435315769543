<template>
  <div class="app-dropdown-wrapper">
    <multiselect
      v-model="vModel"
      :trackBy="trackBy"
      label="text"
      :groupValues="groupValues"
      :groupLabel="groupLabel"
      :groupSelect="groupSelect"
      :options="options"
      :allowEmpty="multiple"
      :closeOnSelect="!multiple"
      :clearOnSelect="false"
      :multiple="multiple"
      :showLabels="showLabels"
      :searchable="searchable"
      :taggable="false"
      :placeholder="placeholder"
      class="app-dropdown"
      :class="{
        single: !multiple,
        multiple: multiple,
        error: isError && !showLabels
      }"
      :disabled="disabled"
    >
      <template
        slot="selection"
      >
        <span
          class="multiselect__single"
          v-if="multiple && !showLabels"
        >
          <template>
            <span>{{ displayingValue }}</span>
          </template>
        </span>
      </template>

      <template
        slot="beforeList"
        v-if="multiple"
      >
        <div
          class="all-option-wrapper"
          @click.stop.self="selectAll"
          :class="{ selected : isSelectingAll}"
        >
          <AppCheckbox
            :indeterminate="isSelectIndeterminate"
            v-model="isSelectingAll"
            @click.native.prevent="selectAll"
          />
          <span class="option__title">{{ selectAllLabel }}</span>
        </div>
      </template>

      <template
        v-if="multiple"
        slot="option"
        slot-scope="{ option }"
      >
        <template v-if="!groupSelect">
          <AppCheckbox
            :value="option"
            v-model="vModel"
            dropDownMode
          />
          <span class="option__title">{{ option.text }}</span>
        </template>

        <template v-if="groupSelect">
          <AppCheckbox
            v-if="!option.$isLabel"
            :value="option"
            v-model="vModel"
            dropDownMode
          />
          <span v-if="option.$groupLabel">
            {{ option.$groupLabel }}
          </span>
          <span
            v-if="!option.$isLabel"
            class="option__title"
          >{{ option.text }}</span>
        </template>
      </template>
    </multiselect>
    <AppCardMessage
      v-if="isError && !showLabels"
      class="alert"
      iconClass="general_hint"
    >
      <span
        slot="message"
        class="message"
      >
        {{ $t('Select at least 1 item') }}<br>
      </span>
    </AppCardMessage>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import AppCardMessage from '@/components/ui/AppCardMessage.vue';

export default {
  name: 'AppDropdown',
  components: {
    multiselect,
    AppCheckbox: () => import('@/components/ui/CheckBox.vue'),
    AppCardMessage
  },
  props: {
    trackBy: {
      type: String,
      required: false,
      default: 'value'
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Object, Boolean, Number, Array],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    groupSelect: {
      type: Boolean,
      default: false,
      required: false
    },
    selectAllLabel: {
      type: String,
      default: '',
      required: false
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      MAX_DISPLAYING_LENGTH: 20
    };
  },
  computed: {
    vModel: {
      set(value) {
        if (this.groupSelect) {
          this.$emit(
            'input',
            value
          );
        } else if (this.multiple && !this.groupSelect) {
          this.$emit(
            'input',
            value.map((item) => item.value)
          );
        } else {
          this.$emit('input', value.value);
        }
      },
      get() {
        if (this.groupSelect) {
          return this.value;
        }

        if (this.multiple) {
          return this.options.filter((item) => this.value.includes(item.value));
        }

        return this.options.filter((item) => item.value === this.value)[0];
      }
    },
    isSelectingAll() {
      if (this.groupSelect) {
        return this.vModel.length === this.groupAllItem.length;
      }

      return this.vModel.length === this.options.length;
    },
    isSelectIndeterminate() {
      if (this.groupSelect) {
        return (
          this.vModel.length !== this.groupAllItem.length
          && this.vModel.length !== 0
        );
      }

      return (
        this.vModel.length !== this.options.length && this.vModel.length !== 0
      );
    },
    groupValues() {
      return this.groupSelect ? 'groupValues' : '';
    },
    groupLabel() {
      return this.groupSelect ? 'groupLabel' : '';
    },
    groupAllItem() {
      if (!this.groupSelect) {
        return [];
      }

      const result = [];
      this.options.forEach((element) => {
        result.push(...element.groupValues);
      });
      return result;
    },
    displayingValue() {
      if (this.isSelectingAll) {
        return this.selectAllLabel;
      }

      let a = [];
      this.vModel.forEach((element, index) => {
        if (a.toString().length <= this.MAX_DISPLAYING_LENGTH) {
          a.push(element.text);
        }
      });
      const remainingAmount = this.vModel.length - a.length;

      a = remainingAmount !== 0 ? `${a.toString().slice(0, this.MAX_DISPLAYING_LENGTH)}...,+${remainingAmount}`
        : a.toString();

      return a;
    }
  },
  methods: {
    selectAll() {
      if (this.groupSelect && !this.isSelectingAll) {
        this.vModel = this.groupAllItem;
      } else if (!this.groupSelect && !this.isSelectingAll) {
        this.vModel = this.options;
      } else {
        this.vModel = [];
      }
    }
  }
};
</script>

<style lang="less">
.multiselect.app-dropdown {
  border: rem(1) solid #adadad;
  min-height: rem(32);
  &:hover {
    border: rem(1) solid #fff;
    .multiselect__select:before {
      border-color: #fff transparent transparent;
    }
  }
  &.multiselect--disabled {
    border: rem(1) solid #4F4F4F;
    .multiselect__select {
      background: transparent;
      &:before {
        border-color: #4F4F4F transparent transparent;
      }
    }
    .multiselect__single {
      color: #4F4F4F;
    }
  }
  &.error{
    border: rem(1) solid #FFB029;
  }

  .multiselect__placeholder {
    margin: 0;
    padding: 0;
  }
  .multiselect__select {
    height: 32px;
    &:before {
      top: 80%;
      border-width: 10px 7px 0;
      border-color: #adadad transparent transparent;
    }
  }
  .multiselect__tags {
    border-radius: 0;
    min-height: 32px;
    padding: 6px 12px;
    background: #121212;
    border: none;
    display: flex;
    align-items: center;
    height: 32px;
  }
  .multiselect__single {
    display: inline-block;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
    min-height: auto;
    margin-bottom: 0;
    background-color: transparent;
    padding: 0;
    color: #c2c2c2;
    text-align: left;
    line-height: initial;
    > span {
      color: #c2c2c2;
    }
  }
  .multiselect__content-wrapper {
    background: #121212;
    border: rem(1) solid #e0e0e0;
    border-radius: 0;
    width: calc(100% + rem(2));
    top: rem(35);
    left: rem(-1);
    bottom: auto;
  }
  .multiselect__option {
    display: flex;
    align-items: center;
    height: rem(32);
    background: #121212;
    font-size: rem(14);
    color: #c2c2c2;
    text-align: left;
    &:hover {
      color: #ffffff;
      background: #2986ff;
    }
  }
  &.single {
    .multiselect__option {
      &.multiselect__option--selected {
        color: #ffffff;
        background: #2986ff;
      }
    }
  }
  .all-option-wrapper {
    display: flex;
    align-items: center;
    height: rem(52);
    padding: rem(16) rem(12);
    background: #121212;
    font-size: rem(14);
    color: #c2c2c2;
    text-align: left;
    border-bottom: rem(1) solid #e0e0e0;
    &:hover,
    &.selected {
      color: #ffffff;
      background: #2986ff;
    }
  }
}

&.small-image .multiselect.app-dropdown {
  min-height: rem(22);
  .multiselect__select {
    height: rem(22);
    width: rem(22);
    &:before {
      top: 90%;
      border-width: rem(6) rem(4) 0;
    }
  }
  .multiselect__tags {
    min-height: rem(22);
    padding: rem(4) rem(6);
    height: rem(22);
    font-size: rem(12);
  }
  .multiselect__single {
    font-size: rem(12);
    font-weight: 600;
  }
  .multiselect__content-wrapper {
    width: calc(100% + rem(2));
    top: rem(24);
    left: rem(-1);
  }
  .multiselect__option {
    height: rem(22);
    font-size: rem(12);
    min-height: rem(22);
    padding: 0 rem(6);
    font-weight: 600;
  }
  .all-option-wrapper {
    font-size: rem(12);
  }
}
</style>
