import Vue from 'vue';

const mutations = {
  toggleSceneEditor(state) {
    state.overlay.showSceneEditor = !state.overlay.showSceneEditor;
  },
  toggleSearchExport(state) {
    state.overlay.showSearchExportModal = !state.overlay.showSearchExportModal;
  },
  toggleSmartSearchExport(state) {
    state.overlay.showSmartSearchExportModal = !state.overlay.showSmartSearchExportModal;
  },
  togglePlaybackExport(state) {
    state.overlay.showPlaybackExportModal = !state.overlay.showPlaybackExportModal;
  },
  toggleSystemRestart(state) {
    state.overlay.showSystemRestartModal = true;
  },
  hideAllOverlay(state, { exclusiveKey }) {
    Object.keys(state.overlay).forEach((key) => {
      if (exclusiveKey !== key) {
        state.overlay[key] = false;
      }
    });
  },
  setViewCellExpanded(state, isExpanded) {
    state.isViewCellExpanded = isExpanded;
  },
  setDirty(state, key) {
    Vue.set(state.dirty, key, true);
  },
  clearDirty(state, key) {
    Vue.set(state.dirty, key, false);
  },
  setURL(state, url) {
    state.navigationURL = url;
  },
  clearURL(state) {
    state.navigationURL = '';
  },
  directToURL(state) {
    if (state.navigationURL === '') {
      return;
    }
    if (state.navigationURL === location.href) {
      location.reload();
    }
    location.href = state.navigationURL;
  },
  registerBusyHandler(state, handler) {
    Vue.set(state, 'busyHandler', handler);
  },
  unregisterBusyHandler(state) {
    Vue.set(state, 'bustHandler', undefined);
  },
  registerConfirmNavigationCallback(state, callback) {
    Vue.set(state, 'confirmNavigationCallback', callback);
  },
  unregisterConfirmNavigationCallback(state) {
    Vue.set(state, 'confirmNavigationCallback', undefined);
  }
};

export default mutations;
