/*
  ref: https://sdd-gitlab.vivotek.tw/vast2/Client/VivoQuick/-/blob/master/VVViewCell/shapes.js
  ref: e96116a7088b5873501194a64cc955bfae94308f
*/
let Timer = function() {}

function MotionDetectionAlert({
    points
  }, reference) {
  this.name = `$LegacyMotion${JSON.stringify(points)}`;
  this.eventInterface = reference.reference.eventInterface.register(this);
  this.points = points;
  this.reference = reference;
}

MotionDetectionAlert.prototype.draw = function(ctx, fw, fh) {
  if (this.points.length > 1) {
    ctx.strokeStyle = '#ff0000';
    ctx.beginPath();
    ctx.moveTo(this.points[0].x * fw, this.points[0].y * fh);
    for (var i = 1; i < this.points.length; i++) {
      ctx.lineTo(this.points[i].x * fw, this.points[i].y * fh);
    }
    ctx.stroke();
  }
}

function drawMissingObjects(ctx, objects, fw, fh) {
  if (objects.length) {
    // var img = ctx.createImageData(8, 1);
    // for (var i = 0; i < 4 * 4; i += 4) {
    //   img.data[i + 0] = 0xff;
    //   img.data[i + 1] = 0x55;
    //   img.data[i + 2] = 0x00;
    //   img.data[i + 3] = 0xff;
    // }
    // ctx.strokeStyle = ctx.createPattern(img, 'repeat');
    ctx.strokeStyle = '#ff5500';
    ctx.lineWidth = 2;
    objects.forEach(function(points) {
      for (var i = 0, j = points.length; i < j; i++) {
        var p0 = points[i];
        var p1 = points[(i + 1) % points.length];
        var dx = (p1.x - p0.x) * fw;
        var dy = (p1.y - p0.y) * fh;
        ctx.save();
        ctx.translate(p1.x * fw, p1.y * fh);
        ctx.rotate(Math.atan2(dy, dx));
        ctx.beginPath();
        ctx.setLineDash([4,4]);
        ctx.moveTo(-Math.sqrt(dx * dx + dy * dy), 0);
        ctx.lineTo(0, 0);
        ctx.stroke();
        ctx.restore();
      }
    });
    ctx.lineWidth = 1;
  }
}

function MissingObject({
  objects,
}, reference) {
  this.name = '$MissingObject'; // for event interface list usage only.
  this.objects = objects;
  this.reference = reference;
  this.eventInterface = reference.reference.eventInterface.register(this);
}

MissingObject.prototype.draw = function(ctx, fw, fh) {
  drawMissingObjects(ctx, this.objects, fw, fh);
}

function CellMotion({
  columnCount,
  rowCount,
  cells = '',
}, reference) {
  this.name = '$CellMotion'; // for event interface list usage only.
  this.columnCount = columnCount;
  this.rowCount = rowCount;
  this.cells = cells;
  this.reference = reference;
  this.eventInterface = reference.reference.eventInterface.register(this);
}

CellMotion.prototype.draw = function(ctx, fw, fh) {
  ctx.fillStyle = "rgba(0, 147, 211, .4)";
  var cellWidth = fw / this.columnCount;
  var cellHeight = fh / this.rowCount;
  for (let i = 0; i < this.cells.length * 8; i++) {
    if (this.cells.charCodeAt(i / 8) & [0x80, 0x40, 0x20, 0x10, 0x08, 0x04, 0x02, 0x01][i % 8]) {
      ctx.fillRect((i % this.columnCount) * cellWidth, (i / this.columnCount) * cellHeight, cellWidth, cellHeight);
    }
  }
}

function Vector2D(x, y) {
  this.x = x;
  this.y = y;
}

Vector2D.prototype = {
  dotProduct: function(other) {
    return this.x * other.x + this.y * other.y;
  },
  times: function(factor) {
    return new Vector2D(this.x * factor, this.y * factor);
  },
  plus: function(other) {
    return new Vector2D(this.x + other.x, this.y + other.y);
  },
  minus: function(other) {
    return new Vector2D(this.x - other.x, this.y - other.y);
  },
  normalized: function() {
    var n = this.length();
    return new Vector2D(this.x / n, this.y / n);
  },
  length: function() {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  },
  rotate: function(theta) {
    var a = Math.cos(theta);
    var b = Math.sin(theta);
    return new Vector2D(this.x * a - this.y * b, this.x * b + this.y * a);
  },
};

function Point(x, y) {
  Vector2D.call(this, x, y);
}

Point.prototype = Object.create(Vector2D.prototype);
Point.prototype.constructor = Point;

Point.prototype.translate = function(vec) {
  return new Point(this.x + vec.x, this.y + vec.y);
};

Point.prototype.scale = function(sx, sy) {
  return new Point(this.x * sx, this.y * sy);
};

function distance(p0, p1) {
  return p0.minus(p1).length();
}

function isPolyline(points) {
  return points.length == 3 && distance(points[0], points[1]) && distance(points[1], points[2]) && distance(points[0], points[2]);
}

function Bounds(point) {
  this.left = point.x;
  this.top = point.y;
  this.right = point.x;
  this.bottom = point.y;
}

Bounds.prototype = {
  add: function(point) {
    this.left = Math.min(this.left, point.x);
    this.top = Math.min(this.top, point.y);
    this.right = Math.max(this.right, point.x);
    this.bottom = Math.max(this.bottom, point.y);
  },
  width: function() {
    return this.right - this.left;
  },
  height: function() {
    return this.bottom - this.top;
  },
  isEmpty: function() {
    return this.right <= this.left || this.bottom <= this.top;
  },
};

function isPolygon(points) {
  if (points.length >= 3) {
    var bounds = new Bounds(points[0]);
    for (var i = 1, j = points.length; i < j; i++) {
      bounds.add(points[i]);
    }
    return !bounds.isEmpty();
  }
  return false;
}

function isFlowPathCounting(segments) {
  return segments.length == 7 && segments.every(function(points) {
    return points.length == 2 && distance(points[0], points[1]);
  });
}

function Rule() {}

Rule.prototype = {
  draw: function() {},
  objectIsInside: function() {},
  crossed: function() {},
  accumulative: function() {},
  objectIsLoitering: function() {},
  crowdAlarm: function() {},
  behaviorAlarm: function() {},
  objectDetected: function() {},
  destroy: function() {}
};

function TransparentRule(canvas, name, points, preset, reference) {
  Rule.call(this);
  this.canvas = canvas;
  this.name = name;
  this.triggered = false;
  this.timer = new Timer({ interval: 1500 }, canvas);
  function timeout() {
    this.triggered = !this.triggered;
    this.canvas.requestPaint();
  }
  this.timer.triggered.connect(timeout.bind(this));
  this.bounds = new Bounds(points[0]);
  for (var i = 1, j = points.length; i < j; i++) {
    this.bounds.add(points[i]);
  }
  this.preset = preset;
  this.eventInterface = reference.reference.eventInterface.register(this);
}

TransparentRule.prototype = Object.create(Rule.prototype);
TransparentRule.prototype.constructor = TransparentRule;

TransparentRule.prototype.destroy = function() {
  this.timer.destroy();
};

function clamp(x, min, max) {
  return x < min ? min : x > max ? max : x;
}

/**
 *
 * 3 if fw round to 1024 / 3
 * 2 if fw round to 1024 / 4
 * 1 if fw round to 1024 / 5
 * 0 if fw round to 1024 / 6
 *
 **/
function fontUnit(fw) {
  return 6 - clamp(Math.round(1024 / fw), 3, 6);
}

TransparentRule.prototype.draw = function(ruleScaleRatio, ctx, fw, fh) {
  var x = (this.bounds.left + this.bounds.right) / 2 * fw;
  var y = this.bounds.top * fh - 10;
    var fontSize = 12*ruleScaleRatio + fontUnit(fw, fh);
  if (y < fontSize * 0.7) {
    y = this.bounds.bottom * fh + 10 + fontSize * 0.7;
  }
  ctx.textAlign = "center";
  ctx.shadowOffsetY = 2;
  ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
  ctx.fillStyle = this.triggered ? "#ff5500" : "#20f4d4";
  ctx.font = "" + fontSize + "px sans-serif";
  ctx.fillText(this.name, x, y);
  ctx.textAlign = "start";
  ctx.shadowOffsetY = 0;
};

TransparentRule.prototype.startTimer = function() {
  if (!this.triggered) {
    this.triggered = true;
    this.canvas.requestPaint();
  }
  this.timer.restart();
};

function LineDetection({canvas, name, points, direction, preset}, reference) {
  TransparentRule.call(this, canvas, name, points, preset, reference);
  this.points = points;
  this.direction = direction;
  this.reference = reference;
}

LineDetection.prototype = Object.create(TransparentRule.prototype);
LineDetection.prototype.constructor = LineDetection;

LineDetection.prototype.draw = function(ruleScaleRatio, ctx, fw, fh, showSummary, showRuleName) {
  if (showSummary && showRuleName) {
        TransparentRule.prototype.draw.call(this, ruleScaleRatio, ctx, fw, fh);
  }
  var A = this.points[0].scale(fw, fh);
  var B = this.points[1].scale(fw, fh);
  var C = this.points[2].scale(fw, fh);
  ctx.beginPath();
  ctx.moveTo(A.x, A.y);
  ctx.lineTo(B.x, B.y);
  ctx.lineTo(C.x, C.y);
  ctx.strokeStyle = this.triggered ? "#ff5500" : "#20f4d4";
    ctx.lineWidth = 2*ruleScaleRatio;
  ctx.shadowOffsetY = 2;
  ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
  ctx.stroke();
    ctx.lineWidth = 1*ruleScaleRatio;
  ctx.shadowOffsetY = 0;
  var ab = B.minus(A);
  var bc = C.minus(B);
  var leftVec = ab.normalized().plus(bc.normalized()).rotate(-Math.PI / 2).normalized();
  var rightVec = leftVec.times(-1);
  if (showSummary) {
    var I = B.translate(leftVec.times(2 + 4 + 25));
    var O = B.translate(rightVec.times(2 + 4 + 25));
    ctx.beginPath();
    ctx.arc(I.x, I.y, 25, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(38, 96, 183, 0.4)";
    ctx.fill();
    ctx.beginPath();
    ctx.arc(O.x, O.y, 25, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(244, 96, 32, 0.4)";
    ctx.fill();
    ctx.textAlign = "center";
    ctx.fillStyle = "#ffffff";
    ctx.font = "12px sans-serif";
    ctx.fillText("IN", I.x, I.y + 12 * 0.35);
    ctx.fillText("OUT", O.x, O.y + 12 * 0.35);
    ctx.textAlign = "start";
  }
};

LineDetection.prototype.crossed = function() {
  TransparentRule.prototype.startTimer.call(this);
};

LineDetection.prototype.behaviorAlarm = function() {
  TransparentRule.prototype.startTimer.call(this);
};

function FieldDetection({canvas, name, points, preset}, reference) {
  TransparentRule.call(this, canvas, name, points, preset, reference);
  this.points = points;
  this.objects = [];
  this.objectsTimer = new Timer({ interval: 1500 }, canvas);
  function timeout() {
    this.objects.splice(0, this.objects.length);
    this.canvas.requestPaint();
  }
  this.objectsTimer.triggered.connect(timeout.bind(this));
}

FieldDetection.prototype = Object.create(Rule.prototype);
FieldDetection.prototype.constructor = FieldDetection;

FieldDetection.prototype.draw = function(ruleScaleRatio, ctx, fw, fh, showSummary, showRuleName) {
  if (showSummary && showRuleName) {
        TransparentRule.prototype.draw.call(this, ruleScaleRatio, ctx, fw, fh);
  }
  ctx.beginPath();
  ctx.moveTo(this.points[0].x * fw, this.points[0].y * fh);
  for (var i = 0, j = this.points.length; i < j; i++) {
    ctx.lineTo(this.points[i].x * fw, this.points[i].y * fh);
  }
  ctx.closePath();
  ctx.strokeStyle = this.triggered ? "#ff5500" : "#20f4d4";
    ctx.lineWidth = 2*ruleScaleRatio;
  ctx.shadowOffsetY = 2;
  ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
  ctx.stroke();
    ctx.lineWidth = 1*ruleScaleRatio;
  ctx.shadowOffsetY = 0;
  drawMissingObjects(ctx, this.objects, fw, fh);
};

FieldDetection.prototype.objectIsInside = function() {
  TransparentRule.prototype.startTimer.call(this);
};

FieldDetection.prototype.objectIsLoitering = function() {
  TransparentRule.prototype.startTimer.call(this);
};

FieldDetection.prototype.crowdAlarm = function() {
  TransparentRule.prototype.startTimer.call(this);
};

FieldDetection.prototype.behaviorAlarm = function() {
  TransparentRule.prototype.startTimer.call(this);
};

FieldDetection.prototype.objectDetected = function(objects) {
  this.objects = objects;
  this.canvas.requestPaint();
  this.objectsTimer.restart();
};

FieldDetection.prototype.destroy = function() {
  TransparentRule.prototype.destroy.call(this);
  this.objectsTimer.destroy();
};

function Counting({canvas, name, points, preset}, reference) {
  TransparentRule.call(this, canvas, name, points, preset, reference);
  this.points = points;
  this.totalIn = 0;
  this.totalOut = 0;
  this.reference = reference;
  this.hasSummary = true;
}

Counting.prototype = Object.create(TransparentRule.prototype);
Counting.prototype.constructor = Counting;

function drawOutlineText(ctx, text, x, y) {
  ctx.fillText(text, x, y);
  ctx.strokeText(text, x, y);
}

Counting.prototype.draw = function(ruleScaleRatio, ctx, fw, fh, showSummary, showRuleName, showDirection) {
  if (showSummary && showRuleName) {
        TransparentRule.prototype.draw.call(this, ruleScaleRatio, ctx, fw, fh);
  }
  var A = this.points[0].scale(fw, fh);
  var B = this.points[1].scale(fw, fh);
  var C = this.points[2].scale(fw, fh);
  ctx.beginPath();
  ctx.moveTo(A.x, A.y);
  ctx.lineTo(B.x, B.y);
  ctx.lineTo(C.x, C.y);
  ctx.strokeStyle = this.triggered ? "#ff5500" : "#20f4d4";
    ctx.lineWidth = 2*ruleScaleRatio;
  ctx.lineCap = "round";
  ctx.shadowOffsetY = 2;
  ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
  ctx.stroke();
    ctx.lineWidth = 1*ruleScaleRatio;
  ctx.lineCap = "butt";
  ctx.shadowOffsetY = 0;
  var ab = B.minus(A);
  var bc = C.minus(B);
  var leftVec = ab.normalized().plus(bc.normalized()).rotate(-Math.PI / 2).normalized();
  var rightVec = leftVec.times(-1);
  if (showSummary) {
    var I = B.translate(leftVec.times(2 + 4 + 25));
    var O = B.translate(rightVec.times(2 + 4 + 25));
    ctx.beginPath();
    ctx.arc(I.x, I.y, 25, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(38, 96, 183, 0.4)";
    ctx.fill();
    ctx.beginPath();
    ctx.arc(O.x, O.y, 25, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(244, 96, 32, 0.4)";
    ctx.fill();
    ctx.textAlign = "center";
    ctx.fillStyle = "#ffffff";
    ctx.font = "12px sans-serif";
    ctx.fillText(this.totalIn, I.x, I.y - 4);
    ctx.fillText("IN", I.x, I.y + 4 + 12 * 0.7);
    ctx.fillText(this.totalOut, O.x, O.y - 4);
    ctx.fillText("OUT", O.x, O.y + 4 + 12 * 0.7);
    ctx.textAlign = "start";
  } else if (showDirection) {
    var I = B.translate(leftVec.times(2 + 4 + 19));
    var O = B.translate(rightVec.times(2 + 4 + 19));
    ctx.beginPath();
    ctx.arc(I.x, I.y, 19, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(38, 96, 183, 0.4)";
    ctx.fill();
    ctx.beginPath();
    ctx.arc(O.x, O.y, 19, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(244, 96, 32, 0.4)";
    ctx.fill();
    ctx.textAlign = "center";
    ctx.fillStyle = "#ffffff";
    ctx.font = "12px sans-serif";
    ctx.fillText("In", I.x, I.y + 12 * 0.35);
    ctx.fillText("Out", O.x, O.y + 12 * 0.35);
    ctx.textAlign = "start";
  }
};

Counting.prototype.accumulative = function(countIn, countOut, showSummary) {
  if (showSummary) {
    TransparentRule.prototype.startTimer.call(this);
  }
  this.totalIn += countIn;
  this.totalOut += countOut;
};

Counting.prototype.behaviorAlarm = function() {
  TransparentRule.prototype.startTimer.call(this);
};

function ExclusiveArea({ points }, reference) {
  Rule.call(this);
  this.points = points;
  this.reference = reference;
}

ExclusiveArea.prototype = Object.create(Rule.prototype);
ExclusiveArea.prototype.constructor = ExclusiveArea;

ExclusiveArea.prototype.draw = function(ctx, fw, fh) {
  ctx.beginPath();
  ctx.moveTo(this.points[0].x * fw, this.points[0].y * fh);
  for (var i = 1, j = this.points.length; i < j; i++) {
    ctx.lineTo(this.points[i].x * fw, this.points[i].y * fh);
  }
  ctx.fillStyle = "rgba(29, 29, 29, 0.6)";
  ctx.fill();
};

function FlowPathCounting({canvas, name, segments, preset}, reference) {
  TransparentRule.call(this, canvas, name, segments.reduce(function(a, b) {
    return a.concat(b);
  }, []), preset, reference);
  this.segments = segments;
  this.totalIn = 0;
  this.totalOut = 0;
  this.reference = reference;
  this.hasSummary = true;
}

FlowPathCounting.prototype = Object.create(TransparentRule.prototype);
FlowPathCounting.prototype.constructor = FlowPathCounting;

function drawArrow(ctx, p0, p1, sx, sy) {
  var dx = p1.x - p0.x, dy = p1.y - p0.y, len = Math.sqrt(dx * dx + dy * dy);
  ctx.save();
  ctx.translate(p1.x, p1.y);
  ctx.rotate(Math.atan2(dy, dx));
  ctx.beginPath();
  ctx.moveTo(-len, 0);
  ctx.lineTo(-sx, 0);
  ctx.stroke();
  ctx.beginPath();
  ctx.moveTo(-sx, -sy);
  ctx.lineTo(0, 0);
  ctx.lineTo(-sx, sy);
  ctx.fill();
  ctx.restore();
}

FlowPathCounting.prototype.draw = function(ruleScaleRatio, ctx, fw, fh, showSummary, showRuleName, showDirection) {
  if (showSummary && showRuleName) {
        TransparentRule.prototype.draw.call(this, ruleScaleRatio, ctx, fw, fh);
  }
  var segments = this.segments.map(function(points) {
    return points.map(function(point) {
      return point.scale(fw, fh);
    });
  });
    ctx.lineWidth = 2*ruleScaleRatio;
  ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
  ctx.fillStyle = ctx.strokeStyle;
  ctx.save();
  ctx.translate(0, 2);
  for (var i = 0; i < 7; i++) {
    drawArrow(ctx, segments[i][0], segments[i][1], 5, 4);
  }
  ctx.restore();
  ctx.strokeStyle = this.triggered ? "#ff5500" : "#20f4d4";
  ctx.fillStyle = ctx.strokeStyle;
  for (var i = 0; i < 7; i++) {
    drawArrow(ctx, segments[i][0], segments[i][1], 5, 4);
  }
    ctx.lineWidth = 1*ruleScaleRatio;
  var leftVec = segments[4][0].minus(segments[4][1]).normalized();
  var rightVec = leftVec.times(-1);
  if (showSummary) {
    var I = segments[4][0].translate(leftVec.times(2 + 25));
    var O = segments[4][1].translate(rightVec.times(2 + 25));
    ctx.beginPath();
    ctx.arc(I.x, I.y, 25, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(38, 96, 183, 0.4)";
    ctx.fill();
    ctx.beginPath();
    ctx.arc(O.x, O.y, 25, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(244, 96, 32, 0.4)";
    ctx.fill();
    ctx.textAlign = "center";
    ctx.fillStyle = "#ffffff";
    ctx.font = "12px sans-serif";
    ctx.fillText(this.totalIn, I.x, I.y - 4);
    ctx.fillText("IN", I.x, I.y + 4 + 12 * 0.7);
    ctx.fillText(this.totalOut, O.x, O.y - 4);
    ctx.fillText("OUT", O.x, O.y + 4 + 12 * 0.7);
    ctx.textAlign = "start";
  } else if (showDirection) {
    var I = segments[4][0].translate(leftVec.times(2 + 19));
    var O = segments[4][1].translate(rightVec.times(2 + 19));
    ctx.beginPath();
    ctx.arc(I.x, I.y, 19, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(38, 96, 183, 0.4)";
    ctx.fill();
    ctx.beginPath();
    ctx.arc(O.x, O.y, 19, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(244, 96, 32, 0.4)";
    ctx.fill();
    ctx.textAlign = "center";
    ctx.fillStyle = "#ffffff";
    ctx.font = "12px sans-serif";
    ctx.fillText("In", I.x, I.y + 12 * 0.35);
    ctx.fillText("Out", O.x, O.y + 12 * 0.35);
    ctx.textAlign = "start";
  }
};

FlowPathCounting.prototype.accumulative = function(countIn, countOut, showSummary) {
  if (showSummary) {
    TransparentRule.prototype.startTimer.call(this);
  }
  this.totalIn += countIn;
  this.totalOut += countOut;
};

FlowPathCounting.prototype.behaviorAlarm = function() {
  TransparentRule.prototype.startTimer.call(this);
};

function Summary() {
  Rule.call(this);
  this.totalIn = 0;
  this.totalOut = 0;
}

Summary.prototype = Object.create(Rule.prototype);
Summary.prototype.constructor = Summary;

Summary.prototype.draw = function(ctx, fw, fh) {
  var fontSize = 15 + fontUnit(fw, fh);
  var remaining = Math.max(this.totalIn - this.totalOut, 0);
  var x0 = 15;
  ctx.font = "" + fontSize + "px sans-serif";
  var x1 = x0 + ctx.measureText(this.totalIn).width + 8;
  var x2 = x1 + ctx.measureText("in").width + 15;
  var x3 = x2 + ctx.measureText(this.totalOut).width + 8;
  var x4 = x3 + ctx.measureText("out").width + 15;
  var x5 = x4 + ctx.measureText(remaining).width + 8;
  var w = x5 + ctx.measureText("remaining").width + 15;
  var y = 15 + fontSize * 0.7;
  var h = y + 15;
  ctx.save();
  ctx.translate(fw / 2 - w / 2, fh - h);
  ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
  ctx.fillRect(0, 0, w, h);
  ctx.fillStyle = "#2660b7";
  ctx.fillText(this.totalIn, x0, y);
  ctx.fillStyle = "#f46020";
  ctx.fillText(this.totalOut, x2, y);
  ctx.fillStyle = "#cccccc";
  ctx.fillText(remaining, x4, y);
  ctx.fillStyle = "#898989";
  ctx.fillText("in", x1, y);
  ctx.fillText("out", x3, y);
  ctx.fillText("remaining", x5, y);
  ctx.restore();
};

Summary.prototype.accumulative = function(countIn, countOut) {
  this.totalIn += countIn;
  this.totalOut += countOut;
};

function getColorById(id) {
  var colors = [
    "rgb(255, 0, 0)",
    "rgb(0, 255, 255)",
    "rgb(250, 128, 114)",
    "rgb(173, 216, 173)",
    "rgb(0, 255, 0)",
    "rgb(154, 205, 154)",
    "rgb(255, 0, 255)",
    "rgb(186, 85, 211)",
    "rgb(65, 105, 255)",
    "rgb(255, 105, 180)",
    "rgb(255, 255, 0)",
    "rgb(0, 128, 0)"
  ];
  return colors[id % colors.length];
}

function LoiteringObject(id, reference) {
  this.id = id;
  this.loitering = false;
  this.eventInterface = reference.reference.eventInterface.register(this);
}

LoiteringObject.prototype = {
  setLoitering: function(loitering) {
    var changed = this.loitering != loitering;
    this.loitering = loitering;
    return changed;
  }
};

function BoundingBox({id, points, color}, reference) {
  LoiteringObject.call(this, id, reference);
  this.points = points;
  this.color = color;
  this.reference = reference;
}

BoundingBox.prototype = Object.create(LoiteringObject.prototype);
BoundingBox.prototype.constructor = BoundingBox;

BoundingBox.prototype.draw = function(ctx, fw, fh, drawMetadataName, showPosition, showTrackingBlock) {
  if (showTrackingBlock) {
    var points = this.points.map(point => point.scale(fw, fh));
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.moveTo(points[0].x, points[0].y);
    for (var i = points.length - 1; i >= 0; i--) {
      ctx.lineTo(points[i].x, points[i].y);
    }
    ctx.strokeStyle = this.loitering ? "rgb(255, 0, 0)" : this.color;
    ctx.fillStyle = this.loitering ? "rgb(255, 0, 0)" : this.color;
    ctx.globalAlpha = 0.3;
    ctx.stroke();
    ctx.globalAlpha = 1;

    // draw corners, ref: http://gitea.vivotek.tw:3000/ivs/new-motion-www/src/branch/master/src/js/shape/Vehicle.js
    // motor / bike could be rotated rectangle in cv25
    const width = Math.sqrt((points[0].x - points[1].x) * (points[0].x - points[1].x) + (points[0].y - points[1].y) * (points[0].y - points[1].y));
    const height = Math.sqrt((points[2].x - points[1].x) * (points[2].x - points[1].x) + (points[2].y - points[1].y) * (points[2].y - points[1].y));
    const step1 = Math.min(8, (width) / 2);
    const step2 = Math.min(8, (height) / 2);

    const vec1 = {
      x: (points[1].x - points[0].x) / width * step1,
      y: (points[1].y - points[0].y) / width * step1,
    };
    const vec2 = {
      x: (points[2].x - points[1].x) / height * step2,
      y: (points[2].y - points[1].y) / height * step2,
    };

    ctx.beginPath();
    points.forEach((point, index) => {
      const dir1 = (index === 0 || index === 3) ? 1 : -1;
      const dir2 = (index < 2) ? 1 : -1;
      ctx.moveTo(points[index].x + dir1 * vec1.x, points[index].y + dir1 * vec1.y);
      ctx.lineTo(points[index].x, points[index].y);
      ctx.lineTo(points[index].x + dir2 * vec2.x, points[index].y + dir2 * vec2.y);
    });
    ctx.stroke();
    ctx.lineWidth = 1;
  }
  if (drawMetadataName) {
    ctx.fillStyle = "rgb(255, 255, 255)";
    ctx.strokeStyle = "rgb(0, 0, 0)";
    ctx.font = "bold 16px sans-serif";
    ctx.textBaseline = "top";
    drawOutlineText(ctx, this.id, this.points[0].x * fw, this.points[0].y * fh);
    ctx.textBaseline = "alphabetic";
  }
};

function BoundingBox3D({id, height, origin, peak, points}, reference) {
  LoiteringObject.call(this, id, reference);
  this.height = height;
  this.origin = origin;
  this.peak = peak;
  this.points = points;
  this.reference = reference;
}

BoundingBox3D.prototype = Object.create(LoiteringObject.prototype);
BoundingBox3D.prototype.constructor = BoundingBox3D;

BoundingBox3D.prototype.draw = function(
  ctx,
  fw,
  fh,
  drawMetadataName,
  showPosition,
  showTrackingBlock,
  showObjectHeight,
) {
  if (showTrackingBlock) {
    var points = this.points.map(function(point) {
      return point.scale(fw, fh);
    });
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    for (var i = 3; i >= 0; i--) {
      ctx.lineTo(points[i].x, points[i].y);
    }
    ctx.lineTo(points[4].x, points[4].y);
    for (var i = 7; i >= 4; i--) {
      ctx.lineTo(points[i].x, points[i].y);
    }
    for (var i = 1; i < 4; i++) {
      ctx.moveTo(points[i].x, points[i].y);
      ctx.lineTo(points[i + 4].x, points[i + 4].y);
    }
    ctx.strokeStyle = this.loitering ? "rgb(255, 0, 0)" : "#2660b7";
    ctx.stroke();
  }
  var origin = this.origin.scale(fw, fh);
  var peak = this.peak.scale(fw, fh);
  if (showPosition) {
    ctx.beginPath();
    ctx.moveTo(origin.x, origin.y);
    ctx.lineTo(peak.x, peak.y);
    var colors = [
      "rgba(19, 143, 124, 0.8)",
      "rgba(161, 118, 25, 0.8)",
      "rgba(155, 60, 167, 0.8)",
      "rgba(79, 168, 20, 0.8)",
      "rgba(46, 143, 179, 0.8)"
    ];
    ctx.strokeStyle = colors[this.id % colors.length];
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(origin.x, origin.y, 3, 0, 2 * Math.PI);
    ctx.fillStyle = ctx.strokeStyle;
    ctx.fill();
    ctx.strokeStyle = "rgba(255, 255, 255, 0.6)";
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(peak.x, peak.y, 12, 0, 2 * Math.PI);
    ctx.fill();
    ctx.stroke();
    ctx.lineWidth = 1;
  }
  ctx.textAlign = "center";
  if (drawMetadataName) {
    ctx.fillStyle = "#ffffff";
    ctx.font = "12px sans-serif";
    ctx.fillText(this.id, peak.x, peak.y + 12 * 0.35);
  }
  if (showPosition || drawMetadataName) {
    peak.y += 12 + 4 + 9;
  }
  if (showObjectHeight) {
    var text = "H:" + Math.floor(this.height / 10);
    var textWidth = ctx.measureText(text).width;
    ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
    ctx.fillRect(peak.x - 20, peak.y - 9, 40, 18);
    ctx.fillStyle = "#ffffff";
    ctx.font = "10px sans-serif";
    ctx.fillText(text, peak.x, peak.y + 10 * 0.35);
  }
  ctx.textAlign = "start";
}

// CONCATENATED MODULE: ./src/js/projectModule/utils.js
function Js3DPoint() {
  this.x = 0;
  this.y = 0;
  this.z = 0;
}
function Transform2D(pot2D, mat) {
  var pot = {
    x: 0,
    y: 0
  };
  pot.x = mat[0][0] * pot2D.x + mat[0][1] * pot2D.y + mat[0][2];
  pot.y = mat[1][0] * pot2D.x + mat[1][1] * pot2D.y + mat[1][2];
  return pot;
}
function Transform3D(pot3D, mat) {
  var pot = {
    x: 0,
    y: 0,
    z: 0
  };
  pot.x = mat[0][0] * pot3D.x + mat[0][1] * pot3D.y + mat[0][2] * pot3D.z + mat[0][3];
  pot.y = mat[1][0] * pot3D.x + mat[1][1] * pot3D.y + mat[1][2] * pot3D.z + mat[1][3];
  pot.z = mat[2][0] * pot3D.x + mat[2][1] * pot3D.y + mat[2][2] * pot3D.z + mat[2][3];
  return pot;
}
function NormalizeAxisZ(pot3D) {
  var tmp = 1 / Math.abs(pot3D.z);
  return {
    x: tmp * pot3D.x,
    y: tmp * pot3D.y,
    z: tmp * pot3D.z
  };
}
function NullSensor2DToCamera2D(sensor2D) {
  return sensor2D;
}
function NullCamera3DtoSensor3D(camera3DPoint) {
  return {
    sensor3D: camera3DPoint,
    iSensorId: 0
  };
}
function UndistortPointImpl(point2D, tCylindParam) {
  var x = (point2D.x - tCylindParam.Cx) / tCylindParam.Fx; // Fx = Fy = F in Cylind restful data

  var y = (point2D.y - tCylindParam.Cy) / tCylindParam.Fy; // Fx = Fy = F in Cylind restful data

  var x0 = x;
  var y0 = y;
  var iIters = 5;

  for (var i = 0; i < iIters; i += 1) {
    var r2 = x * x + y * y;
    var icdist = (1 + ((tCylindParam.K[7][0] * r2 + tCylindParam.K[6][0]) * r2 + tCylindParam.K[5][0]) * r2) / (1 + ((tCylindParam.K[4][0] * r2 + 1 * tCylindParam.K[1][0]) * r2 + tCylindParam.K[0][0]) * r2);
    var deltaX = 2 * tCylindParam.K[2][0] * x * y + tCylindParam.K[3][0] * (r2 + 2 * x * x) + tCylindParam.K[8][0] * r2 + tCylindParam.K[9][0] * r2 * r2;
    var deltaY = tCylindParam.K[2][0] * (r2 + 2 * y * y) + 2 * tCylindParam.K[3][0] * x * y + tCylindParam.K[10][0] * r2 + tCylindParam.K[11][0] * r2 * r2;
    x = (x0 - deltaX) * icdist;
    y = (y0 - deltaY) * icdist;
  }

  return {
    x: x,
    y: y
  };
}
function OriginToCylind(point2D, tCylindParam) {
  tCylindParam.Fx = tCylindParam.F;
  tCylindParam.Fy = tCylindParam.F;
  const UndistortPot2D = UndistortPointImpl(point2D, tCylindParam);
  point2D.x = UndistortPot2D.x * tCylindParam.F;
  point2D.y = UndistortPot2D.y * tCylindParam.F;
  if (tCylindParam.OffsetX !== undefined && tCylindParam.OffsetY !== undefined) {
    point2D.x -= tCylindParam.OffsetX;
    point2D.y -= tCylindParam.OffsetY;
  }
  var fThetaH = Math.atan(Math.abs(point2D.x / tCylindParam.R)) * (point2D.x > 0 ? 1 : -1);
  var fThetaV = Math.atan(Math.abs(point2D.y / Math.sqrt(tCylindParam.R * tCylindParam.R + point2D.x * point2D.x))) * (point2D.y > 0 ? 1 : -1);
  point2D.x = fThetaH * tCylindParam.R;
  point2D.y = fThetaV * tCylindParam.R;
  var potTmp = {
    x: point2D.x,
    y: point2D.y
  };
  point2D = Transform2D(potTmp, tCylindParam.A);
  point2D.x += tCylindParam.Cx;
  point2D.y += tCylindParam.Cy;
  return point2D;
}
// CONCATENATED MODULE: ./src/js/projectModule.js

function Project(objCamInfo) {
    this.m_bIsReady = false;
    this.objCamInfo;
    this.OriOToLdcTable;
    this.m_dCx;
    this.m_dCy;
    this.m_dFx;
    this.m_dFy;
    this.m_dCx;
    this.m_dCy;
    this.m_k;
    this.m_iCamHeight;
    this.m_iAngle;
    this.m_iRotate;
    this.m_iOrgHeight;
    this.m_iOrgWidth;
    this.m_bIsFishEye;
    this.m_strModuleName;
    this.m_a;
    this.m_f;
    this.m_iOffsetX;
    this.m_iOffsetY;
    this.m_iROIHeight;
    this.m_iROIWidth;
    this.m_iSensorSize = {
      width: 2592,
      height: 1944
    }; // default MS9390 sensor size

    this.m_iResolutionW;
    this.m_iResolutionH;
    this.m_iModResolutionH;
    this.m_fZoomInFactor;
    this.m_iZoomInOffsetX;
    this.m_iZoomInOffsetY;
    this.m_iTranslationX = 0;
    this.m_iTranslationY = 0;
    this.m_bIsStitchType = false;
    this.m_strStitchType;
    this.m_iMasterROI = {
      width: 1,
      height: 1
    };
    this.m_iStitchMapScale = 1;
    this.m_bIsCylindStitch = false;
    this.m_CylindStitch;
    this.BOUND_POINT_NUM = 20;
    this.EEdgeIndex = {
      eLeftEdge: 0,
      eRightEdge: 1,
      eTopEdge: 2,
      eBottomEdge: 3
    };
    this.m_vBoundUndistort2D;
    this.m_vReprojectBoundary;
    this.WorldPointToCamera3DPoint;
    this.MultiSensor3D;
    this.ProjectTo2D;
    this.MultiSensor2D;
    this.DistortionToLdc;
    this.Get2DBoundingBox;
    this.m_mat = {
      M00: undefined,
      M01: undefined,
      M02: undefined,
      M10: undefined,
      M11: undefined,
      M12: undefined,
      M20: undefined,
      M21: undefined,
      M22: undefined
    };
    this.SetConfig(objCamInfo);
}

Project.prototype = {
    SetConfig: function(objCamInfo) {
      if (typeof objCamInfo.M1 !== 'undefined') {
        this.m_dCx = objCamInfo.M1[0][2];
        this.m_dCy = objCamInfo.M1[1][2];
        this.m_dFx = objCamInfo.M1[0][0];
        this.m_dFy = objCamInfo.M1[1][1];
      } else {
        this.m_dCx = objCamInfo.Cx;
        this.m_dCy = objCamInfo.Cy;
      }

      this.m_k = objCamInfo.D1;
      this.m_iCamHeight = objCamInfo.CamHeight;
      this.m_iAngle = objCamInfo.TiltAngle;
      this.m_iRotate = objCamInfo.RollAngle;
      this.m_iOrgHeight = objCamInfo.ORGHeight;
      this.m_iOrgWidth = objCamInfo.ORGWidth;
      this.m_bIsFishEye = objCamInfo.IsFishEye;
      this.m_strModuleName = objCamInfo.ModuleName;
      this.m_a = objCamInfo.Baseline;
      this.m_f = objCamInfo.FocalLength;
      this.m_iOffsetX = objCamInfo.OffsetX;
      this.m_iOffsetY = objCamInfo.OffsetY;
      this.m_iROIHeight = objCamInfo.ROIHeight;
      this.m_iROIWidth = objCamInfo.ROIWidth;

      if (typeof objCamInfo.SensorWidth !== 'undefined' && typeof objCamInfo.SensorHeight !== 'undefined') {
        this.m_iSensorSize = {
          width: objCamInfo.SensorWidth,
          height: objCamInfo.SensorHeight
        };
      }

      this.m_iResolutionW = objCamInfo.ResolutionW;
      this.m_iResolutionH = objCamInfo.ResolutionH;
      this.m_iModResolutionH = this.m_iResolutionH / this.m_iResolutionW > 0.6125 ? parseInt(this.m_iResolutionW * 0.6125, 10) : this.m_iResolutionH;
      this.m_fZoomInFactor = objCamInfo.ZoomInFactor;
      this.m_iZoomInOffsetX = objCamInfo.ZoomInOffsetX;
      this.m_iZoomInOffsetY = objCamInfo.ZoomInOffsetY;
      this.m_bIsCylindStitch = objCamInfo.IsCylindStitch;
      this.m_CylindStitch = objCamInfo.CylindStitch;
      this.WorldPointToCamera3DPoint = this.CreateAngleAnyRotation();
      this.MultiSensor3D;
      this.ProjectTo2D = this.CreateLensModelPtr();
      this.MultiSensor2D;
      this.DistortionToLdc = this.CreateLdc();
      this.Get2DBoundingBox = this.CreateEstimateBoundingBox();
      this.CreateMultiSensor();
      this.m_bIsReady = true;
    },
    SetTranslation: function(objTranslation) {
      this.m_iTranslationX = objTranslation['2DtranslationToMaster'].TranslationX;
      this.m_iTranslationY = objTranslation['2DtranslationToMaster'].TranslationY;
    },
    CreateAngleAnyRotation: function() {
      if (this.m_iRotate === undefined || this.m_iRotate === 0) {
        this.m_mat.M11 = Math.cos(this.m_iAngle * Math.PI / 180);
        this.m_mat.M12 = -Math.sin(this.m_iAngle * Math.PI / 180);
        this.m_mat.M21 = Math.sin(this.m_iAngle * Math.PI / 180);
        this.m_mat.M22 = Math.cos(this.m_iAngle * Math.PI / 180);
        return this.AngleAny;
      }

      var cosX = Math.cos(this.m_iAngle * Math.PI / 180);
      var sinX = Math.sin(this.m_iAngle * Math.PI / 180);
      var cosZ = Math.cos(this.m_iRotate * Math.PI / 180);
      var sinZ = Math.sin(this.m_iRotate * Math.PI / 180);
      this.m_mat.M00 = cosZ;
      this.m_mat.M01 = sinZ * cosX;
      this.m_mat.M02 = sinZ * sinX;
      this.m_mat.M10 = -sinZ;
      this.m_mat.M11 = cosZ * cosX;
      this.m_mat.M12 = cosZ * sinX;
      this.m_mat.M20 = 0;
      this.m_mat.M21 = -sinX;
      this.m_mat.M22 = cosX;
      return this.AngleTiltRotate;
    },
    AngleAny: function(ObjectPoint) {
      return {
        x: ObjectPoint.x,
        y: this.m_mat.M11 * ObjectPoint.y + this.m_mat.M21 * ObjectPoint.z,
        z: this.m_mat.M12 * ObjectPoint.y + this.m_mat.M22 * ObjectPoint.z
      };
    },
    AngleTiltRotate: function(ObjectPoint) {
      return {
        x: this.m_mat.M00 * ObjectPoint.x + this.m_mat.M01 * ObjectPoint.y + this.m_mat.M02 * ObjectPoint.z,
        y: this.m_mat.M10 * ObjectPoint.x + this.m_mat.M11 * ObjectPoint.y + this.m_mat.M12 * ObjectPoint.z,
        z: this.m_mat.M20 * ObjectPoint.x + this.m_mat.M21 * ObjectPoint.y + this.m_mat.M22 * ObjectPoint.z
      };
    },
    CreateLensModelPtr: function() {
      if (this.m_bIsFishEye === undefined) {
        return this.Lens3DNormal;
      }

      if (this.m_bIsFishEye) {
        return this.LensFisheye;
      }

      return this.LensNormal;
    },
    CreateLdc: function() {
      if (this.m_strModuleName !== undefined && this.OriOToLdcTable !== undefined) {
        return this.LdcOn;
      }

      return this.LdcOff;
    },
    CreateEstimateBoundingBox: function() {
      if (this.m_bIsCylindStitch) {
        this.SetBoundUndistort2D();
        this.SetAllReprojectBoundary();
        return this.EstimateBoundingBoxOn;
      }

      return this.EstimateBoundingBoxOff;
    },
    CreateMultiSensor: function() {
      if (this.m_bIsCylindStitch) {
        this.ConvertOldJsonFormat();
        this.MultiSensor3D = this.Camera3DtoSensor3D;
        this.MultiSensor2D = this.Sensor2DToCamera2D;
      } else {
        this.MultiSensor3D = NullCamera3DtoSensor3D;
        this.MultiSensor2D = NullSensor2DToCamera2D;
      }
    },
    LdcOn: function(ObjectPoint, ObjectPoint3D) {
      var scaleX = 4;
      var scaleY = 4;
      var x = 1280 / scaleX;
      var y = (720 + 120) / scaleY;
      var tableSize = {
        x: x,
        y: y
      };
      var pointDistort = ObjectPoint;
      pointDistort.x = Math.max(pointDistort.x, 0);
      pointDistort.y = Math.max(pointDistort.y, 0);
      pointDistort.x = Math.min(pointDistort.x, 1280 - 1);
      pointDistort.y = Math.min(pointDistort.y, 720 + 120 - 1);
      var tableIndexX = Math.floor(pointDistort.x / scaleX);
      var tableIndexY = Math.floor(pointDistort.y / scaleY);
      var tableOffset = tableIndexY * tableSize.x + tableIndexX;
      var tableOffsetRight = tableIndexY * tableSize.x + Math.min(tableIndexX + 1, tableSize.x - 1);
      var tableOffsetDown = Math.min(tableIndexY + 1, tableSize.y - 1) * tableSize.x + tableIndexX;
      var potLdcX = this.OriOToLdcTable[tableOffset * 2] + (this.OriOToLdcTable[tableOffsetRight * 2] - this.OriOToLdcTable[tableOffset * 2]) * (pointDistort.x % scaleX) / scaleX;
      var potLdcY = this.OriOToLdcTable[tableOffset * 2 + 1] + (this.OriOToLdcTable[tableOffsetDown * 2 + 1] - this.OriOToLdcTable[tableOffset * 2 + 1]) * (pointDistort.y % scaleY) / scaleY;

      if (this.OriOToLdcTable[tableOffset * 2] < 0 || this.OriOToLdcTable[tableOffsetRight * 2] < 0) {
        potLdcX = Math.max(this.OriOToLdcTable[tableOffset * 2], this.OriOToLdcTable[tableOffsetRight * 2]);
      }

      if (this.OriOToLdcTable[tableOffset * 2] > 1280 || this.OriOToLdcTable[tableOffsetRight * 2] > 1280) {
        potLdcX = Math.min(this.OriOToLdcTable[tableOffset * 2], this.OriOToLdcTable[tableOffsetRight * 2]);
      }

      potLdcY -= 156;
      potLdcX = Math.max(potLdcX, 0);
      potLdcY = Math.max(potLdcY, 0);
      potLdcX = Math.min(potLdcX, 1280 - 1); // potLdcY = Math.min(potLdcY, 720-1);

      if (ObjectPoint3D.z > 800) {
        var head = ObjectPoint3D;
        head.z -= 1650;
        var pointCamera2 = this.WorldPointToCamera3DPoint(head);
        var potDistort2 = this.ProjectTo2D(pointCamera2);
        var potLdc2 = this.DistortionToLdc(potDistort2, ObjectPoint3D);
        potLdcX = potLdc2.x;
      }

      return {
        x: potLdcX,
        y: potLdcY
      };
    },
    LdcOff: function(ObjectPoint) {
      return ObjectPoint;
    },
    Lens3DNormal: function(ObjectPoint) {
      var x = ObjectPoint.x * this.m_f / ObjectPoint.z + this.m_dCx;
      var y = ObjectPoint.y * this.m_f / ObjectPoint.z + this.m_dCy;
      var dst = {
        x: x,
        y: y
      };
      dst.x -= this.m_iOffsetX;
      dst.y -= this.m_iOffsetY;
      return dst;
    },
    LensFisheye: function(ObjectPoint) {
      var fObjectZ = ObjectPoint.z ? 1 / ObjectPoint.z : 1;
      var fObjectX = ObjectPoint.x * fObjectZ;
      var fObjectY = ObjectPoint.y * fObjectZ;
      var r2 = fObjectX * fObjectX + fObjectY * fObjectY;
      var r = Math.sqrt(r2); // Angle of the incoming ray:

      var theta = Math.atan(r);
      var theta2 = theta * theta;
      var theta3 = theta2 * theta;
      var theta4 = theta2 * theta2;
      var theta5 = theta4 * theta;
      var theta6 = theta3 * theta3;
      var theta7 = theta6 * theta;
      var theta8 = theta4 * theta4;
      var theta9 = theta8 * theta;
      var thetaD = theta + this.m_k[0] * theta3 + this.m_k[1] * theta5 + this.m_k[2] * theta7 + this.m_k[3] * theta9;
      var invR = r > 1e-8 ? 1 / r : 1;
      var cdist = r > 1e-8 ? thetaD * invR : 1;
      var xd1 = fObjectX * cdist;
      var xd2 = fObjectY * cdist;
      var fImageX = xd1 * this.m_dFx + this.m_dCx;
      var fImageY = xd2 * this.m_dFy + this.m_dCy;
      return {
        x: fImageX,
        y: fImageY
      };
    },
    LensNormal: function(ObjectPoint) {
      var fObjectZ = ObjectPoint.z ? 1 / ObjectPoint.z : 1;
      var fObjectX = ObjectPoint.x * fObjectZ;
      var fObjectY = ObjectPoint.y * fObjectZ;
      var r2 = fObjectX * fObjectX + fObjectY * fObjectY;
      var r4 = r2 * r2;
      var r6 = r4 * r2;
      var a1 = 2 * fObjectX * fObjectY;
      var a2 = r2 + 2 * fObjectX * fObjectX;
      var a3 = r2 + 2 * fObjectY * fObjectY;
      var cdist = 1 + this.m_k[0] * r2 + this.m_k[1] * r4 + this.m_k[4] * r6;
      var icdist2 = 1 / (1 + this.m_k[5] * r2 + this.m_k[6] * r4 + this.m_k[7] * r6);
      var xd = fObjectX * cdist * icdist2 + this.m_k[2] * a1 + this.m_k[3] * a2 + this.m_k[8] * r2 + this.m_k[9] * r4;
      var yd = fObjectY * cdist * icdist2 + this.m_k[2] * a3 + this.m_k[3] * a1 + this.m_k[10] * r2 + this.m_k[11] * r4;
      var fImageX = xd * this.m_dFx + this.m_dCx;
      var fImageY = yd * this.m_dFy + this.m_dCy;
      return {
        x: fImageX,
        y: fImageY
      };
    },
    Camera3DtoSensor3D: function(camera3DPoint) {
      var iNewSensorId = this.GetSensorId(camera3DPoint);
      var potc3D = camera3DPoint;
      potc3D = NormalizeAxisZ(potc3D);
      var sensor3D = Transform3D(potc3D, this.m_CylindStitch.Cam3DToLens3D[iNewSensorId]);
      sensor3D = NormalizeAxisZ(sensor3D);
      return {
        sensor3D: sensor3D,
        iSensorId: iNewSensorId
      };
    },
    Sensor2DToCamera2D: function(sensor2D, iSensorId) {
      var pot2D = this.CalcSensor2DToCamera2D(sensor2D, iSensorId);
      return pot2D;
    },
    GetSensorId: function(camera3D) {
      for (var i = 0; i < this.m_CylindStitch.Blend3D.length; i += 1) {
        var point = {
          x: camera3D.x,
          y: camera3D.y
        };
        point.x /= camera3D.z;
        point.y /= camera3D.z;
        var p1 = {
          x: this.m_CylindStitch.Blend3D[i][0].x,
          y: this.m_CylindStitch.Blend3D[i][0].y
        };
        var p2 = {
          x: this.m_CylindStitch.Blend3D[i][1].x,
          y: this.m_CylindStitch.Blend3D[i][1].y
        };
        var p3 = {
          x: p2.x - p1.x,
          y: p2.y - p1.y
        };
        var p4 = {
          x: point.x - p1.x,
          y: point.y - p1.y
        };
        var cross = p3.x * p4.y - p3.y * p4.x;

        if (cross < 0) {
          return i;
        }
      }

      return this.m_CylindStitch.Blend3D.length;
    },
    CalcSensor2DToCamera2D: function(sensor2D, iSensorId) {
      var pot = Transform2D(sensor2D, this.m_CylindStitch.Pre2D[iSensorId]);
      pot = OriginToCylind(pot, this.m_CylindStitch.Cylind[iSensorId]);
      pot = Transform2D(pot, this.m_CylindStitch.Lens2DToStream[iSensorId]);
      return pot;
    },
    SetSingleCamInfo: function(iMasterROI) {
      this.m_bIsStitchType = false;
      this.m_iMasterROI.width = iMasterROI.width;
      this.m_iMasterROI.height = iMasterROI.height;
    },
    SetStitchInfo: function(strStitchType, iMasterROI, iStitchMapScale) {
      if (strStitchType === 'Stereo' || strStitchType === 'Fisheye') {
        this.m_strStitchType = strStitchType;
        this.m_bIsStitchType = true;
      }

      this.m_iMasterROI.width = iMasterROI.width;
      this.m_iMasterROI.height = iMasterROI.height;
      this.m_iStitchMapScale = iStitchMapScale;
    },
    ProjectPoint: function(ObjectPoint) {
      var pot = {
        x: 0,
        y: 0
      };
      var pot2D = {
        x: 0,
        y: 0
      };

      if (this.m_bIsReady) {
        if (!this.m_bIsStitchType) {
          var sensor2D = {
            x: 0,
            y: 0
          };
          var sensorObj = {
            sensor3D: {
              x: 0,
              y: 0,
              z: 0
            },
            iSensorId: 0
          };
          ObjectPoint.z = this.m_iCamHeight - ObjectPoint.z;
          pot = this.WorldPointToCamera3DPoint(ObjectPoint);
          sensorObj = this.MultiSensor3D(pot);
          sensor2D = this.ProjectTo2D(sensorObj.sensor3D);
          pot2D = this.MultiSensor2D(sensor2D, sensorObj.iSensorId);
          pot2D = this.DistortionToLdc(pot2D, ObjectPoint);
          pot2D.x = pot2D.x * this.m_iMasterROI.width / this.GetImgSize().Width;
          pot2D.y = pot2D.y * this.m_iMasterROI.height / this.GetImgSize().Height;
        } else if (this.m_strStitchType === 'Stereo') {
          ObjectPoint.z = this.m_iCamHeight - ObjectPoint.z;
          pot = this.WorldPointToCamera3DPoint(ObjectPoint);
          pot2D = this.ProjectTo2D(pot);
          pot2D = this.DistortionToLdc(pot2D, ObjectPoint);
          pot2D.x = pot2D.x * this.m_iMasterROI.width / this.GetImgSize().Width * this.m_iStitchMapScale + this.m_iTranslationX * this.m_iStitchMapScale;
          pot2D.y = pot2D.y * this.m_iMasterROI.height / this.GetImgSize().Height * this.m_iStitchMapScale + this.m_iTranslationY * this.m_iStitchMapScale;
        } else if (this.m_strStitchType === 'Fisheye') {
          pot.x = ObjectPoint.x * this.m_dFx / this.m_iCamHeight; // x in ORGSize coordinate

          pot.y = ObjectPoint.y * this.m_dFy / this.m_iCamHeight; // y in ORGSize coordinate

          pot.x = pot.x * this.m_iMasterROI.width / this.GetImgSize().Width; // x in snapshot coordinate

          pot.y = pot.y * this.m_iMasterROI.height / this.GetImgSize().Height; // y in snapshot coordinate

          pot2D.x = (pot.x + this.m_iTranslationX + this.m_iMasterROI.width / 2) * this.m_iStitchMapScale;
          pot2D.y = (pot.y + this.m_iTranslationY + this.m_iMasterROI.height / 2) * this.m_iStitchMapScale;
        }
      }

      return new Point(pot2D.x, pot2D.y);
    },
    GetCamHeight: function() {
      if (this.m_bIsReady) {
        return this.m_iCamHeight;
      }

      return 1;
    },
    GetImgSize: function() {
      if (this.m_bIsReady) {
        if (this.m_bIsFishEye === undefined) {
          return {
            Height: this.m_iModResolutionH,
            Width: this.m_iResolutionW
          };
        }

        return {
          Height: this.m_iOrgHeight,
          Width: this.m_iOrgWidth
        };
      }

      return {
        Height: 1,
        Width: 1
      };
    },
    GetTranslation: function() {
      return {
        TranslationX: this.m_iTranslationX,
        TranslationY: this.m_iTranslationY
      };
    },
    IsStitchType: function() {
      return this.m_bIsStitchType;
    },
    EstimateBoundingBoxOn: function(vecBoundingBox3D) {
      const vecBoundingBox2D = [];
      let bSuccess = false;
      let bPass = true;
      if (!this.m_bIsStitchType) {
        for (let i = 0; i < vecBoundingBox3D.length; i += 1) {
          const pt = this.ProjectPoint(vecBoundingBox3D[i]);
          vecBoundingBox2D.push(pt);
          if(!this.IsCamera3DPointInBoundary(vecBoundingBox3D[i])) {
            bPass = false;
          }
        }
        if (!bPass) {
          bSuccess = this.EstimateFromHead(vecBoundingBox3D, vecBoundingBox2D);
          if (!bSuccess) {
            bSuccess = this.EstimateFromFeet(vecBoundingBox3D, vecBoundingBox2D);
          }
        }
        if (bPass || bSuccess) {
          return vecBoundingBox2D;
        }
      }
      return this.EstimateBoundingBoxOff(vecBoundingBox3D);
    },
    EstimateFromHead: function(vecBoundingBox3D, vecBoundingBox2D) {
      if (!this.IsFeetOrHead2DInBoundary(vecBoundingBox2D, 4)) {
        return false;
      }

      return this.ReviseCuboid(vecBoundingBox3D, vecBoundingBox2D, false);
    },
    EstimateFromFeet: function(vecBoundingBox3D, vecBoundingBox2D) {
      if (!this.IsFeetOrHead2DInBoundary(vecBoundingBox2D, 0)) {
        return false;
      }

      return this.ReviseCuboid(vecBoundingBox3D, vecBoundingBox2D, true);
    },
    IsFeetOrHead2DInBoundary: function(vecBoundingBox2D, offset) {
      for (var k = 0; k < 4; k += 1) {
        var pt = vecBoundingBox2D[k + offset];

        if (!this.IsPointInImage(pt)) {
          return false;
        }
      }

      return true;
    },
    IsPointInImage: function(pt) {
      if (pt.x >= 0 && pt.x < this.m_iMasterROI.width && pt.y >= 0 && pt.y < this.m_iMasterROI.height) {
        return true;
      }

      return false;
    },
    ReviseCuboid: function(vecBoundingBox3D, vecBoundingBox2D, bFeetEstimate) {
      var vecFeet3D = vecBoundingBox3D.slice(0, 4);
      var vecHead3D = vecBoundingBox3D.slice(4);

      if (bFeetEstimate) {
        return this.EstimateWithBinarySearch(vecFeet3D, vecHead3D, vecBoundingBox2D, 0, 4);
      }

      return this.EstimateWithBinarySearch(vecHead3D, vecFeet3D, vecBoundingBox2D, 4, 0);
    },
    EstimateWithBinarySearch: function(vecBeg3D, vecEnd3D, vecBoundingBox2D, iBeg2DIdx, iEnd2DIdx) {
      var iTotalStep = 32;
      var iBegTh = iTotalStep / 5;
      var iBeg = 0;
      var iEnd = iTotalStep;
      var iMid = Math.floor((iBeg + iEnd + 1) / 2);
      var i;
      var point3D;
      var point2D;
      var vecDiff3D = [];
      var vecBeg = new Array(4).fill(iBeg, 0);
      var vecEnd = new Array(4).fill(iEnd, 0);
      var vecMid = new Array(4).fill(iMid, 0);
      var vecMid3D = [new Js3DPoint(), new Js3DPoint(), new Js3DPoint(), new Js3DPoint()];

      for (i = 0; i < 4; i += 1) {
        point3D = new Js3DPoint();
        point3D.x = vecEnd3D[i].x - vecBeg3D[i].x;
        point3D.y = vecEnd3D[i].y - vecBeg3D[i].y;
        point3D.z = vecEnd3D[i].z - vecBeg3D[i].z;
        vecDiff3D.push(point3D);
      }

      for (i = 0; i < 4; i += 1) {
        while (vecBeg[i] !== vecEnd[i] && vecEnd[i] > iBegTh) {
          vecMid3D[i].x = vecBeg3D[i].x + vecDiff3D[i].x * vecMid[i] / iTotalStep;
          vecMid3D[i].y = vecBeg3D[i].y + vecDiff3D[i].y * vecMid[i] / iTotalStep;
          vecMid3D[i].z = vecBeg3D[i].z + vecDiff3D[i].z * vecMid[i] / iTotalStep;

          if (this.CanProjectPoints3DInImage(vecMid3D[i])) {
            vecBeg[i] = vecMid[i];
          } else {
            vecEnd[i] = vecMid[i] - 1;
          }

          vecMid[i] = Math.floor((vecBeg[i] + vecEnd[i] + 1) / 2);
        }

        if (vecBeg[i] < iBegTh) {
          return false;
        }
      }

      for (i = 0; i < 4; i += 1) {
        point3D = new Js3DPoint();
        point3D.x = vecBeg3D[i].x + vecDiff3D[i].x * vecBeg[i] / iTotalStep;
        point3D.y = vecBeg3D[i].y + vecDiff3D[i].y * vecBeg[i] / iTotalStep;
        point3D.z = vecBeg3D[i].z + vecDiff3D[i].z * vecBeg[i] / iTotalStep;
        point2D = this.ProjectPoint(point3D);
        vecBoundingBox2D[iEnd2DIdx + i].x = vecBoundingBox2D[iBeg2DIdx + i].x + (point2D.x - vecBoundingBox2D[iBeg2DIdx + i].x) * iTotalStep / vecBeg[i];
        vecBoundingBox2D[iEnd2DIdx + i].y = vecBoundingBox2D[iBeg2DIdx + i].y + (point2D.y - vecBoundingBox2D[iBeg2DIdx + i].y) * iTotalStep / vecBeg[i];
      }

      return true;
    },
    CanProjectPoints3DInImage: function(point3D) {
      var point2D = this.ProjectPoint(point3D);

      if (!this.IsCamera3DPointInBoundary(point3D) || !this.IsPointInImage(point2D)) {
        return false;
      }

      return true;
    },
    IsCamera3DPointInBoundary: function(pot3D) {
      var sensorObj = {
        sensor3D: {
          x: 0,
          y: 0,
          z: 0
        },
        iSensorId: 0
      };
      var pot = this.WorldPointToCamera3DPoint(pot3D);
      sensorObj = this.MultiSensor3D(pot);
      var _sensorObj = sensorObj,
          sensor3D = _sensorObj.sensor3D;

      if (sensor3D.z < 0) {
        return false;
      }

      var fXZ = sensor3D.x / sensor3D.z;
      var fYZ = sensor3D.y / sensor3D.z;
      var Left = this.IsBoundaryFound(fYZ, this.EEdgeIndex.eLeftEdge);
      var Right = this.IsBoundaryFound(fYZ, this.EEdgeIndex.eRightEdge);
      var Top = this.IsBoundaryFound(fXZ, this.EEdgeIndex.eTopEdge);
      var Bottom = this.IsBoundaryFound(fXZ, this.EEdgeIndex.eBottomEdge);

      if (!(Left.found && Right.found && Top.found && Bottom.found)) {
        return false;
      }

      if (fXZ < Left.value || fXZ > Right.value || fYZ < Top.value || fYZ > Bottom.value) {
        return false;
      }

      return true;
    },
    IsBoundaryFound: function(fCoordinate, eEdgeIndex) {
      var bIsFound = false;
      var fVal = 0;

      for (var i = 0; i < this.BOUND_POINT_NUM - 1; i += 1) {
        var fSmallerCoordinate = eEdgeIndex === this.EEdgeIndex.eLeftEdge || eEdgeIndex === this.EEdgeIndex.eRightEdge ? this.m_vBoundUndistort2D[i + eEdgeIndex * this.BOUND_POINT_NUM].y : this.m_vBoundUndistort2D[i + eEdgeIndex * this.BOUND_POINT_NUM].x;
        var fGreaterCoordinate = eEdgeIndex === this.EEdgeIndex.eLeftEdge || eEdgeIndex === this.EEdgeIndex.eRightEdge ? this.m_vBoundUndistort2D[i + eEdgeIndex * this.BOUND_POINT_NUM + 1].y : this.m_vBoundUndistort2D[i + eEdgeIndex * this.BOUND_POINT_NUM + 1].x;

        if (fCoordinate >= fSmallerCoordinate && fCoordinate <= fGreaterCoordinate) {
          bIsFound = true;
          fVal = this.m_vReprojectBoundary[i + eEdgeIndex * (this.BOUND_POINT_NUM - 1)];
          break;
        }
      }

      return {
        found: bIsFound,
        value: fVal
      };
    },
    SetBoundUndistort2D: function() {
      // only support MS series now
      var vBoundDistort2D = new Array(this.BOUND_POINT_NUM * 4);

      for (var i = 0; i < this.BOUND_POINT_NUM; i += 1) {
        var pot2D = {
          x: 0,
          y: 0
        };
        pot2D.y = this.m_iSensorSize.height * i / (this.BOUND_POINT_NUM - 1);
        vBoundDistort2D[i] = {
          x: pot2D.x,
          y: pot2D.y
        };
        pot2D.x = this.m_iSensorSize.width;
        pot2D.y = this.m_iSensorSize.height * i / (this.BOUND_POINT_NUM - 1);
        vBoundDistort2D[i + this.BOUND_POINT_NUM] = {
          x: pot2D.x,
          y: pot2D.y
        };
        pot2D.x = this.m_iSensorSize.width * i / (this.BOUND_POINT_NUM - 1);
        pot2D.y = 0;
        vBoundDistort2D[i + this.BOUND_POINT_NUM * 2] = {
          x: pot2D.x,
          y: pot2D.y
        };
        pot2D.x = this.m_iSensorSize.width * i / (this.BOUND_POINT_NUM - 1);
        pot2D.y = this.m_iSensorSize.height;
        vBoundDistort2D[i + this.BOUND_POINT_NUM * 3] = {
          x: pot2D.x,
          y: pot2D.y
        };
      }

      this.m_vBoundUndistort2D = new Array(this.BOUND_POINT_NUM * 4);
      var tParam = {
        Fx: this.m_dFx,
        Fy: this.m_dFy,
        Cx: this.m_dCx,
        Cy: this.m_dCy,
        K: this.m_k
      };

      for (var _i = 0; _i < vBoundDistort2D.length; _i += 1) {
        this.m_vBoundUndistort2D[_i] = UndistortPointImpl(vBoundDistort2D[_i], tParam);
      }
    },
    SetAllReprojectBoundary: function() {
      this.m_vReprojectBoundary = new Array((this.BOUND_POINT_NUM - 1) * 4);
      this.SetReprojectBoundary(this.EEdgeIndex.eLeftEdge);
      this.SetReprojectBoundary(this.EEdgeIndex.eRightEdge);
      this.SetReprojectBoundary(this.EEdgeIndex.eTopEdge);
      this.SetReprojectBoundary(this.EEdgeIndex.eBottomEdge);
    },
    SetReprojectBoundary: function(eEdgeIndex) {
      for (var i = 0; i < this.BOUND_POINT_NUM - 1; i += 1) {
        var fCandidate1 = eEdgeIndex === this.EEdgeIndex.eLeftEdge || eEdgeIndex === this.EEdgeIndex.eRightEdge ? this.m_vBoundUndistort2D[i + eEdgeIndex * this.BOUND_POINT_NUM].x : this.m_vBoundUndistort2D[i + eEdgeIndex * this.BOUND_POINT_NUM].y;
        var fCandidate2 = eEdgeIndex === this.EEdgeIndex.eLeftEdge || eEdgeIndex === this.EEdgeIndex.eRightEdge ? this.m_vBoundUndistort2D[i + eEdgeIndex * this.BOUND_POINT_NUM + 1].x : this.m_vBoundUndistort2D[i + eEdgeIndex * this.BOUND_POINT_NUM + 1].y;

        if (eEdgeIndex === this.EEdgeIndex.eLeftEdge || eEdgeIndex === this.EEdgeIndex.eTopEdge) {
          this.m_vReprojectBoundary[i + eEdgeIndex * (this.BOUND_POINT_NUM - 1)] = Math.max(fCandidate1, fCandidate2);
        } else {
          this.m_vReprojectBoundary[i + eEdgeIndex * (this.BOUND_POINT_NUM - 1)] = Math.min(fCandidate1, fCandidate2);
        }
      }
    },
    ConvertOldJsonFormat: function() {
      // LensToCam3D, LensToCamCorr, Post2D, StreamToLens2D not used in project, so not to convert
      if (typeof this.m_CylindStitch.Cylind === 'undefined') {
        this.m_CylindStitch.Blend = JSON.parse(' [[{"x":2242,"y":1},{"x":2242,"y":0}]] ');
        this.m_CylindStitch.Blend3D = JSON.parse(' [[{"x":0,"y":1},{"x":0,"y":0}]] ');
        var A = [];
        A.push([1, 0, 0]);
        A.push([0, 1 / this.m_CylindStitch.Scale, 0]);
        A.push([0, 0, 1]);
        this.m_CylindStitch.Cylind = [];
        this.m_CylindStitch.Cylind.push({
          A: A,
          Cx: this.m_CylindStitch.Cx,
          Cy: this.m_CylindStitch.Cy,
          F: this.m_CylindStitch.F,
          K: this.m_CylindStitch.K,
          R: this.m_CylindStitch.R,
          Scale: this.m_CylindStitch.Scale
        });
        this.m_CylindStitch.Cylind.push({
          A: A,
          Cx: this.m_CylindStitch.Cx,
          Cy: this.m_CylindStitch.Cy,
          F: this.m_CylindStitch.F,
          K: this.m_CylindStitch.K,
          R: this.m_CylindStitch.R,
          Scale: this.m_CylindStitch.Scale
        });
        this.m_CylindStitch.Pre2D = JSON.parse('[[[1, 0, 0], [0, 1, 0], [0, 0, 1]], [[1, 0, 0], [0, 1, 0], [0, 0, 1]]]');
        this.m_CylindStitch.SensorNum = 2;
      }
    },
    EstimateBoundingBoxOff: function(vecBoundingBox3D) {
      var vecBoundingBox2D = [];

      for (var i = 0; i < vecBoundingBox3D.length; i += 1) {
        var pt = this.ProjectPoint(vecBoundingBox3D[i]);
        vecBoundingBox2D.push(pt);
      }

      return vecBoundingBox2D;
    },
};

function Face({id, rectangle}, reference) {
  LoiteringObject.call(this, id, reference);
  this.x = rectangle.x / 9999;
  this.y = rectangle.y / 9999;
  this.w = rectangle.w / 9999;
  this.h = rectangle.h / 9999;
}

Face.prototype = Object.create(LoiteringObject.prototype);
Face.prototype.constructor = Face;

Face.prototype.draw = function(ctx, fw, fh, drawMetadataName, showPosition, showTrackingBlock) {
  if (showTrackingBlock) {
    ctx.beginPath();
    ctx.ellipse(this.x * fw, this.y * fh, this.w * fw, this.h * fh, 0, 0, 2 * Math.PI);
    ctx.strokeStyle = "#a6ceff";
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.ellipse(this.x * fw - 1, this.y * fh - 1, this.w * fw + 2, this.h * fh + 2, 0, 0, 2 * Math.PI);
    ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
    ctx.stroke();
  }
};

function HumanDetectionArea({fovPointsList, manImg, footPoint, headPoint}, reference) {
  Rule.call(this);
  this.fovPointsList = fovPointsList;
  this.manImg = manImg;
  this.headPoint = headPoint;
  this.manVector = footPoint.minus(headPoint);
  this.reference = reference;
}

HumanDetectionArea.prototype = Object.create(Rule.prototype);
HumanDetectionArea.prototype.constructor = HumanDetectionArea;

HumanDetectionArea.prototype.draw = function(ctx, fw, fh, displayConfig) {
  if (!displayConfig.showHumanDetectionArea) {
    return;
  }
  this.fovPointsList.forEach(function(points) {
    ctx.beginPath();
    ctx.moveTo(points[0].x * fw + 2, points[0].y * fh);
    for (var i = 1, j = points.length; i < j; i++) {
      ctx.lineTo(points[i].x * fw + 2, points[i].y * fh);
    }
    ctx.closePath();
    ctx.strokeStyle = 'rgba(0, 0, 0, ' + (points[points.length - 1].y * fh / 1000 + 0.2) + ')';
    ctx.stroke();
  });
  this.fovPointsList.forEach(function(points) {
    ctx.beginPath();
    ctx.moveTo(points[0].x * fw, points[0].y * fh);
    for (var i = 1, j = points.length; i < j; i++) {
      ctx.lineTo(points[i].x * fw, points[i].y * fh);
    }
    ctx.closePath();
    ctx.strokeStyle = 'rgba(120, 217, 9, ' + points[points.length - 1].y * fh / 1000 * 1.3 + ')';
    ctx.stroke();
  });
if (this.manImg !== undefined) {
  ctx.save();
  ctx.translate(this.headPoint.x * fw, this.headPoint.y * fh);
  var dx = this.manVector.x * fw;
  var dy = this.manVector.y * fh;
  ctx.rotate(Math.atan2(dy, dx) - 90 * Math.PI / 180);
  var o = Math.sqrt(dx * dx + dy * dy);
  ctx.drawImage(this.manImg, 0, 0, 62 * o / 111, o);
  ctx.restore();
}
};

function PassingZone({canvas, name, points}, reference) {
  TransparentRule.call(this, canvas, name, points, null, reference);
  this.points = points;
  this.reference = reference;
}

PassingZone.prototype = Object.create(TransparentRule.prototype);
PassingZone.prototype.constructor = PassingZone;

PassingZone.prototype.draw = function(ruleScaleRatio, ctx, fw, fh, showSummary, showRuleName) {
  if (showSummary && showRuleName) {
    TransparentRule.prototype.draw.call(this, ruleScaleRatio, ctx, fw, fh);
  }

  // var img = ctx.createImageData(8, 2);
  // if (this.triggered) {
  //   img.data[0] = 0xff;
  //   img.data[1] = 0x55;
  //   img.data[2] = 0x00;
  // } else {
  //   img.data[0] = 0x20;
  //   img.data[1] = 0xf4;
  //   img.data[2] = 0xd4;
  // }
  // img.data[3] = 0xff;
  // for (var i = 4; i < 4 * 4; i += 4) {
  //   img.data[i + 0] = img.data[0];
  //   img.data[i + 1] = img.data[1];
  //   img.data[i + 2] = img.data[2];
  //   img.data[i + 3] = img.data[3];
  // }
  // ctx.strokeStyle = ctx.createPattern(img, 'repeat');

  if (this.triggered) {
    ctx.strokeStyle = '#ff5500';
  } else {
    ctx.strokeStyle = '#20f4f4';
  }

  ctx.lineWidth = 2 * ruleScaleRatio;
  for (var i = 0, off = 0; i < this.points.length; i++) {
    var p0 = this.points[i];
    var p1 = this.points[(i + 1) % this.points.length];
    var dx = (p1.x - p0.x) * fw;
    var dy = (p1.y - p0.y) * fh;
    var len = off + Math.sqrt(dx * dx + dy * dy);
    ctx.save();
    ctx.translate(p0.x * fw, p0.y * fh);
    ctx.rotate(Math.atan2(dy, dx));
    ctx.beginPath();
    ctx.setLineDash([4,4]);
    ctx.translate(-off, 0);
    ctx.moveTo(off, 0);
    ctx.lineTo(len, 0);
    ctx.stroke();
    ctx.restore();
    off = len % 8;
  }
  ctx.lineWidth = 1;
};

function VehicleDetectionArea({ points }, reference) {
  Rule.call(this);
  this.points = points;
  this.reference = reference;
}

VehicleDetectionArea.prototype = Object.create(Rule.prototype);
VehicleDetectionArea.prototype.constructor = VehicleDetectionArea;

VehicleDetectionArea.prototype.draw = function(ctx, fw, fh, displayConfig) {
  if (!displayConfig.showVehicleDetectionArea) {
    return;
  }
  ctx.beginPath();
  ctx.moveTo(this.points[0].x * fw, this.points[0].y * fh);
  for (var i = 1, j = this.points.length; i < j; i++) {
    ctx.lineTo(this.points[i].x * fw, this.points[i].y * fh);
  }
  ctx.closePath();
  ctx.strokeStyle = 'rgba(120, 217, 9, 1)';
  ctx.lineWidth = 2;
  ctx.shadowOffsetX = 2;
  ctx.shadowColor = "rgba(0, 0, 0, 0.4)";
  ctx.stroke();
  ctx.lineWidth = 1;
  ctx.shadowOffsetX = 0;
};

function ExclusiveMask({points, preset}, reference) {
  ExclusiveArea.call(this, points);
  this.preset = preset;
  this.reference = reference;
}

ExclusiveMask.prototype = Object.create(ExclusiveArea.prototype);
ExclusiveMask.prototype.constructor = ExclusiveMask;

ExclusiveMask.prototype.draw = function(ruleScaleRatio, ctx, fw, fh, showSummary, showRuleName, showDirection, showExclusiveArea) {
  if (showExclusiveArea) {
    ExclusiveArea.prototype.draw.call(this, ctx, fw, fh);
  }
};

function installTimer(timerClass) {
  Timer = timerClass;
}

/*
  ref: https://sdd-gitlab.vivotek.tw/vast2/Client/VivoQuick/-/blob/master/VVViewCell/DrawMotionCanvas.qml
  ref: e96116a7088b5873501194a64cc955bfae94308f
*/
export default {
  MotionDetectionAlert,
  MissingObject,
  isPolygon,
  isPolyline,
  isFlowPathCounting,
  BoundingBox,
  BoundingBox3D,
  Counting,
  ExclusiveArea,
  ExclusiveMask,
  Face,
  FieldDetection,
  FlowPathCounting,
  HumanDetectionArea,
  PassingZone,
  LineDetection,
  Point,
  Project,
  Summary,
  VehicleDetectionArea,
  CellMotion,
  installTimer,
};
