<template>
  <div class="empty-block">
    <svg-icon
      v-if="status === 'loading'"
      class="spinning"
      iconClass="status_loading"
      size="60px"
    />
    <template
      v-else
    >
      <div
        class="icon"
        :class="statusInfo.icon"
      />
      <h6>{{ statusInfo.text }}</h6>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppTableStatusInfoIcon',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  methods: {
  },
  computed: {
    statusInfo() {
      let icon = '';
      let text = '';
      switch (this.status) {
        case 'searched':
          icon = 'no-search-icon';
          text = this.$t('No search results');
          break;
        case 'initial':
          icon = 'no-search-icon';
          text = this.$t('Define your search criteria and click search');
          break;
        case 'failed':
          icon = 'search-failed-icon';
          text = this.$t('No search results');
          break;
        case 'busy':
          icon = 'search-failed-icon';
          text = this.$t('The system is busy. Please try again later.');
          break;
        default:
          break;
      }
      return {
        icon,
        text
      };
    }
  }
};
</script>

<style>

</style>
