import { unpack, access, isPointVerJSON as isPoint, toPointVerJSON as toPoint } from './utility.mjs';
import Shapes from './shapes.mjs';

export default class RuleParserVerJSON {
  constructor(reference, parent) {
    this.reference = reference;
    this.parent = parent;
  }

  parseCounting(counting) {
    const { reference, parent } = this;
    unpack(counting).slice(0, 1).forEach((ruleMap) => {
      Object.values(ruleMap).forEach((rule) => {
        unpack(rule.Line).slice(0, 1).forEach((line) => {
          var points = unpack(line).filter(isPoint).map(toPoint);
          if (Shapes.isPolyline(points)) {
            parent.addShape(
              new Shapes.Counting({
                canvas: reference.drawer,
                name: rule.RuleName,
                points,
                preset: rule.PresetName
              }, parent)
            );
          }
        });
      });
    });
  }

  createFieldDetection(ruleConfig, attribute = 'Field') {
    const self = this;
    Object.values(ruleConfig).forEach((rule) => {
      self.createFieldDetectionUponRule(rule, attribute);
    });
  }

  createFieldDetectionUponRule(rule, attribute = 'Field') {
    const { reference, parent } = this;
    unpack(rule[attribute]).slice(0, 1).forEach((field) => {
      var points = unpack(field).filter(isPoint).map(toPoint);
      if (Shapes.isPolygon(points)) {
        parent.addShape(
          new Shapes.FieldDetection({
            canvas: reference.drawer,
            name: rule.RuleName,
            points,
            preset: rule.PresetName
          }, parent)
        );
      }
    });
  }

  parseAndCreateFieldDetection(detectionConfigMap) {
    const self = this;
    unpack(detectionConfigMap).slice(0, 1).forEach((ruleMap) => {
      self.createFieldDetection(ruleMap);
    });
  }

  parseLineCrossing(detectionConfigMap) {
    const { reference, parent } = this;
    unpack(detectionConfigMap).slice(0, 1).forEach((ruleMap) => {
      Object.values(ruleMap).forEach((rule) => {
        unpack(rule.Line).slice(0, 1).forEach((line) => {
          var points = unpack(line).filter(isPoint).map(toPoint);
          if (Shapes.isPolyline(points)) {
            parent.addShape(
              new Shapes.LineDetection({
                canvas: reference.drawer,
                name: rule.RuleName,
                points,
                direction: rule.Direction,
                preset: rule.PresetName,
              }, parent)
            );
          }
        });
      });
    });
  }

  parseRestrictedZone(detectionConfigMap) {
    const { reference, parent } = this;
    const self = this;
    unpack(detectionConfigMap).slice(0, 1).forEach((ruleMap) => {
      Object.values(ruleMap).forEach((rule) => {
        unpack(rule.DefinedZone).forEach((field) => {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            parent.addShape(
              new Shapes.PassingZone({
                canvas: reference.drawer,
                name: rule.RuleName,
                points,
                preset: rule.PresetName
              }, parent)
            );
          }
        });
        self.createFieldDetectionUponRule(rule, 'RestrictedZone');
      });
    });
  }

  parseFlowPathCounting(configMap) {
    const { reference, parent } = this;
    unpack(configMap).slice(0, 1).forEach((ruleMap) => {
      Object.values(ruleMap).forEach((rule) => {
        var segments = unpack(rule.Line).map((line) => {
          return unpack(line).filter(isPoint).map(toPoint);
        });
        if (Shapes.isFlowPathCounting(segments)) {
          parent.addShape(
            new Shapes.FlowPathCounting({
              canvas: reference.drawer,
              name: rule.RuleName,
              segments,
              preset: rule.PresetName
            }, parent)
          );
        }
      });
    });
  }

  parse($rule) {
    // ExclusiveArea/ExclusiveMask is under Rule; it will be done in ExclusiveArea
    this.parseFlowPathCounting($rule.FlowPathCounting);
    this.parseCounting($rule.Counting);
    this.parseLineCrossing($rule.LineCrossingDetection);
    this.parseRestrictedZone($rule.RestrictedZoneDetection);

    // All the following shares the same parsing method.
    this.parseAndCreateFieldDetection($rule.CrowdDetection);
    this.parseAndCreateFieldDetection($rule.LoiteringDetection);
    this.parseAndCreateFieldDetection($rule.FaceDetection);
    this.parseAndCreateFieldDetection($rule.IntrusionDetection);
    this.parseAndCreateFieldDetection($rule.RunningDetection);
    this.parseAndCreateFieldDetection($rule.MissingObjectDetection);
    this.parseAndCreateFieldDetection($rule.ParkingViolationDetection);
    this.parseAndCreateFieldDetection($rule.SmartTrackingDetection);
    this.parseAndCreateFieldDetection($rule.UnattendedObjectDetection);
    this.parseAndCreateFieldDetection($rule.ZoneDetection);
  }
}
