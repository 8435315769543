<template>
  <div class="search-playback-player-wrapper">
    <div class="viewcell">
      <DisplayStreamingBlock
        :key="viewcell.channel"
        mode="export"
        ref="viewcell"
        :viewcell="viewcell"
        :isActiveView="true"
        :shouldDisableExpand="true"
        :noStatusbar="true"
        :shouldDisplayEmptyIcon="false"
        :duration="viewcell.duration"
        v-on="$listeners"
      >
        <div
          slot="empty"
          class="empty-view"
        >
          <span>{{ $t('Play to see more details') }}</span>
        </div>
      </DisplayStreamingBlock>
    </div>
    <div class="player-control">
      <div
        class="timeline-wrapper"
        :class="{ disabled: shouldDisableTimelineControl }"
      >
        <h5 class="play-time">
          {{ viewcell.displayElapsedTime }}/{{ viewcell.displayDuration }}
        </h5>
        <div class="timeline">
          <AppSlider
            :key="viewcell._id"
            :disabled="shouldDisableTimelineControl"
            :value="viewcell.elapsedTime"
            :min="0"
            :max="viewcell.duration + viewcell.startingPointBySec"
            :lazy="true"
            @change="updatePlayTime"
            width="100%"
          />
        </div>
      </div>
      <div class="control-wrapper">
        <div class="volume">
          <SvgIcon
            :disabled="shouldDisableTimelineControl"
            size="28px"
            :iconClass="isAudioMute? 'general_volume_mute' :'general_volume_on'"
            @click="isAudioMute ? unmute() : mute()"
          />
          <AppSlider
            :disabled="shouldDisableTimelineControl"
            v-model="viewcell.volume"
            :width="'100%'"
          />
        </div>
        <div class="action">
          <SvgIcon
            :disabled="shouldDisableTimelineControl"
            size="28px"
            outerSize="44px"
            iconClass="general_backward_10"
            @click="backwardHandler"
          />
          <SvgIcon
            :disabled="shouldDisableTimelineControl"
            v-if="!isPause && !isStopped"
            size="28px"
            outerSize="44px"
            iconClass="general_pause_primary"
            @click="$_togglePauseHandler"
          />
          <SvgIcon
            :disabled="shouldDisableTimelineControl"
            v-if="isPause || isStopped"
            size="28px"
            outerSize="44px"
            iconClass="general_play_primary"
            @click="$_togglePauseHandler"
          />
          <SvgIcon
            :disabled="shouldDisableTimelineControl"
            size="28px"
            outerSize="44px"
            iconClass="general_forward_10"
            @click="forwardHandler"
          />
        </div>
        <div
          class="go-to-playback"
          :data-active="!shouldDisableTimelineControl"
          @click="openTabToPlayback"
        >
          {{ $t('Playback') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSlider from '@/components/ui/AppSlider.vue';
import DisplayStreamingBlock from '@/components/DisplayStreamingBlock.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchPlaybackPlayer',
  components: {
    DisplayStreamingBlock,
    AppSlider,
  },
  props: {
    viewcell: {
      validator: (value) => typeof value === 'object' || value === null,
      required: true
    }
  },
  data() {
    return {
      lastVolume: 0
    };
  },
  computed: {
    ...mapGetters('system', ['systemTimezone']),
    shouldDisableTimelineControl() {
      return !this.viewcell?.camera || this.isLoading || this.isNoRecording;
    },
    isPause() {
      return this.viewcell.status === 'PAUSED';
    },
    isPlaying() {
      return this.viewcell.status === 'PLAYING';
    },
    isPlayEnd() {
      // Calculating endtime based on duration, instead of pluginless real end time.
      return this.viewcell.elapsedTime >= this.viewcell.duration + this.viewcell.startingPointBySec;
    },
    isStopped() {
      return this.viewcell.status === 'STOPPED';
    },
    isNoRecording() {
      return this.viewcell.status === 'NO_RECORDING';
    },
    isLoading() {
      return this.viewcell.status === 'LOADING';
    },
    isAudioMute() {
      return this.viewcell.volume === 0;
    },
    isDevMode() {
      return process.env.NODE_ENV === 'development';
    },
    playbackURL() {
      return `${window.location.origin}/playback.html`;
    }
  },
  watch: {
    viewcell: {
      handler() {
        // XXX: maybe move into store when playingChannel is being set.
        if (this.viewcell.channel !== -1) {
          this.viewcell.queryRecordingData();
        }

        if (this.viewcell.$_elapsedTime !== 0 || this.viewcell.startingPointBySec !== 0) {
          this.viewcell.startingPointBySec = 0;
          this.viewcell.$_elapsedTime = 0;
        }
      },
      immediate: true,
    },
    isPlayEnd(params) {
      if (params) {
        this.$emit('end');
      }
    },
    isStopped(params) {
      // handle if can not get recording when streaming stopped , change to next channel.
      if (params && this.viewcell.elapsedTime !== 0) {
        this.$emit('end');
      }
    },
    isNoRecording(value) {
      if (value) {
        this.$emit('end');
      }
    }
  },
  methods: {
    updatePlayTime(value) {
      // Resume playback
      if (this.isStopped) {
        this.control('play');
      }
      this.viewcell.startingPointBySec = value;
      this.viewcell.elapsedTime = 0;
      this.viewcell.playTime.timestamp = Math.floor(
        this.viewcell.options.begin.localTimestamp + value * 1000
      );
    },
    control(action) {
      this.$bus.$emit('export-viewcell-control', action);
    },
    setVolume(value) {
      this.viewcell.volume = value;
    },
    $_togglePauseHandler() {
      if (this.isStopped) {
        this.viewcell.startingPointBySec = 0;
        this.viewcell.elapsedTime = 0;
        this.control('play');
      } else if (this.isPause) {
        this.control('resume');
      } else {
        this.control('pause');
      }
    },
    backwardHandler() {
      if (this.viewcell.elapsedTime < 10) {
        return;
      }
      const backwordTime = this.viewcell.elapsedTime - 10;
      this.updatePlayTime(backwordTime);
    },
    forwardHandler() {
      if (this.viewcell.duration < 10) {
        return;
      }
      const forwardime = this.viewcell.elapsedTime + 10;
      this.updatePlayTime(forwardime);
    },
    mute() {
      this.lastVolume = this.viewcell.volume;
      this.setVolume(0);
    },
    unmute() {
      this.setVolume(this.lastVolume);
    },
    openTabToPlayback() {
      if (this.shouldDisableTimelineControl) {
        return;
      }
      const url = new URL(this.playbackURL);
      // Notes: always redirect to triggered time
      url.search = new URLSearchParams({
        channel: this.viewcell.channel,
        timestamp: this.viewcell.options.begin.localTimestamp,
        tzoffs: this.systemTimezone
      });
      window.open(`${this.playbackURL}#/${url.search}`, '_blank');
    }
  },
};
</script>

<style lang="less" scoped>
.viewcell {
  width: 100%;
  height: rem(242);
  ::v-deep.streaming-block {
    background-color: #000;
  }
}
&.empty-view {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .span {
    color: #8F8F8F;
  }
}
.player-control {
  display: flex;
  flex-wrap: wrap;
  background-color: #000;
  box-sizing: border-box;
  > div {
    box-sizing: border-box;
  }
  .timeline-wrapper {
    height: rem(40);
    padding: rem(10) rem(15);
    display: flex;
    align-items: center;
    width: 100%;
    &.disabled {
      color: #4f4f4f;
    }
    .play-time {
      line-height: 1;
      margin-right: rem(15);
    }
    .timeline {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
  .control-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 rem(10);
    height: rem(40);
    .action{
      margin-right: auto;
    }
    .volume {
      margin-right: auto;
      display: flex;
      align-items: center;
      width: rem(140);
      > div.svg-icon {
        margin-right: rem(8);
      }
    }
    .go-to-playback {
      font-size: 12px;
      color: #4F4F4F;
      border: 1px solid #4F4F4F;
      padding: 2px 9px;
      margin-left: auto;
      user-select: none;
      &[data-active] {
        cursor: pointer;
        color: #2986ff;
        border: 1px solid #2986ff;
      }
    }

  }
}
</style>
