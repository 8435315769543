import { ab2str, str2ab } from '@vivotek/lib-utility/convert_arraybuffer_string';

import MicroEvent from '@vivotek/lib-utility/microevent';
import SdpParser from '../v2/utils/SdpParser';
import {
  parseBinaryToJSON,
  parseSdp,
  readUInt16BE,
  appendBuffer,
  checkStringInHead,
  checkIsRTPData,
  isIE
} from './rtsp_tools';

function RtspProtocol(channel) {
  this.RESPONSE_TIMEOUT = 6;

  this.responseMap = {};
  this.trackMap = {};

  this.channel = MicroEvent.mixin(channel);
  this.CSeq = 0;

  this.emitRTPData = () => {};
  this.headForIE = 4;
  this.packets = [];
  this.packetLength = 0;
  this.sdpParser = new SdpParser();

  channel.binaryType = 'arraybuffer';

  if (channel.addEventListener) {
    channel.addEventListener('message', this.processMessageEvent.bind(this));
    channel.addEventListener('close', this.processCloseEvent.bind(this));
  } else if (channel.onmessage && channel.onclose) {
    channel.onmessage = (message) => {
      this.processMessageEvent(message);
      this.trigger('message', message);
    };
    channel.onclose = (ev) => {
      this.processCloseEvent(ev);
      this.trigger('close', ev);
    };
  }

  this.on('packet', this.receiveRTSPPacket);
  this.on('command', (data, isAnnounce) => {
    this.packets = [];
    this.packetLength = 0;
    this.parseRTSPCommand(data, isAnnounce)
      .then(this.receiveSdpResponse.bind(this));
  });
}

RtspProtocol.prototype.processMessageEvent = function processMessageEvent(message) {
  // var arrayData = new Uint8Array(message.data);
  const msgData = isIE ? message.data.toArray() : message.data;
  const arrayData = new Uint8Array(msgData);

  // check 'RTSP/1.0 ...'
  const isRtspRes = checkStringInHead(arrayData, 'RTSP');
  // check 'ANNOUNCE rtsp://...'
  const isAnnounce = checkStringInHead(arrayData, 'ANNOUNCE');

  if (!isRtspRes && !isAnnounce) {
    this.trigger('packet', arrayData, message);
  } else {
    this.trigger('command', ab2str(message.data), isAnnounce);
  }
};

RtspProtocol.prototype.processCloseEvent = function processCloseEvent() {
  console.error('WebSocket closed.');
  this.trigger('close');
};

RtspProtocol.prototype.receiveRTSPPacket = function receiveRTSPPacket(arrayData, message) {
  let extraData,
    packetPiece;

  const parseRTPData = (bArray, preLength, parseCallback) => {
    let pLength;
    let pPiece;
    let combinePackets;
    let prefill = 0;

    if (preLength > 0) {
      if (bArray.byteLength >= preLength) {
        if (isIE) {
          this.packets.push(message);
          combinePackets = this.packets.reduce((prev, curr) => {
            if (!prev) {
              return curr;
            }

            prev.AppendData(curr.data);
            return prev;
          }, combinePackets);

          parseCallback(combinePackets, this.headForIE);
        } else {
          this.packets.push(bArray.slice(0, preLength));
          combinePackets = this.packets.reduce((prev, curr) => {
            if (!prev) {
              return curr;
            }

            return appendBuffer(prev, curr);
          }, combinePackets);
          // parseCallback(appendBuffer(packets[0], packets[1]).buffer);
          parseCallback(combinePackets.buffer ? combinePackets.buffer : combinePackets);
        }

        this.packets = [];
        this.packetLength = 0;
        bArray = bArray.slice(preLength);
      } else {
        this.packetLength -= bArray.byteLength;
        if (isIE) {
          this.packets.push(message);
        } else {
          this.packets.push(bArray);
        }
        // packets.push(bArray);
        return [];
      }
    }

    if (!checkIsRTPData(bArray)) {
      return bArray;
    }

    pLength = readUInt16BE(bArray, 2);

    if (pLength >= 65535) {
      pLength = readUInt16BE(bArray, 4, 4);
      pPiece = bArray.slice(8, pLength + 8);
      prefill = 4;

      this.headForIE = 8;
    } else {
      pPiece = bArray.slice(4, pLength + 4);
      this.headForIE = 4;
    }

    if (pLength - pPiece.length <= 0) {
      if (isIE) {
        parseCallback(message, this.headForIE);
      } else {
        parseCallback(pPiece.buffer);
      }
      // parseCallback(pPiece.buffer);

      return parseRTPData(bArray.slice(pLength + 4 + prefill), -1, parseCallback);
    }

    return bArray;
  };

  // set data callback
  if (checkIsRTPData(arrayData)) {
    this.emitRTPData = this.trackMap[arrayData[1]] || function () {};
  }

  if (checkIsRTPData(arrayData) || this.packetLength > 0) {
    extraData = parseRTPData(arrayData, this.packetLength, this.emitRTPData);
    if (extraData.length <= 0) {
      return;
    }

    this.packetLength = readUInt16BE(extraData, 2);

    if (this.packetLength >= 65535) {
      this.packetLength = readUInt16BE(extraData, 4, 4);
      packetPiece = extraData.slice(8, this.packetLength + 8);
    } else {
      packetPiece = extraData.slice(4, this.packetLength + 4);
    }

    this.packetLength -= packetPiece.length;
    if (isIE) {
      this.packets.push(message);
    } else {
      this.packets.push(packetPiece);
    }
  }
};

RtspProtocol.prototype.parseRTSPCommand = function parseRTSPCommand(data, isAnnounce) {
  const sdpData = parseSdp(data);
  // console.log('S->C:');
  // console.log(data);

  if (isAnnounce) {
    const eventMsg = sdpData?.headers['Event-Type']?.split(' ');
    if (!eventMsg) {
      return Promise.reject();
    }
    this.CSeq = sdpData.headers.CSeq;
    this.sendRtspCommand([
      'RTSP/2.0 200 OK',
      `CSeq: ${sdpData.headers.CSeq}`,
      `Session: ${sdpData.headers.Session}`
    ].join('\r\n'));

    sdpData.status = 200;

    this.trigger('announce', eventMsg[0], eventMsg[1], sdpData);
  }

  return Promise.resolve([sdpData.headers.CSeq, sdpData.status, sdpData]);
};

RtspProtocol.prototype.receiveSdpResponse = function receiveSdpResponse([CSeq, ...args]) {
  if (!this.responseMap[CSeq]) {
    return;
  }

  this.responseMap[CSeq].apply(undefined, args);
  delete this.responseMap[CSeq];
};

RtspProtocol.prototype.sendRtspCommand = function (message) {
  if (this.channel.readyState !== 'open' && this.channel.readyState !== 1) { // 'open' for datachannel, 1 for websocket
    return Promise.reject();
  }

  const { CSeq } = this;
  const sdpMsg = `${message}\r\n\r\n`;

  return new Promise((resolve, reject) => {
    try {
      this.channel.send(str2ab(sdpMsg));
      // console.log('C->S:');
      // console.log(sdpMsg);
      this.responseMap[CSeq] = (status, data) => {
        if (status === 200) {
          resolve([status, data]);
        } else {
          reject(status);
        }

        // clear request timeout
        if (this.responseMap[CSeq].timeout) {
          clearTimeout(this.responseMap[CSeq].timeout);
        }
      };
      // send request and set timeout if no matched response
      this.responseMap[CSeq].timeout = setTimeout(() => {
        console.error('Request Timeout');
        reject(new Error('Request Timeout'));
        delete this.responseMap[CSeq];
      }, this.RESPONSE_TIMEOUT * 1000);
    } catch (err) {
      reject(err);
    }
  });
};

RtspProtocol.prototype.sendOptions = function sendOptions(url) {
  this.CSeq += 1;
  return this.sendRtspCommand([
    `OPTIONS ${url} RTSP/1.0`,
    `CSeq: ${this.CSeq}`,
    'Require: implicit-play',
    'User-Agent: WebRTSP/0.0.0.1',
    'Proxy-Require: gzipped-messages'
  ].join('\r\n'));
};

RtspProtocol.prototype.sendDescribe = function sendDescribe(url, auth, hasBackchannel) {
  this.CSeq += 1;
  const message = [
    `DESCRIBE ${url} RTSP/1.0`,
    `CSeq: ${this.CSeq}`
  ];

  if (typeof auth === 'string') {
    message.push(`Authorization: username="${auth}"`);
  } else if (typeof auth === 'object') {
    message.push(`Authorization: ${
      Object.keys(auth).map((key) => `${key}="${auth[key]}"`).join(', ')}`);
  }

  if (hasBackchannel) {
    // backchannel for 2-way audio
    message.push('Require: www.onvif.org/ver20/backchannel');
  }

  return this.sendRtspCommand(message.join('\r\n'))
    .then(([status, sdpData]) => {
      const { mediaHeaders } = sdpData;
      if (!mediaHeaders.length) {
        return Promise.reject(new Error('no describe tracks'));
      }
      return Promise.resolve([status, this.parseSdp(sdpData.mediaHeaders.join('\r\n'))]);
    });
};

RtspProtocol.prototype.sendBack = function sendBack(trackID, packet) {
  let filling = [36, trackID];
  let bLength = packet.byteLength.toString(16);

  // 'open' for datachannel, 1 for websocket
  if (this.channel.readyState !== 'open' && this.channel.readyState !== 1) {
    return;
  }

  if (packet.byteLength >= 65535) {
    filling.push(255, 255);
    bLength = (`0000000${bLength}`).substr(-8);
  } else {
    bLength = (`000${bLength}`).substr(-4);
  }

  for (let i = 0; i < bLength.length; i += 2) {
    filling.push(parseInt(bLength.substr(i, 2), 16));
  }

  filling = new Uint8Array(filling).buffer;
  filling = appendBuffer(filling, packet);

  this.channel.send(filling);
};

RtspProtocol.prototype.parseSdp = function parseSdp(content) {
  const tracks = this.sdpParser.parse(content);
  tracks.forEach((track) => {
    track.mediatype = track.type;
    track.mimetype = track.type; // XXX: refine
    switch (track.type) {
      case 'application':
        this.trackMap[track.trackID] = function processTrack(id, notify) {
          const parsedNotify = parseBinaryToJSON(notify);
          // user data event
          this.trigger(id, parsedNotify);
        }.bind(this, track.trackID);
        break;

      default:
        this.trackMap[track.trackID] = function processTrack(id, packet, param) {
          // streaming packet event
          this.trigger(id, packet, param);
        }.bind(this, track.trackID);
        break;
    }
  });
  return tracks;
};

RtspProtocol.prototype.sendSetup = function sendSetup(url) {
  this.CSeq += 1;
  const message = [
    `SETUP ${url} RTSP/1.0`,
    `CSeq: ${this.CSeq}`,
    'Transport: TCP/WS;unicast;interleave=1'
  ].join('\r\n');

  return this.sendRtspCommand(message)
    .then(([status, sdpData]) => Promise.resolve([status, sdpData.headers.Session]));
};

RtspProtocol.prototype.sendPlay = function sendPlay(url, session = '', scale = 1, options = {}) {
  this.CSeq += 1;

  const command = [
    `PLAY ${url} RTSP/1.0`,
    `CSeq: ${this.CSeq}`,
    'Range: now',
    `Scale: ${scale}`,
    `Session: ${session}`
  ];

  Object.keys(options)
    .forEach((attr) => command.push(`${attr}: ${options[attr]}`));

  return this.sendRtspCommand(command.join('\r\n'));
};

RtspProtocol.prototype.sendChange = function sendChange(url, session = '', scale = 1) {
  this.CSeq += 1;
  return this.sendRtspCommand([
    `CHANGE ${url} RTSP/1.0`,
    `CSeq: ${this.CSeq}`,
    'Range: now',
    `Scale: ${scale}`,
    `Session: ${session}`
  ].join('\r\n'));
};

RtspProtocol.prototype.sendPause = function sendPause(url, session = '') {
  this.CSeq += 1;
  return this.sendRtspCommand([
    `PAUSE ${url} RTSP/1.0`,
    `CSeq: ${this.CSeq}`,
    `Session: ${session}`
  ].join('\r\n'));
};

RtspProtocol.prototype.sendTeardown = function sendTeardown(url, session = '') {
  this.CSeq += 1;
  return this.sendRtspCommand([
    `TEARDOWN ${url} RTSP/1.0`,
    `CSeq: ${this.CSeq}`,
    `Session: ${session}`
  ].join('\r\n'));
};

RtspProtocol.prototype.removeTrack = function removeTrack(tracks) {
  let trackArray = tracks;
  if (!Array.isArray(tracks)) {
    trackArray = [tracks];
  }

  trackArray.forEach((track) => {
    if (this.trackMap[track]) {
      delete this.trackMap[track];
    }
  });
};

RtspProtocol.prototype.close = function close() {
  this.channel.close();
};

export default MicroEvent.mixin(RtspProtocol);
