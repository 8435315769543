/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
export default function exitFullscreen() {
  const elem = document;
  elem.webkitCancelFullScreen
    ? elem.webkitCancelFullScreen()
    : elem.mozCancelFullScreen
      ? elem.mozCancelFullScreen()
      : elem.cancelFullScreen
        ? elem.cancelFullScreen()
        : elem.msExitFullscreen
          ? elem.msExitFullscreen()
          : elem.exitFullscreen();
}
