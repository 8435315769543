import { unpack, access, isPointVerJSON as isPoint, toPointVerJSON as toPoint} from './utility.mjs';
import Shapes from './shapes.mjs';
import VAOMCore from './VAOM.mjs';

// XXX: We should integrate old motion and new motion together
// no matter it's coming from streaming or rule query

export default class MotionDetectionAlertManager {
  constructor(reference) {
    this.reference = reference;
    this.shapes = [];
    this.rules = [];
    this.polygons = [];
    this.rectangles = [];
    this.core = new VAOMCore(this);
    this.motionWindowExpire = 250;
  }

  addPolygon(polygons) {
    this.polygons.push({
      coordinates: polygons,
      expire: new Date(Date.now() + this.motionWindowExpire)
    });
  }

  drawPolygons(canvas, ctx) {
    const now = new Date();
    const lineWidth = this.motionWindowLineWidth;

    const { width, height } = canvas;

    this.polygons = this.polygons.filter((polygon) => polygon.expire >= now);

    this.polygons.forEach((polygon) => {
      const { coordinates } = polygon;
      let x; let y; let
        i;

      ctx.beginPath();
      ctx.moveTo(coordinates[0] * width, coordinates[1] * height);

      for (i = 2; i < coordinates.length; i += 2) {
        x = coordinates[i];
        y = coordinates[i + 1];

        if (x !== undefined && y !== undefined) {
          ctx.lineTo(x * width, y * height);
        }
      }

      ctx.closePath();
      ctx.strokeStyle = '#ff0000';
      ctx.lineWidth = lineWidth;
      ctx.stroke();
    });
  }

  drawRectangles(canvas, ctx) {
    const now = new Date();
    const lineWidth = this.motionWindowLineWidth;

    const { width, height } = canvas;

    this.rectangles = this.rectangles.filter((rectangle) => rectangle.expire >= now);

    this.rectangles.forEach((rectangle) => {
      ctx.strokeStyle = '#FF0000';
      ctx.lineWidth = lineWidth;
      ctx.strokeRect(
        rectangle.x * width,
        rectangle.y * height,
        rectangle.width * width,
        rectangle.height * height
      );
    });
  }

  addRectangles(x, y, width, height) {
    this.rectangles.push({
      x,
      y,
      width,
      height,
      expire: new Date(Date.now() + this.motionWindowExpire)
    });
  }

  handleRule(rule) {
    if (!rule) {
      return;
    }
    unpack(access(rule.MotionDetection, "Roi")).forEach((roi) => {
      var points = unpack(roi.PolygonStd).filter(isPoint).map(toPoint);
      if (Shapes.isPolygon(points)) {
        this.rules.push(new Shapes.FieldDetection({
          canvas: this.reference.drawer,
          name: roi.Name,
          points,
          preset: roi.PresetName
        }, this));
      }
    });
  }

  drawIfNecessary(canvas, ctx) {
    const { reference } = this;
    const { displayConfig } = this.reference;
    // show trigger over rule config, so render rule first if necessary
    if (displayConfig.showMotionDetectionRule) {
      this.rules.forEach(e => {
        if (
          (displayConfig.showAnalyticRules === undefined || e.name === displayConfig.showAnalyticRules) &&
          (e.preset === undefined || e.preset === reference.ptzInfoManager.currentPreset)
        ) {
          e.draw(
            displayConfig.ruleScaleRatio,
            ctx,
            canvas.width,
            canvas.height,
            displayConfig.showSummary,
            displayConfig.showRuleName,
            displayConfig.showDirection,
            displayConfig.showExclusiveArea
          );
        }
      });
    }
    this.drawPolygons(canvas, ctx);
    this.drawRectangles(canvas, ctx);
    this.core.draw(
      true,
      ctx,
      canvas.width,
      canvas.height,
    );
  }

  clear() {
    this.core.clear();
  }

  handle(data) {
    this.clear();
    if (!data.length) {
      return;
    }
    this.shapes = data.map((points) => new Shapes.MotionDetectionAlert({ points }, this));
    this.reference.requestPaint();
  }
}
