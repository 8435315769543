<template>
  <div
    class="left-navigation-bar"
    :class="{ hover: showNavigationBar }"
  >
    <div class="navigation-content-wrapper">
      <div
        class="top-button-list"
        @mouseover="showNavigationBar=true"
        @mouseout="showNavigationBar=false"
      >
        <div
          v-for="page in showPages"
          :key="page"
          class="navigation-button"
          :class="{ active: mode === page}"
          @click="navigateTo(page)"
        >
          <SvgIcon
            :iconClass="`general_${page}`"
            size="32px"
          />
          <div class="label">
            <h5>{{ pageLabelMap[page] }}</h5>
          </div>
        </div>
      </div>
      <div
        class="bottom-button-list"
        @mouseover="showNavigationBar=true"
        @mouseout="showNavigationBar=false"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'NavigationBar',
  props: {
    mode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      indexPage: 'liveview',
      pages: [
        'liveview',
        'playback',
        'search',
        'ai',
        'settings'
      ],
      showNavigationBar: false
    };
  },
  computed: {
    // If we don't use computed but put the $t in data, it will not change while lang changes.
    ...mapState(['ui']),
    ...mapGetters('search', ['currentResult']),
    ...mapGetters('user', ['isAdministrator']),
    ...mapGetters('system', ['systemCapability']),
    pageLabelMap() {
      return {
        liveview: this.$t('Live'),
        playback: this.$t('Playback'),
        search: this.$t('Search'),
        settings: this.$t('Settings'),
        ai: this.$t('AI')
      };
    },
    isDevMode() {
      return process.env.NODE_ENV === 'development';
    },
    showPages() {
      return this.pages.filter((page) => !(page === 'settings' && !this.isAdministrator)
       && !(page === 'ai' && !this.systemCapability.support_smart_search_ii));
    }
  },
  methods: {
    ...mapActions('ui', ['navigate']),
    navigateTo(page) {
      if (page === this.mode) {
        return;
      }
      const url = `${window.location.origin}/${page}.html`;
      if (page === 'settings') {
        window.open(url, '_blank');
        return;
      }
      this.navigate({ url });
    }
  },
};
</script>

<style lang="less" scoped>
.left-navigation-bar {
  width: rem(64);
  z-index: 51;
  box-shadow: rem(2) 0 rem(6) rgba(0, 0, 0, 0.3);
  overflow: hidden;
  &.hover {
    overflow:visible;
  }
  .navigation-content-wrapper {
    background-color: @navigation-bar-background-color;
    box-shadow: rem(2) 0 rem(6) rgba(0, 0, 0, 0.3);
    width: rem(364);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top-button-list {
      display: flex;
      flex-direction:column;
    }

    .bottom-button-list {
      align-items:flex-end;
    }
  }
}

.navigation-button {
  height: rem(48);
  color:#E3E3E3;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  .svg-icon {
    padding: rem(8) rem(16);
    &[data-state="active"] {
      background-color: @navigation-bar-button-hover-background-color;
    }
  }
  .label {
    cursor: pointer;
    margin-left: rem(8);
  }
  &:hover {
    color: #FFFFFF;
    background-color: @navigation-bar-button-hover-background-color;
  }
  &.active {
    h5 {
      font-weight: 600; // semi-bold // 影響不了內部的 h5
    }
    color: #FFFFFF;
    background-color: @navigation-bar-button-active-background-color;
  }
}
</style>
