<template>
  <div class="logo">
    <img
      class="desktop"
      alt="logo"
      src="logo_images/logo_settings.png"
    >
  </div>
</template>
<script>

export default {
  name: 'AppLogo',
};
</script>

<style lang="less">
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 rem(15);
  height: 100%;
  img {
    width: auto;
    height: rem(32);
  }
}
img.desktop {
  display: block;
}
</style>
