import LocalTime from '@/utility/local_time';
import RANGE from '@/constants/dateRangeValidation';

function calculateDifference(start, end, unit = 'minute') {
  // Note: startDate, endDater string format is YYYY-MM-DD
  // startTime and endTime format is HH:MM
  let denominator = 1000 * 60 * 60;
  switch (unit) {
    case 'minute':
      denominator = 1000 * 60;
      break;
    case 'second':
      denominator = 1000;
      break;
    default:
      denominator = 1000 * 60 * 60;
  }
  let result = 0;
  if (start instanceof LocalTime && end instanceof LocalTime) {
    result = (end.localTimestamp - start.localTimestamp) / denominator;
  } else {
    const startTimestamp = new Date(`${start.date} ${start.time}`).getTime();
    const endTimestamp = new Date(`${end.date} ${end.time}`).getTime();
    result = (endTimestamp - startTimestamp) / denominator;
  }
  return result;
}

export default class DateRangeValidation {
  constructor({
    errors = [],
    unit = 'minute',
    max = RANGE.DEFAULT_MAX_MINUTE,
    min = RANGE.DEFAULT_MIN_MINUTE
  }) {
    this.errors = errors;
    this.UNIT = unit;
    this.MAX = max;
    this.MIN = min;
  }

  // id is binding validation element id
  validateRange(start, end, id, maximum = true) {
    // this is interface for calendar
    const difference = calculateDifference(
      start,
      end,
      this.UNIT
    );
    // clear target id error to re-validate
    this.errors = this.errors.filter((v) => (v.id !== id));
    // note: the reason that we do not parameterize max, min and unit is for translation collecting
    switch (true) {
      case difference > this.MAX && this.MAX === 60 * 24 && this.UNIT === 'minute' && maximum:
        // Vue.prototype.$t('Maximum 24 hours')
        this.errors.push({ id, message: 'Maximum 24 hours', type: 'EXCEED_MAX' });
        break;
      case difference < 0:
        // Vue.prototype.$t('End time should be later than starting time')
        this.errors.push({ id, message: 'End time should be later than starting time', type: 'MISMATCH_ORDER' });
        break;
      case difference < this.MIN && this.MIN === 1 && this.UNIT === 'minute' && maximum:
        // Vue.prototype.$t('Minimum 1 minute is required')
        this.errors.push({ id, message: 'Minimum 1 minute is required', type: 'SUBCEED_MIN' });
        break;
      default:
    }
  }

  hasErrorHighlight(id) {
    if (!this.errors
      || this.errors.length === 0) {
      return false;
    }
    return this.errors.some((error) => (error.id === id));
  }

  getErrors(id) {
    if (this.errors.length === 0) {
      return [];
    }
    return this.errors.filter((error) => (error.id === id));
  }
}
