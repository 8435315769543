<template>
  <div class="settings-container">
    <div v-if="isHideExportSettings">
      <h5 class="bold title">
        {{ $t('Settings') }}
      </h5>
    </div>
    <div class="settings-content">
      <div>
        <div
          class="block"
          v-if="supportTimeRange"
        >
          <h6 class="bold input-title">
            {{ $t('From') }}
          </h6>
          <AppDateTimePicker
            id="export-start"
            v-model="settings.startDateTime"
            @validate="validator.validateRange(
              settings.startDateTime,
              settings.endDateTime,
              'export-end'
            )"
          />
          <h6 class="bold input-title">
            {{ $t('To') }}
          </h6>
          <AppDateTimePicker
            id="export-end"
            v-model="settings.endDateTime"
            @validate="validator.validateRange(
              settings.startDateTime,
              settings.endDateTime,
              'export-end'
            )"
            :errorMessages="validator.getErrors('export-end')"
            :isError="validator.hasErrorHighlight('export-end')"
          />
          <span
            class="p"
            v-if="validator.errors.length === 0"
          >
            {{ $t('Maximum 24 hr') }}
          </span>
        </div>
        <div class="block">
          <div v-if="showAttachedData">
            <h6 class="input-title">
              {{ $t('Attach data') }}
            </h6>
            <div>
              <CheckBox
                id="pos"
                name="pos"
                class="attached-data-item"
                :label="$t('POS')"
                @change="handleChangedEvent"
                :value="settings.pos"
                :disabled="disabled"
                v-model="settings.attachedDataType"
              />
              <CheckBox
                id="gps"
                name="gps"
                class="attached-data-item"
                :label="$t('GPS')"
                @change="handleChangedEvent"
                :value="settings.gps"
                :disabled="disabled"
                v-model="settings.attachedDataType"
              />
            </div>
          </div>
        </div>
        <div
          class="block"
          v-if="supportEncryption"
        >
          <CheckBox
            :label="$t('Set password')"
            v-model="settings.enableEncryption"
          />
          <div class="password">
            <AppInputFields
              v-if="settings.enableEncryption"
              id="password"
              v-model="settings.password"
              type="password"
              :autoValidate="true"
              :validate="passwordPattern"
            />
          </div>
        </div>
      </div>
      <AppCardMessage
        v-show="showExportWarning"
        iconClass="general_hint"
        class="export-warning"
      >
        <span
          slot="message"
          class="message"
        >
          <!-- eslint-disable-next-line max-len -->
          {{ $t('It will take a while for large video recordings exporting.') }}
          {{ $t('Please make sure your network connection is good.') }}</span>
      </AppCardMessage>
    </div>
  </div>
</template>

<script>
import AppInputFields from '@/components/ui/AppInputFields.vue';
import AppDateTimePicker from '@/components/ui/AppDateTimePicker.vue';
import DateRangeValidation from '@/utility/dateRangeValidation';
import AppCardMessage from '@/components/ui/AppCardMessage.vue';
import { mapState } from 'vuex';

export default {
  name: 'ExportSettings',
  props: {
    value: {
      type: Object,
      default: () => ({
        attachedDataType: [],
        enableEncryption: false,
        fileFormat: '3GP',
        password: '',
        startDateTime: {
          date: '',
          time: ''
        },
        endDateTime: {
          date: '',
          time: ''
        },
        gps: 'gps',
        pos: 'pos',
      })
    },
    timeRangeMax: {
      type: Number,
      default: 30
    },
    supportFileFormat: {
      type: Boolean,
      default: false,
    },
    supportTimeRange: {
      type: Boolean,
      default: true,
    },
    supportEncryption: {
      type: Boolean,
      default: false,
    },
    showExportWarning: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      requied: true
    }
  },
  components: {
    AppInputFields,
    CheckBox: () => import('@/components/ui/CheckBox.vue'),
    AppDateTimePicker,
    AppCardMessage
  },
  data() {
    return {
      validator: new DateRangeValidation({ max: this.timeRangeMax }),
      fileFormats: [{ text: '3GP', value: '3GP' }, { text: 'EXE', value: 'EXE' }],
    };
  },
  methods: {
    handleChangedEvent() {
      this.$emit('input', this.settings);
    }
  },
  computed: {
    ...mapState(['system']),
    settings: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', this.value);
      }
    },
    passwordPattern() {
      if (this.settings.enableEncryption) {
        return [{ isRequired: true }, { errorMessage: this.$t('At least 8 characters'), pattern: (v) => (v.length >= 8) }];
      }
      return [{ errorMessage: this.$t('At least 8 characters'), pattern: (v) => (v.length >= 8) }];
    },
    folderNamePattern() {
      return [{ isRequired: true }, {
        errorMessage: this.$t('At least 1 character'),
        pattern: (v) => {
          if (v) {
            return (v.length >= 1);
          }
          return false;
        }
      }];
    },
    isHideExportSettings() {
      return this.system.info.brand === 'SCOO' || this.system.info.brand === 'SystemK';
    },
    showAttachedData() {
      if (this.isHideExportSettings) {
        return false;
      }
      return this.settings.fileFormat === '3GP';
    }
  },
  watch: {
    // handle with local dirty settings
    settings: {
      handler(newValue, oldValue) {
        this.$bus.$emit('ExportSettings:isDirty');
      },
      deep: true,
    },
  }
};
</script>
<style lang="less" scoped>
.settings-container {
  height: 100%;
  padding: 0 rem(40);
  min-width: rem(316);
  margin-left: rem(36);
  display: flex;
  flex-direction: column;
  >.title {
    height: rem(24);
    padding: rem(18) 0;
  }
  >.settings-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .block {
    margin: rem(10) 0;
  }
  .input-title {
    height: rem(20);
    padding: rem(10) 0;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .attached-data-item {
    height: rem(30);
  }
  .password {
    padding: rem(10) 0;
  }
  .error {
    color: #ffb029;
  }
  .message {
    color: white;
  }
  .export-warning {
    margin: 0;
  }
}
</style>
