import binaryTools from '@vivotek/lib-utility/binaryTools';
import { getTagged, Logger } from '@vivotek/lib-utility/logger';
import EventEmitter from 'events';
import SdpResponse from './model/SdpResponse';
import {
  checkStringInHead, checkIsRTPData, combinePacketArray, readUInt16BE, parseSdp
} from './utils/rtspTools';
import SdpParser from './utils/SdpParser';
import RTPFactory from './utils/RtpFactory';
import NaluAssembler from './utils/NaluAssembler';
import md5 from './utils/md5';

const Log = getTagged('rtsp', Logger.DEBUG);

class RtspProtocol extends EventEmitter {
  constructor(channel, options) {
    super();

    this.responseMap = {};
    this.channel = channel;
    this.rtspServer = '';
    this.userAgent = 'RTPExPlayer';
    this.CSeq = 0;
    this.interleaveChannelIndex = 0;

    this.payloadQueue = {};
    this.packets = [];
    this.packetLength = 0;
    this.timeOffset = {};
    this.rtpBuffer = {};
    this.lastTimestamp = {};
    this.previousResponse = null;

    this.sdpParser = new SdpParser();
    this.rtpFactory = new RTPFactory(this.sdpParser);
    this.naluAssembler = new NaluAssembler();

    this.channel.on('message', this.processMessageEvent);
    this.channel.on('close', this.processCloseEvent);

    this.on('packet', this.receiveRTSPPacket);

    this.on('command', (data, isAnnounce) => {
      this.packets = [];
      this.parseRTSPCommand(data, isAnnounce)
        .then((responses) => {
          responses.forEach((response) => {
            this.receiveSdpResponse(response);
          });
        });
    });
  }

  destroy() {
    if (this.channel) {
      this.channel.off('message', this.processMessageEvent);
      this.channel.off('close', this.processCloseEvent);
      this.channel = null;
    }

    this.removeSdpResponseMap();

    this.payloadQueue = {};
    this.packets = [];
    this.timeOffset = {};
    this.rtpBuffer = {};
    this.lastTimestamp = {};

    this.sdpParser = null;
    this.rtpFactory = null;
    this.naluAssembler = null;
  }

  processMessageEvent = (message) => {
    const msgData = message.data;
    const arrayData = new Uint8Array(msgData);

    // check 'RTSP/1.0 ...'
    const isRtspRes = checkStringInHead(arrayData, 'RTSP');
    // check 'ANNOUNCE rtsp://...'
    const isAnnounce = checkStringInHead(arrayData, 'ANNOUNCE');

    if (!isRtspRes && !isAnnounce) {
      this.emit('packet', arrayData, message);
    } else {
      this.emit('command', binaryTools.ab2str(message.data), isAnnounce);
    }
    this.emit('message', message);
  }

  processCloseEvent = (ev) => {
    Log.error('RTSPChannel closed.');
    this.removeSdpResponseMap();
    this.emit('close', ev);
  }

  parseRTSPCommand(data, isAnnounce) {
    const { parsedData, remainedData } = parseSdp(data, this.previousResponse);
    this.previousResponse = remainedData;
    return new Promise((resolve, reject) => {
      if (isAnnounce) {
        parsedData.forEach((sdpData) => {
          const eventMsg = sdpData?.headers['Event-Type']?.split(' ');
          if (!eventMsg) {
            reject();
            return;
          }
          this.CSeq = sdpData.headers.cseq;
          this.sendRtspCommand([
            'RTSP/2.0 200 OK',
            `CSeq: ${sdpData.headers.cseq}`,
            `Session: ${sdpData.headers.session}`,
          ].join('\r\n'));

          sdpData.status = 200;

          this.emit('announce', eventMsg[0], eventMsg[1], sdpData);
        });
      }
      resolve(parsedData.map((sdpData) => {
        const responseTime = new Date().getTime() - this.responseMap[sdpData.headers.cseq]?.requestTime;
        Log.log(`S->C: CSeq ${sdpData.headers.cseq} response in ${responseTime} ms\n${sdpData.content}`);
        return [sdpData.headers.cseq, sdpData.status, sdpData];
      }));
    });
  }

  receiveSdpResponse([CSeq, ...args]) {
    if (!this.responseMap[CSeq]) {
      return;
    }

    this.responseMap[CSeq].onResponse(args);
    delete this.responseMap[CSeq];
  }

  receiveRTSPPacket = (packet /* , message */) => {
    let arrayData = packet;
    let extraData;
    let packetPiece;

    if (!checkIsRTPData(packet) && this.packets.length && this.packetLength === 0) {
      const combinePackets = combinePacketArray([...this.packets, packet]);
      arrayData = new Uint8Array(combinePackets.buffer ? combinePackets.buffer : combinePackets);
      this.packets = [];
    }

    if (checkIsRTPData(arrayData) || this.packetLength > 0) {
      extraData = this.parseRTPData(arrayData, this.packetLength, this.processRTSPPacket);
      if (extraData.length <= 0) {
        return;
      }

      if (extraData.length < 4) {
        this.packetLength = 0;
        this.packets.push(extraData);
        return;
      }

      this.packetLength = readUInt16BE(extraData, 2);

      if (this.packetLength >= 65535 && extraData.length < 8) {
        this.packetLength = 0;
        this.packets.push(extraData);
        return;
      }

      if (this.packetLength >= 65535) {
        this.packetLength = readUInt16BE(extraData, 4, 4);
        packetPiece = extraData.slice(8, this.packetLength + 8);
      } else {
        packetPiece = extraData.slice(4, this.packetLength + 4);
      }

      this.packetLength -= packetPiece.length;
      this.packets.push(packetPiece);
    }
  }

  parseRTPData(dataArray, preLength, parseCallback) {
    let pLength;
    let pPiece;
    let combinePackets;
    let prefill = 0;
    let bArray = dataArray;

    if (preLength > 0) {
      if (bArray.byteLength >= preLength) {
        this.packets.push(bArray.slice(0, preLength));
        combinePackets = combinePacketArray(this.packets);
        parseCallback(combinePackets.buffer ? combinePackets.buffer : combinePackets);

        this.packets = [];
        this.packetLength = 0;
        bArray = bArray.slice(preLength);
      } else {
        this.packetLength -= bArray.byteLength;
        this.packets.push(bArray);
        return [];
      }
    }

    if (!checkIsRTPData(bArray) || bArray.length < 4) {
      return bArray;
    }

    pLength = readUInt16BE(bArray, 2);

    if (pLength >= 65535 && bArray.length < 8) {
      return bArray;
    }

    if (pLength >= 65535) {
      pLength = readUInt16BE(bArray, 4, 4);
      pPiece = bArray.slice(8, pLength + 8);
      prefill = 4;
    } else {
      pPiece = bArray.slice(4, pLength + 4);
    }

    if (pLength - pPiece.length <= 0) {
      parseCallback(pPiece.buffer);
      return this.parseRTPData(bArray.slice(pLength + 4 + prefill), -1, parseCallback);
    }

    return bArray;
  }

  processRTSPPacket = (packet) => {
    const data = new Uint8Array(packet);
    const rtpData = this.rtpFactory.build(data);
    const { trackID } = rtpData;

    if (!rtpData.media) {
      // continuous receive unknown payload type 72
      // which is not recorded in sdp
      // TODO: need to check with camera RD
      // Log.debug('Receive unsupport media packet', rtpData);
      return;
    }

    if (rtpData.media && rtpData.getPayload().byteLength === 0) {
      Log.debug('Receive media packet with empty data', rtpData);
      return;
    }

    if (rtpData.isVideo && !this.firstVideoPacket) {
      this.firstVideoPacket = rtpData;
      Log.debug('Receive first video packet', rtpData);
    }

    if (this.timeOffset[trackID] === undefined) {
      this.rtpBuffer[trackID].push(rtpData);
      return;
    }

    if (this.lastTimestamp[trackID] === undefined) {
      this.lastTimestamp[trackID] = rtpData.timestamp - this.timeOffset[trackID];
    }

    const queue = this.rtpBuffer[trackID];
    queue.push(rtpData);

    while (queue.length) {
      try {
        const rtp = queue.shift();
        rtp.timestamp -= (this.timeOffset[rtp.trackID] + this.lastTimestamp[rtp.trackID]);
        if (rtp.media) {
          let payload = null;

          if (rtp.isVideo) {
            payload = this.naluAssembler.onNALUFragment(rtp);
          } else if (rtp.isAudio) {
            payload = {
              data: rtp.getPayload(),
              applicationData: rtp.applicationData
            };
          }

          if (payload) {
            this.payloadQueue[rtp.trackID].push(payload);
          }

          this.emit(rtp.media.trackID, rtp.trackID);
        }
      } catch (error) {
        this.emit('error', error);
      }
    }
  }

    // NOTE: ONLY FOR DEMO
    handleAuthFailed(data) {
      const [method, ...auth] = data.headers['www-authenticate'].split(' ');
      const chunks = auth.join(' ').split(',');
      if (method.toLowerCase() === 'digest') {
        const parsedChunks = {};
        chunks.forEach((chunk) => {
          const [k, v] = chunk.trim().split('=');
          parsedChunks[k] = v.substr(1, v.length - 2);
        });
        this.authenticator = (_method, url) => {
          const ep = {
            user: 'vsaas',
            pass: 'vsaas123'
          };
  
          const ha1 = md5(`${ep.user}:${parsedChunks.realm}:${ep.pass}`);
          const ha2 = md5(`${_method}:${url}`);
          const response = md5(`${ha1}:${parsedChunks.nonce}:${ha2}`);
          return `Digest username="${ep.user}", realm="${parsedChunks.realm}", nonce="${parsedChunks.nonce}", uri="${url}", response="${response}"`;
        };
      }
    }

  sendRtspCommand(message, timeout) {
    if (this.channel.readyState !== 'open' && this.channel.readyState !== 1) { // 'open' for datachannel, 1 for websocket
      return Promise.reject(new Error('data channel is not open'));
    }

    message.push(`User-Agent: ${this.userAgent}`);

    // NOTE: ONLY FOR DEMO
    if (this.authenticator) {
      const [method, url] = message[0].split(' ');
      message.push(
        `Authorization: ${this.authenticator(method, url)}`
      );
    }

    const { CSeq } = this;
    const sdpMsg = `${message.join('\r\n')}\r\n\r\n`;
    return new Promise((resolve, reject) => {
      try {
        this.channel.send(binaryTools.str2ab(sdpMsg));
        Log.debug(`C->S:\n${sdpMsg}`, `\nchannel state: ${this.channel.readyState}, timeout: ${timeout}`);
        this.responseMap[CSeq] = new SdpResponse({
          CSeq,
          sdpMsg,
          timeout,
          responseHandler: (status, data, responseTime) => {
            if (status === 200) {
              resolve([status, data, responseTime]);
            } else {
              if (status === 401) {
                this.handleAuthFailed(data);
              }
              reject(new Error(`${status} ${data.statusText}`));
            }
          },
          timeoutHandler: () => {
            delete this.responseMap[CSeq];
            reject(new Error('Request Timeout'));
          }
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  sendOptions(url) {
    this.interleaveChannelIndex = 0;
    this.CSeq += 1;
    return this.sendRtspCommand([
      `OPTIONS ${url} RTSP/1.0`,
      `CSeq: ${this.CSeq}`,
    ])
      .then(([, sdpData]) => {
        this.rtspServer = sdpData.headers.server || this.rtspServer;
        this.userAgent = this.rtspServer.indexOf('Streaming Server') >= 0 ? 'WebRTSP' : 'RTPExPlayer';
        this.rtspMethods = sdpData.headers.public.split(',').map((e) => e.trim());
      });
  }

  sendDescribe(url, hasBackchannel) {
    this.CSeq += 1;
    const message = [
      `DESCRIBE ${url} RTSP/1.0`,
      `CSeq: ${this.CSeq}`,
    ];

    if (hasBackchannel) {
      // backchannel for 2-way audio
      message.push('Require: www.onvif.org/ver20/backchannel');
    }

    return this.sendRtspCommand(message)
      .then(([status, sdpData]) => this.parseSdp(sdpData.content)
        .then((tracks) => {
          this.tracks = tracks;
          this.tracks.forEach((track) => this.initializeTrackMap(track));
          return Promise.resolve([status, tracks]);
        }));
  }

  initializeTrackMap(track) {
    const { trackID } = track;
    this.rtpBuffer[trackID] = [];
    this.timeOffset[trackID] = 0;
    this.payloadQueue[trackID] = [];
  }

  parseSdp(content) {
    return Promise.resolve(this.sdpParser.parse(content));
  }

  sendSetup(url) {
    this.CSeq += 1;
    const interleavedChannels = `${this.interleaveChannelIndex}-${this.interleaveChannelIndex += 1}`;
    this.interleaveChannelIndex += 1;

    const message = [
      `SETUP ${url} RTSP/1.0`,
      `CSeq: ${this.CSeq}`,
      `Transport: RTP/AVP/TCP;unicast;interleaved=${interleavedChannels}`,
      `Date: ${new Date().toUTCString()}`,
    ];

    if (this.session) {
      message.push(`Session: ${this.session}`);
    }

    return this.sendRtspCommand(message)
      .then(([status, sdpData]) => {
        if (sdpData.headers.session) {
          this.session = sdpData.headers.session;
          [this.sessionId] = sdpData.headers.session.split(';');
        }
        return Promise.resolve([status, sdpData.headers.session]);
      });
  }

  sendPlay(url, options = {}) {
    this.CSeq += 1;

    const command = [
      `PLAY ${url} RTSP/1.0`,
      `CSeq: ${this.CSeq}`,
      `Session: ${this.session}`,
    ];

    Object.keys(options)
      .forEach((attr) => command.push(`${attr}: ${options[attr]}`));

    return this.sendRtspCommand(command);
  }

  sendPause(url, options = {}) {
    this.CSeq += 1;
    const command = [
      `PAUSE ${url} RTSP/1.0`,
      `CSeq: ${this.CSeq}`,
      `Session: ${this.session}`,
    ];
    Object.keys(options)
      .forEach((attr) => command.push(`${attr}: ${options[attr]}`));

    return this.sendRtspCommand(command);
  }

  sendTeardown(url) {
    this.CSeq += 1;
    return this.sendRtspCommand([
      `TEARDOWN ${url} RTSP/1.0`,
      `CSeq: ${this.CSeq}`,
      `Session: ${this.session}`,
    ], 1 * 1000);
  }

  removeTrack(tracks) {
    let trackArray = tracks;
    if (!Array.isArray(tracks)) {
      trackArray = [tracks];
    }

    trackArray.forEach((track) => {
      if (this.trackMap[track]) {
        delete this.trackMap[track];
      }
    });
  }

  removeSdpResponseMap() {
    if (!this.responseMap) {
      return;
    }
    Object.keys(this.responseMap).forEach((CSeq) => {
      this.responseMap[CSeq].destroy();
      delete this.responseMap[CSeq];
    });
  }
}

export default RtspProtocol;
