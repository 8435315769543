/**
 * This is actually a wrapper of canvas element, to enhance it with render/requestPaint ability.
 */

export default class BrowserCanvas {
  constructor(reference, isFishEye, useOffscreenCanvas) {
    this.origin = useOffscreenCanvas ? new OffscreenCanvas(300, 200) : document.createElement('canvas');
    this.originCtx = this.origin.getContext('2d');
    this.origin.width = 3840;
    this.origin.height = 2160;
    this.canvas = reference.destination;
    this.context = this.canvas.getContext('2d');
    this.reference = reference;

    if (isFishEye) {
      // for fisheye's watermark check
      this.canvas.width = this.origin.width;
      this.canvas.height = this.origin.height;
    } else {
      this.canvas.width = 3840;
      this.canvas.height = 2160;
    }
    this.origin.render = this.render.bind(this);
    this.origin.requestPaint = function() {}; // mock requestPaint for shapes
    this.origin.destroy = this.destroy.bind(this);
    return this.origin;
  }

  destroy() {
    if (this.canvas.parentNode) {
      this.canvas.parentNode.removeChild(this.canvas);
    }
    delete this.canvas;
    delete this.origin;
  }

  render() {
    const { canvas, origin, originCtx, context } = this;
    if (!canvas) {
      return;
    }

    if (this.reference.source) {
      const { videoWidth, videoHeight } = this.reference.source;

      origin.setAttribute('width', videoWidth);
      origin.setAttribute('height', videoHeight);
      originCtx.drawImage(this.reference.source, 0, 0, origin.width, origin.height);
    } else {
      originCtx.clearRect(0, 0, origin.width, origin.height);
    }

    // we don't need this transformation for the current design and usage of DrawMotionCanvas,
    // I keep these source code for reference just in case
    //var m = videoOutput.mapNormalizedRectToItem(Qt.rect(0, 0, 1, 1));
    //ctx.transform(m.width / canvas.width, 0, 0, m.height / canvas.height, m.x, m.y);

    if (this.reference.enable) {
      originCtx.strokeStyle = "#ff0000";
      (this.reference.managers || []).forEach((manager) => {
        manager.drawIfNecessary && manager.drawIfNecessary(origin, originCtx);
      });
    } else {
      console.log('ignored');
    }

    context.clearRect(0, 0, origin.width, origin.height);
    context.drawImage(origin, 0, 0, origin.width, origin.height);
  }
}
