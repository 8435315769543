export default Object.freeze({
  SUPPORT_VIDEO_CODEC: ['H264', 'HEVC', 'H265', 'SVC'],
  SUPPORT_AUDIO_CODEC: [
    'DMYV', 'G7221', 'G729A', 'AAC', 'GAMR', 'SAMR', 'G711', 'G711A', 'G726',
    'DMYA', 'IVA1', 'METX', 'ALLCODEC'
  ],
  DEWARP: {
    '1O': 0,
    '1R': 1,
    '1P': 2,
    /* These options are supported but for some reason we don't want to open them to the use
    '1O3R': 3,
    '1O8R': 4,
    */
  },
  MOUNTTYPE: {
    wall: 0,
    ceiling: 1,
    floor: 2,
  },
  PLAYBACK_RATE_MAP: [0.25, 0.5, 1, 2, 4],
  WORKER_264_SRC: `${process.env.BASE_URL}libde264/h264decoder-1.0.0.1.js`,
  WORKER_265_SRC: `${process.env.BASE_URL}libde265/h265decoder-1.0.0.3.js`,
});
