const mutations = {
  updateBuzzer(state, buzzer) {
    state.buzzer = !!buzzer;
  },
  updateStorageStatus(state, storage) {
    Object.keys(storage).forEach((key) => {
      state.storage[key] = storage[key];
    });
  },
  updateWebsocket(state, { websocket, interval }) {
    state.websocket = websocket;
    state.interval = interval;
  },
  updateSerial(state) {
    state.serial += 1;
  }
};

export default mutations;
