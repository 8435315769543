<template>
  <div
    class="layout-container"
    @mousemove="displayFloatingFooter"
  >
    <PageLayout
      ref="page"
      v-if="activePage"
      :layout="activeLayout"
      :isCellExpanded="isViewCellExpanded"
      :expandedIndex="expandedViewCellIndex"
    >
      <template v-slot:cell="{index}">
        <div
          :class="`viewcell ${activeViewIndex === index ? 'active' : ''}`"
          @click="clickViewcell(index)"
          @dblclick="toggleViewCellExpand(index)"
        >
          <DisplayStreamingBlock
            :ref="`viewcell${index}`"
            :refInFor="true"
            :viewcell="viewcells[index]"
            :key="viewcells[index].$_id"
            :isActiveView="activeViewIndex === index"
            :globalDisplayConfig="layoutDisplayConfig[index]"
            :shouldDisableExpand="shouldDisableExpand"
            :needLiteMode="needLiteMode"
            :isViewCellExpanded="isViewCellExpanded"
            :isFullscreen="fullscreen"
            @toggleViewCellExpand="toggleViewCellExpand(index)"
          />
        </div>
      </template>
    </PageLayout>
    <slot
      name="empty-container"
      class="empty-container"
    />
    <CentralPanelFooter
      v-bind="$props"
      v-on="$listeners"
      v-if="shouldDisplayFooter"
      ref="footer"
    >
      <div
        class="controls"
        slot="left-control"
      >
        <svg-icon
          iconClass="general_change_layout"
          v-if="!fullscreen && !rotating && !scene.isSceneMode"
          size="32px"
          outerSize="48px"
          @click="$_changeLayout"
        />
        <svg-icon
          iconClass="general_rotate"
          v-if="!fullscreen && !rotating"
          size="32px"
          outerSize="48px"
          @click="rotate"
          :disabled="shouldDisableRotate"
        />
      </div>
      <div
        class="pagination-content"
        slot="center-control"
      >
        <div
          v-if="rotating"
        >
          {{ $t('Rotation mode') }}
        </div>
        <svg-icon
          iconClass="general_arrow_left"
          @click="goPreviousPage"
          size="16px"
          outerSize="32px"
          v-if="!rotating"
          :disabled="currentPageIndex <= 1"
        />
        <div
          class="page-label"
          :class="{ disabled: currentPageIndex === 0 }"
          v-if="!rotating"
        >
          <div>
            {{ currentPageIndex }}
          </div>
          /
          <div>
            {{ pageCount }}
          </div>
        </div>
        <svg-icon
          iconClass="general_arrow_right"
          @click="goNextPage"
          size="16px"
          outerSize="32px"
          v-if="!rotating"
          :disabled="currentPageIndex === pageCount"
        />
      </div>
      <div
        class="controls"
        slot="right-control"
      >
        <svg-icon
          iconClass="general_rotate"
          @click="rotate"
          size="32px"
          outerSize="48px"
          v-if="fullscreen && !rotating"
          :disabled="shouldDisableRotate"
        />
        <svg-icon
          iconClass="general_unrotate"
          @click="stopRotate"
          size="32px"
          outerSize="48px"
          v-if="rotating"
        />
        <svg-icon
          iconClass="general_fullscreen_primary"
          size="32px"
          outerSize="48px"
          v-if="!fullscreen && !rotating"
          @click="setFullscreen"
        />
        <svg-icon
          iconClass="general_unfullscreen_primary"
          size="32px"
          outerSize="48px"
          v-if="fullscreen && !rotating"
          @click="unsetFullscreen"
        />
      </div>
    </CentralPanelFooter>
    <AppDialogue
      name="layout"
      ref="layout"
      :largeContent="true"
      :closeWhenClickButton="true"
      :title="$t('Layout template')"
      :primaryText="$t('Apply')"
      @confirm="layoutDialogueConfirm"
    >
      <LayoutSwitch
        ref="ls"
        slot="content"
        :value="activeLayout"
        :hiddenLayouts="hiddenLayouts"
      />
    </AppDialogue>
  </div>
</template>
<script>
import {
  mapState, mapActions, mapGetters, mapMutations
} from 'vuex';
import exitFullScreen from '@vivotek/lib-utility/exitFullScreen';
import LAYOUT_COUNT from '@/constants/layoutCount';
import LayoutSwitch from '@/pages/liveview/components/LayoutSwitch.vue';
import AppDialogue from '@/components/ui/AppDialogue.vue';
import DisplayStreamingBlock from '@/components/DisplayStreamingBlock.vue';
import CentralPanelFooter from '@/components/CentralPanelFooter.vue';
import PageLayout from '@/components/PageLayout.vue';
import SystemApi from '@/services/SystemApi';
import { LAYOUT_COUNT_THRESHOLD, PREFER_MAINSTREAM_LIMIT } from '@/constants/layoutCountThreshold';

export default {
  name: 'ViewcellLayout',
  props: {
    leftOpened: {
      type: Boolean,
      default: true,
    },
    rightOpened: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DisplayStreamingBlock,
    PageLayout,
    AppDialogue,
    LayoutSwitch,
    CentralPanelFooter,
  },
  data() {
    return {
      LAYOUT_COUNT,
      expandedViewCellIndex: -1,
      interval: null,
      rotating: false,
      fullscreen: false,
      exitFullScreen,
      shouldDisplayFloatingFooter: false,
      displayFooterTimer: null,
      rotatePeriod: 10
    };
  },
  computed: {
    ...mapState(['scene', 'ui']),
    ...mapGetters('scene', [
      'pageCount', 'activeLayout', 'activePage', 'layoutDisplayConfig', 'hasNoViewcells', 'preferMainStream'
    ]),
    ...mapGetters('encoder', ['channelCount']),
    viewcells() {
      return this.activePage.view;
    },
    isPageEmpty() {
      return this.viewcells?.filter((viewcell) => viewcell.channel !== -1).length === 0;
    },
    shouldDisplayFooter() {
      if (!this.fullscreen && !this.rotating) {
        return true;
      }
      return this.shouldDisplayFloatingFooter;
    },
    shouldDisableExpand() {
      return this.activeLayout === '1x1';
    },
    shouldDisableRotate() {
      return this.pageCount <= 1 || this.hasNoViewcells;
    },
    activeViewIndex() {
      return this.scene.activeViewIndex;
    },
    currentPageIndex() {
      return this.pageCount ? this.scene.activePageIndex + 1 : 0;
    },
    hiddenLayouts() {
      return Object.keys(this.LAYOUT_COUNT)
        .filter((key) => this.LAYOUT_COUNT[key] > this.channelCount);
    },
    needLiteMode() {
      const validCameraCount = this.activePage
        .view.filter((viewcell) => (!viewcell.isEmpty)).length;
      return validCameraCount >= LAYOUT_COUNT_THRESHOLD;
    },
    isViewCellExpanded: {
      get() {
        return this.ui.isViewCellExpanded;
      },
      set(value) {
        this.setViewCellExpanded(value);
      }
    }
  },
  watch: {
    activeLayout() {
      this.$_resetExpandViewCell();
    },
    activePage() {
      this.$_resetExpandViewCell();
      this.$_recountPreferedStream();
    },
    activeViewIndex(prevActiveIndex) {
      // disable digitalZoom when select another view
      // workaround for pip info error when canvas is not visible
      if (this.viewcells && prevActiveIndex !== -1 && this.viewcells[prevActiveIndex]) {
        this.viewcells[prevActiveIndex].enableDigitalZoom = false;
      }
    }
  },
  methods: {
    ...mapActions('scene', ['goNextPage', 'goPreviousPage', 'selectActiveView', 'changeLayout']),
    ...mapMutations('scene', ['goNextNonEmptyPage']),
    ...mapMutations('ui', ['setViewCellExpanded']),
    $_recountPreferedStream() {
      if (!this.activePage) {
        return;
      }
      /**
       * The reason why we are doing this is because if we
       * re-config all viewcell whenever viewcell is changed
       * or layout is changed, the overhead is high.
       * So we only care about current page to set preference for view.
       */
      const layoutCount = LAYOUT_COUNT[this.activePage.layout];
      this.activePage.view.forEach((view) => {
        if (view.options.stream_index !== -1) {
          return;
        }
        if (layoutCount > PREFER_MAINSTREAM_LIMIT) {
          view.preferMainStream = false;
        } else {
          view.preferMainStream = true;
        }
      });
    },
    handleBeforeUnload() {
      Object.values(this.$refs).forEach((component) => {
        if (component && component.unload) {
          component.unload();
        }
      });
    },
    handleVisiblityChange() {
      this.retryViewcells();
    },
    handleFocus() {
      this.retryViewcells();
    },
    retryViewcells() {
      if (!document.hasFocus() && document.hidden) {
        Object.keys(this.$refs).forEach((key) => {
          if (key.startsWith('viewcell')) {
            this.$refs[key].retry();
          }
        });
      }
    },
    toggleViewCellExpand(index) {
      if (this.shouldDisableExpand) {
        return;
      }
      this.isViewCellExpanded = !this.isViewCellExpanded;
      if (this.isViewCellExpanded) {
        this.expandedViewCellIndex = index;
      } else {
        this.expandedViewCellIndex = -1;
      }
    },
    $_resetExpandViewCell() {
      this.isViewCellExpanded = false;
      this.expandedViewCellIndex = -1;
    },
    $_changeLayout() {
      this.$refs.layout.show();
    },
    setFullscreen() {
      this.fullscreen = true;
      this.$el.requestFullscreen();
      this.displayFloatingFooter();
    },
    unsetFullscreen() {
      this.fullscreen = false;
      this.exitFullScreen();
      clearTimeout(this.displayFooterTimer);
    },
    rotate() {
      this.rotating = true;
      this.setFullscreen();
      this.displayFloatingFooter();
      if (this.isPageEmpty) {
        this.goNextNonEmptyPage();
      }
      this.interval = setInterval(() => {
        this.$_rotatePage();
      }, this.rotatePeriod * 1000);
    },
    $_rotatePage() {
      this.goNextNonEmptyPage();
    },
    stopRotate() {
      window.clearInterval(this.interval);
      this.interval = null;
      this.rotating = false;
      this.shouldDisplayFloatingFooter = false;
      this.unsetFullscreen();
    },
    onFullscreenChange() {
      if (!document.fullscreenElement) {
        this.stopRotate();
      }
    },
    displayFloatingFooter($event) {
      if ((!this.rotating && !this.fullscreen)
        || ($event && $event.movementX === 0 && $event.movementY === 0)) {
        return;
      }
      clearTimeout(this.displayFooterTimer);
      this.shouldDisplayFloatingFooter = true;
      this.displayFooterTimer = setTimeout(() => {
        this.shouldDisplayFloatingFooter = false;
      }, 5000);
    },
    clickViewcell(index) {
      this.selectActiveView({ viewIndex: index, deviceIndex: this.viewcells[index]?.camera?.index });
    },
    layoutDialogueConfirm(params) {
      if (!params) {
        return;
      }
      this.changeLayout(this.$refs.ls.layout);
    },
  },
  mounted() {
    window.vl = this;
    this.$bus.$on('active-viewcell-expand', () => {
      this.toggleViewCellExpand(this.activeViewIndex);
    });
    document.addEventListener('fullscreenchange', this.onFullscreenChange);
    SystemApi.getRotatePeriod().then((value) => {
      this.rotatePeriod = value;
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn('cannot get rotate period');
    });
    window.addEventListener('focus', this.handleFocus);
    window.addEventListener('visibilitychange', this.handleVisiblityChange);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.$_recountPreferedStream();
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.handleFocus);
    window.removeEventListener('visibilitychange', this.handleVisiblityChange);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    this.$bus.$off('active-viewcell-expand');
    document.removeEventListener('fullscreenchange', this.onFullscreenChange);
  },
};
</script>
<style lang="less" scoped>
.viewcell {
  border: solid 2px black;
  display: flex;
  box-sizing: border-box;
  &.active {
    border-color: @general-active-color;
  }
}

.layout-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.controls {
  display: flex;
  > svg {
    &:hover {
      color: #2E2E2E;
    }
  }
}

.central-panel-footer {
  .icons:last-child {
    margin-left: auto;
  }
  .icons:first-child {
    margin-right: auto;
  }
}

</style>
