export default {
  encoder_id: -1,
  camera_type: 'default',
  dewarp_mode: 0,
  dewarp_preset: '',
  stream_index: -1, // range(-1,1)
  // -1: user never sets this value.
  //  0: main stream
  //  1: sub stream
  stretch: '1',
  zoom_enable: false,
  timestamp: 0,
  pip_info: {
    x: 0,
    y: 0,
    width: 1,
    height: 1
  },
  volume: 50,
  display: {
    camera_type: '1',
    camera_ip: '0',
    camera_name: '1',
    camera_time: '1',
    icon: '1',
    tmis: '1',
    vca: '1'
  }
};
