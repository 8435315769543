<template>
  <div class="slider-wrapper">
    <div
      class="block-line"
      v-if="label || hasInput"
    >
      <div
        class="label"
        :for="`${id}-slider-input`"
        v-if="label"
      >
        {{ label }}
      </div>
      <div
        class="label"
        v-if="hasValueLabel"
      >
        {{ vModel }}
      </div>
      <div
        class="box"
        v-if="hasInput"
      >
        <input
          :id="`${id}-slider-input`"
          v-model.lazy="vModel"
          maxlength="3"
          size="3"
          @focus="selectInput"
          @blur="handleBlur"
        >
      </div>
    </div>
    <div class="block-line">
      <slot name="icon" />
      <vue-slider
        :dragOnClick="true"
        v-bind="options"
        :value="vModel"
        @change="onChange"
        @drag-end="onDragEnd"
        :width="width"
        :tooltip="tooltip"
        :interval="interval"
        :disabled="disabled"
        :min="min"
        :max="max"
        :data="data"
        :adsorb="adsorb"
        :marks="marks"
        :included="included"
        :lazy="lazy"
        v-on="$listeners"
      />
    </div>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component';

export default {
  name: 'AppSlider',
  model: {
    prop: 'value',
    event: 'change',
  },
  components: {
    VueSlider,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    width: {
      type: [String],
      default: '100%',
    },
    tooltip: {
      type: String,
      default: 'none',
    },
    interval: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    data: {
      type: [Array, Object, Number],
      default: undefined,
    },
    adsorb: {
      type: Boolean,
      default: false,
    },
    included: {
      type: Boolean,
      default: false,
    },
    marks: {
      type: [Object, Boolean],
      default: undefined,
      required: false
    },
    lazy: {
      type: Boolean,
      default: false
    },
    hasInput: {
      type: Boolean,
      default: false,
    },
    hasValueLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vModel: {
      set(v) {
        if (!v || !this.checkInputTypeIsNumber(v)) {
          return;
        }
        let value = Number(v);

        if (value > this.max) {
          value = this.max;
        }
        if (value < this.min) {
          value = this.min;
        }
        this.$emit('change', value);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    selectInput(evt) {
      const { target } = evt;
      target.select();
    },
    handleBlur(evt) {
      let { value } = evt.target;
      if (!this.checkInputTypeIsNumber(value)) {
        value = this.value;
      }
      this.vModel = value;
      this.$forceUpdate();
    },
    onChange() {
      this.$emit('change', this.value);
    },
    onDragEnd() {
      this.$emit('input', this.value);
    },
    checkInputTypeIsNumber(value) {
      return !Number.isNaN(Number(value));
    },
  },
};
</script>

<style lang="less" scoped>
.slider-wrapper {
  width: 100%;
  position: relative;
}
</style>

<style lang="less">
@import (reference) '../../less/common.less';

.box {
  position: absolute;
  top: rem(7);
  right: 0;
  height: rem(8);
  padding: rem(8) rem(6);
  border: rem(1) solid #ADADAD;
  display: flex;
  align-items: center;
  input {
    height: rem(16);
    box-sizing: border-box;
    text-align: center;
    font-size: rem(12);
    color: #C2C2C2;
    background-color: transparent;
    border: none;
  }
  &:hover {
    border: rem(1) solid #FFFFFF;
    input {
      color: #FFFFFF;
    }
  }
  &:active, &:focus-within {
    border: rem(1) solid #2986FF;
    input {
      color: #FFFFFF;
    }
  }
}

.vue-slider {
  &:hover {
    .vue-slider-rail {
      background: #AEAEAE;
    }
  }
  .vue-slider-rail {
    background: #AEAEAE;
    height: 2px;
    border-radius: 0px;
    .vue-slider-process {
      background-color: #2986FF;
      border-radius: 0px;
    }
    .vue-slider-marks {
      .vue-slider-mark {
        .vue-slider-mark-step {
          width: 3px;
          height: 100%;
          background-color: #000;
        }
        .vue-slider-mark-label {
          &:extend(p,span);
          color: #E0E0E0;
          position: static;
          transform: none;
          display: flex;
          margin-top: 17px;
        }
        &:last-child {
          .vue-slider-mark-label {
            justify-content: flex-end;
          }
        }
      }
    }
    .vue-slider-dot {
      height: 12px !important;
      width: 12px !important;
      .vue-slider-dot-handle {
        border: 0px;
        background: none;
        background-image: url('../../assets/icon_status_slider_normal.svg');
        &:hover {
          background-image: url('../../assets/icon_status_slider_hover.svg');
        }
        &:active {
          background-image: url('../../assets/icon_status_slider_active.svg');
        }
      }
    }
  }
}
.vue-slider-disabled {
  &:hover {
    .vue-slider-rail {
      background: #121210;
    }
  }
  .vue-slider-rail {
    background: #121210;
    .vue-slider-process {
      background: #4F4F4F;
    }
    .vue-slider-dot {
      background-color: #4F4F4F;
      .vue-slider-dot-handle {
        border: 0px;
        background-image: url('../../assets/icon_status_slider_disable.svg');
        &:hover {
          background-image: url('../../assets/icon_status_slider_disable.svg');
        }
        &:active {
          background-image: url('../../assets/icon_status_slider_disable.svg');
        }
      }
    }
  }
}
</style>
