export default {
  DB_NAME: 'VivotekNVR',
  STORE_NAME: 'snapshot',
  MAX_AVAILABLE_TIME: 24 * 60 * 60 * 1000,
  db: null,
  getIndexedDB() {
    return window.indexedDB
           || window.mozIndexedDB
           || window.webkitIndexedDB
           || window.msIndexedDB;
  },
  getDatabase() {
    return new Promise((resolve, reject) => {
      if (this.db) {
        resolve(this.db);
        return;
      }

      const indexedDB = this.getIndexedDB();

      if (!indexedDB) {
        reject(new Error('Unsupport IndexedDB'));
        return;
      }

      const request = indexedDB.open(this.DB_NAME, 1);

      request.onerror = (event) => { // request.errorCode
        reject(new Error('Database error:', request.error));
      };
      request.onsuccess = (event) => {
        this.db = request.result;
        const objectStore = this.getObjectStore(this.db, 'readwrite');

        // remove expired snapshot
        const range = IDBKeyRange.upperBound(Date.now() - this.MAX_AVAILABLE_TIME);
        const openCursorRequest = objectStore.index('created').openCursor(range);
        openCursorRequest.onsuccess = (e) => {
          const cursor = e.target.result;
          if (!cursor) {
            return;
          }
          cursor.delete();
          cursor.continue();
        };
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;

        // Create an objectStore for this database
        const store = db.createObjectStore(this.STORE_NAME, { keyPath: 'id' });
        store.createIndex('created', 'created');
      };
    });
  },
  getObjectStore(db, option) {
    const transaction = db.transaction([this.STORE_NAME], option);
    return transaction.objectStore(this.STORE_NAME);
  },
  deleteDatabase() {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        resolve();
        return;
      }
      const indexedDB = this.getIndexedDB();

      if (!indexedDB) {
        reject(new Error('Unsupport IndexedDB'));
        return;
      }

      const request = indexedDB.deleteDatabase(this.DB_NAME);

      request.onerror = (event) => { // request.errorCode
        reject(new Error('Database error:', request.error));
      };
      request.onsuccess = (event) => {
        this.db = null;
      };
    });
  },
  addObject(key, content) {
    return this.add(key, content);
  },
  updateObject(key, content) {
    return this.getDatabase()
      .then((db) => new Promise((resolve) => {
        const objectStore = this.getObjectStore(db, 'readwrite');
        const request = objectStore.put({
          id: key, content, created: Date.now()
        });
        request.onsuccess = resolve;
        request.onerror = (event) => {
          // eslint-disable-next-line no-console
          console.warn(event.target.error);
        };
      }));
  },
  add(key, content) {
    return this.getDatabase()
      .then((db) => new Promise((resolve) => {
        const objectStore = this.getObjectStore(db, 'readwrite');
        const request = objectStore.add({
          id: key, content, created: Date.now()
        });
        request.onsuccess = resolve;
        request.onerror = (event) => {
          // eslint-disable-next-line no-console
          console.warn(event.target.error);
        };
      }));
  },
  get(key) {
    return this.getDatabase()
      .then((db) => new Promise((resolve, reject) => {
        const objectStore = this.getObjectStore(db);
        const request = objectStore.get(key);
        request.onsuccess = () => resolve(request.result);
        request.onerror = reject;
      }));
  },
  delete(key) {
    return this.getDatabase()
      .then((db) => new Promise((resolve, reject) => {
        const objectStore = this.getObjectStore(db, 'readwrite');
        const request = objectStore.delete(key);

        request.onsuccess = resolve;
        request.onerror = reject;
      }));
  }
};
