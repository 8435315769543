export default class CropCanvas {
  constructor({
    source = null,
    useOffscreenCanvas = true,
  }) {
    this.source = source;
    this.width = 128;
    this.height = 96;
    this.destination = useOffscreenCanvas ? new OffscreenCanvas(1280, 960) : document.createElement('canvas');
    this.destinationContext = this.destination.getContext('2d');
    this.$_pip = {
      x: 0,
      y: 0,
      width: 1,
      height: 1,
      ratio: 100,
    };
  }

  render(source, width, height) {
    this.resize(width, height);
    this.source = source;
    this.destinationContext.clearRect(0, 0, this.width, this.height);
    this.destinationContext.drawImage(
      this.source,
      this.$_pip.x * this.width,
      this.$_pip.y * this.height,
      this.$_pip.width * this.width,
      this.$_pip.height * this.height,
      0, 0, this.width, this.height,
    );
  }

  set pip(value) {
    if (value.cx || value.cy) {
      // use zoom to pip mode
      const {
        x, y, width, height
      } = this.$_pip;

      let newX = Math.min(Math.max(x + (width - value.width) * value.cx, 0), 1);
      let newY = Math.min(Math.max(y + (height - value.height) * value.cy, 0), 1);

      if (newX + value.width > 1) {
        newX += 1 - (newX + value.width);
      }

      if (newY + value.height > 1) {
        newY += 1 - (newY + value.height);
      }

      this.$_pip = {
        x: newX,
        y: newY,
        width: value.width,
        height: value.height,
        ratio: value.ratio,
      };
    } else {
      this.$_pip = {
        ...this.$_pip,
        ...value,
      };
    }
  }

  get pip() {
    return this.$_pip;
  }

  resize(width, height) {
    if (this.width === width && this.height === height) {
      return;
    }
    this.width = width;
    this.height = height;
    this.destination.width = width;
    this.destination.height = height;
  }

  destroy() {
    delete this.source;
    delete this.destination;
    delete this.destinationContext;
  }
}
