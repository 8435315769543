import { access } from './utility.mjs';

export default class PtzInfoManager {
  constructor(reference) {
    this.reference = reference;
    this.currentPreset = null;
    this.isTracking = false;
    this.status = '';
  }

  drawIfNecessary() {}

  parseStatus($metadata) {
    var preset = access($metadata.PTZInfoADV, "Preset", "Current");
    if (preset !== undefined && this.currentPreset !== preset) {
      this.currentPreset = preset;
      if (!this.isTracking) {
        this.reference.requestPaint();
      }
    }
    this.status = access($metadata.PTZInfo, "Status") || '';
    switch (access($metadata.PTZInfo, "Status")) {
      case "Sleep":
      case "Tracking":
        if (!this.isTracking) {
          this.isTracking = true;
          this.reference.requestPaint();
        }
        break;
      case "Waiting":
        if (this.isTracking) {
          this.isTracking = false;
          // clear cellmotion while waiting
          this.reference.cellMotionManager.clear();
          this.reference.requestPaint();
        }
        break;
      default:
        break;
      }
  }
}