const NALU_TYPE = {
  NDR: 1,
  SLICE_PART_A: 2,
  SLICE_PART_B: 3,
  SLICE_PART_C: 4,
  IDR: 5,
  SEI: 6,
  SPS: 7,
  PPS: 8,
  DELIMITER: 9,
  EOSEQ: 10,
  EOSTR: 11,
  FILTER: 12,
  STAP_A: 24,
  STAP_B: 25,
  FU_A: 28,
  FU_B: 29,
};

export default Object.freeze({
  TYPE: NALU_TYPE,
  TYPES_MAP: {
    [NALU_TYPE.IDR]: 'IDR',
    [NALU_TYPE.SEI]: 'SEI',
    [NALU_TYPE.SPS]: 'SPS',
    [NALU_TYPE.PPS]: 'PPS',
    [NALU_TYPE.NDR]: 'NDR',
  }
});
