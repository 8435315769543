<template>
  <div class="base-layout">
    <AppHeader />
    <div
      class="overlay"
      v-if="$slots.overlay"
    >
      <slot name="overlay" />
    </div>
    <div class="content">
      <NavigationBar
        :mode="mode"
      />
      <div
        class="left-menu"
        :style="{
          'flex-basis': `${currentLeftBasis}px`,
        }"
      >
        <slot name="left-menu" />
      </div>
      <div class="center-view">
        <slot
          name="center"
          :toggleLeft="toggleLeft"
          :toggleRight="toggleRight"
          :rotate="rotate"
          :leftOpened="currentLeftBasis !== 0"
          :rightOpened="currentRightBasis !== 0"
        />
      </div>
      <div
        class="right-menu"
        :style="{
          'flex-basis': `${currentRightBasis}px`,
        }"
      >
        <slot name="right-menu" />
      </div>
    </div>
    <AppDialogueManager />
    <RestartOverlay />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import NavigationBar from '@/components/NavigationBar.vue';
import AppDialogueManager from '@/components/AppDialogueManager.vue';
import RestartOverlay from '@/components/RestartOverlay.vue';

export default {
  name: 'BaseLayout',
  props: {
    mode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      RIGHT_BASIS: {
        liveview: 366,
        playback: 366,
        search: 510,
        ai: 510
      },
      LEFT_BASIS: {
        liveview: 302,
        playback: 302,
        search: 302,
        ai: 302
      },
      currentRightBasis: 0,
      currentLeftBasis: 0
    };
  },
  components: {
    AppHeader,
    NavigationBar,
    AppDialogueManager,
    RestartOverlay
  },
  methods: {
    toggleLeft() {
      if (this.currentLeftBasis !== 0) {
        this.currentLeftBasis = 0;
      } else {
        this.currentLeftBasis = this.LEFT_BASIS[this.mode];
      }
    },
    toggleRight() {
      if (this.currentRightBasis !== 0) {
        this.currentRightBasis = 0;
      } else {
        this.currentRightBasis = this.RIGHT_BASIS[this.mode];
      }
    },
    rotate() {
      this.currentLeftBasis = 0;
      this.currentRightBasis = 0;
    },
  },
  mounted() {
    this.currentLeftBasis = this.LEFT_BASIS[this.mode];
    if (this.mode === 'liveview' || this.mode === 'search') {
      this.currentRightBasis = this.RIGHT_BASIS[this.mode];
    }
  }
};
</script>

<style lang="less">
.base-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > .overlay {
    overflow-y: auto;
    z-index: 998;
    width: 100%;
    height: 100%;
    background-color: @overlay-background-color;
  }

  > .content {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;

    > .left-navigation-bar {
      flex-basis: rem(64);
    }

    > .left-menu {
      flex-basis: rem(300);
      flex-shrink: 1;
      width: 0;
      // If not set to 0, after set basis-basis to 0, the width will be sustained by its content
      transition: flex-basis 0.25s ease-in-out;
      background-color: #202020;
      border-right: rem(1) solid #000;
    }
    > .center-view {
      flex: 1;
      height: 100%;
      background-color: #928c84;
      transition: flex-basis 1s ease-in-out;
    }
    > .right-menu {
      flex-grow: 0;
      flex-shrink: 1;
      transition: flex-grow 0.25s ease-in-out;
      transition: flex-basis 0.25s ease-in-out;
      background-color: #202020;
      overflow-y: auto;
      border-left: rem(1) solid #000;
    }
  }
}

</style>
