<template>
  <div class="modal-container">
    <div class="modal-header">
      <div class="close">
        <svg-icon
          iconClass="general_cross"
          size="28px"
          @click="close"
          :disabled="shouldDisableClose"
        />
      </div>
      <div class="title">
        <h2>{{ title }}</h2>
      </div>
    </div>
    <div class="modal-content">
      <slot name="modal-content" />
    </div>
    <div class="modal-controls">
      <slot name="modal-controls" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    shouldDisableClose: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="less" scoped>
.modal-container {
  background-color: #202020;
  padding-left: rem(114);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  .modal-header {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    >.close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px;
      transform: translateX(-15px);
    }
    >.title {
      margin-top: rem(86);
      margin-bottom: rem(40);
    }
  }

  .modal-content {
    flex: 1 1 auto;
    margin-bottom: rem(20);
    overflow-y: auto;
  }

  .modal-controls {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: rem(82);
    flex: 0 0 auto;
  }
}
</style>
