import encryption from '@/utility/encryption';
import axios from 'axios';
import SystemApi from '@/services/SystemApi';

const actions = {
  async checkLoginVerified({ dispatch, getters }, payload) {
    if (!payload) {
      await dispatch('$_login');
    } else {
      const key = await dispatch('fetchKey');
      await dispatch('$_login', {
        userName: payload.userName,
        password: payload.password,
        publicKey: key
      });
    }
    await Promise.all([
      dispatch('system/fetchSystemInfo', {}, { root: true }),
      dispatch('system/fetchSystemCapability', {}, { root: true }),
      dispatch('fetchAllUsers'),
    ]);
    await dispatch('fetchTmisInfo');
    return Promise.resolve();
  },
  fetchKey() {
    const timeStamp = (new Date()).getTime();
    return axios.get(`/fcgi-bin/system.key?${timeStamp}`)
      .then(({ data }) => Promise.resolve(data)).catch((error) => Promise.reject(error));
  },
  fetchAllUsers({ commit }) {
    return axios.post('/fcgi-bin/operator/gconf.query', 'path=/system/software/raphael/user')
      .then((res) => {
        if (res.data) {
          commit('setAllUsers', res.data);
          return Promise.resolve(res.data);
        }
        return Promise.reject();
      });
  },
  fetchTmisInfo({ commit, rootGetters }, user) {
    if (!rootGetters['system/systemCapability'].support_tmis) {
      return Promise.resolve();
    }
    return SystemApi.getTmisManagerInfo()
      .then((res) => {
        if (res) {
          commit('setTmisInfo', res);
          return Promise.resolve(res);
        }
        return Promise.reject();
      });
  },
  setUserInfo({ commit, state }, { userName, payload }) {
    const userIndex = Object.keys(state.users).find((id) => state.users[id].username === userName);
    const user = state.users[userIndex];
    Object.keys(payload).forEach((key) => {
      user[key] = payload[key];
    });
    const path = '/system/software/raphael/user';
    const data = JSON.stringify(state.users);
    const params = [
      `path=${encodeURIComponent(path)}`,
      `data=${encodeURIComponent(data)}`,
    ].join('&');
    return axios.post('/fcgi-bin/operator/gconf.update', params)
      .then((res) => {
        if (res.data) {
          user.password = '';
          commit('setUserInfo', { userIndex, userInfo: user });
          return Promise.resolve();
        }
        return Promise.reject();
      });
  },
  $_login({ commit }, payload) {
    const cipherText = payload ? encryption.encrypt(payload.userName, payload.password, payload.publicKey) : '';
    return axios.post('/fcgi-bin/system.login', `encode=${cipherText}`, {
      headers: {
        Authorization: `Basic ${cipherText}`,
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    })
      .then(({ data }) => {
        // eslint-disable-next-line no-underscore-dangle
        commit('setProfile', { ...data, userName: data.username, sessionId: data._SID_ });
        return Promise.resolve(data);
      }).catch((error) => Promise.reject(error));
  },
  logout({ commit }) {
    return axios.post('/fcgi-bin/system.logout')
      .then(({ data }) => new Promise((resolve) => {
        commit('clearProfile');
        resolve();
      }))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
  restart({ commit, dispatch }) {
    return axios.post('/fcgi-bin/admin/system.reboot')
      .then(({ data }) => new Promise((resolve) => {
        dispatch('ui/waitForSystemRestart', null, { root: true });
        resolve();
      }))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }
};
export default actions;
