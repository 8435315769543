const SNAPSHOT_FETCH_MAX_COUNT = 4;

const mutations = {
  setRtspChannel(state, { rtspChannel }) {
    state.rtspChannel = rtspChannel;
  },
  async processTask(state, { task, parameters }) {
    state.queue.push({ task, parameters });
    const snapshotTask = (taskCount) => {
      const next = state.queue.shift();
      if (next) {
        next.task(next.parameters).then(() => snapshotTask(taskCount));
      } else {
        state.taskCount -= 1;
      }
    };
    if (state.taskCount < SNAPSHOT_FETCH_MAX_COUNT) {
      snapshotTask(state.taskCount);
      state.taskCount += 1;
    }
  }
};

export default mutations;
