import { isInsideThePolygon } from './utility.mjs';
import Shapes from './shapes.mjs';
import VAOMCore from './VAOM.mjs';

export default class DetectionAreaManager {
  constructor(reference, detectionArea = {}) {
    this.reference = reference;
    this.shapes = [];
    this.projection = null;
    this.core = new VAOMCore(this);
    this.handle(detectionArea);
  }

  clear() {
    this.core.clear();
  }

  drawIfNecessary(canvas, ctx) {
    let displayConfig = this.reference.displayConfig;
    this.core.draw(
      displayConfig,
      ctx,
      canvas.width,
      canvas.height,
    );
  }

  handleHumanDetectionArea(fov, fov3D, projection) {
    if (!fov3D || !fov) {
      return;
    }

    var fov2dPointList = fov.map((e) => {
      return { x: e.x / 10000, y: e.y / 10000 };
    });

    var gridCentersOfGround = [];

    var maxDistanceFromCameraCenter = Math.ceil(fov3D.reduce((a, b) => {
      return Math.max(a, Math.max(Math.abs(b.x), Math.abs(b.y)));
    }, 0) / 1000) * 1000;

    for (var cy = -maxDistanceFromCameraCenter; cy <= maxDistanceFromCameraCenter; cy += 1000) {
      for (var cx = -maxDistanceFromCameraCenter; cx <= maxDistanceFromCameraCenter; cx += 1000) {
        if (isInsideThePolygon({ x: cx, y: cy }, fov3D)) {
          var gridPointsList = [
            projection.ProjectPoint({ x: cx - 500, y: cy - 500, z: 0 }),
            projection.ProjectPoint({ x: cx + 500, y: cy - 500, z: 0 }),
            projection.ProjectPoint({ x: cx + 500, y: cy + 500, z: 0 }),
            projection.ProjectPoint({ x: cx - 500, y: cy + 500, z: 0 })
          ];
          if (gridPointsList.every((e) => {
            return isInsideThePolygon(e, fov2dPointList);
          })) {
            gridCentersOfGround.push({
              gridPointsList: gridPointsList,
              raw: { x: cx, y: cy }
            });
          }
        }
      }
    }

    var toppestPointOfGrids = gridCentersOfGround.reduce((a, b) => {
      return b.raw.y < a.y ? b.raw : a;
    }, { x: Number.POSITIVE_INFINITY, y: Number.POSITIVE_INFINITY });

    this.shapes.push(
      new Shapes.HumanDetectionArea({
        fovPointsList: gridCentersOfGround.map((e) => {
          return e.gridPointsList;
        }),
        manImg: this.reference.humanDetectionAreaManImg,
        footPoint: projection.ProjectPoint({ x: toppestPointOfGrids.x, y: toppestPointOfGrids.y, z: 0 }),
        headPoint: projection.ProjectPoint({ x: toppestPointOfGrids.x, y: toppestPointOfGrids.y, z: 1750 })
      }, this)
    );
  }

  handleVehicleDetectionArea(fov) {
    if (!fov) {
      return;
    }
    this.shapes.push(
      new Shapes.VehicleDetectionArea({
        points: fov.map(pt => new Shapes.Point(pt.x / 10000, pt.y / 10000))
      }, this),
    );
  }
  
  handle($data) {
    /* The format of area is composing from result of /VCA/Project and /VCA/Fov
      as {
        Fov: {
          Fov: [{ x, y }],
          Fov3D: [{x, y, z}],
          RunningFov: [],
          Status: Number,
          h: Number,
          w: Number,
        },
        Project: {
          
        }, 
      }
    */
    this.clear();
    if (!$data || !$data.Fov) {
      console.warn('no data provided');
      return;
    }
  
    this.handleVehicleDetectionArea($data.Fov.VehicleFov);

    if (!this.reference.projectionManager.projection && $data.Project) {
      this.reference.projectionManager.updateInitialProjection($data.Project);
    }
    const projection = this.reference.projectionManager.projection || null;
    if (!projection) {
      return;
    }
    this.handleHumanDetectionArea($data.Fov.Fov, $data.Fov.Fov3D, projection);
  }
}
