<template>
  <div
    :class="`layout ${ isCellExpanded ? 'expand-cell' : '' }`"
    :data-layout="layout"
  >
    <div
      class="cell"
      v-for="(v, i) in new Array(count).fill(0)"
      :key="i"
      v-show="!isCellExpanded || i === expandedIndex"
    >
      <slot
        name="cell"
        :index="i"
      />
    </div>
  </div>
</template>
<script>
import LAYOUT_COUNT from '@/constants/layoutCount';

export default {
  name: 'PageLayout',
  props: {
    layout: {
      type: String,
      default: '2x2',
    },
    active: {
      type: Number,
      default: 0,
    },
    isCellExpanded: {
      type: Boolean,
      default: false
    },
    expandedIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      LAYOUT_COUNT,
      count: 4,
    };
  },
  watch: {
    layout: {
      immediate: true,
      handler(value) {
        this.count = this.LAYOUT_COUNT[value];
      },
    },
  },
};
</script>

<style lang="less" scoped>
@plugin "repeat-sub";
.rotate-control {
  position: absolute;
  bottom: rem(20);
  left: rem(0);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .flex {
    background-color: black;
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
    padding: rem(5);
  }
}
div.cell {
  display: flex;
  > * {
    flex: 1;
  }
  background-color: black;
  > .active {
    border-color: @general-active-color;
  }
}
.shadow {
}
.grid(@n, @i:2) when (@i <= @n) {
  &:nth-child(@{i}) {
    grid-area: ~"sub@{i}";
  }
  .grid(@n,(@i + 1));
}
.generate-sub(@n, @i:1) when (@i <= @n) {
  @return: "sub@{.generate-sub(@n, (@i + 1))} "
}

.layout {
  background-color: #0186d1;
  width: 100%;
  height: 100% !important;
  display: grid;
  position: relative;
}

.layout.expand-cell,
.layout[data-layout="1x1"] {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "sub1";
}

.layout:not(.expand-cell) {

  &[data-layout="1M+12"] {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "sub2 sub3 sub4 sub5"
      "sub13 main main sub6"
      "sub12 main main sub7"
      "sub11 sub10 sub9 sub8";

    > div:nth-child(1) {
      grid-area: main;
    }
    > div {
      .grid(13);
    }
  }

  &[data-layout="8x8"] {
    grid-tempalte-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-tempalte-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      repeat-sub(1, 8)
      repeat-sub(9, 16)
      repeat-sub(17, 24)
      repeat-sub(25, 32)
      repeat-sub(33, 40)
      repeat-sub(41, 48)
      repeat-sub(49, 56)
      repeat-sub(57, 64);
      > div:nth-child(1) {
        grid-area: sub1;
      }
      > div {
        .grid(64);
      }
  }

  &[data-layout="1M+5"] {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "main main sub2"
      "main main sub3"
      "sub4 sub5 sub6";

    > div:nth-child(1) {
      grid-area: main;
    }
    > div {
      .grid(6);
    }
  }

  &[data-layout="1M+7"] {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "main main main sub2"
      "main main main sub3"
      "main main main sub4"
      "sub5 sub6 sub7 sub8";

    > div:nth-child(1) {
      grid-area: main;
    }
    > div {
      .grid(8);
    }
  }

  &[data-layout="1M+31"] {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "main main sub2 sub3 sub4 sub5 sub6"
      "main main sub7 sub8 sub9 sub10 sub11"
      repeat-sub(12, 18)
      repeat-sub(19, 25)
      repeat-sub(26, 32);

    > div:nth-child(1) {
      grid-area: main;
    }
    > div {
      .grid(32);
    }
  }

  &[data-layout="2x2"] {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "sub1 sub2"
      "sub3 sub4";
    > div {
      .grid(4);
    }
  }

  &[data-layout="3x3"] {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "sub1 sub2 sub3"
      "sub4 sub5 sub6"
      "sub7 sub8 sub9";
    > div {
      .grid(9);
    }
  }

  &[data-layout="1P+3"] {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "main main main"
      "main main main"
      "sub2 sub3 sub4";
    > div:nth-child(1) {
      grid-area: main;
    }
    > div {
      .grid(4);
    }
  }

  &[data-layout="1P+6"] {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "main main main"
      "sub2 sub3 sub4"
      "sub5 sub6 sub7";
    > div:nth-child(1) {
      grid-area: main;
    }
    > div {
      .grid(7);
    }
  }

  &[data-layout="2P+3"] {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "main main main"
      "main2 main2 main2"
      "sub3 sub4 sub5";
    > div:nth-child(1) {
      grid-area: main;
    }
    > div:nth-child(2) {
      grid-area: main2 !important;
    }
    > div {
      .grid(5);
    }
  }

  &[data-layout="1V+6"] {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "main sub2 sub3"
      "main sub4 sub5"
      "main sub6 sub7";
    > div:nth-child(1) {
      grid-area: main;
    }
    > div {
      .grid(7);
    }
  }

  &[data-layout="2V+3"] {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "main main2 sub3"
      "main main2 sub4"
      "main main2 sub5";
    > div:nth-child(1) {
      grid-area: main;
    }
    > div:nth-child(2) {
      grid-area: main2 !important;
    }
    > div {
      .grid(5);
    }
  }

  &[data-layout="3V"] {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      "sub1 sub2 sub3";
    > div {
      .grid(3);
    }
  }

  &[data-layout="4x4"] {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "sub1 sub2 sub3 sub4"
      "sub5 sub6 sub7 sub8"
      "sub9 sub10 sub11 sub12"
      "sub13 sub14 sub15 sub16";
    > div {
      .grid(16);
    }
  }

  &[data-layout="5x5"] {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "sub1 sub2 sub3 sub4 sub5"
      "sub6 sub7 sub8 sub9 sub10"
      "sub11 sub12 sub13 sub14 sub15"
      "sub16 sub17 sub18 sub19 sub20"
      "sub21 sub22 sub23 sub24 sub25";
    > div {
      .grid(25);
    }
  }
}
</style>
