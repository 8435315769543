<template>
  <div class="system-time-display">
    <div
      class="system-time"
      @click="toggleSystemTimeDropdown"
      v-on-clickaway="onSystemTimeDropdownClickaway"
    >
      <div class="time">
        {{ formettedTime }}
      </div>
      <div class="date">
        {{ formettedDate }}
      </div>
    </div>
    <div
      class="system-time-dropdown"
      v-if="isSystemTimeDropdownVisible"
    >
      <div class="information">
        <div class="time bold">
          {{ formettedTime }}
        </div>
        <div class="date">
          {{ formettedDate }}
        </div>
      </div>
      <div class="option-separator" />
      <a
        v-if="isAdministrator"
        class="setting-button"
        target="_new"
        href="/settings.html#!settings/settings_system_information"
      >
        {{ $t('Date and time settings') }}
      </a>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import { mapGetters, mapActions } from 'vuex';
import DateFormatter from '@/utility/format_date';

export default {
  name: 'SystemTimeDisplay',
  data() {
    return {
      timeoffset: 0, // offset between systemTime and Date.now()/1000
      currentTimestamp: 0, // Unix time in seconds
      nextTimeZoneProbeTimeout: null,
      probingTimezoneInterval: null,
      pollingCounter: null,
      PROBING_TIMEOUT: 10000,
      PROBING_COUNT_PER_HOUR: 3,
      isSystemTimeDropdownVisible: false
    };
  },
  directives: {
    onClickaway,
  },
  computed: {
    ...mapGetters('user', ['isAdministrator']),
    ...mapGetters('system', ['systemTime', 'systemTimezone']),
    localTimestamp() {
      return this.currentTimestamp - this.systemTimezone;
    },
    formettedTime() {
      return DateFormatter.localeUTCTime(this.localTimestamp);
    },
    formettedDate() {
      return DateFormatter.localeUTCDate(this.localTimestamp);
    },
    nextProbingTime() {
      const timePinterMs = this.localTimestamp * 1000;
      const fiftyNine = new Date(timePinterMs);
      const hour = fiftyNine.getUTCHours();
      fiftyNine.setUTCHours(hour, 59, 40, 0);

      let delta = fiftyNine.getTime() - timePinterMs;
      const oneHour = 60 * 60 * 1000;
      if (delta < 0) {
        delta += oneHour;
      } else if (delta > oneHour) {
        delta -= (oneHour);
      }
      return delta;
    },
  },
  methods: {
    ...mapActions('system', ['fetchSystemTime']),
    setTimeZoneProbing() {
      if (this.nextTimeZoneProbeTimeout) {
        clearTimeout(this.nextTimeZoneProbeTimeout);
      }
      this.nextTimeZoneProbeTimeout = setTimeout(this.probingTimezone, this.nextProbingTime);
    },
    probingTimezone() {
      if (this.probingTimezoneInterval) {
        clearInterval(this.probingTimezoneInterval);
      }
      const oneHour = 60 * 60 * 1000;
      this.pollingCounter = 0;
      this.probingTimezoneInterval = setInterval(this.pollingTimezone, this.PROBING_TIMEOUT);
      setTimeout(this.probingTimezone, oneHour);
    },
    pollingTimezone() {
      this.pollingCounter += 1;
      if (this.pollingCounter > this.PROBING_COUNT_PER_HOUR) {
        if (this.probingTimezoneInterval) {
          clearInterval(this.probingTimezoneInterval);
          delete this.probingTimezoneInterval;
        }
        return Promise.resolve();
      }

      return this.fetchSystemTime();
    },
    updateTimeOffset() {
      const oldOffset = this.timeoffset;
      this.currentTimestamp = this.systemTime;
      this.timeoffset = this.currentTimestamp - (new Date().getTime()) / 1000;

      if (Math.abs(oldOffset - this.timeoffset) > 5) {
        this.setTimeZoneProbing();
      }
    },
    toggleSystemTimeDropdown() {
      this.isSystemTimeDropdownVisible = !this.isSystemTimeDropdownVisible;
    },
    onSystemTimeDropdownClickaway() {
      this.isSystemTimeDropdownVisible = false;
    },
  },
  watch: {
    systemTime() {
      this.updateTimeOffset();
    }
  },
  mounted() {
    this.fetchSystemTime().then(() => {
      setInterval(() => {
        this.currentTimestamp = this.timeoffset + (new Date().getTime()) / 1000;
      }, 1000);
    });
  },
};
</script>

<style lang="less" scoped>
.system-time-display {
  font-size: rem(12);
  color: #FFFFFF;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 rem(8);
  margin-right: rem(15);

  .system-time {
    cursor: pointer;
  }
  .system-time-dropdown {
    position: absolute;
    top: rem(0);
    right: rem(15);
    min-width: rem(280);
    background: #2E2E2E;
    border: 1px solid #000000;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.30);;
    z-index: 999;
    transform: translateY(rem(48)) translateX(0);
    .information {
      padding: rem(18) rem(24);
      text-align: left;
      font-size: rem(14);
      > .date {
        margin-top: rem(10);
      }
    }

    .setting-button {
      display: block;
      margin: rem(10) 0;
      padding-left: rem(24);
      line-height: rem(54);
      text-align: left;
      text-decoration: none;
      font-size: rem(14);
      color: #FFFFFF;
      &:hover {
        background-color: #3E3E3E;
      }
    }
  }
}
</style>
