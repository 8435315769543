import axios from 'axios';

export default {
  turnOffBuzzer() {
    return axios.post('/fcgi-bin/admin/system.buzzer', 'target=Buzzer&action=Stop&times=1')
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
        return Promise.reject();
      });
  },
};
