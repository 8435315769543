<template>
  <div
    class="restart-overlay"
    v-if="showSystemRestartModal"
  >
    <div class="spinning restart-icon" />
    <h6 class="bold">
      {{ $t('Restarting') }}
    </h6>
    <span>{{ $t('System will restart in a few minutes.') }}</span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AppRestartOverlay',
  computed: {
    ...mapState(['ui']),
    showSystemRestartModal() {
      return this.ui.overlay.showSystemRestartModal;
    }
  },
  watch: {
    showSystemRestartModal() {
      setTimeout(() => {
        this.checkServerBeingReadyByPolling();
      }, 30000);
    },
  },
  methods: {
    ...mapActions('user', ['fetchKey']),
    checkServerBeingReadyByPolling() {
      const interval = 4000;
      const id = setInterval(() => {
        this.fetchKey()
          .then(() => {
            clearInterval(id);
            location.reload();
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.warn('polling', error);
          });
      }, interval);
    },
  },
};
</script>

<style lang="less" scope>
.restart-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 32, 32, 0.7);
  > .restart-icon {
    width: rem(100);
    height: rem(100);
    background-image: url("../assets/illustration_restart.svg");
    &.spinning {
      animation-duration: 3000ms;
    }
  }
  h6.bold {
    margin-bottom: rem(10);
  }
}
</style>
