import DigitalZoomProcessor from './DigitalZoomProcessor';
import JoystickProcessor from './JoystickProcessor';

const EVENT_PROCESSORS = {
  joystick: JoystickProcessor,
  draggable: DigitalZoomProcessor,
};

export default class MouseEventPreprocessor {
  constructor() {
    this.handlers = {};

    Object.entries(EVENT_PROCESSORS).forEach(([name, ProcessorCls]) => {
      this.addHandler(name, new ProcessorCls());
    });
  }

  addHandler(name, processor, enable = false) {
    this.handlers[name] = {
      processor,
      enable,
    };
  }

  removeHandler(name) {
    delete this.handlers[name];
  }

  setHandlerEnable(name, enable = true) {
    if (this.handlers[name]) {
      this.handlers[name].enable = enable;
    }
  }

  setHandlerDisable(name) {
    this.setHandlerEnable(name, false);
  }

  resize(w, h) {
    Object.values(this.handlers).forEach(({ processor }) => processor.resize
      && processor.resize(w, h));
  }

  render(w, h) {
    Object.values(this.handlers).forEach(({ processor }) => processor.render
      && processor.render(w, h));
  }

  handleEvent(evt, ref) {
    const handlers = Object.values(this.handlers).filter(({ enable }) => enable);

    return !!handlers.length && handlers.every(({ processor }) => processor.handleEvent
      && processor.handleEvent(evt, ref));
  }
}
