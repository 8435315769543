import { access, unpack } from './utility.mjs';
import Shapes from './shapes.mjs';
import VAOMCore from './VAOM.mjs';

export default class MissingObjectManager {
  constructor(reference) {
    this.reference = reference;
    this.shapes = []; // Currently this is data not real instances of Shapes.
    this.core = new VAOMCore(this);
  }

  drawIfNecessary(canvas, ctx) {
    // XXX: have missingObject shape
    this.core.draw(true,
      ctx,
      canvas.width,
      canvas.height,
    );
  }

  clear() {
    this.core.clear();
  }

  update(shapes) {
    this.core.updateShapes(shapes);
  }
}
