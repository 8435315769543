import { unpack, access, isPoint, toPoint, fromSimpleItem, isRuleName, isObjectId, isNumber } from './utility.mjs';
import Shapes from './shapes.mjs';

export default class MetadataParserVerXML {
  constructor(reference, parent) {
    this.reference = reference;
    this.parent = parent;
  }

  parseProjection(ttMetaDataStream) {
    const { reference } = this;
    if (!reference.initialProjection) {
      var p = access(ttMetaDataStream, "tt:VideoAnalytics", "Project");
      if (["@fx", "@fy", "@cx", "@cy", "@k1", "@k2", "@k3", "@k4", "@IsFishEye", "@ORGWidth", "@ORGHeight", "@CamHeight", "@TiltAngle"].every(isNumber.bind(p))) {
        reference.projectionManager.update(new Shapes.Project({
          M1: [[Number(p["@fx"]), 0, Number(p["@cx"])],
            [0, Number(p["@fy"]), Number(p["@cy"])],
            [0, 0, 1]],
          D1: [Number(p["@k1"]), Number(p["@k2"]), Number(p["@k3"]), Number(p["@k4"]),
            Number([p["@k5"]]), Number([p["@k6"]]), Number([p["@k7"]]), Number([p["@k8"]]),
            Number([p["@k9"]]), Number([p["@k10"]]), Number([p["@k11"]]), Number([p["@k12"]])],
          CamHeight: Number(p["@CamHeight"]),
          TiltAngle: Number(p["@TiltAngle"]),
          RollAngle: Number([p["@RollAngle"]]),
          ORGHeight: Number(p["@ORGHeight"]),
          ORGWidth: Number(p["@ORGWidth"]),
          IsFishEye: Number(p["@IsFishEye"])
        }, reference.projectionManager));
      }
    }
    let projection = reference.projectionManager.projection;
    if (projection) {
      unpack(access(ttMetaDataStream, "tt:VideoAnalytics", "Frame")).slice(0, 1).forEach((f) => {
        var rectangles = unpack(f.Object).filter((o) => {
          return ["@x", "@y"].every(isNumber.bind(o.Centroid)) && ["@Height", "@Id"].every(isNumber.bind(o)) && ["@x", "@y"].every(isNumber.bind(o.Origin));
        }).map((o) => {
          return new Shapes.BoundingBox3D({
            id: Number(!isNaN(o["@GId"]) ? o["@GId"] : o["@Id"]),
            height: Number(o["@Height"]),
            origin: projection.ProjectPoint({ x: Number(o.Origin["@x"]), y: Number(o.Origin["@y"]), z: 0 }),
            peak: projection.ProjectPoint({ x: Number(o.Centroid["@x"]), y: Number(o.Centroid["@y"]), z: Number(o["@Height"]) * 2 / 3 }),
            points: [
              projection.ProjectPoint({ x: o.Centroid["@x"] - 180, y: o.Centroid["@y"] - 180, z: 0 }),
              projection.ProjectPoint({ x: Number(o.Centroid["@x"]) + 180, y: o.Centroid["@y"] - 180, z: 0 }),
              projection.ProjectPoint({ x: Number(o.Centroid["@x"]) + 180, y: Number(o.Centroid["@y"]) + 180, z: 0 }),
              projection.ProjectPoint({ x: o.Centroid["@x"] - 180, y: Number(o.Centroid["@y"]) + 180, z: 0 }),
              projection.ProjectPoint({ x: o.Centroid["@x"] - 180, y: o.Centroid["@y"] - 180, z: Number(o["@Height"]) }),
              projection.ProjectPoint({ x: Number(o.Centroid["@x"]) + 180, y: o.Centroid["@y"] - 180, z: Number(o["@Height"]) }),
              projection.ProjectPoint({ x: Number(o.Centroid["@x"]) + 180, y: Number(o.Centroid["@y"]) + 180, z: Number(o["@Height"]) }),
              projection.ProjectPoint({ x: o.Centroid["@x"] - 180, y: Number(o.Centroid["@y"]) + 180, z: Number(o["@Height"]) })
            ]
          }, reference.boundingBoxManager);
        });
        reference.boundingBoxManager.update(rectangles);
      });
    }
  }

  parseEvent(ttMetaDataStream) {
    const { reference } = this;
    var ttEvent = access(ttMetaDataStream, "tt:Event");
    if (ttEvent !== undefined) {
      for (var prop in ttEvent) {
        switch (prop)
        {
        case "Counting":
          unpack(ttEvent["Counting"]).forEach((counting) => {
            // XXX: put this in MotionCanvas
            refernece.eventInterface.dispatch('accumulative', counting["@RuleName"], Number(counting["@In"]) || 0, Number(counting["@Out"]) || 0);
          });
          break;
        }
      }
    }
  }

  parseVideoAnalytics(ttMetaDataStream) {
    const { reference } = this;
    unpack(access(ttMetaDataStream, "tt:VideoAnalytics", "tt:Frame")).slice(0, 1).forEach((ttFrame) => {
      var rectangles = [];
      var ttScale = unpack(access(ttFrame["tt:Transformation"], "tt:Scale")).slice(0, 1).filter(isPoint).pop();
      unpack(ttFrame["tt:Object"]).filter((ttObject) => {
        return !isNaN(ttObject["@ObjectId"]);
      }).forEach((ttObject) => {
        var ttShape = access(ttObject["tt:Appearance"], "tt:Shape");
        var ttExtension = access(ttShape, "tt:Extension");
        var ttCoordinate = access(ttExtension, "tt:RectifiedImageCoordinate");
        if (ttCoordinate !== undefined) {
          var ttHeight = ttExtension["tt:Height"];
          var ttOrigin = ttCoordinate["tt:Origin"];
          var ttPeak = ttCoordinate["tt:Peak"];
          if (!isNaN(ttHeight) && ttOrigin !== undefined && isPoint(ttOrigin) && ttPeak !== undefined && isPoint(ttPeak)) {
            var points = unpack(access(ttCoordinate["tt:BoundingBox3D"], "tt:Point")).filter(isPoint).map(toPoint);
            if (points.length == 8) {
              rectangles.push(new Shapes.BoundingBox3D({
                id: Number(ttObject["@ObjectId"]),
                height: Number(ttHeight),
                origin: toPoint(ttOrigin),
                peak: toPoint(ttPeak),
                points
              }, reference.boundingBoxManager));
            }
          }
        } else if (ttScale !== undefined) {
          var scaleX = Math.abs(parseFloat(ttScale["@x"]));
          var scaleY = Math.abs(parseFloat(ttScale["@y"]));
          var ttBoundingBox = access(ttShape, "tt:BoundingBox");
          if (["@left", "@top", "@right", "@bottom"].every((elem) => {
              return !isNaN(access(ttBoundingBox, elem));
            })) {
            var objectId = Number(ttObject["@ObjectId"]);
            rectangles.push(new Shapes.BoundingBox({
              id: objectId,
              points: [
                new Shapes.Point(ttBoundingBox["@left"] * scaleX, ttBoundingBox["@top"] * scaleY),
                new Shapes.Point(ttBoundingBox["@right"] * scaleX, ttBoundingBox["@top"] * scaleY),
                new Shapes.Point(ttBoundingBox["@right"] * scaleX, ttBoundingBox["@bottom"] * scaleY),
                new Shapes.Point(ttBoundingBox["@left"] * scaleX, ttBoundingBox["@bottom"] * scaleY)
              ],
              color: Shapes.getColorById(objectId)
            }, reference.boundingBoxManager));
          }
        }
      });
      reference.boundingBoxManager.update(rectangles);
    });
  }

  parseNotification(ttMetaDataStream) {
    const { reference } = this;
    unpack(access(ttMetaDataStream, "tt:Event", "wsnt:NotificationMessage")).forEach((wsntNotificationMessage) => {
      var ttMessage = access(wsntNotificationMessage["wsnt:Message"], "tt:Message");
      if (ttMessage !== undefined) {
        switch (access(wsntNotificationMessage["wsnt:Topic"], "#text"))
        {
        case "tns1:RuleEngine/FieldDetector/VVTK_ObjectsInside":
          var ttData = unpack(access(ttMessage["tt:Data"], "tt:SimpleItem")).reduce(fromSimpleItem, {});
          if (ttData.IsInside === "true") {
            unpack(access(ttMessage["tt:Source"], "tt:SimpleItem"))
              .filter(isRuleName)
              .map((ttSimpleItem) => (ttSimpleItem['@Value']))
              .forEach((name) => {
                reference.eventInterface.dispatch('objectIsInside', name);
              });
          }
          break;
        case "tns1:RuleEngine/LineDetector/VVTK_Crossed":
          unpack(
            access(wsntNotificationMessage["wsnt:Message"], "tt:Message", "tt:Source", "tt:SimpleItem")
          ).filter(isRuleName).map((ttSimpleItem) => (ttSimpleItem["@Value"])).forEach((name) => {
            reference.eventInterface.dispatch('crossed', name);
          });
          break;
        case "tns1:RuleEngine/CountAggregation/VVTK_Accumulative":
          var ttData = unpack(access(ttMessage["tt:Data"], "tt:SimpleItem")).reduce(fromSimpleItem, {});
          unpack(access(ttMessage["tt:Source"], "tt:SimpleItem")).filter(isRuleName).forEach((ttSimpleItem) => {
            reference.eventInterface.dispatch('accumulative', ttSimpleItem["@Value"], Number(ttData.In) || 0, Number(ttData.Out) || 0);
          });
          unpack(access(ttMessage["tt:Key"], "tt:SimpleItem")).filter(isRuleName).forEach((ttSimpleItem) => {
            reference.eventInterface.dispatch('accumulative', ttSimpleItem["@Value"], Number(ttData.In) || 0, Number(ttData.Out) || 0);
          });
          break;
        case "tns1:RuleEngine/LoiteringDetector/VVTK_ObjectIsLoitering":
          var propertyOperation = ttMessage["@PropertyOperation"];
          if (propertyOperation === "Deleted") {
            unpack(
              access(ttMessage["tt:Key"], "tt:SimpleItem")
            ).filter(isObjectId).map(
              (ttSimpleItem) => Number(ttSimpleItem["@Value"])
            ).forEach((id) => {
              reference.eventInterface.dispatch('setLoitering', id, false);
            });
          } else {
            unpack(
              access(ttMessage["tt:Source"], "tt:SimpleItem")
            ).filter(isRuleName).map((ttSimpleItem) => ttSimpleItem["@Value"]).forEach((name) => {
              reference.eventInterface.dispatch('isLoitering', name);
            });
            if (propertyOperation === "Initialized") {
              unpack(
                access(ttMessage["tt:Source"], "tt:SimpleItem")
              ).filter(isRuleName).map((ttSimpleItem) => ttSimpleItem["@Value"]).forEach((name) => {
                reference.eventInterface.dispatch('setLoitering', name, true);
              })
            }
          }
          break;
        case "tns1:VideoAnalytics/tnsvca:CrowdAlarm":
          unpack(
            access(ttMessage["tt:Data"], "tt:ElementItem", "ivs:DetectAreaReference")
          ).map((ivsDetectAreaReference) => (ivsDetectAreaReference["#text"])).forEach((name) => {
            reference.eventInterface.dispatch('crowdAlarm', name);
          });
          break;
        }
      }
    });
  }

  parse($metadata) {
    var ttMetaDataStream = $metadata["tt:MetadataStream"] || $metadata["tt:MetaDataStream"];
    if (!ttMetaDataStream) {
      return;
    }

    if (this.reference.displayConfig.ignoreVideoAnalytics) {
      delete ttMetaDataStream["tt:VideoAnalytics"];
    }

    this.parseVideoAnalytics(ttMetaDataStream);
    this.parseProjection(ttMetaDataStream);
    this.parseNotification(ttMetaDataStream);
    this.parseEvent(ttMetaDataStream);
  }
}