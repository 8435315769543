/* eslint-disable class-methods-use-this */
import VCARuleService from '../services/VCARuleService';

export default class Encoder {
  constructor(encoderConfig, channel) {
    this.options = encoderConfig;
    this.data = {};
    this.rule = [];
    this.recording_event = {
      pre_event: 0,
      post_event: 0,
      trigger_interval: 0,
    };
    if (typeof channel === 'number') {
      this.index = channel; // Record n-th in the list, maybe not put here.
      this.channel = channel;
    }
    return this;
  }

  set props(params) {
    this.options[params.key] = params.value;
  }

  get name() {
    return this.options.name;
  }

  get address() {
    return this.options.address;
  }

  /** Recording duration, unit is second */
  get eventRecordingDuration() {
    return this.recording_event.pre_event + this.recording_event.post_event;
  }

  get displayName() {
    return `${(`0${Number(this.index) + 1}`).slice(-2)} - ${this.options.name}`;
  }

  // Backward compatibility
  get status() {
    return this.options.status;
  }

  get isCameraOffline() {
    return this.options.status === 'CAM_OFFLINE';
  }

  get maxSpeed() {
    const panSpeed = this.setting?.uid_ptz_pan_speed_capability?.CurrentOpt;
    const tiltSpeed = this.setting?.uid_ptz_tilt_speed_capability?.CurrentOpt;
    // use default speed level 0~7
    let maxSpeed = 7;

    if (panSpeed || tiltSpeed) {
      const valArr = [panSpeed, tiltSpeed].map((val) => Number(val, 10))
        .filter((val) => !isNaN(val));
      maxSpeed = Math.max(...valArr);
    }
    return maxSpeed;
  }

  get cameraTypeString() {
    if (this.isPTZ) {
      return 'ptz';
    }
    if (this.isFisheye) {
      return 'fisheye';
    }
    return 'default';
  }

  get generalIconClass() {
    if (this.isRecording && this.options.status === 'CAM_ACTIVE') {
      return `status_camera_${this.cameraTypeString}_recording`;
    }
    if (this.options.status === 'CAM_OFFLINE') {
      return `status_camera_${this.cameraTypeString}_disconnected`;
    }
    return `status_camera_${this.cameraTypeString}`;
  }

  get isManualRecording() {
    return this.recording_status && this.recording_status.manual === 'ON';
  }

  get isRecording() {
    return !this.recording_status ? false : ['manual', 'state'].map((type) => this.recording_status[type])
      .some((recordingStatus) => recordingStatus === 'ON');
  }

  get isAvailable() {
    return this.options.status !== 'CAM_FILTER' && this.options.status !== 'CAM_EMPTY';
  }

  get extModel() {
    return this.options.ext_model;
  }

  get isOnline() {
    return this.options.status === 'CAM_ACTIVE';
  }

  get isOffline() {
    return this.options.status === 'CAM_OFFLINE';
  }

  get isFisheye() {
    return this.options.enable_fisheye === 1;
  }

  get isVCA() {
    return this.options.vca !== 0;
  }

  get displayCapability() {
    return {
      tmis: this.options.event_properties !== undefined && this.options.event_properties.length !== 0,
      vca: this.options.vca !== undefined && this.options.vca !== 0
    };
  }

  get isPTZ() {
    if (this.setting?.uid_ptz_buildin_zoom?.CurrentOpt === '1') {
      return true;
    }

    /*
      bit 2: PAN
      bit 3: TILT
      bit 4: ZOOM
      bit 7: EXTERNAL PTZ
    */
    const { camctrl, isptz } = this.options;
    // eslint-disable-next-line no-bitwise
    if (camctrl & 128) {
      return isptz > 0;
    }

    // eslint-disable-next-line no-bitwise
    return (((camctrl & 4) && (camctrl & 8)) || (camctrl & 16)) !== 0;
  }

  get mainStream() {
    return this.options.main_stream || 0;
  }

  get subStream() {
    return this.options.sub_stream || 1;
  }

  get h264StreamIndex() {
    const streamCount = this.options.stream_count;
    if (streamCount === 1) {
      return 0;
    }
    if (!this.setting) {
      return 0;
    }

    /**
     * Stream 0 is usually the detailed stream with most throughput, so we search from stream 1.
    */
    for (let idx = 1; idx < streamCount; idx += 1) {
      const codec = this.setting[`uid_s${idx}_video_codec`]?.CurrentOpt;
      if (codec === 'H.264') {
        return idx;
      }
    }
    return this.setting?.uid_s0_video_codec?.CurrentOpt === 'H.264' ? 0 : 1;
  }

  get isSupportedByDevicePack() {
    return this.options.device_pack_supported !== '';
  }

  queryPlaybackVCARule(index, timestamp) {
    return VCARuleService.getPlaybackVCARule(index, timestamp).then((result) => {
      const rule = Object.keys(result).map((key) => JSON.parse(result[key]))[0];
      this.rule = rule;
    });
  }

  fetchPlaybackRule(timestamp, channel) {
    const index = this.index || channel;

    return this.queryPlaybackVCARule(index, timestamp).catch((err) => {
      // fix for query rules before header mounted
      if (err.toString() === 'Error: WebSocket Not Connect') {
        return new Promise((resolve) => {
          setTimeout(() => resolve(this.queryPlaybackVCARule(index, timestamp)), 2000);
        });
      }

      return Promise.reject(err);
    });
  }

  fetchLiveviewRule() {
    if (!this.isVCA) {
      this.rule = [];
      return Promise.resolve();
    }

    return VCARuleService.getLiveviewVCARule(this.index).then((result) => {
      this.rule = result;
    });
  }
}
