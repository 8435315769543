/* eslint-disable no-console */
import NaluParser from './NaluParser';

class NaluAssemBler {
  constructor() {
    this.naluParser = new NaluParser();
  }

  onNALUFragment(rtp) {
    const rawData = rtp.getPayload();
    const dts = rtp.getTimestampMS();
    const applicationData = rtp.getApplicationData();

    const fragment = {
      rawData,
      dts,
      applicationData
    };

    if (rtp.encodingName === 'H264') {
      return this.naluParser.parseFragment(fragment);
    }
    console.error('rtp encoding type is not supported');
    return null;
  }
}

export default NaluAssemBler;
