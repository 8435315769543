<template>
  <div
    class="display-view-cell-header"
    @dblclick.stop
  >
    <div class="viewcell-info">
      <div
        class="camera-icon"
        v-if="shouldDisplayCameraType"
      >
        <svg-icon
          class="icon"
          :iconClass="viewcell.camera.generalIconClass"
          size="26px"
        />
      </div>
      <div
        v-if="shouldDisplayCameraName"
        :title="cameraName"
        class="camera-name"
      >
        <h6 class="bold">
          {{ cameraName }}
        </h6>
      </div>
      <div
        v-if="shouldDisplayCameraIp"
        class="camera-name"
      >
        <h6 class="bold">
          {{ cameraIp }}
        </h6>
      </div>
    </div>
    <div class="viewcell-control">
      <div
        v-if="shouldDisplayCameraTime && viewcell.displayFullTime"
        class="camera-time"
      >
        {{ viewcell.displayFullTime }}
      </div>
      <div
        class="control-group"
        v-show="!simpleMode"
      >
        <SvgIcon
          class="icon"
          iconClass="general_capture_line"
          size="26px"
          :disabled="shouldDisableViewcellControl"
          @click="saveSnapshot"
        />
        <SvgIcon
          v-show="shouldShowStatusIcon"
          class="icon"
          iconClass="general_show"
          size="26px"
          :disabled="shouldDisableViewcellControl"
          @click="hideStatus"
        />
        <SvgIcon
          v-show="!shouldShowStatusIcon"
          class="icon"
          iconClass="general_hide"
          size="26px"
          :disabled="shouldDisableViewcellControl"
          @click="showStatus"
        />
        <SvgIcon
          v-if="!isViewCellExpanded"
          class="icon"
          iconClass="general_fullscreen_secondary"
          size="26px"
          :disabled="shouldDisableViewcellControl || shouldDisableExpand"
          @click="toggleViewCellExpand"
        />
        <SvgIcon
          v-if="isViewCellExpanded"
          class="icon"
          iconClass="general_unfullscreen_secondary"
          size="26px"
          :disabled="shouldDisableViewcellControl || shouldDisableExpand"
          @click="toggleViewCellExpand"
        />
        <SvgIcon
          class="icon"
          iconClass="general_more"
          size="26px"
          :disabled="shouldDisableControlToggler"
          @click="toggleControlPanel"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisplayStatusBar',
  props: {
    viewcell: {
      type: Object,
      default: () => {},
    },
    shouldDisableExpand: {
      type: Boolean,
      default: false,
    },
    shouldDisableControlToggler: {
      type: Boolean
    },
    globalDisplayConfig: {
      type: Object,
      default: null,
    },
    isViewCellExpanded: {
      type: Boolean,
      default: false,
    },
    simpleMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldShowStatusIcon() {
      return this.viewcell.shouldShowStatusIcon;
    },
    camera() {
      return this.viewcell.camera;
    },
    cameraName() {
      return this.camera.name;
    },
    cameraIp() {
      return this.camera.address;
    },
    displayConfig() {
      return this.viewcell.displayConfig;
    },
    shouldDisplayCameraType() {
      return this.globalDisplayConfig
        ? this.globalDisplayConfig.cameraType && this.displayConfig.cameraType
        : this.displayConfig.cameraType;
    },
    shouldDisplayCameraName() {
      // XXX: Bad practice. Need refine
      return this.globalDisplayConfig
        ? this.globalDisplayConfig.cameraName && this.displayConfig.cameraName
        : this.displayConfig.cameraName;
    },
    shouldDisplayCameraIp() {
      return this.globalDisplayConfig
        ? this.globalDisplayConfig.cameraIp && this.displayConfig.cameraIp
        : this.displayConfig.cameraIp;
    },
    shouldDisplayCameraTime() {
      if (this.viewcell.status !== 'PLAYING' && this.viewcell.status !== 'PAUSED') {
        return false;
      }
      return this.globalDisplayConfig
        ? this.globalDisplayConfig.cameraTime && this.displayConfig.cameraTime
        : this.displayConfig.cameraTime;
    },
    shouldDisableViewcellControl() {
      return this.viewcell.mode === 'liveview' && this.camera.status === 'CAM_OFFLINE';
    }
  },
  methods: {
    showStatus() {
      this.viewcell.options.display.icon = '1';
    },
    hideStatus() {
      this.viewcell.options.display.icon = '0';
    },
    toggleControlPanel() {
      this.$bus.$emit('toggle-control-panel');
    },
    toggleViewCellExpand() {
      this.$emit('toggleViewCellExpand');
    },
    saveSnapshot() {
      this.$emit('saveSnapshot');
    },
  }
};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 1366px){
  .display-view-cell-header {
    .camera-icon, .control-group {
      display: none !important;
    }
  }
}

.display-view-cell-header {
  height: rem(26);
  display: flex;
  justify-content: space-between;
  background-color: #121212;
  .viewcell-info {
    display: flex;
    line-height: rem(26);
    flex: 1;
    flex-wrap: wrap;
    .svg-icon {
      margin-left: rem(8);
    }
    .camera-name {
      margin-left: rem(8);
      overflow: hidden;
      flex: 1;
      font-size: rem(14);
      color: #FFFFFF;
      max-width: rem(150);
      height: rem(26);
      > h6 {
        line-height: rem(26);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .viewcell-control {
    display: flex;
    justify-items: right;
    align-items: center;
    .camera-time {
      margin-right: rem(8);
      font-size: 14px;
      color: #ADADAD;
      white-space:nowrap;
    }
    .control-group {
      display: flex;
      justify-items: right;
      align-items: center;
      .svg-icon {
        color: #E0E0E0;
        margin-right: rem(8);
      }
    }
  }
}

</style>
