export default function postMessage(data, instance) {
  try {
    if (data.config) {
      instance.config(data.config, data.value);
    } else if (data.command) {
      instance[data.command](...data.arguments);
    } else if (data.dispatch) {
      instance.handleEvent(data.event);
    }
  } catch (e) {
    console.trace();
    console.log('error', data);
    console.log(e);
  }
}
