import axios from 'axios';

export default {
  saveBookMark({
    encoderId, cameraName, content, start = 0
  }) {
    return axios.post(
      '/fcgi-bin/operator/bookmark.add', `channel=${encoderId}&camera_name=${cameraName}&start=${start * 1000}&content=${content}`,
    ).then((res) => {
      if (res.status === 200 && res.data) {
        return Promise.resolve();
      }
      return Promise.reject();
    });
  }
};
