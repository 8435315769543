/* eslint-disable class-methods-use-this */
import RecordingApi from '@/services/RecordingApi';
import AlarmApi from '@/services/AlarmApi';
import EventApi from '@/services/EventApi';
import PLUGINFREE from '@/constants/pluginfree';
import LocalTime from '@/utility/local_time';

let id = 0;

const QUERY_INTERVAL = 86400000;

const SUPPORT_VIDEO_CODEC_MAP = {
  H264: 'H.264',
  HEVC: 'H.265'
};

export default class Viewcell {
  constructor(options, camera) {
    this.options = options;
    this.lastVolume = 0;
    this.camera = camera;
    this.dewarpInfo = {
      isValid: false,
      isLocalDewarped: false,
    };
    this.dewarpPreset = '';
    this.playTime = {
      timestamp: 0,
      tzoffs: 0,
    };
    this.$_cameraTime = new LocalTime(0).useUniTimezone();
    this.recording_data = [];
    this.alarm_data = [];
    this.bookmark_data = [];
    this.vca_data = [];
    this.peopleDetectionArea = null;
    this.$_elapsedTime = 0;
    this.startingPointBySec = 0; // XXX: if we specify play again, getCurrentTime will become 0.
    // We need this to adjust ellpased time.
    this.$_status = 'STOPPED'; // playing status
    this.recordingQueried = false;
    this.codec = '';
    this.frameRate = 0;
    this.hasAudioCapability = false;
    this.isFisheye = false;
    this.preferMainStream = true;
    this.resolution = {
      width: 0,
      height: 0,
    };

    id += 1;
    this.$_id = id;
    this.isOutOfScope = false;

    this.resetPlaybackRate();
    return this;
  }

  set status(value) {
    if (value !== 'PLAYING' && value !== 'PAUSED') { // reset camera time
      this.$_cameraTime.timestamp = 0;
      this.$_cameraTime.tzoffs = 0;
    }
    this.$_status = value;
  }

  get status() {
    return this.$_status;
  }

  get noRecording() {
    return !this.recording_data || this.recording_data.length === 0;
  }

  /**
   * This is guessing from options.
   */
  get mode() {
    if ('clipMode' in this.options) {
      return 'playback';
    }
    return 'liveview';
  }

  get shouldShowCameraOffline() {
    // Only when live streaming mode we need to know camera is offline.
    return this.camera?.isOffline && this.mode === 'liveview';
  }

  get isAvailable() {
    // If we are not live streaming mode, need to query before playing.
    if (this.mode !== 'liveview' && !this.recordingQueried) {
      return false;
    }
    if (this.mode === 'liveview' && this.camera.status === 'CAM_OFFLINE') {
      return false;
    }
    if (this.isEquivalentToStopped) {
      return false;
    }
    return true;
  }

  get isEquivalentToStopped() {
    return this.status === 'NOT_SUPPORT'
    || this.status === 'NO_RECORDING'
    || this.status === 'TIMEOUT';
  }

  get resolutionString() {
    if (!this.resolution.width) {
      return '';
    }
    return `${this.resolution.width}x${this.resolution.height}`;
  }

  get codecString() {
    return SUPPORT_VIDEO_CODEC_MAP[this.codec] || this.codec || '';
  }

  get frameRateString() {
    if (!this.frameRate) {
      return '';
    }
    return `${this.frameRate} fps`;
  }

  get displayFullTime() {
    return this.$_cameraTime.displayFullTime;
  }

  get elapsedTime() {
    // Avoid timing issue with over-writing elapsedTime by updating from medama
    if (this.status === 'SEEKING' || this.status === 'LOADING') {
      return this.startingPointBySec;
    }
    const totalTime = this.$_elapsedTime + this.startingPointBySec;

    if (!this.camera) {
      return totalTime;
    }

    const cameraDuration = this.camera.eventRecordingDuration;
    return totalTime >= cameraDuration ? cameraDuration : totalTime;
  }

  set elapsedTime(value) {
    this.$_elapsedTime = value;
  }

  get isEmpty() {
    return this.camera === undefined || this.camera.status === 'CAM_EMPTY' || this.camera.status === 'CAM_FILTER';
  }

  get channel() {
    return this.options.encoder_id;
  }

  set channel(value) {
    this.options.encoder_id = value;
  }

  get shouldShowStatusIcon() {
    return this.options.display?.icon === '1';
  }

  get streamType() {
    return this.streamIndex === 0 ? 'main' : 'sub';
  }

  async queryAlarmData() {
    if (this.channel === -1) {
      return Promise.reject();
    }
    const alarmQueryStart = this.options.begin;
    const alarmQueryEnd = this.options.end;
    return AlarmApi.queryAlarms({
      channels: this.channel,
      start: alarmQueryStart.toString(),
      end: alarmQueryEnd.toString(),
      alarmNames: [],
      eventTypes: [],
      haveRecordingOnly: false,
      maxRecCnt: 200,
    }).then((allAlarmData) => {
      const alarmData = allAlarmData?.filter((alarm) => alarm.source_type !== 'bookmark');
      this.alarm_data = alarmData || [];
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      return Promise.resolve([]);
    });
  }

  async queryBookmarkData() {
    if (this.channel === -1) {
      return Promise.reject();
    }
    const alarmQueryStart = this.options.begin;
    const alarmQueryEnd = this.options.end;
    return AlarmApi.queryAlarms({
      channels: this.channel,
      start: alarmQueryStart.toString(),
      end: alarmQueryEnd.toString(),
      alarmNames: [],
      eventTypes: ['bookmark'],
      haveRecordingOnly: false,
      maxRecCnt: 200,
    }).then((bookmarkData) => {
      this.bookmark_data = bookmarkData || [];
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      return Promise.resolve([]);
    });
  }

  async fetchPlaybackRule(timestamp) {
    this.camera.fetchPlaybackRule(timestamp, this.channel);
  }

  async queryRecordingData() {
    if (this.channel === -1) {
      return Promise.reject(new Error('no camera'));
    }

    if (this.status !== 'PLAYING') {
      this.status = 'LOADING';
    }
    if (this.options.display.vca) {
      const timestamp = this.playTime.timestamp !== 0
        ? this.playTime.timestamp
        : this.options.begin.projection(this.options.begin.tzoffs).timestamp;
      this.camera.fetchPlaybackRule(timestamp, this.channel);
    }

    let begin = this.options.begin.forwardMilliseconds(QUERY_INTERVAL * -1).toString();
    let end = this.options.end.forwardMilliseconds(QUERY_INTERVAL).toString();
    if (this.options.clipMode) {
      begin = this.options.begin.toString();
      end = this.options.end.toString();
    }

    return RecordingApi.queryTimeInterval({
      channel: this.options.encoder_id, begin, end, streamType: this.streamType
    }).then((recordings) => {
      const recordingData = recordings.recording_data;
      this.recording_data = recordingData;

      if (!this.playTime.timestamp) {
        const { timestamp, tzoffs } = this.options.begin.projection(this.options.tzoffs);

        this.playTime.timestamp = timestamp;
        this.playTime.tzoffs = tzoffs;
      }
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    }).finally(() => {
      this.recordingQueried = true;
    });
  }

  queryEvent({ deviceList, startTime, endTime }) {
    return EventApi.queryEvent({
      deviceList, startTime, endTime
    }).then((requestResInfo) => {
      const vcaEventData = requestResInfo[0]?.EvnRec;
      this.vca_data = vcaEventData ? vcaEventData.map((event) => new Date(event.StaTm).getTime() * 1000) : [];
    });
  }

  queryVCAEventData() {
    if (this.channel === -1) {
      return Promise.reject(new Error('no camera'));
    }
    const startTime = this.options.begin.toString();
    const endTime = this.options.end.toString();
    const deviceList = [`C_${this.channel + 1}`];
    const options = {
      deviceList, startTime, endTime
    };

    return this.queryEvent(options).catch((err) => {
      // fix for query rules before header mounted
      if (err.toString() === 'Error: WebSocket Not Connect') {
        return new Promise((resolve) => {
          setTimeout(() => resolve(this.queryEvent(options)), 2000);
        });
      }
      // eslint-disable-next-line no-console
      console.error(err);
      return Promise.resolve([]);
    });
  }

  alignRecording({
    query = true,
    timestamp
  }) {
    const t = (typeof timestamp === 'undefined') ? this.timestamp : timestamp;
    const interval = this.options.end.localTimestamp - this.options.begin.localTimestamp;
    const nextRecordingBegin = new LocalTime({
      timestamp: t,
      tzoffs: this.$_cameraTime.tzoffs
    });
    this.options.begin = nextRecordingBegin;
    this.options.end = nextRecordingBegin.forwardMilliseconds(interval);
    if (query) {
      this.queryRecordingData();
      this.queryAlarmData();
      this.queryVCAEventData();
      this.queryBookmarkData();
    }
  }

  checkTimestampOutOfScope(localTime) {
    const { begin, end } = this.options;
    // default playing start time could not be on the hour
    // 23:59:50 if user want to play from mid night
    const OFFSET = 10 * 1000;
    // handle transitional state
    if (this.status === 'SEEKING' || localTime.localTimestamp === 0 || !begin || !end) {
      return false;
    }
    return !(localTime.localTimestamp - (begin.localTimestamp - OFFSET) > 0
      && localTime.localTimestamp - end.localTimestamp < 0);
  }

  get duration() {
    if (!this.options.end || !this.options.clipMode) {
      return 0;
    }

    return (this.options.end - this.options.begin) / 1000 - this.startingPointBySec;
  }

  get displayElapsedTime() {
    if (!this.camera) {
      return '00:00';
    }

    if (this.elapsedTime > this.camera.eventRecordingDuration) {
      return this.convertToMinuteSeconds(this.camera.eventRecordingDuration);
    }

    return this.convertToMinuteSeconds(this.elapsedTime);
  }

  get displayDuration() {
    if (!this.camera || this.status === 'NO_RECORDING') {
      return '00:00';
    }
    return this.convertToMinuteSeconds(this.camera.eventRecordingDuration);
  }

  convertToMinuteSeconds(seconds) {
    const date = new Date(0);
    date.setSeconds(seconds);
    const timeString = date.toISOString().substr(14, 5);
    return timeString;
  }

  get displayConfig() {
    return {
      cameraType: this.options.display?.camera_type === '1',
      cameraName: this.options.display?.camera_name === '1',
      cameraIp: this.options.display?.camera_ip === '1',
      cameraTime: this.options.display?.camera_time === '1',
      tmis: this.options.display?.tmis === '1',
      vca: this.options.display?.vca === '1',
    };
  }

  get timestamp() {
    return this.$_cameraTime.timestamp;
  }

  get tzoffs() {
    return this.$_cameraTime.tzoffs;
  }

  get cameraTime() {
    return this.$_cameraTime;
  }

  set cameraTime(value) {
    this.$_cameraTime = new LocalTime(value);

    if (this.mode === 'liveview') {
      return;
    }
    const localTime = new LocalTime({
      timestamp: this.$_cameraTime.timestamp,
      tzoffs: this.$_cameraTime.tzoffs
    });
    this.isOutOfScope = this.checkTimestampOutOfScope(localTime);
    if (this.isOutOfScope) {
      // eslint-disable-next-line no-console
      console.warn('viewcell recording is aligned due to out of scope');
      this.alignRecording({
        query: true,
      });
    }
  }

  get snapshotTime() {
    return this.options.begin.timestamp;
  }

  get interval() {
    if (typeof this.options.end !== 'object') {
      return 86400;
    }
    return (this.options.end.localTimestamp - this.options.begin.localTimestamp) / 1000;
  }

  // Fallback area
  get encoder_id() {
    return this.options.encoder_id;
  }
  // Fallback area

  commit() {
    window.$_store.dispatch('scene/updateScene');
  }

  get dewarpType() {
    return Object.keys(PLUGINFREE.DEWARP).find(
      (key) => PLUGINFREE.DEWARP[key] === this.options.dewarp_mode
    );
  }

  get stretch() {
    return this.options.stretch === '1';
  }

  set stretch(value) {
    this.options.stretch = value ? '1' : '0';
    this.commit();
  }

  setVolume(val) {
    this.volume = val;
  }

  get volume() {
    return this.options.volume;
  }

  set volume(value) {
    this.options.volume = value;
    this.commit();
  }

  get streamIndex() {
    if (this.options.stream_index === -1) {
      if (this.preferMainStream) {
        return 0;
      }
      return 1;
    }

    return this.options.stream_index !== 0 ? 1 : 0;
  }

  set streamIndex(value) {
    this.options.stream_index = value;
    this.commit();

    this.resetDewarp();
    this.enableDigitalZoom = false;
    if (this.mode === 'playback') {
      this.queryRecordingData();
      this.resetPlaybackRate();
    }
  }

  mute() {
    this.lastVolume = this.volume;
    this.setVolume(0);
  }

  unmute() {
    this.setVolume(this.lastVolume || 50);
  }

  get dewarpMode() {
    return this.options.dewarp_mode;
  }

  set dewarpMode(dewarpType) {
    this.options.dewarp_mode = dewarpType;
    this.commit();
  }

  get enableDigitalZoom() {
    return this.options.zoom_enable;
  }

  set enableDigitalZoom(value) {
    this.options.zoom_enable = !!value;
    if (!value) {
      this.options.pip_info = {
        x: 0, y: 0, width: 1, height: 1
      };
    }
  }

  get pipInfo() {
    return this.options.pip_info;
  }

  set pipInfo(value) {
    this.options.pip_info.x = value.x;
    this.options.pip_info.y = value.y;
    this.options.pip_info.width = value.width;
    this.options.pip_info.height = value.height;
  }

  get shouldDisplayTMISIcon() {
    return this.shouldShowStatusIcon && this.displayConfig.tmis && this.camera?.displayCapability.tmis;
  }

  get shouldDisplayVCAIcon() {
    if (this.mode === 'liveview') {
      return this.shouldShowStatusIcon && this.displayConfig.vca && this.camera?.displayCapability.vca;
    }
    return this.shouldShowStatusIcon && this.displayConfig.vca && this.camera?.rule;
  }

  resetPlaybackRate() {
    this.playbackRate = 1;
    this.playbackRateIndex = PLUGINFREE.PLAYBACK_RATE_MAP.indexOf(1);
  }

  backward() {
    const backwardTime = this.$_cameraTime.timestamp - 10000;
    if (this.duration && backwardTime >= this.camera.eventRecordingDuration) {
      return;
    }
    this.playTime.tzoffs = this.tzoffs;
    this.playTime.timestamp = backwardTime;
    this.resetPlaybackRate();
  }

  forward() {
    const forwardTime = this.$_cameraTime.timestamp + 10000;
    if (this.duration && forwardTime >= this.camera.eventRecordingDuration) {
      return;
    }
    this.playTime.tzoffs = this.tzoffs;
    this.playTime.timestamp = forwardTime;
    this.resetPlaybackRate();
  }

  speedUp() {
    if (this.playbackRateIndex < PLUGINFREE.PLAYBACK_RATE_MAP.length - 1) {
      this.playbackRateIndex += 1;
      this.playbackRate = PLUGINFREE.PLAYBACK_RATE_MAP[this.playbackRateIndex];
    }
  }

  speedDown() {
    if (this.playbackRateIndex > 0) {
      this.playbackRateIndex -= 1;
      this.playbackRate = PLUGINFREE.PLAYBACK_RATE_MAP[this.playbackRateIndex];
    }
  }

  updatePeopleDetectionArea(val) {
    this.peopleDetectionArea = val;
  }

  updateCameraRule(val) {
    this.camera.rule = { ...this.camera.rule, ...val };
  }

  resetDewarp() {
    this.dewarpInfo = {
      isValid: false,
      isLocalDewarped: false,
    };
    this.dewarpPreset = '';
  }
}
