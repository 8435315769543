<script>
import { Playback } from '@vivotek/lib-medama';
import PluginlessWrapper from '@/components/viewcell/PluginlessWrapper.vue';

export default {
  name: 'PluginlessWrapperPlayback',
  extends: PluginlessWrapper,
  props: {
    playTime: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: true,
    },
    playbackRate: {
      type: Number,
      default: 1,
    },
    duration: {
      type: Number,
      default: 0, // infinity
    },
  },
  watch: {
    isPlayEnd(value) {
      if (value) {
        this.stop();
      }
    },
    stream() {
      const { cameraTime, cameraTzoffs } = this;

      this.replayTimestamp = cameraTime;
      this.replayTzoffs = cameraTzoffs;
    },
    playTime: {
      handler() {
        // eslint-disable-next-line no-console
        console.warn('playtime changed', this.channel);
        if (!this.isPlayEnd) {
          if (this.viewinfo.status === 'PLAYING') {
            this.$emit('retry');
          }
          this.viewinfo.status = 'SEEKING';
          // the old player will fire play again before stop.
          if (this.viewcell) {
            this.viewcell.off('play', this.handleViewcellPlay);
          }
          this.replay();
        }
      },
      deep: true
    },
    playbackRate(speed) {
      this.setPlaybackRate(speed);
    },
    'viewinfo.status': {
      handler() {
        if (this.viewinfo.status === 'PLAYING' && !this.image && !this.showWithLiteMode) {
          this.createImage();
        }
      }
    },
    'viewinfo.timestamp': {
      handler(newValue, oldValue) {
        // fetch once when timestamp start to be updated and viewcell is playing
        if (this.viewinfo.status === 'PLAYING' && oldValue === 0 && newValue !== 0) {
          this.setFetchPlaybackRule();
        }
      }
    }
  },
  computed: {
    url() {
      return `rtsp://localhost//Media/Database/Normal?LOCATION=Loc${this.channel}${this.stream === 0 ? '' : '_Sub'}&fullspeedbeforejam=yes&knowgoodbye=true`;
    },
    hasInstance() {
      return this.viewcell instanceof Playback;
    },
    showWithDrag() {
      return this.hasImage;
    },
    isPlayEnd() {
      return this.viewinfo.duration
      && (this.viewinfo.elapsedTime >= this.viewinfo.duration + this.viewinfo.startingPointBySec);
    },
  },
  data() {
    return {
      replayTimestamp: null,
      replayTzoffs: null,
      fetchRuleTimeout: null,
    };
  },
  methods: {
    handleViewcellStop() {
      this.removeImage();
      if (this.isPlayEnd) {
        this.$emit('end');
      }
    },

    nextFrame() {
      if (!this.hasInstance) {
        return;
      }
      this.viewcell.nextFrame();
    },

    pause() {
      if (!this.hasInstance) {
        return;
      }
      this.viewcell.pause();
    },

    resume() {
      if (!this.hasInstance || !this.viewcell.isPause) {
        return;
      }
      this.viewcell.play();
    },

    play() {
      if (!this.playTime || !this.playTime.timestamp) {
        return Promise.reject();
      }
      return this.tryToPlayLooped();
    },

    /* internal methods */
    tryToPlay() {
      if (!this.playTime || !this.playTime.timestamp) {
        return Promise.reject();
      }
      this.viewinfo.status = 'LOADING';
      return this.createViewCell()
        .then((viewcell) => {
          const { replayTimestamp, replayTzoffs } = this;
          const { timestamp, tzoffs } = this.playTime;
          if (!replayTimestamp) {
            return viewcell.play(timestamp, tzoffs);
          }

          this.replayTimestamp = null;
          this.replayTzoffs = null;

          return viewcell.play(replayTimestamp, replayTzoffs);
        });
    },

    createViewCell() {
      return this.getRtspChannel()
        .then((rtspChannel) => {
          const config = {
            rtspChannel,
            url: this.url,
            username: this.username,
            sessionId: this.sessionId,
            workerLibde265Path: this.workerSrc,
          };
          if (this.duration) {
            config.duration = this.duration;
          }
          this.viewcell = new Playback(config);
          this.viewcell.SOURCEBUFFER_KEEP_LENGTH = 90;
          this.bindViewcellEventProcess(this.viewcell);
          this.setVolume(this.volume);
          this.setMute(this.mute);

          return this.viewcell;
        });
    },

    handleViewcellError(err) {
      // eslint-disable-next-line no-console
      console.error(`${err.toString()} in channel: ${this.channel} and stream: ${this.stream}`);

      this.stop();

      // pause by browser when switching tab
      if (err.toString().startsWith('AbortError: The play() request was interrupted by a call to pause().')) {
        this.retry();
        return;
      }

      switch (err.toString()) {
        case 'Error: Player Timeout':
        case 'Error: codec: H264 has been changed':
        case 'Error: codec: HEVC has been changed':
        case 'Error: Continuous play failed':
          this.storeReplayInfo();
          this.retry(0);
          break;
        case 'Error: Failed to get stream.':
        case 'Error: codec: JPEG is not support':
        default:
          this.$emit('error', err);
          break;
      }
    },

    storeReplayInfo() {
      const { cameraTime, cameraTzoffs } = this;

      this.replayTimestamp = cameraTime;
      this.replayTzoffs = cameraTzoffs;
    },

    handleVisibilityChange() {
      // do nothing
    },

    setPlaybackRate(speed) {
      if (!this.hasInstance) {
        return;
      }
      this.viewcell.setPlaybackRate(speed);
    },

    setFetchPlaybackRule() {
      this.clearFetchPlaybackRule();
      this.viewinfo.fetchPlaybackRule(this.viewinfo.timestamp);
      this.fetchRuleTimeout = setTimeout(() => {
        this.setFetchPlaybackRule();
      }, 30 * 1000);
    },

    clearFetchPlaybackRule() {
      if (this.fetchRuleTimeout) {
        clearTimeout(this.fetchRuleTimeout);
      }
      this.fetchRuleTimeout = null;
    },
  },
  beforeDestroy() {
    this.clearFetchPlaybackRule();
  },
};
</script>
