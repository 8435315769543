import axios from 'axios';

const SCENE_PATH = '/system/software/raphael/web/scene';

const serializeScene = (scenes, sceneIndex) => (
  {
    scene_list: {
      [sceneIndex]: {
        name: scenes[sceneIndex].name,
        pages: {
          ...(scenes[sceneIndex].pages.map(
            (page) => ({
              layout: page.layout,
              view: JSON.stringify(page.view.map((viewcell) => viewcell.options))
            })
          ))
        }
      }
    }
  }
);

const actions = {
  changeLayout({ rootState, rootGetters, commit }, layout) {
    commit('changeLayout', {
      layout,
      encoders: rootState.encoder,
      channelCount: rootGetters['encoder/channelCount']
    });
  },

  fetch({ commit, rootGetters, rootState }, isFirstFetch) {
    return axios.post(
      '/fcgi-bin/operator/gconf.query', `path=${SCENE_PATH}`,
    ).then(
      (res) => commit('setScene', {
        scenes: res.data,
        encoders: rootState.encoder,
      })
    ).then(() => commit('generateDeviceScene', {
      layout: '2x2',
      encoders: rootState.encoder,
      channelCount: rootGetters['encoder/channelCount']
    })).then(() => {
      if (isFirstFetch) {
        commit('handleFirstFetch');
      }
    })
      .catch((err) => {
      // eslint-disable-next-line no-console
        console.warn(err);
      });
  },

  updateScene({
    state, commit, dispatch, rootState
  }, sceneIndex) {
    if (!state.isSceneMode && sceneIndex === undefined) {
      return Promise.resolve();
    }
    const index = (sceneIndex === undefined) ? state.active : sceneIndex;
    const scene = serializeScene(state.scenes, index);
    scene.default_scene = state.default;

    // update encoder of view
    state.scenes[index].pages.forEach((page) => {
      page.view.forEach((view) => {
        if (view.channel !== -1) {
          view.camera = rootState.encoder[view.channel];
        } else {
          view.camera = undefined;
        }
      });
    });

    const params = new URLSearchParams({
      path: SCENE_PATH,
      data: JSON.stringify(scene)
    }).toString();

    return axios.post(
      '/fcgi-bin/operator/gconf.update',
      params
    );
  },

  removeScene({
    state, commit, dispatch, rootGetters, rootState,
  }, index) {
    return axios.post(
      '/fcgi-bin/operator/gconf.delete',
      `path=${SCENE_PATH}/${index}`
    ).then(
      () => (dispatch('fetch'))
    ).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
      return Promise.reject(err);
    }).then(
      () => {
        if (state.active === index) {
          commit('setActive', -1);
        }
      }
    )
      .catch((err) => {
      // eslint-disable-next-line no-console
        console.warn(err);
        return Promise.reject(err);
      });
  },

  setIsSceneMode({ state, commit }, isSceneMode) {
    commit('setIsSceneMode', isSceneMode);
  },

  setActive({ state, commit }, active) {
    commit('setActive', active);
  },

  setActiveDeviceIndex({ state, commit, getters }, deviceIndex) {
    commit('setActiveDeviceIndex', deviceIndex);
    const activeViewInfo = getters.scenePageLayoutInfo
      .filter(
        (info) => (info.encoder_id !== -1 && info.status !== 'CAM_EMPTY' && info.status !== 'CAM_FILTER')
      )
      .find((info) => info.encoder_id === deviceIndex);

    if (activeViewInfo) {
      commit('setActivePageIndex', activeViewInfo.pageIndex);
      commit('setActiveViewIndex', activeViewInfo.viewIndex);
    } else {
      commit('setActiveViewIndex', -1);
    }
  },

  selectActiveView({ state, commit, getters }, { viewIndex, pageIndex, deviceIndex }) {
    commit('setActiveViewIndex', viewIndex);
    if (pageIndex) {
      commit('setActivePageIndex', pageIndex);
    }

    commit('setActiveDeviceIndex', deviceIndex);
  },

  updateAllViewDisplayConfig({ state, commit, dispatch }, displayConfig) {
    commit('setAllViewDisplayConfig', displayConfig);
    dispatch('updateScene');
  },

  goPreviousPage({ state, commit }) {
    if (state.activePageIndex === 0) {
      return;
    }
    commit('goPreviousPage');
  },

  goNextPage({ state, getters, commit }) {
    if (state.activePageIndex + 1 >= getters.pageCount) {
      return;
    }
    commit('goNextPage');
  },

  setPlayer({ commit }, { viewIndex, player }) {
    commit('setPlayer', { viewIndex, player });
  },
};
export default actions;
