import axios from 'axios';

export default {
  queryMonthRecording({ channel, date, streamType = 'dual' }) {
    if (!date) {
      return this.queryRecentMonthRecording({ channel, streamType });
    }
    return axios.post('/fcgi-bin/operator/database.month', `channel=${channel}&date=${date}&streamtype=${streamType}`).then((res) => {
      if (res.data) {
        return Promise.resolve({
          current: date,
          dates: res.data
        });
      }
      return Promise.reject();
    });
  },

  queryRecentMonthRecording({ channel, streamType = 'dual' }) {
    return axios.post('/fcgi-bin/operator/database.recent_month', `channel=${channel}&streamtype=${streamType}`).then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
      return Promise.reject();
    });
  },

  queryTimeInterval({
    channel, begin, end, streamType = 'main'
  }) {
    return axios.post('/fcgi-bin/operator/database.time_interval',
      `channel=${channel}&begin=${begin}&end=${end}&streamtype=${streamType}`).then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
      return Promise.reject();
    });
  }
};
