<template>
  <div class>
    <div class="top-label">
      <span class="p">{{ topLabel }}</span>
    </div>
    <div class="status-progress-bar">
      <div :style="[progressBarRailStyle]" />
    </div>
    <div
      class="bottom-label"
      v-if="bottomLabel !== ''"
    >
      <span class="p">{{ bottomLabel }}</span>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AppProgressStatusBar',
  props: {
    progress: {
      type: Number,
      default: 0,
      validator: (value) => (value >= 0) && (value <= 100),
    },
    rules: {
      type: Array,
      default: () => []
    },
    topLabel: {
      type: String,
      default: '',
    },
    bottomLabel: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      defaultColor: '#2986FF',
    };
  },
  computed: {
    progressBarRailStyle() {
      return {
        width: `${this.progress}%`,
        background: `${this.color}`,
        height: '2px',
        'z-index': 1000,
      };
    },

    color() {
      // notes: the rule will check rule in rules array asecending order
      let colorIndex = -1;
      const result = this.rules.some((item, index) => {
        colorIndex = index;
        if (typeof item.rule === 'function') {
          return item.rule(this.progress);
        }
        return false;
      });
      if (!result || colorIndex === -1) {
        return this.defaultColor;
      }
      return this.rules[colorIndex].color;
    },
  }
};
</script>

<style lang="less" scoped>
.status-progress-bar {
  max-width: 356px;
  background-color: grey;
  height: rem(2);
}
.top-label {
  display: flex;
  flex-direction: row-reverse;
  margin: rem(10) 0;
}
.bottom-label {
  margin: rem(10) 0;
}
</style>
