const padL2x0 = function padL2x0(str) {
  return str.padStart(2, '0');
};

const localDateTimeMethods = {
  year: 'getFullYear',
  month: 'getMonth',
  date: 'getDate',
  hours: 'getHours',
  minutes: 'getMinutes',
  seconds: 'getSeconds'
};
const UTCDateTimeMethods = {
  year: 'getUTCFullYear',
  month: 'getUTCMonth',
  date: 'getUTCDate',
  hours: 'getUTCHours',
  minutes: 'getUTCMinutes',
  seconds: 'getUTCSeconds'
};

const formatString = {
  date: 'yy/mm/dd',
  time: 'HH:mm:ss'
};

const genFormatDate = function(map) {
  const getField = function (d, f) {
    return d[map[f]]();
  };
  return function(dateFormat) {
    let format = dateFormat || formatString.date;

    const date = this;
    const month = getField(date, 'month') + 1;
    const year = getField(date, 'year');

    if (format.indexOf('mm') > -1) {
      format = format.replace('mm', padL2x0(month.toString()));
    } else if (format.indexOf('m') > -1) {
      format = format.replace('m', month.toString());
    }

    if (format.indexOf('yy') > -1) {
      format = format.replace('yy', year.toString());
    } else if (format.indexOf('y') > -1) {
      format = format.replace('y', year.toString().substr(2, 2));
    }

    if (format.indexOf('dd') > -1) {
      format = format.replace('dd', padL2x0(getField(date, 'date').toString()));
    } else if (format.indexOf('d') > -1) {
      format = format.replace('d', getField(date, 'date').toString());
    }

    return format;
  };
};

const genFormatTime = function(map) {
  const getField = function (d, f) {
    return d[map[f]]();
  };
  return function(time, timeFormat) {
    let format = timeFormat || formatString.time;
    let hours = getField(time, 'hours');

    if (format.indexOf('tt') > -1) {
      if (hours > 11) {
        format = format.replace('tt', 'pm');
      } else {
        format = format.replace('tt', 'am');
      }
    } else if (format.indexOf('TT') > -1) {
      if (hours > 11) {
        format = format.replace('TT', 'PM');
      } else {
        format = format.replace('TT', 'AM');
      }
    } else if (format.indexOf('cc') > -1) {
      if (hours > 11) {
        format = format.replace('cc', '下午');
      } else {
        format = format.replace('cc', '上午');
      }
    }

    if (format.indexOf('HH') > -1) {
      format = format.replace('HH', padL2x0(hours.toString()));
    } else if (format.indexOf('H') > -1) {
      format = format.replace('H', hours.toString());
    }

    if (format.indexOf('hh') > -1) {
      if (hours > 12) {
        hours -= 12;
      }
      if (hours === 0) {
        hours = 12;
      }
      format = format.replace('hh', padL2x0(hours.toString()));
    } else if (format.indexOf('h') > -1) {
      if (hours > 12) {
        hours -= 12;
      }
      if (hours === 0) {
        hours = 12;
      }
      format = format.replace('h', hours.toString());
    }

    if (format.indexOf('mm') > -1) {
      format = format.replace('mm', padL2x0(getField(time, 'minutes').toString()));
    } else if (format.indexOf('m') > -1) {
      format = format.replace('m', getField(time, 'minutes').toString());
    }

    if (format.indexOf('ss') > -1) {
      format = format.replace('ss', padL2x0(getField(time, 'seconds').toString()));
    } else if (format.indexOf('s') > -1) {
      format = format.replace('s', getField(time, 'seconds').toString());
    }

    return format;
  };
};

const $formatDate = genFormatDate(localDateTimeMethods);
const $formatUTCDate = genFormatDate(UTCDateTimeMethods);
const $formatTime = genFormatTime(localDateTimeMethods);
const $formatUTCTime = genFormatTime(UTCDateTimeMethods);

const formatUTCDate = function(pureDate, format) {
  return $formatUTCDate.call(pureDate, format || formatString.date);
};
const formatUTCTime = function(pureTime, format) {
  return $formatUTCTime(pureTime, format || formatString.time);
};
const localeUTCDate = function(timestamp, format) {
  return $formatUTCDate.call(new Date(timestamp * 1000), format || formatString.date);
};
const localeUTCTime = function(timestamp, format) {
  return $formatUTCTime(new Date(timestamp * 1000), format || formatString.time);
};
const localeUTCDatetime = function(pureDate) {
  let date = pureDate;
  if (typeof (date) === 'number') { // timepointer in seconds
    date = new Date(date * 1000);
  } else if (Object.prototype.toString.call(date) !== '[object Date]') {
    return '';
  }
  return [$formatUTCDate.call(date), $formatUTCTime(date)].join(' ');
};

const formatDate = function(pureDate, format) {
  return $formatDate.call(pureDate, format || formatString.date);
};
const formatTime = function(pureTime) {
  return $formatTime(pureTime, formatString.time);
};
const localeDate = function(timestamp) {
  return $formatDate.call(new Date(timestamp * 1000), formatString.date);
};
const localeTime = function(timestamp) {
  return $formatTime(new Date(timestamp * 1000), formatString.time);
};
const localeDatetime = function(date) {
  if (Object.prototype.toString.call(date) !== '[object Date]') {
    return '';
  }
  return [$formatDate.call(date), $formatTime(date)].join(' ');
};

export default {
  formatString,
  formatUTCDate,
  formatUTCTime,
  localeUTCDate,
  localeUTCTime,
  localeUTCDatetime,
  formatDate,
  formatTime,
  localeDate,
  localeTime,
  localeDatetime
};
