<template>
  <div
    class="table-layout-wrapper"
    :data-grid-mode="grid"
    :class="{
      export: theme === 'export'
    }"
  >
    <div
      class="table-container"
      :class="{
        scroll: scroll,
        export: theme === 'export',
        dido: theme === 'dido'
      }"
    >
      <div class="header-wrapper">
        <table
          class="table-header"
          :class="{
            disabled: rowData.length === 0
          }"
        >
          <thead>
            <slot name="table-header">
              <div
                class="table-row"
                v-if="grid"
              >
                <AppCheckbox
                  :indeterminate="isSelectIndeterminate"
                  v-model.lazy="isSelectAll"
                  :disabled="rowData.length === 0 || disabled"
                  @click.native.prevent="selectAll"
                />
                <AppTableSortDropdown
                  :disabled="disabled"
                />
              </div>
              <tr
                class="table-row"
                v-else
              >
                <template v-if="type === 'select'">
                  <th
                    class="play"
                    v-if="!disablePlay"
                  />
                  <th class="select">
                    <AppCheckbox
                      :indeterminate="isSelectIndeterminate"
                      v-model.lazy="isSelectAll"
                      :disabled="rowData.length === 0 || disabled"
                      @click.native.prevent="selectAll"
                    />
                  </th>
                  <template v-if="!sortDropdown">
                    <th
                      v-for="(item, index) in headerList"
                      :key="index"
                      :title="item.name"
                      @click="sortHandler(index)"
                    >
                      <div class="cell">
                        <h6 class="bold">
                          {{ item.name }}
                        </h6>
                        <SvgIcon
                          v-if="currentHeaderIndex === index && item.sort !== null"
                          :iconClass="sortIcon"
                          size="20px"
                        />
                        <SvgIcon
                          v-else
                          class="default-sort-icon"
                          iconClass="general_sort"
                          size="20px"
                        />
                      </div>
                    </th>
                  </template>
                  <AppTableSortDropdown
                    :disabled="disabled"
                    v-if="sortDropdown"
                  />
                </template>
                <template v-else-if="type === 'list' && !sort">
                  <th
                    v-for="(item, index) in headerList"
                    :title="item.name"
                    :key="index"
                  >
                    {{ item.name }}
                  </th>
                </template>
                <template v-else-if="type ==='list' && sort">
                  <template v-if="!sortDropdown">
                    <th
                      v-for="(item, index) in headerList"
                      :key="index"
                      :title="item.name"
                      @click="sortHandler(index)"
                    >
                      <div class="cell">
                        <h6 class="bold">
                          {{ item.name }}
                        </h6>
                        <SvgIcon
                          v-if="currentHeaderIndex === index && item.sort !== null"
                          :iconClass="sortIcon"
                          size="20px"
                        />
                        <SvgIcon
                          v-else
                          class="default-sort-icon"
                          iconClass="general_sort"
                          size="20px"
                        />
                      </div>
                    </th>
                  </template>
                </template>
              </tr>
            </slot>
          </thead>
        </table>
      </div>
      <div class="body-wrapper">
        <table
          class="table-content"
          v-if="rowData.length !== 0"
        >
          <tbody>
            <slot name="body">
              <template v-if="grid">
                <div
                  v-for="(item, index) in paginatedRowData"
                  :key="index"
                  class="grid-item"
                  :data-selected="($_selectedArray.indexOf(item.id) >= 0)"
                >
                  <div class="checkbox-container">
                    <AppCheckbox
                      :disabled="disabled"
                      :value="item.id"
                      v-model.lazy="$_selectedArray"
                    />
                  </div>
                  <slot
                    name="table-content-row"
                    :item="item"
                    :index="index"
                  />
                </div>
              </template>
              <tr
                class="table-row"
                v-for="(item, index) in paginatedRowData"
                :key="index"
                v-else
              >
                <slot
                  name="head-control"
                  :item="item"
                />
                <td
                  class="select"
                  v-if="type === 'select'"
                >
                  <AppCheckbox
                    :disabled="disabled"
                    :value="item.id"
                    v-model.lazy="$_selectedArray"
                  />
                </td>
                <slot
                  name="table-content-row"
                  :item="item"
                />
              </tr>
            </slot>
          </tbody>
        </table>
        <AppTableStatusInfoIcon
          v-else
          :status="searchStatus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppTableSortDropdown from './AppTableSortDropdown.vue';
import AppTableStatusInfoIcon from './AppTableStatusInfoIcon.vue';

export default {
  name: 'SearchTableLayout',
  components: {
    AppCheckbox: () => import('@/components/ui/CheckBox.vue'),
    AppTableSortDropdown,
    AppTableStatusInfoIcon,
  },
  props: {
    grid: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'list',
    },
    headerList: {
      type: Array,
      required: false,
      default: () => [],
    },
    bodyList: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      required: false,
      default: 'normal',
    },
    selectedArray: {
      type: Array,
      required: false,
      default: () => [],
    },
    sort: {
      type: Boolean,
      default: false,
      required: false
    },
    sortDropdown: {
      type: Boolean,
      default: false,
    },
    disablePlay: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: true,
    },
    defaultSortIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    searchStatus: {
      type: String,
      required: false,
      default: ''
    },
    currentPageIndex: {
      type: Number,
      required: false,
      default: null,
    },
    pageCount: {
      type: Number,
      required: false,
      default: null,
    },
    keyword: {
      type: String,
      requied: false,
      default: ''
    },
    keywordFilterRule: {
      type: Function,
      required: false,
      default() {
        return this.bodyList;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rowData: [],
      reverse: false,
      currentHeaderIndex: null,
      sortOrder: 'asc',
      sortType: 'Name',
      hovering: -1,
    };
  },
  methods: {
    sortHandler(headerListIndex) {
      const clone = [...this.rowData];

      if (this.currentHeaderIndex !== headerListIndex) {
        this.reverse = false;
        this.currentHeaderIndex = headerListIndex;

        if (this.headerList[headerListIndex].sort) {
          this.rowData = clone.sort((a, b) => this.headerList[headerListIndex].sort(a, b));
        }
      } else {
        this.reverse = !this.reverse;
        this.rowData = clone.reverse();
      }
      this.$emit('handleSortedList', Object.freeze(this.rowData));
    },
    selectAll() {
      if (this.disabled) {
        return;
      }

      if (this.$_selectedArray.length !== this.rowData.length) {
        const clone = [...this.rowData.map((item) => item.id)];
        this.$_selectedArray = Object.freeze(clone);
      } else {
        this.$_selectedArray = Object.freeze([]);
      }
    },
    changeSortOption({ type, order }) {
      this.sortType = `${type}`;
      this.sortOrder = order;
      this.sortHandler(type);
    },
  },
  computed: {
    sortOptions() {
      return this.headerList
        .map((header, index) => ({
          name: header.name,
          type: `${index}`,
          sort: header.sort,
        }))
        .filter((mappedHeader) => mappedHeader.sort);
    },
    $_selectedArray: {
      get() {
        return this.selectedArray.map((item) => item.id);
      },
      set(value) {
        const $_selected = this.bodyList.filter((item) => value.includes(item.id));
        this.$emit('update:selectedArray', Object.freeze($_selected));
      },
    },
    isSelectAll() {
      if (this.rowData.length === 0) {
        return false;
      }

      return this.$_selectedArray.length === this.rowData.length;
    },
    isSelectIndeterminate() {
      if (this.rowData.length === 0) {
        return false;
      }

      return (
        this.$_selectedArray.length !== 0
        && this.$_selectedArray.length !== this.rowData.length
      );
    },
    sortIcon() {
      if (
        this.headerList[this.currentHeaderIndex]?.defaultArrowDirection
        === 'down'
      ) {
        return this.reverse ? 'general_sort_top' : 'general_sort_bottom';
      }
      return !this.reverse ? 'general_sort_top' : 'general_sort_bottom';
    },
    paginatedRowData() {
      if (this.currentPageIndex === null && this.pageCount === null) {
        return this.rowData;
      }

      return this.rowData.slice(
        this.currentPageIndex * this.pageCount,
        (this.currentPageIndex + 1) * this.pageCount
      );
    },
  },
  watch: {
    bodyList: {
      handler() {
        const clone = [...this.bodyList];
        this.$set(this, 'rowData', Object.freeze(clone));
        if (this.rowData.length === 0) {
          this.reverse = null;
          this.currentHeaderIndex = null;
          return;
        }

        if (this.currentHeaderIndex === null) {
          this.sortHandler(this.defaultSortIndex);
        }
      },
      immediate: true
    },
    keyword: {
      handler(value) {
        const clone = [...this.bodyList];

        if (value) {
          this.$set(this, 'rowData', Object.freeze(this.keywordFilterRule(clone, value)));
        } else {
          this.$set(this, 'rowData', Object.freeze(clone));
          this.reverse = null;
          this.currentHeaderIndex = null;
          this.sortHandler(this.defaultSortIndex);
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
@import "~less/table-theme.less";

.table-layout-wrapper[data-grid-mode="true"] {
  tbody {
    margin-top: rem(10);
    margin-left: rem(-10);
    margin-right: rem(-10);
    display: flex;
    flex-wrap: wrap;
    tr {
      width: rem(228);
      height: rem(234) !important;
      display: inline-flex;
      flex-direction: column;
      margin: 1rem;
      background-color: #2e2e2e;
    }
    .grid-item {
      position: relative;
      margin: rem(10);
      &:hover {
        background-color: @search-table-row-background-color-hover;
      }
      &[data-selected="true"] {
        > .checkbox-container {
          display: inline-flex;
        }
      }
      &:hover {
        > .checkbox-container {
          display: inline-flex;
        }
      }
      > .checkbox-container {
        width: rem(40);
        height: rem(40);
        background-color: rgba(23, 23, 23, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: none;
        > div {
          width: rem(25);
          height: rem(25);
        }
      }
    }
  }
}

.table-layout-wrapper {
  background: @search-table-background-color;
  flex: 1;
  display: flex;
  flex-direction: column;
  &.export{
    height: 100%;
  }

  .table-container {
    position: relative;
    box-sizing: border-box;
    overflow: initial;
    height: 100%;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    &.scroll {
      .theme-scroll;
    }
    &.export {
      .theme-export;
    }
    &.dido {
      .theme-dido;
    }

    .header-wrapper {
      overflow: initial;
      div.table-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #fff;
      }
    }

    .body-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      .empty-block {
        flex: 1;
        background-color: @search-table-background-color-empty;
      }
    }

    table {
      width: 100%;
      table-layout: fixed;
      border: 0;
      border-spacing: 0;
      border-collapse: collapse;
      &.table-header {
        &.disabled {
          pointer-events: none;
          tr {
            th {
              div {
                color: @body-font-color-disabled;
              }
            }
          }
        }

        thead {
          tr {
            height: rem(40);
            border-bottom: 1px solid #fff;
            th {
              .thtd-default;
              color: @body-font-color;
              &.play,
              &.select {
                padding: 0;
                text-align: center;
              }
              &:not(.play):not(.select):hover {
                &:not(:active):not(.sort-button-wrapper) {
                  background-color: #292929;
                }
                > div.cell {
                  h6 {
                    color: #E0E0E0;
                  }
                  .icon-container {
                    color: #FFF;
                    &.default-sort-icon {
                      display: inline-flex !important;
                    }
                  }
                }
              }
              > div.cell {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .icon-container {
                  color: #E0E0E0;
                }

                h6 {
                  width: 100%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
                .default-sort-icon {
                  display: none;
                }
              }
            }
          }
        }
      }
      tbody {
        tr {
          position: relative;
          height: rem(40);
          border-bottom: 1px solid @search-table-row-border-color;

          &:hover {
            background: @search-table-row-background-color-hover;
          }
          &.active {
            background: @search-table-row-background-color-active;
          }

          td {
            .thtd-default;
            color: @search-table-row-font-color;
            font-size: rem(14);
            &.play,
            &.select {
              width: rem(40);
              padding: 0;
              text-align: center;
            }
            &.playing {
              &::after {
                content: "";
                position: absolute;
                display: block;
                width: 4px;
                height: 100%;
                top: 0;
                left: 0;
                background-color: @search-table-row-border-color-active;
              }
            }
          }
        }
      }
    }
  }
}
.thtd-default() {
  padding: rem(10) rem(12);
  box-sizing: border-box;
  vertical-align: middle;
  position: relative;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &.play {
    width: rem(40);
  }
  &.select {
    &:extend(.play);
    width: rem(40);

    text-align: center;
    .check-box {
      display: inline-flex;
    }
    ::v-deep.label-box {
      display: inline-block;
    }
  }
}
.status_loading {
  pointer-events: none;
  animation: spin 1.5s linear infinite;
}
</style>
