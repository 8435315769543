export default class StretchCanvas {
  constructor({
    source = null,
    useOffscreenCanvas = true,
    enable = true,
  }) {
    this.source = source;
    this.sourceRatio = 1;
    this.width = 1280;
    this.height = 960;
    this.destination = useOffscreenCanvas ? new OffscreenCanvas(1280, 960) : document.createElement('canvas');
    this.destinationContext = this.destination.getContext('2d');
    this.destinationRatio = this.width / this.height;
    this.enable = enable;
  }

  render(source, width, height) {
    const { destinationRatio } = this;
    this.source = source;
    this.sourceRatio = width / height;
    this.destinationContext.clearRect(0, 0, this.width, this.height);
    if (this.enable) {
      this.destination.width = width;
      this.destination.height = height;
      this.destinationContext.drawImage(
        this.source,
        0, 0, width, height,
      );
    } else if (this.sourceRatio > this.destinationRatio) {
      const frameW = width;
      const frameH = width / destinationRatio;
      const x = 0;
      const y = Math.round((frameH - height) / 2);
      this.destination.width = frameW;
      this.destination.height = frameH;
      this.destinationContext.drawImage(
        this.source,
        x, y, width, height,
      );
    } else {
      const frameW = destinationRatio * height;
      const frameH = height;
      const x = Math.round((frameW - width) / 2);
      const y = 0;
      this.destination.width = frameW;
      this.destination.height = frameH;
      this.destinationContext.drawImage(
        this.source,
        x, y, width, height,
      );
    }
  }

  resize(width, height) {
    if (this.width === width && this.height === height) {
      return;
    }
    this.width = width;
    this.height = height;
    this.destinationRatio = width / height;
  }

  destroy() {
    delete this.source;
    delete this.destination;
    delete this.destinationContext;
  }
}
