<template>
  <div
    class="app-date-picker"
    ref="datePicker"
  >
    <div
      class="date-input-wrapper"
      :class="{ active: isCalendarShow, error: isError}"
    >
      <input
        ref="yearInput"
        :id="`year-input-${id}`"
        class="app-time-picker-input year-input"
        type="text"
        maxlength="4"
        v-model.lazy="formattedYear"
        autocomplete="off"
        @blur="handleBlur('checkYearInput',$event)"
        @focus="openCalendar"
        @keyup.enter="handleBlur"
        :disabled="pickerDisabled"
      >
      /
      <input
        ref="monthInput"
        :id="`month-input-${id}`"
        class="app-time-picker-input"
        type="text"
        maxlength="2"
        v-model.lazy="formattedMonth"
        autocomplete="off"
        @blur="handleBlur('checkMonthInput',$event)"
        @focus="openCalendar"
        :disabled="pickerDisabled"
      >
      /
      <input
        ref="dayInput"
        :id="`day-input-${id}`"
        class="app-time-picker-input"
        type="text"
        maxlength="2"
        v-model.lazy="formattedDay"
        autocomplete="off"
        @blur="handleBlur('checkDayInput',$event)"
        @focus="openCalendar"
        @keyup.enter="handleBlur"
        :disabled="pickerDisabled"
      >
    </div>
    <AppCalendar
      class="app-calendar-panel"
      ref="calendarPanel"
      v-show="isCalendarShow"
      :today="today"
      @selectDate="selectCalendarDate"
      @selectMonth="selectCalendarMonth"
      :disabled="calendarDisabled"
      :disableDates="calendarDisabledDates"
    />
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import { createPopper } from '@popperjs/core';
import AppCalendar from '@/components/ui/AppCalendar.vue';

export default {
  name: 'AppDatePicker',
  directives: {
    onClickaway
  },
  components: {
    AppCalendar
  },
  props: {
    id: {
      required: true,
      type: String
    },
    today: {
      type: Date,
      default: () => new Date()
    },
    value: {
      type: [Date, String],
      required: true,
      default: ''
    },
    isError: {
      type: Boolean,
      default: false
    },
    calendarDisabled: {
      type: Boolean,
      default: false,
    },
    calendarDisabledDates: {
      type: Array,
      default: () => []
    },
    pickerDisabled: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      isCalendarShow: false,
      year: new Date(this.value).getUTCFullYear(),
      month: new Date(this.value).getUTCMonth(),
      day: new Date(this.value).getUTCDate()
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', `${this.formattedYear}-${this.formattedMonth}-${this.formattedDay}`);
      }
    },
    formattedYear: {
      get() {
        return `0000${this.year}`.substr(-4);
      },
      set(v) {
        const value = Number(v);
        if (!this.checkInputTypeIsNumber(value)
          || !this.checkDayInMonth(value, this.month, this.day)) {
          return;
        }
        this.year = value;
      }
    },
    formattedMonth: {
      get() {
        return `00${this.month + 1}`.substr(-2);
      },
      set(v) {
        const value = Number(v);
        const monthIndex = value - 1;
        if (!this.checkInputTypeIsNumber(value) || !(value <= 12 && value >= 1)
          || !this.checkDayInMonth(this.year, monthIndex, this.day)) {
          return;
        }
        this.month = monthIndex;
      }
    },
    formattedDay: {
      get() {
        return `00${this.day}`.substr(-2);
      },
      set(v) {
        const value = Number(v);
        if (!this.checkInputTypeIsNumber(value)
          || !this.checkDayInMonth(this.year, this.month, value)) {
          return;
        }
        this.day = v;
      }
    },
    dynamicOptions() {
      const element = this.$refs.datePicker;
      const panel = this.$refs.calendarPanel;
      const rect = element.getBoundingClientRect();
      const isFlip = (document.documentElement.clientHeight - rect.bottom < panel.offsetHeight);
      return {
        placement: isFlip ? 'top' : 'bottom',
        enable: true,
        modifiers: [{
          name: 'offset',
          enabled: true,
          options: {
            offset: isFlip ? [70, 25] : [80, 5],
            fallbackPlacements: ['top', 'right']
          }
        }],
      };
    }
  },
  methods: {
    handleBlur(type, ev) {
      if (!type) {
        this.$refs.yearInput.blur();
        this.$refs.monthInput.blur();
        this.$refs.dayInput.blur();
        return;
      }

      if (this.isCalendarShow) {
        this.isCalendarShow = false;
        return;
      }

      this[type](ev);
    },
    openCalendar(ev) {
      const { target } = ev;
      target.select();
      if (!this.isCalendarShow) {
        this.popperElement = createPopper(this.$refs.datePicker, this.$refs.calendarPanel.$el,
          this.dynamicOptions);
        this.isCalendarShow = true;
        this.$nextTick(async () => {
          await this.popperElement.setOptions(this.dynamicOptions);
        });
      }

      this.$refs.calendarPanel.setSelectedDate(this.year, this.month, this.day);
      this.$emit('opened', `${this.formattedYear}-${this.formattedMonth}-${this.formattedDay}`);
    },
    closeCalendarPanel(event) {
      this.isCalendarShow = false;
      this.popperElement = null;
    },
    selectCalendarDate(date) {
      const { year, month, day } = date;
      this.year = year;
      this.month = month;
      this.day = day;
      this.vModel = `${this.year}-${this.month + 1}-${this.day}`;
      this.handleBlur();
    },
    checkInputTypeIsNumber(value) {
      if (value === 0) {
        return false;
      }

      return !Number.isNaN(Number(value));
    },
    checkDayInMonth(year, month, day) {
      const daysInMonth = new Date(year, month + 1, 0).getUTCDate() + 1;
      return day > 0 && day <= daysInMonth;
    },
    checkYearInput(ev) {
      let { value } = ev.target;
      if (!this.checkInputTypeIsNumber(value)) {
        value = this.year;
        this.year = -1;
      }
      this.setDate('year', parseFloat(value));
      this.closeCalendarPanel(ev);
    },
    checkMonthInput(ev) {
      let { value } = ev.target;
      if (!this.checkInputTypeIsNumber(value) || !(value <= 12 && value >= 1)) {
        value = this.month + 1;
        this.month = -1;
      }
      this.setDate('month', parseFloat(value - 1));
      this.closeCalendarPanel(ev);
    },
    checkDayInput(ev) {
      let { value } = ev.target;
      if (!this.checkInputTypeIsNumber(value)
        || !this.checkDayInMonth(this.year, this.month, value)) {
        value = this.day;
        this.day = -1;
      }
      this.setDate('day', parseFloat(value));
      this.closeCalendarPanel(ev);
    },
    setDate(type, number) {
      this[type] = number;
      this.$refs.calendarPanel.setSelectedDate(this.year, this.month, this.day);
      this.vModel = `${this.year}-${this.month + 1}-${this.day}`;
    },
    selectCalendarMonth(value) {
      this.$emit('selectMonth', value);
    }
  },
  watch: {
    value() {
      this.year = new Date(this.value).getUTCFullYear();
      this.month = new Date(this.value).getUTCMonth();
      this.day = new Date(this.value).getUTCDate();
    },
    year(v) {
      this.$nextTick(() => {
        this.setDate('year', v);
      });
    },
    month(v) {
      this.$nextTick(() => {
        this.setDate('month', v);
      });
    },
    day(v) {
      this.$nextTick(() => {
        this.setDate('day', v);
      });
    },
  }
};
</script>

<style lang="less" scoped>
.app-date-picker {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  outline: none;
  .date-input-wrapper {
    background-color: #121212;
    width: rem(148);
    height: rem(30);
    line-height: rem(30);
    display: flex;
    border: rem(1) solid #adadad;
    &:hover {
      border: rem(1) solid #fff;
      color: #fff;
    }
    &.active {
      border: rem(1) solid #2986ff;
      color: #fff;
    }
    .app-time-picker-input {
      padding: 0;
      background-color: #121212;
      border: none;
      font-size: rem(14);
      outline: none;
      width: 100%;
      &.year-input {
        width: rem(35);
        margin-left: rem(12);
      }
      &:not(.year-input) {
        width: rem(23);
        text-align: center;
      }
    }
    &.error {
      border: 1px solid #FFB029;
    }
  }
}
</style>
