const LAYOUT_COUNT_THRESHOLD = 1;

const PLAYBACK_LAYOUT_LIMIT = 4;

const PREFER_MAINSTREAM_LIMIT = 1;

export {
  LAYOUT_COUNT_THRESHOLD,
  PLAYBACK_LAYOUT_LIMIT,
  PREFER_MAINSTREAM_LIMIT,
};
