import MicroEvent from '@vivotek/lib-utility/microevent';

function RTCVolalarmProtocol(channel) {
  this.channel = channel;

  channel.binaryType = 'arraybuffer';

  channel.on('message', (message) => {
    this.trigger('volalarm', message);
  });

  this.send = function send(message) {
    try {
      this.channel.send(message);
    } catch (e) {
      console.warn(e);
    }
  };
}

export default MicroEvent.mixin(RTCVolalarmProtocol);
