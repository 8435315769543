let init = function () {
  const { location } = window;

/* eslint-disable */
// from mixpanel, modified to use https only
(function(e,a){if(!a.__SV){var b=window;try{var c,l,i,j=b.location,g=j.hash;c=function(a,b){return(l=a.match(RegExp(b+"=([^&]*)")))?l[1]:null};g&&c(g,"state")&&(i=JSON.parse(decodeURIComponent(c(g,"state"))),"mpeditor"===i.action&&(b.sessionStorage.setItem("_mpcehash",g),history.replaceState(i.desiredHash||"",e.title,j.pathname+j.search)))}catch(m){}var k,h;window.mixpanel=a;a._i=[];a.init=function(b,c,f){function e(b,a){var c=a.split(".");2==c.length&&(b=b[c[0]],a=c[1]);b[a]=function(){b.push([a].concat(Array.prototype.slice.call(arguments,
0)))}}var d=a;"undefined"!==typeof f?d=a[f]=[]:f="mixpanel";d.people=d.people||[];d.toString=function(b){var a="mixpanel";"mixpanel"!==f&&(a+="."+f);b||(a+=" (stub)");return a};d.people.toString=function(){return d.toString(1)+".people (stub)"};k="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
for(h=0;h<k.length;h++)e(d,k[h]);a._i.push([b,c,f])};a.__SV=1.2;b=e.createElement("script");b.type="text/javascript";b.async=!0;b.src="https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";e.body.appendChild(b)}})(document,window.mixpanel||[]);
// end of copied from mixpanel
  /* eslint-enable */

  if (location.protocol === 'https:' && location.hostname === 'service.vivocloud.com') {
    window.mixpanel.init('e3b914edf2f8ae8adcbd86fad39f981c');
  } else {
    // anywhere other than production site
    window.mixpanel.init('dea369dca32d7164ba1eb9f7bcb029c7');
  }
};

export default {
  track(ev, props) {
    // This configuration will be set in `control/portal.js`,
    // after the privacy policy for the user has been checked
    if (window.VVTK_ENABLE_DATA_COLLECTION !== true) {
      return;
    }
    if (init) {
      init();
      init = null;
    }
    try {
      window.mixpanel.track(ev, props);
    } catch (e) {
      console.log(`Analytic tracking failed: ${String(e)}`);
    }
  }
};
