/* eslint-disable no-bitwise */

import { combineByteArray } from '@vivotek/lib-utility/byteArray';
import NALU_TYPE from './NaluType';

class NALU {
  constructor({
    type, nri, data, dts, pts, applicationData
  }) {
    this.data = data;
    this.type = type;
    this.nri = nri;
    this.dts = dts;
    this.pts = pts || this.dts;
    this.sliceType = null;
    this.applicationData = applicationData;
  }

  appendData(idata) {
    this.data = combineByteArray(this.data, idata);
  }

  get isReferencePicture() {
    return (this.nri >> 5) > 0;
  }

  get isFrame() {
    return this.type === NALU_TYPE.TYPE.IDR || this.type === NALU_TYPE.TYPE.NDR;
  }

  get isKeyframe() {
    return this.type === NALU_TYPE.TYPE.IDR || this.sliceType === 7;
  }

  get isSPS() {
    return this.type === NALU_TYPE.TYPE.SPS;
  }

  get isPPS() {
    return this.type === NALU_TYPE.TYPE.PPS;
  }

  get size() {
    return this.getData().length;
  }

  getData() {
    const header = new Uint8Array(4 + 1 + this.data.byteLength);
    const view = new DataView(header.buffer);
    view.setUint32(0, 1);
    view.setUint8(4, (0x0 & 0x80) | (this.nri & 0x60) | (this.type & 0x1F));
    header.set(this.data, 5);
    return header;
  }
}

export default NALU;
