import { detect } from 'detect-browser';

const browser = detect();
const { name, version } = browser || {};

export default {
  browser: name,
  version,
  isIE: name === 'ie',
  isEdge: name === 'edge' || name === 'edge-chromium',
  isChrome: name === 'chrome',
  isFirefox: name === 'firefox',
  isSafari: name === 'safari',
};
