/**
 * This is to simulate DOM event propagation/bubbling
 * when managed node is triggering an event, the manager(reference) of the node
 * will be informed by the same event with calling its function with same naming.
 * 
 * For example, if a VOAM node is having 'loitering' event(from metadata parsing),
 * it will be notified by calling the VOAM's loitering method with event data
 * 
 * vaom.loitering(data);
 * 
 * then the manager(voam.reference for now) will be called
 * 
 * vaom.reference.loitering(data);
 */
export default class EventInterface {
  constructor() {
    this.list = [];
  }

  register(target) {
    this.list[EventInterface.IDENTIFYING_STRATEGY(target)] = target;
    return this;
  }

  dispatch(evt, identifier, ...args) {
    let target = this.list[identifier];
    if (target && target[evt]) {
      target[evt](...args);
      if (target.reference && target.reference[evt]) {
        // XXX: This is to simulate event propagation/bubbling; we should do it more generally later.
        target.reference[evt](target.reference, ...args);
      }
    } else {
      console.warn('no instance', identifier);
    }
  }

  destroy() {
    this.list = [];
  }
}

EventInterface.IDENTIFYING_STRATEGY = (object) => (object.name || object.id);

