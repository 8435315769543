import NOTIFICATION_COUNT from '@/constants/notificationCount';
import ClientNotification from '@/models/ClientNotification';

const mutations = {
  updateNotifications(state, { notifications, timezone, encoders }) {
    state.info = notifications.sort((a, b) => b.us_timestamp - a.us_timestamp)
      .splice(0, NOTIFICATION_COUNT).map((info) => new ClientNotification(info, timezone, encoders));
  },
  addNewNotification(state, { notification, timezone, encoders }) {
    state.info.splice(0, 0, new ClientNotification(notification, timezone, encoders));
  },
  updateLatestNotificationsCount(state, count) {
    state.latestNotificationsCount = count;
  },
  setNotificationAsRead(state, id) {
    if (id === 0) {
      // mark all notification as read
      state.info.forEach((item) => {
        item.isRead = true;
      });
      return;
    }

    state.info.find((item) => item.notificationId === id).isRead = true;
  }
};

export default mutations;
