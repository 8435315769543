import SystemApi from '@/services/SystemApi';

const actions = {
  fetchSystemConfigure({ commit }) {
    return SystemApi.getSystemConfigure()
      .then((result) => {
        const parsedConfigure = {};
        result.split(';').forEach((config) => {
          const [key, value] = config.trim().split(' = ');
          if (key && value) {
            parsedConfigure[key] = value.replace(/^"|"$/g, '');
          }
        });
        commit('setSystemConfigure', parsedConfigure);
      });
  },
  fetchSystemCapability({ commit }) {
    return SystemApi.getSystemCapability()
      .then((result) => {
        commit('setSystemCapability', result);
      });
  },
  fetchSystemInfo({ commit }) {
    return SystemApi.getSystemInfo()
      .then((info) => {
        commit('setSystemInfo', info);
      });
  },
  fetchSystemTime({ commit }) {
    return SystemApi.getSystemTime()
      .then((info) => {
        commit('setSystemTimeInfo', info);
        commit('search/setInitialTimeFrame', info, { root: true });
        commit('ai/setInitialTimeFrame', info, { root: true });
      });
  },
  updateSystemInfo({ commit, state, dispatch }, info) {
    return SystemApi.updateSystemInfo(Object.assign(state.info, { ...info }))
      .then(() => {
        dispatch('fetchSystemInfo');
      });
  },
  pollingSystemTime({ state, dispatch }) {
    if (state.pollingId > 0) {
      return;
    }
    state.pollingId = setInterval(() => {
      dispatch('fetchSystemTime');
    }, 60 * 1000);
  }
};

export default actions;
