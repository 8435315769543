/* eslint-disable no-bitwise */
class RTP {
  constructor(packet/* uint8array */, sdp) {
    // ref: https://datatracker.ietf.org/doc/html/rfc3550
    const bytes = new DataView(packet.buffer, packet.byteOffset, packet.byteLength);
    this.version = bytes.getUint8(0) >> 6;
    this.padding = (bytes.getUint8(0) >> 5) & 1;
    this.hasExtension = (bytes.getUint8(0) >> 4) & 1;
    this.csrc = bytes.getUint8(0) & 0x0F;
    this.marker = bytes.getUint8(1) >> 7;
    this.payloadType = bytes.getUint8(1) & 0x7F;
    this.sequence = bytes.getUint16(2);
    this.timestamp = bytes.getUint32(4);
    this.ssrc = bytes.getUint32(8);
    this.csrcs = [];

    let packetIndex = 12;
    if (this.csrc > 0) {
      this.csrcs.push(bytes.getUint32(packetIndex));
      packetIndex += 4;
    }

    if (this.hasExtension === 1) {
      this.extension = bytes.getUint16(packetIndex);
      this.extensionHeaderLength = bytes.getUint16(packetIndex + 2);
      const extensionData = packet.slice(
        packetIndex + 4, packetIndex + 4 + this.extensionHeaderLength * 4,
      );
      this.extensionDataList = [];
      let index = 0;
      while (index < extensionData.length) {
        const readByte = extensionData[index];
        if (readByte === 0) {
          index += 1; // find first byte is not 0
        } else {
          const extensionType = extensionData[index];
          // const lengthTag = extensionData[index + 1] >> 7;
          const length = extensionData[index + 1] & 0x7F;
          const data = extensionData.slice(index, index + 2 + length);
          this.extensionDataList.push(data);
          if (extensionType === 0x01) { // 0x01 means Application data extension type
            const array = new Uint8Array(2 + data.length);
            array.set(data, 2);
            this.applicationData = array;
          }
          index += length + 2;
        }
      }
      packetIndex += 4 + extensionData.length;
    }

    this.headerLength = packetIndex;
    this.padLength = 0;
    if (this.padding) {
      this.padLength = bytes.getUint8(packet.byteLength - 1);
    }

    this.media = sdp.getMediaBlockByPayloadType(this.payloadType);
    this.data = packet.subarray(packetIndex);
  }

  get trackID() {
    return this.media?.trackID;
  }

  get isVideo() {
    return this.media?.type === 'video';
  }

  get isAudio() {
    return this.media?.type === 'audio';
  }

  get encodingName() {
    if (!this.isVideo && !this.isAudio) {
      return null;
    }
    return this.media.rtpmap[this.payloadType].encodingName;
  }

  getPayload() {
    return this.data;
  }

  getExtensionHeaderData() {
    return this.extensionDataList;
  }

  getApplicationData() {
    return this.applicationData;
  }

  getTimestampMS() {
    return this.timestamp;
  }

  toString() {
    return `${'RTP('
            + 'version:'}${this.version}, `
            + `padding:${this.padding}, `
            + `has_extension:${this.hasExtension}, `
            + `csrc:${this.csrc}, `
            + `marker:${this.marker}, `
            + `payloadType:${this.payloadType}, `
            + `sequence:${this.sequence}, `
            + `timestamp:${this.timestamp}, `
            + `ssrc:${this.ssrc})`;
  }
}

export default RTP;
