<template>
  <div class="header">
    <div class="left inner-wrapper">
      <AppLogo />
      <h5 class="semi-bold title">
        {{ modelName }}
      </h5>
    </div>
    <div class="right inner-wrapper">
      <div class="right-button-list">
        <AppHeaderBuzzerButton />
        <AppHeaderStorageButton />
        <AppHeaderNotificationButton />
        <AppHeaderAccountButton />
      </div>
      <SystemTimeDisplay />
    </div>
  </div>
</template>

<script>
import AppLogo from '@/components/AppLogo.vue';
import SystemTimeDisplay from '@/components/SystemTimeDisplay.vue';
import AppHeaderAccountButton from '@/components/AppHeaderAccountButton.vue';
import AppHeaderNotificationButton from '@/components/AppHeaderNotificationButton.vue';
import AppHeaderBuzzerButton from '@/components/AppHeaderBuzzerButton.vue';
import AppHeaderStorageButton from '@/components/AppHeaderStorageButton.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AppHeader',
  components: {
    AppLogo,
    SystemTimeDisplay,
    AppHeaderAccountButton,
    AppHeaderNotificationButton,
    AppHeaderBuzzerButton,
    AppHeaderStorageButton,
  },
  data() {
    return {
      modelName: '',
    };
  },
  methods: {
    ...mapActions('websocket', { fetchWebsocket: 'fetch' }),
    ...mapActions('system', ['fetchSystemInfo', 'pollingSystemTime']),
    getSystemInfo() {
      return this.fetchSystemInfo().then(() => {
        this.modelName = this.system.info.extended_model_name;
        document.title = `${this.system.info.brand} ${this.system.info.extended_model_name}`;
      });
    },
  },
  computed: {
    ...mapState(['system'])
  },
  mounted() {
    this.pollingSystemTime(); // polling time to refresh session
    this.getSystemInfo().then(this.fetchWebsocket());
  },
};
</script>

<style lang="less" scoped>
.left.inner-wrapper, .right.inner-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  min-width: -webkit-min-content; /* Workaround to Chrome bug */
}
.right.inner-wrapper {
  justify-content: flex-end;
}

.header {
  height: rem(48);
  background-color: #202020;
  display: flex;
  justify-content: space-between;
  border-bottom: rem(1px) solid #000000;
  .title {
    overflow: hidden;
    line-height: rem(48);
  }
  .right-button-list {
    padding: 0 rem(15);
    display: flex;
    .right-button {
      display: flex;
      height: 100%;
      width: rem(48);
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      &:hover {
        background-color: @general-hover-background-color;
      }
    }
  }
}
</style>
