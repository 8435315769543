import Vue from 'vue';
import Vuex from 'vuex';
import user from './user';
import ui from './ui';
import websocket from './websocket';
import system from './system';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    ui,
    websocket,
    system,
  },
});


const LAZYLOAD_MODULES = [
  'encoder',
  'search',
  'scene',
  'playback',
  'exportRecording',
  'ai',
  'snapshot',
  'notification'
];

/* eslint-disable */
store.loadStore = () => {
  return Promise.all(LAZYLOAD_MODULES.map((module) => {
    return import(`@/store/${module}`).then((theModule) => {
      store.registerModule(module, theModule.default);
    });
  }));
};
/* eslint-enable */

export default store;
