import LAYOUT_COUNT from '@/constants/layoutCount';

const defaultConfig = {
  cameraType: true,
  cameraName: true,
  cameraIp: true,
  cameraTime: true,
};

export default {
  getLayoutDisplayConfig(layout) {
    const viewCellCount = LAYOUT_COUNT[layout];
    return [...Array(viewCellCount).keys()].map((viewIndex) => {
      if (layout === '1x1' || layout === '2x2') {
        return defaultConfig;
      }
      if (layout === '3x3' || layout === '4x4' || layout === '1V+6' || layout === '2V+3' || layout === '3V') {
        return { ...defaultConfig, cameraTime: false };
      }
      if (layout === '5x5') {
        return {
          ...defaultConfig, cameraName: false, cameraIp: false, cameraTime: false
        };
      }
      if (layout === '8x8') {
        return {
          cameraType: false,
          cameraName: false,
          cameraIp: false,
          cameraTime: false,
        };
      }
      if (layout === '1M+5' || layout === '1M+7' || layout === '1M+12'
        || layout === '1P+3' || layout === '1P+6') {
        if (viewIndex === 0) {
          return defaultConfig;
        }
        return { ...defaultConfig, cameraTime: false };
      }
      if (layout === '1m+31') {
        if (viewIndex === 0) {
          return { ...defaultConfig, cameraTime: false };
        }
        return {
          cameraType: false,
          cameraName: false,
          cameraIp: false,
          cameraTime: false,
        };
      }
      if (layout === '2P+3') {
        if (viewIndex === 0 || viewIndex === 1) {
          return defaultConfig;
        }
        return { ...defaultConfig, cameraTime: false };
      }
      return {

      };
    });
  }
};
