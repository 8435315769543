import { render, staticRenderFns } from "./AppHeaderAccountButton.vue?vue&type=template&id=3fd39692&scoped=true&"
import script from "./AppHeaderAccountButton.vue?vue&type=script&lang=js&"
export * from "./AppHeaderAccountButton.vue?vue&type=script&lang=js&"
import style0 from "./AppHeaderAccountButton.vue?vue&type=style&index=0&id=3fd39692&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../.yarn/__virtual__/vue-loader-virtual-783d792af9/0/cache/vue-loader-npm-15.10.0-778eb54c35-1bf6c9ffea.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fd39692",
  null
  
)

export default component.exports