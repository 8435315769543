/* eslint-disable no-bitwise */
export const combineByteArray = function appendByteArray(buffer1, buffer2) {
  const tmp = new Uint8Array((buffer1.byteLength | 0) + (buffer2.byteLength | 0));
  tmp.set(buffer1, 0);
  tmp.set(buffer2, buffer1.byteLength | 0);
  return tmp;
};

// duplicated
export const combineBuffer = function combineBuffer(buffer1, buffer2) {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
};

export const base64ToArrayBuffer = function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const hexToByteArray = function hexToByteArray(hex) {
  const len = hex.length >> 1;
  const bufView = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bufView[i] = parseInt(hex.substr(i << 1, 2), 16);
  }
  return bufView;
};
