<template>
  <div class="block wrap">
    <div class="block-line flex-center">
      <PTZMoveControl @move="$_ptzMoveHandler" />
    </div>
    <div class="block-line">
      <h6 class="bold">
        {{ $t('Zoom') }}
      </h6>
    </div>
    <div class="block-line">
      <button
        class="secondary layout large"
        @click="$_zoomClickHandler('out')"
      >
        <SvgIcon
          iconClass="general_zoom_out"
          size="20px"
        />
      </button>
      <button
        class="secondary layout large"
        @click="$_zoomClickHandler('in')"
      >
        <SvgIcon
          iconClass="general_zoom_in"
          size="20px"
        />
      </button>
    </div>
    <div class="block-line">
      <h6 class="bold">
        {{ $t('Focus') }}
      </h6>
    </div>
    <div class="block-line">
      <button
        class="secondary layout large"
        @click="$_focusClickHandler('far')"
      >
        <SvgIcon
          iconClass="general_focus_out"
          size="20px"
        />
      </button>
      <button
        class="secondary layout large"
        @click="$_focusClickHandler('near')"
      >
        <SvgIcon
          iconClass="general_focus_in"
          size="20px"
        />
      </button>
    </div>
    <div class="block-line">
      <button
        class="ghost layout large"
        @click="$_focusClickHandler('auto')"
      >
        {{ $t('Auto') }}
      </button>
    </div>
    <div class="block-line">
      <h6 class="bold">
        {{ $t('Pan & Patrol & Track') }}
      </h6>
    </div>
    <div class="block-line">
      <button
        class="secondary layout medium pan-patrol-button"
        @click="$_panClickHandler('start')"
      >
        {{ $t('Pan') }}
      </button>
      <button
        class="secondary layout medium"
        @click="$_patrolClickHandler('start')"
      >
        {{ $t('Patrol') }}
      </button>
    </div>
    <div class="block-line">
      <button
        class="ghost layout"
        @click="$_panClickHandler('stop')"
      >
        {{ $t('Stop') }}
      </button>
    </div>
    <div class="block-line">
      <h6 class="bold">
        {{ $t('Preset') }}
      </h6>
    </div>
    <div class="block-line">
      <AppDropdown
        :options="presetOptions"
        :disabled="presetOptions.length === 0"
        v-model="currentPreset"
        :placeholder="presetPlaceHolder"
      />
    </div>
  </div>
</template>

<script>
import AppDropdown from '@/components/ui/AppDropdown.vue';
import CameraPTZApi from '@/services/CameraPTZApi';
import PTZMoveControl from './PTZMoveControl.vue';

export default {
  name: 'ControlControlPanelPTZ',
  components: {
    PTZMoveControl,
    AppDropdown,
  },
  props: {
    channel: {
      type: Number,
      required: true
    },
    presetOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentPreset: '',
    };
  },
  methods: {
    $_ptzMoveHandler(position) {
      return CameraPTZApi.move(this.channel, position).then(() => {
        // wip
      });
    },
    $_panClickHandler(params) {
      return CameraPTZApi.panControl(this.channel, params).then(() => {
        // wip
      });
    },
    $_patrolClickHandler(params) {
      return CameraPTZApi.patrol(this.channel, params).then(() => {
        // wip
      });
    },
    $_zoomClickHandler(params) {
      return CameraPTZApi.zoom(this.channel, params).then(() => {
        // wip
      });
    },
    $_focusClickHandler(params) {
      return CameraPTZApi.focus(this.channel, params).then(() => {
        // wip
      });
    },
  },
  computed: {
    presetPlaceHolder() {
      return this.presetOptions.length === 0 ? this.$t('No preset') : this.$t('Select 1 preset');
    }
  },
  watch: {
    currentPreset(preset) {
      if (!this.currentPreset) {
        return;
      }

      CameraPTZApi.preset(this.channel, preset);
      this.currentPreset = '';
    }
  },
};
</script>

<style lang="less" scoped>
.block-line {
  > button:not(:last-child) {
    margin-right: rem(1);
    &.pan-patrol-button{
      margin-right: 20px;
    }
  }
}

.app-dropdown-wrapper{
  width: 100%;
}

.block-line.flex-center{
  justify-content: center;
}
</style>
