// Commented code is for collecting translation e.g. $t('xxxx')
// Add a new value with same comment with $t('xxxxx') format
const TMIS_EVENT_TYPE = {
  // $t('Brute force attack'),
  // $t('Cyber attack'),
  // $t('Quarantine event'),
  0: 'Brute force attack',
  1: 'Cyber attack',
  2: 'Quarantine event',
};

const VCA_EVENT_TYPE = {
  // $t('Line crossing detection'),
  // $t('Loitering detection'),
  // $t('Intrusion detection'),
  // $t('Crowd detection'),
  // $t('Face detection'),
  // $t('Missing object detection'),
  // $t('Unattended object detection'),
  // $t('Running detection'),
  // $t('Restricted zone detection'),
  // $t('Parking violation detection'),
  // $t('Smart tracking advanced'),
  LineDetection: 'Line crossing detection',
  Loitering: 'Loitering detection',
  FieldDetection: 'Intrusion detection',
  CrowdDetection: 'Crowd detection',
  FaceDetection: 'Face detection',
  MissingObject: 'Missing object detection',
  AbandonObject: 'Unattended object detection',
  RunningDetection: 'Running detection',
  RestrictedZoneDetection: 'Restricted zone detection',
  ParkingViolationDetection: 'Parking violation detection',
  SmartTrackingDetection: 'Smart tracking advanced',
};

export {
  TMIS_EVENT_TYPE,
  VCA_EVENT_TYPE
};
