/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */

/**
 * 'develop mode' can set variables in the file '.env', for example:
 *   VUE_APP_LOG_LEVEL=Debug
 *   VUE_APP_LOG_ENABLE=true
 *
 * 'production mode' can set variables in the file '.env.production'
 *   VUE_APP_LOG_LEVEL=Warn
 *   VUE_APP_LOG_ENABLE=true
 *
 * and You can enable Logger by devtools' consle in browser, such as
 * > console.Logger.setEnable(true)
 * > console.Logger.setLevel('Debug')
 *
 * If you want to filter logs by Tag:
 * > console.Logger.addFilterTag('xxx')
 * > console.Logger.removeFilterTag('xxx')
 */

const start = new Date().getTime();

// ERROR=0, WARN=1, LOG=2, DEBUG=3
const LogLevel = {
  ERROR: 0,
  WARN: 1,
  LOG: 2,
  DEBUG: 3,
};

const LEVEL_MAP = {
  [LogLevel.DEBUG]: 'log',
  [LogLevel.LOG]: 'log',
  [LogLevel.WARN]: 'warn',
  [LogLevel.ERROR]: 'error',
};

class Logger {
  constructor(level, tag) {
    this.tag = tag;
    this.setLevel(level);
  }

  setLevel(level = '') {
    this.level = LogLevel[level.toUpperCase()];
  }

  static setEnable(value = true) {
    Logger.enable = JSON.parse(value);
  }

  static setLevel(level = 'error') {
    Logger.level = level;
  }

  static getLevelList() {
    return Object.keys(LogLevel).map((key) => key.toLowerCase());
  }

  static addFilterTag(tag) {
    Logger.filterTagList.push(tag);
  }

  static deleteFilterTag(tag) {
    Logger.filterTagList.splice(Logger.filterTagList.indexOf(tag), 1);
  }

  print(lvl, args) {
    const consoleArgs = Array.prototype.slice.call(args);

    if (this.tag) {
      consoleArgs.unshift(`[${this.tag}]`);
    }
    consoleArgs.unshift(`[${((new Date().getTime() - start) / 1000).toFixed(2)}]`);

    const level = this.level !== undefined ? this.level : LogLevel[Logger.level.toUpperCase()];
    const { enable, filterTagList, whiteTagList } = Logger;

    if (enable && level >= lvl) {
      if (whiteTagList.length) {
        if (whiteTagList.some((t) => this.tag.indexOf(t) >= 0)) {
          console[LEVEL_MAP[lvl]].apply(console, consoleArgs);
        }
      } else if (filterTagList.indexOf(this.tag) < 0) {
        console[LEVEL_MAP[lvl]].apply(console, consoleArgs);
      }
    }
  }

  log() {
    this.print(LogLevel.LOG, arguments);
  }

  debug() {
    this.print(LogLevel.DEBUG, arguments);
  }

  error() {
    this.print(LogLevel.ERROR, arguments);
  }

  warn() {
    this.print(LogLevel.WARN, arguments);
  }
}

Logger.setEnable(process?.env?.VUE_APP_LOG_ENABLE);
Logger.setLevel(process?.env?.VUE_APP_LOG_LEVEL);

Object.keys(LogLevel).forEach((level) => {
  Logger[level] = level.toLowerCase();
});

Logger.filterTagList = [];
Logger.whiteTagList = [];

if (typeof window !== 'undefined' && window.localStorage) {
  if (window.localStorage.getItem('logger/whitelist') !== null) {
    Logger.whiteTagList = JSON.parse(window.localStorage.getItem('logger/whitelist'));
  }
}

const taggedLoggers = new Map();
function getTagged(tag, level) {
  if (!taggedLoggers.has(tag)) {
    taggedLoggers.set(tag, new Logger(level, tag));
  }
  return taggedLoggers.get(tag);
}

if (console) {
  console.Logger = Logger;
}

export {
  getTagged,
  Logger,
};
