<template>
  <div class="searchable-device-list-container">
    <div class="search-device-content">
      <div class="search-input-fields-wrapper">
        <AppInputFields
          class="search-input"
          v-model="searchName"
          id="search-device-fields"
          :searchable="true"
          :disabled="disabled || availableEncoders.length === 0"
        />
      </div>
      <AppDeviceListFilterButton
        class="filter-button"
        v-model="filterType"
        :disabled="disabled || availableEncoders.length === 0"
      />
    </div>
    <div class="device-content">
      <div
        class="empty-block"
        v-show="hasNoSearchedResult"
      >
        <div
          class="icon search-failed-icon"
        />
        <h6>{{ $t('No search results') }}</h6>
      </div>
      <div
        class="empty-block"
        v-show="!hasNoSearchedResult && encoders.length === 0"
      >
        <div
          class="icon no-camera-icon"
        />
        <h6>{{ $t('Add camera for easier live monitoring') }}</h6>
        <button
          class="ghost medium-secondary"
          @click="addDevice"
          v-if="isAdministrator"
        >
          {{ $t('Add camera here') }}
        </button>
      </div>
      <AppDeviceList
        v-show="encoders.length !== 0"
        :disabled="disabled"
        :draggable="draggable"
        :multiSelectable="multiSelectable"
        :selectedCountLimit="selectedCountLimit"
        :devicesData="encoders"
        :activeDeviceIndex="activeDeviceIndex"
        :defaultSelectedDevices="defaultSelectedDevices"
        @selectDevice="selectDevice"
        @confirmDevice="confirmDevice"
        @toggleSelectedDevices="toggleSelectedDevices"
      />
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import AppInputFields from '@/components/ui/AppInputFields.vue';
import AppDeviceList from '@/components/AppDeviceList.vue';
import AppDeviceListFilterButton from '@/components/AppDeviceListFilterButton.vue';

export default {
  name: 'SearchableDeviceList',
  props: {
    activeDeviceIndex: {
      type: [Number],
      default: -1,
    },
    multiSelectable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    selectedCountLimit: {
      type: Number,
      default: Infinity
    },
    defaultSelectedDevices: {
      type: Array,
      default: () => []
    }
  },
  components: {
    AppInputFields,
    AppDeviceList,
    AppDeviceListFilterButton,
  },
  data() {
    return {
      searchName: '',
      filterType: 'index',
    };
  },
  computed: {
    ...mapGetters('user', ['isAdministrator']),
    ...mapGetters('encoder', ['availableEncoders', 'connectedEncoders', 'filteredEncoders']),
    encoders() {
      return this.filteredEncoders(this.filterType, this.searchName);
    },
    hasNoSearchedResult() {
      return this.encoders.length === 0 && this.availableEncoders.length !== 0;
    },
  },
  methods: {
    selectDevice(deviceIndex) {
      return this.$emit('selectDevice', deviceIndex);
    },
    toggleSelectedDevices({ selectedDevices, index }) {
      this.$emit('toggleSelectedDevices', { selectedDevices, index });
    },
    addDevice() {
      window.open(`${window.location.origin}/settings.html#!settings/settings_camera_management`);
    },
    confirmDevice(deviceIndex) {
      return this.$emit('confirmDevice', deviceIndex);
    },
  },
};

</script>

<style lang="less" scoped>
.searchable-device-list-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: rem(1) solid #000;
  flex: 1;
}

.search-device-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(15) rem(18);
  border-bottom: rem(1) solid #000;

  .search-input-fields-wrapper {
    flex: 1;
  }

  .filter-button {
    flex: 0;
    margin-left: rem(10);
  }
}

.device-content {
  padding: rem(10) 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > div:nth-child(3) {
    flex: 1;
    overflow-y: auto;
  }
}

.empty-block {
  padding: 0 rem(20);
  .icon {
    margin-top: rem(40);
    &.no-camera-icon {
      background-image: url('../assets/illustration_no_camera.svg');
    }
  }
}
</style>
